import React, { useContext, useEffect, useRef, useState } from 'react'
import Menubar from '../Menubar/Menubar'
import SnackbarToast from '../../utils/SnackbarToast';
import $ from 'jquery';
import axiosInstance from '../../Axios/axios';
import LoadingSpinner from '../../utils/LoadingSpinner';
import EmailTemplate from './EmailTemplate/EmailTemplate';
import PreviewTemplateDialog from './PreviewTemplateDialog/PreviewTemplateDialog';
import HTMLReactParser from 'html-react-parser';
import { Button, Chip } from '@material-ui/core';
import {AuthContext} from '../../Context/userContext';
import { ContactMail } from '@material-ui/icons';
import CKEditor from 'ckeditor4-react';
import logger from '../../utils/logger';
import { API_VERSION, BASE_URL } from '../../Axios/api';

function EmailCampaign(props) {
    
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [openPreviewTemplateDialog, setOpenPreviewTemplateDialog] = useState(false);
    const [previewTemplateContent, setPreviewTemplateContent] = useState('');
    const [activeTemplateId, setActiveTemplateId] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [templateContent, setTemplateContent] = useState(null);
    const [changeTemplateIntoEditMode, setChangeTemplateIntoEditMode] = useState(false);

    const userContext = useContext(AuthContext);

    useEffect(() => {

        if (window.CKEDITOR && window.CKEDITOR.instances.editor && previewTemplateContent) {

            window.CKEDITOR.instances.editor.setData(HTMLReactParser(previewTemplateContent));
        }

        if (!window.CKEDITOR && previewTemplateContent) {
        
            loadCkEditor();
        }

    }, [previewTemplateContent]);

    const loadCkEditor = () => {

        const script = document.createElement('script');
        script.src = 'https://cdn.ckeditor.com/4.16.0/standard-all/ckeditor.js';
        script.async = true;
        document.head.appendChild(script);
        script.addEventListener('load', onLoadCkEditor);

    }

    const onLoadCkEditor = () => {

        window.CKEDITOR.replace('editor', {
            fullPage: true,
            extraPlugins: 'docprops',
            allowedContent: true,
            height: 500
        });
    };

    useEffect(() => {
        if (window.mainInitAll) {
            window.mainInitAll();
        }

        onToggleMobileSidebar();

        $('.content-wrapper').on('click', function (e) {

            onToggleMobileSidebar();

        });

        const getTemplates = async () => {

            const token = localStorage.getItem("token");

            if (token) {

                setIsLoading(true);
                const response = await axiosInstance.get('/getTemplate', {
                    headers: {
                        Authorization: "Bearer " +token
                    }
                })
                .catch(err => {

                    if (err.response && err.response.data.status == 401) {

                        props.history.push('/login');
                        localStorage.clear();
                        setIsLoading(false);
                        return;
                    }
                })

                if (response && response.data.status === 200) {

                    if (localStorage.getItem("user_role") == "Admin") {

                        setTemplates(!response.data.payload.data ? [] : response.data.payload.data);
                        setIsLoading(false);
                        return;
                    }

                    const templatesByUserResponse = await axiosInstance.post('/getTemplateByUser');

                    if (templatesByUserResponse.data.status === 200) {

                        let userTemplates = [];
                        for (let templates of templatesByUserResponse.data.payload.data) {

                            if (Array.isArray(templates)) {

                                for (let template of templates) {

                                    userTemplates.push(template);
                                }
                            }

                            userTemplates.push(templates);
                        }
                        
                        const templatesByUser = response.data.payload.data && response.data.payload.data.filter(template => userTemplates.findIndex(temp => temp.templateId == template.ID) !== -1)
                        setTemplates(!templatesByUser ? [] : templatesByUser);
                        setIsLoading(false);                        
                    }
                }
            }

            else {

                props.history.push('/login');
                localStorage.clear();
            }
        }
        
        getTemplates();

    }, []);

    const onToggleMobileSidebar = () => {

        if ($('.sidebar-main').width() == 56) {

            $('.content-wrapper').addClass('content-wrapper-collapse');
        }

        if ($('body').hasClass('sidebar-mobile-main')) {

            $('body').removeClass('sidebar-mobile-main');
        }

        $('.sidebar-main').removeClass('sidebar-expand-toggle');
    }
    
    const onShowSnackbarWithDetails = (snackbarType, snackbarMessage, isSnackbarOpen) => {

        setSnackbarType(snackbarType);
        setOpenSnackbar(isSnackbarOpen);
        setMessageForSnackbar(snackbarMessage);
    }

    const handleSnackbarClose = () => {

        setOpenSnackbar(false);
        setSnackbarType("");
        setMessageForSnackbar("");
    }

    const onClosePreviewTemplateDialog = () => {

        setOpenPreviewTemplateDialog(false);
        setPreviewTemplateContent('');
        setActiveTemplateId(null);
    }

    const onOpenPreviewTemplateDialog = async (templateId) => {

        const token = localStorage.getItem("token");

        if (token) {

            setIsLoading(true);
            const response = await axiosInstance.get('/getTemplateDetail/' + templateId, {
                headers: {
                    Authorization: "Bearer " +token
                }
            })
            .catch(err => {

                if (err.response && err.response.data.status == 401) {

                    props.history.push('/login');
                    localStorage.clear();
                    setIsLoading(false);
                    return;
                }
            })

            if (response && response.data.status === 200) {

                setActiveTemplateId(templateId);
                setPreviewTemplateContent(response.data.payload.data[0]["Html-part"]);
                setIsLoading(false);
                setOpenPreviewTemplateDialog(true);
            }
        }

        else {

            props.history.push('/login');
            localStorage.clear();
        }
    }

    const onSelectTemplateHandler = (templateId) => {

        setSelectedTemplateId(templateId);
    }

    const onSendEmailCampaignHandler = async () => {

        if (!selectedTemplateId) {

            onShowSnackbarWithDetails("info", "Choose Template from below in order to send emails!", true);
        }

        else if (userContext.contactIds.length === 0) {

            onShowSnackbarWithDetails("info", "Select Contacts from Contacts Page and come back!", true);
        }

        else {

            const token = localStorage.getItem("token");

            if (token) {

                setIsLoading(true);
                const response = await axiosInstance.post('/sendEmailByTemplate/', {
                    "template_id": selectedTemplateId,
                    "contact_ids": userContext.contactIds
                } ,{
                    headers: {
                        Authorization: "Bearer " +token
                    }
                })
                    .then(
                        logger({
                            title:'send Email By Template',
                            url:window.location.href,
                            method:'POST',
                            activity: 'sendEmailByTemplate',
                            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                            api:BASE_URL+ API_VERSION +'/sendEmailByTemplate' ,
                            session:localStorage.getItem('session'),
                            projectId:null,
                            unitId:null,
                            assignedId:null,
                            })      
                    )    
                .catch(err => {

                    if (err.response && err.response.data.status == 401) {

                        props.history.push('/login');
                        localStorage.clear();
                        setIsLoading(false);
                        return;
                    }
                })

                if (response && response.data.status === 200) {

                    setSelectedTemplateId(null);
                    props.setContactIds([]);
                    setIsLoading(false);
                    onShowSnackbarWithDetails("success", "Email(s) sent successfully!", true);
                }
            }

            else {

                props.history.push('/login');
                localStorage.clear();
            }
        }
    }

    const onClearSelectedContacts = () => {

        props.setContactIds([]);

    }
    
    return (
        
        <div className="page-content">
            <Menubar/>
            <div className="content-wrapper">
                <div className="page-header page-header-light">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Home</span> - Email Campaign</h4>
                        </div>
                    </div>

                    <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                        <div className="d-flex">
                            <div className="breadcrumb">
                                <a href=" " className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</a>
                                <span className="breadcrumb-item active">Email Campaign</span>
                            </div>

                            <a href=" " className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                    </div>
                </div>

                <div className="content">

                    <div className="flex-wrap d-flex align-items-center">
                        
                        { templates.length > 0 &&
                            <Button 
                                className="mb-3 mr-3"  
                                onClick={onSendEmailCampaignHandler} 
                                variant="contained" 
                                style={{backgroundColor:"#26a69a", color:"white"}}
                            >
                                Send Email
                            </Button>
                        }

                        { templates.length > 0 && userContext.contactIds.length > 0 &&

                            <Chip
                                icon={<ContactMail style={{color: "white"}}/>}
                                style={{
                                    backgroundColor: "#324148", 
                                    color: "white", 
                                    padding: "10px", 
                                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                                }}
                                className="contact-chip mb-3"
                                label={`${userContext.contactIds.length === 1 ? userContext.contactIds.length + ' Contact selected' : userContext.contactIds.length + ' Contacts selected'}`}
                                onDelete={onClearSelectedContacts}
                            />
                        }
                    </div>

                    <div className="row m-0">

                        { templates.length > 0 ? 
                        
                            templates.map(template => (

                                    <EmailTemplate
                                        key={template.ID}
                                        templateId={template.ID}
                                        templateName={template.Name}
                                        templateAuthor={template.Author}
                                        categories={template.Categories}
                                        purposes={template.Purposes}
                                        onSelectTemplateHandler={onSelectTemplateHandler}
                                        selectedTemplateId={selectedTemplateId}
                                        onOpenPreviewTemplateDialog={onOpenPreviewTemplateDialog}
                                    />
                                ))

                                : 

                            !isLoading && templates.length === 0 ?

                                <h6>No Templates Found.</h6>

                                : 

                                null
                        
                        }

                        <PreviewTemplateDialog
                            openPreviewTemplateDialog={openPreviewTemplateDialog}
                            onClosePreviewTemplateDialog={onClosePreviewTemplateDialog}
                            templateId={activeTemplateId}
                            previewTemplateContent={previewTemplateContent}
                            setChangeTemplateIntoEditMode={setChangeTemplateIntoEditMode}
                            changeTemplateIntoEditMode={changeTemplateIntoEditMode}
                            setTemplateContent={setTemplateContent}
                        />
                        
                        <SnackbarToast
                            handleSnackbarClose={handleSnackbarClose}
                            openSnackbar={openSnackbar}
                            snaSure nckbarType={snackbarType}
                            message={messageForSnackbar}
                        />

                        <LoadingSpinner isLoading={isLoading} />

                        {/* <CKEditor
                            data={HTMLReactParser(previewTemplateContent)}
                        /> */}

                        {/* <div id="editor">
                            {HTMLReactParser(previewTemplateContent)}
                        </div>  */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailCampaign;
