import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'

import React from 'react'

function DeleteTabDialog({
    openDeleteTabDialog,
    onCloseDeleteTabDialog,
    onDeleteSelectedTab
}) {

    return (

        <Dialog
        
            open={openDeleteTabDialog}
        
            onClose={onCloseDeleteTabDialog}
        
            aria-labelledby="alert-dialog-title"
        
            aria-describedby="alert-dialog-description"
        
        >
        
            <DialogContent>
        
                <DialogContentText id="alert-dialog-description">
        
                Do you want to delete this Tab? This action cannot be
                undone.
        
                </DialogContentText>
        
            </DialogContent>
        
            <DialogActions>
        
                <Button
        
                onClick={onCloseDeleteTabDialog}
        
                color="primary"
        
                >
        
                No
        
                </Button>
        
                <Button
        
                onClick={onDeleteSelectedTab}
        
                color="primary"
        
                autoFocus
        
                >
        
                Yes
        
                </Button>
        
            </DialogActions>
        
        </Dialog>
    
    )

}

export default DeleteTabDialog
