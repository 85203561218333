import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const ProtectedRoute = ({component:Component,user, ...rest}) => {
  return (
    <Route {...rest} render={
      props => {
        if(localStorage.getItem('token') && localStorage.getItem('METROENGINE-USER')){
          return <Component {...rest} {...props} />
        }
       return <Redirect to='/login' />
    }
    } />
  )
}

export default ProtectedRoute
