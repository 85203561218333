import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Checkbox, Button, Slide } from "@material-ui/core";

import "../../Assets/css/iofrm-style.css";
import "../../Assets/css/iofrm-theme1.css";
import image1 from "../../Assets/img/graphic2.svg";
import axiosInstance from "../../Axios/axios";
import LoadingSpinner from "../../utils/LoadingSpinner";
import SnackbarToast from '../../utils/SnackbarToast';
import logger from "../../utils/logger";
import { API_VERSION, BASE_URL } from "../../Axios/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const METROENGINE_USER = 'METROENGINE-USER'

const ForgotPassword = ({history}) => {
    // const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [resetEmail, setResetEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSnackbarClose = () => {
       setOpenSnackbar(false);
       setSnackbarType("");
       setMessageForSnackbar("");
    }

    const sendCode = () => {
      setIsLoading(true);
      if (!!resetEmail) {
        axiosInstance.post('user/requestResetPassword', { email: resetEmail })
        .then(res => {
          logger({
            api:BASE_URL+ API_VERSION +'user/requestResetPassword' ,
            title:'User Request Reset Password',
            url:window.location.href,
            method:'POST',
            activity: 'requestResetPassword',
            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
            session:localStorage.getItem('session'),
            projectId:null,
            unitId:null,
            assignedId:null,
            })
          setIsLoading(false);
          setOpenSnackbar(true);
          if (res && res.data && res.data.status === 200) {
            setSnackbarType('success');
          }
          else {
            setSnackbarType("error");
          }
          setMessageForSnackbar(res.data.message);
        }).catch(e => {
          setIsLoading(false);
          setOpenSnackbar(true);
          setSnackbarType("error");
          setMessageForSnackbar(e.response.data.message);
        });
      }
    }

    const handleResetPassword = (e) => {
      let form = document.getElementById('reset-form');
      if (!form.checkValidity()) {
        return;
      }

      e.preventDefault();
      let data = {
        email: resetEmail,
        code: otp,
        password: password,
        repeatPassword: confirmpassword
      }

      setIsLoading(true);
      axiosInstance.post('user/updatePassword', data)
      .then(res => {
        logger({
          api:BASE_URL+ API_VERSION +'user/updatePassword' ,
          title:'User Update Password',
          url:window.location.href,
          method:'POST',
          activity: 'updatePassword',
          user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
          session:localStorage.getItem('session'),
          projectId:null,
          unitId:null,
          assignedId:null,
          })
        setIsLoading(false);
        setOpenSnackbar(true);
        if (res && res.data && res.data.status === 200) {
          setSnackbarType('success');
          setTimeout(() => {
            history.replace('/login');
          }, 1000);
        }
        else {
          setSnackbarType("error");
        }
        setMessageForSnackbar(res.data.message);
      }).catch(e => {
        setIsLoading(false);
        setOpenSnackbar(true);
        setSnackbarType("error");
        setMessageForSnackbar(e.response.data.message);
      });
    }

    return (
    <div className="form-body" style={{backgroundColor: "#0092FF"}}>
      
      <LoadingSpinner isLoading={isLoading} />

      <SnackbarToast
          handleSnackbarClose={handleSnackbarClose}
          openSnackbar={openSnackbar}
          snackbarType={snackbarType}
          message={messageForSnackbar}
      />       

      <div className="row">
        <div className="img-holder" style={{backgroundColor:"#5CBBFF"}}>
          <div className="bg"></div>
          <div className="info-holder">
              <img src={image1} alt="" />
          </div>
        </div>
        <div className="form-holder">
          <div className="form-content"  style={{backgroundColor: "#0092FF"}}>
            <div className="form-items" style={{maxWidth: "450px"}}>
              <h3>Sell more and sell faster with Metroengine</h3>
              <p>Access the most powerful pre-sales tool for real estate.</p>
              <form id="reset-form" onSubmit={handleResetPassword}>
                <label htmlFor="" style={{width:'100%'}}>
                <input
                  className="form-control input-field"
                  type="email"
                  name="reset_email"
                  placeholder="Email Address"
                  required
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  style={{marginBottom:5}}
                />
                </label>
                <label htmlFor="" style={{width:'100%', display: "flex", alignItems: 'center'}}>
                <input
                  className="form-control input-field"
                  type="text"
                  name="code"
                  placeholder="Code"
                  required
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  style={{marginBottom:5, width: 'calc(100% - 120px)'}}
                />
                <div className="form-button" style={{ margin: "0 0 5px auto" }}>
                  <button id="sendcode" type="button" className="ibtn" style={{color:"#263238", padding: '6px 12px'}} onClick={sendCode}>Send code</button>
                </div>
                </label>
                <label htmlFor="" style={{width:'100%'}}>
                <input
                  className="form-control input-field"
                  type="password"
                  name="password"
                  placeholder="New Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{marginBottom:5}}
                />
                </label>
                <label htmlFor="" style={{width:'100%'}}>
                <input
                  className="form-control input-field"
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  required
                  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{marginBottom:5}}
                />
                </label>
                <div className="form-button">
                  <button id="submit" type="submit" className="ibtn" style={{color:"#263238"}}>
                    Reset Password
                  </button>{" "}
                  <Link to="/login">
                    <span className="text-white" style={{ cursor: "pointer", padding: "10px 6px"}}>Login</span>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
