import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import '../../Assets/css/iofrm-style.css';
import "../../Assets/css/iofrm-theme1.css";
import image1 from '../../Assets/img/graphic2.svg'
import axiosInstance from '../../Axios/axios';
import LoadingSpinner from '../../utils/LoadingSpinner';
import SnackbarToast from '../../utils/SnackbarToast';
import firebase from "../../Firebase/firebase";
import { Check, Clear } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_VERSION, BASE_URL } from '../../Axios/api';
import logger from '../../utils/logger';
// import InputMask from 'react-input-mask';
// import Select from 'react-select'

var tempRecaptcha;
var firebaseError;
const Register = (props) => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [role, setRole] = useState('');
  const [title, setTitle] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState("");
  const [messageForSnackbar, setMessageForSnackbar] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState('0');
  const [otpSent, setOtpSent] = useState(false);
  const [otpGoing, setOtpGoing] = useState(false);
  const [otpVerifyGoing, setOtpVerifyGoing] = useState(null);
  const [otpValue, setOtpValue] = useState("");
  const [confirmationResult, setConfirmationResult] = useState({});
  const [recapatchaSet, setRecapatchaSet] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [checkTerms, setCheckTerms] = useState(false);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    notValidEmail: false,
    password: false,
    confirmPassword: false,
    notMatchPassword: false,
    phone: false,
    validPhone: false,
  });

  // const setUpRecapatcha = () => {
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //     "recaptcha",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         console.log("resolved");
  //         setOtpGoing(false);
  //         onSignInSubmit();
  //       },
  //     }
  //   );
  // };

  const verifyOtp = (e = null) => {
    if (e) e.preventDefault();

    let code = otpValue;
    if (code === null) {
      return;
    }

    if (confirmationResult) {
      console.log('confirmationResult exits')
      confirmationResult
        .confirm(code)
        .then((result) => {
          console.log(result.user);
          console.log(result.user.uid + "signed in");
          setOtpVerified(true);
          setOtpVerifyGoing(false);
          // setOpenSnackbar((openSnackbar) => !openSnackbar);
          // setSnackbarType("success");
          // setMessageForSnackbar("OTP verified");
        })
        .catch((err) => {
          console.log('err: ', err);
          console.log("wrong OTP");
          setOtpVerifyGoing(false);
          // setOpenSnackbar((openSnackbar) => !openSnackbar);
          // setSnackbarType("error");
          // setMessageForSnackbar("Wrong OTP");
        });
    }
  };

  const checkForDisableValue = () => {
    return otpSent || !firstName || errors.firstName || !lastName || errors.lastName
      || !email || errors.email || errors.notValidEmail || !password || !confirmPassword || errors.notMatchPassword
      || !mobile || errors.phone
    // return otpSent
  }

  const onSignInSubmit = (event, mob) => {
    firebaseError = null;
    event.preventDefault();
    setOtpGoing(true);
    // console.log('mob')
    // console.log(mob)
    // let recatphca = new firebase.auth.RecaptchaVerifier('sign-in-button', {
    //   'size': 'invisible',
    //   'callback': (response) => {
    //     // reCAPTCHA solved, allow signInWithPhoneNumber.
    //     onSignInSubmit();
    //   }
    // });
    if (!tempRecaptcha) {
      tempRecaptcha = new firebase.auth.RecaptchaVerifier('recaptcha', {
        size: 'invisible',
      })
    }
    const phoneNumber = mob;

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, tempRecaptcha)
      .then(confirmationResultVal => {
        setConfirmationResult(confirmationResultVal);
        setMessageForSnackbar(`OTP sent on phone number ${phoneNumber}`);
        setOtpSent(true);
        setOtpGoing(false);
        console.log('confirmResult')
        console.log(confirmationResultVal)
      })
      .catch(error => {
        firebaseError = 'Something went wrong!'
        setOtpGoing(false);
        // alert('error.message')
        setMessageForSnackbar(error.message);

        console.log(error)
      })

    // setOtpGoing(true);
    // if (recapatchaSet === false) {
    //   setUpRecapatcha();
    //   setRecapatchaSet(true);
    // }

    // const phoneNumber = '+918485913224';
    // firebase
    //   .auth()
    //   .signInWithPhoneNumber(phoneNumber, true)
    //   .then((confirmationResultVal) => {
    //     setConfirmationResult(confirmationResultVal);
    //     setOpenSnackbar((openSnackbar) => !openSnackbar);
    //     setSnackbarType("success");
    //     setMessageForSnackbar(`OTP sent on phone number ${phoneNumber}`);
    //     setOtpSent(true);
    //   })
    //   .catch((error) => {
    //     // Error; SMS not sent
    //     console.log(error);
    //     setOpenSnackbar((openSnackbar) => !openSnackbar);
    //     setSnackbarType("error");
    //     setMessageForSnackbar(`Can't send OTP, check phone number.`);
    //     setOtpGoing(false);

    //     // ...
    //   });
  };

  const openPopup = (url, e) => {
    e.preventDefault();
    var w = window.screen.width / 2;
    var h = window.screen.height / 2;
    var left = window.screen.width / 2 - w / 2;
    var top = window.screen.height / 2 - h / 2;
    window.open(
      url,
      "MsgWindow",
      "width=" +
      w +
      ",height=" +
      h +
      ",top=" +
      top +
      ",left=" +
      left +
      ",scrollbars=yes,resizable=no,toolbar=no"
    );
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar((openSnackbar) => !openSnackbar);
    setSnackbarType("");
    setMessageForSnackbar("");
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const fetchProjectDataResponse = await axiosInstance
        .get("/projectList")
        .catch((err) => console.log(err));
      if (
        fetchProjectDataResponse &&
        fetchProjectDataResponse.data.status === 200
      ) {
        setProjects(fetchProjectDataResponse.data.payload.data);
      }
    };
    fetchProjects();
  }, []);

  const projectOptions = projects.map((item) => (
    <option value={item.id} key={item.id}>
      ID-{item.id} - {item.name}
    </option>
  ));

  // const options = [{ id: 1,name: "Test Project" }, { id: 2,name: "Test Project 2" }, { id: 3,name: "Test Project 3" }, { id: 4,name: "Test Project 4" }]
  // const options = [
  //   { id: 0, name: "Register as a Free Agent"},
  //   {
  //     name: "Group 1",
  //     children: [{ id: 1,name: "Test Project" }, { id: 2,name: "Test Project 2" }, { id: 3,name: "Test Project 3" }, { id: 4,name: "Test Project 4" }]
  //   },

  // ];
  // const options = [
  //   { value: 'chocolate', label: 'Chocolate' },
  //   { value: 'strawberry', label: 'Strawberry' },
  //   { value: 'vanilla', label: 'Vanilla' }
  // ]



  const onRegisterUser = (e) => {
    e.preventDefault();
    if ((!firstName || firstName.trim().length === 0) ||
      (!lastName || lastName.trim().length === 0) ||
      (!email || email.trim().length === 0) ||
      (!mobile || mobile.trim().length === 0) ||
      (!password || password.trim().length === 0) ||
      (!confirmPassword || confirmPassword.trim().length === 0) ||
      (!role || role.trim().length === 0) ||
      (!title || title.trim().length === 0) ||
      (!projectId || title.trim().length === 0)
    ) {
      setOpenSnackbar((openSnackbar) => !openSnackbar);
      setSnackbarType("error");
      setMessageForSnackbar("Any field cannot be empty");
      return;
    } else if (password.trim() !== confirmPassword.trim()) {
      setOpenSnackbar((openSnackbar) => !openSnackbar);
      setSnackbarType("error");
      setMessageForSnackbar("Password do not match");
      return;
    } else {
      setIsLoading(true);
      const userDetails = {
        firstName,
        lastName,
        role,
        title,
        password,
        email,
        mobile,
        projectId: projectId,
        // url: process.env.REACT_APP_DOMAIN_URL + 'login'
        url: window.location.origin + '/login'
      }

      axiosInstance
        .post("/register", userDetails)
        .then((res) => {
          logger({
            title:'Register',
            url:window.location.href,
            method:'POST',
            activity: 'register',
            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
            api:BASE_URL+ API_VERSION +'/register' ,
            session:localStorage.getItem('session'),
            projectId:null,
            unitId:null,
            assignedId:null,
            })
          if (res.data.status === 200) {
            setIsLoading(false);
            setOpenSnackbar((openSnackbar) => !openSnackbar);
            setSnackbarType("success");
            setMessageForSnackbar(
              "Registration successful! Please check your inbox or spam folder to verify email."
            );
            setTimeout(() => {
              props.history.push("/login");
            }, 4000);
          }
        })
        .catch((err) => {
          if (err.response.data.status === 422) {
            setIsLoading(false);
            setOpenSnackbar((openSnackbar) => !openSnackbar);
            setSnackbarType("error");
            setMessageForSnackbar(
              err.response.data && err.response.data?.payload?.error
            );
          } else {
            console.log(err);
            setIsLoading(false);
          }
        });
    }
  };

  const validateFieldOnBlur = (value, field) => {
    const values = { ...errors, [field]: value ? false : true };
    if (field === 'email') {
      const emailRegex = /\S+@\S+\.\S+/;
      values.notValidEmail = value && !emailRegex.test(value) ? true : false
    } else if (field === 'password') {
      values.notMatchPassword = value && confirmPassword && value !== confirmPassword ? true : false
    } else if (field === 'confirmPassword') {
      values.notMatchPassword = value && password && value !== password ? true : false
    } else if (field === 'validPhone') {
      values.validPhone = value && (value.length === 11 || value.length === 12 || value.length === 13) ? true : false
      values.phone = value && (value.length === 11 || value.length === 12 || value.length === 13) ? false : true
      // if (value && value.length === 13) setOtpSent(true);
    }
    setErrors(values)
  }

  useEffect(() => {
    if (otpValue && otpValue.length === 6) {
      setOtpVerifyGoing(true);
      verifyOtp();
    }
  }, [otpValue]);

  const onClickSendCodeAgain = (event) => {
    event.preventDefault();
    const count = resendCount + 1;
    setResendCount(count);
    setOtpVerified(false);
    setOtpValue('');
    setOtpVerifyGoing(null);
    onSignInSubmit(event, mobile);
  }

  const onClickChangePhone = (event) => {
    event.preventDefault();
    setOtpSent(false);
    setOtpValue('');
    setOtpVerifyGoing(null);
    setOtpVerified(false);
    setTimeout(() => {
      if (document.getElementsByName("mobile")[0]) {
        document.getElementsByName("mobile")[0].focus();
      }
    }, 50);
  }

  const validateField = (value, field) => {
    switch (field) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value)
        break;
      case 'password':
        setPassword(value)
        break;
      case 'confirmPassword':
        setConfirmPassword(value)
        break;
      case 'email':
        setEmail(value)
        break;
      case 'validPhone':
        const newValue = value ? value.charAt(0) !== '+' ? `+${value}` : value : ''
        setMobile(newValue)
        break;
      // default:
      //   break;
    }
    validateFieldOnBlur(value, field);
  }

  const options = [
    { id: '0', name: "Register as a Free Agent" },
    {
      name: "Or register with one of the following projects",
      children: [{ id: 1, name: "Test Project" }, { id: 2, name: "Test Project 2" }, { id: 3, name: "Test Project 3" }, { id: 4, name: "Test Project 4" }]
    }
  ];

  const formatOptionLabel = (data) => {
    if (data.children) {
      return data.children.map((item) => {
        return (
          <span className="inner-option"><span class="id-box">ID &nbsp; {item.id}</span>{item.name}</span>
        );
      })
    } else {
      return <span className="inner-option">{data.name}</span>
    }
  }

  return (
    <div className="form-body" style={{ backgroundColor: "#0092FF" }}>
      <SnackbarToast
        handleSnackbarClose={handleSnackbarClose}
        openSnackbar={openSnackbar}
        snackbarType={snackbarType}
        message={messageForSnackbar}
      />

      <LoadingSpinner isLoading={isLoading} />
      <div className="row">
        <div className="img-holder" style={{ backgroundColor: "#5CBBFF" }}>
          <div className="bg"></div>
          <div className="info-holder">
            <img src={image1} alt="" />
          </div>
        </div>
        <div className="form-holder">
          <div className="form-content" style={{ backgroundColor: "#0092FF" }}>
            <div className="form-items" style={{ maxWidth: "450px" }}>
              <h3>Sell more and sell faster with < a href = "https://metroengine.com.au/"
              style = {
                {
                  color: "white",
                  cursor: "pointer",
                  'text-decoration': 'underline'
                }
              }
              target = 'blank' > Metroengine </a></h3>
              <p>Access the most powerful pre-sales tool for real estate.</p>
              <div className="page-links">
                <Link to="/login">Login</Link>
                <Link to="/register" className="active">
                  Register
                </Link>
              </div>
              <form onSubmit={onRegisterUser} className="register-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control input-field"
                        onChange={(e) => validateField(e.target.value, 'firstName')}
                        onBlur={(e) => validateFieldOnBlur(e.target.value, 'firstName')}
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        required
                      />
                      {errors.firstName && (<span>First Name is required</span>)}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control input-field"
                        onChange={(e) => validateField(e.target.value, 'lastName')}
                        onBlur={(e) => validateFieldOnBlur(e.target.value, 'lastName')}
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        required
                      />
                      {errors.lastName && (<span>Last Name is required</span>)}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control input-field"
                        onChange={(e) => validateField(e.target.value, 'password')}
                        onBlur={(e) => validateFieldOnBlur(e.target.value, 'password')}
                        type="password"
                        name="password"
                        placeholder="Password"
                        required
                      />
                      {errors.password && (<span>Password is required</span>)}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control input-field"
                        onChange={(e) => validateField(e.target.value, 'confirmPassword')}
                        onBlur={(e) => validateFieldOnBlur(e.target.value, 'confirmPassword')}
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        required
                      />
                      {errors.confirmPassword && (<span>Confirm Password is required</span>)}
                      {errors.notMatchPassword && (<span>Passwords don't match</span>)}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        className="form-control input-field"
                        onChange={(e) => validateField(e.target.value, 'email')}
                        onBlur={(e) => validateFieldOnBlur(e.target.value, 'email')}
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        required
                      />
                      {errors.email && (<span>Email is required</span>)}
                      {errors.notValidEmail && (<span>Please enter valid email</span>)}
                    </div>
                  </div>

                  <div className="col-md-9">
                    <div className="form-group">
                    <input
                        className="form-control input-field"
                        onChange={(e) => validateField(e.target.value, 'validPhone')}
                        onBlur={(e) => validateFieldOnBlur(e.target.value, 'validPhone')}
                        value={mobile}
                        disabled={otpSent}
                        type="tel"
                        name="mobile"
                        placeholder="+611234567890"
                        required
                      />
                      {errors.phone && (<span>Please enter valid number with code</span>)}
                      {firebaseError && (<span>Please enter valid number with code</span>)}
                      {otpSent ? <span style={{ cursor: "pointer", textAlign: "right", display: "block" }} onClick={(e) => onClickChangePhone(e)}>Change</span> : null}
                    </div>
                  </div>
                  {errors.validPhone ? (
                    <div className="col-md-3">
                      <div className="form-button form-verify-button m-0">
                        {otpGoing ? (
                          <button
                            type="button"
                            id="wait"
                            disabled={true}
                            className="ibtn form-control"
                            style={{
                              color: "grey",
                              pointer: "default",
                              padding: "0px 0px 0px 0px",
                              cursor: "default",
                            }}
                          >
                            Please Wait
                          </button>
                        ) : (
                          <button
                            type="button"
                            id="send OTP"
                            className="ibtn form-control"
                            disabled={checkForDisableValue()}
                            onClick={(event) => onSignInSubmit(event, mobile)}
                            style={{
                              pointer: "default",
                              padding: "0px 0px 0px 0px",
                            }}
                          >
                            Send OTP
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {otpSent ? (
                    <>
                      <div className="col-md-10">
                        <div className="form-group">
                          <input
                            className="form-control input-field"
                            type="text"
                            name="otp"
                            disabled={otpVerifyGoing || otpVerified}
                            onChange={(e) => setOtpValue(e.target.value)}
                            placeholder="Enter code from your phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        {/* <div className="form-button form-verify-button">
                          <button
                            type="button"
                            id="verify"
                            onClick={(event) => verifyOtp(event)}
                            className="ibtn form-control input-field"
                            style={{
                              color: "#263238",
                              padding: "0px 0px 0px 0px",
                            }}
                          >
                            Verify
                          </button>
                        </div> */}
                        {/* <Check style={{ color: "#bb1a00" }} /> */}
                        {/* <CircularProgress /> */}
                        {otpVerifyGoing === false ? (
                          otpVerified ? <span className="status-container"><Check style={{ color: "#16ff13" }} /></span> : <span className="status-container"><Clear style={{ color: "#bb1a00" }} /></span>
                        ) : otpVerifyGoing === true ? <span className="status-container"><CircularProgress /></span> : null}
                      </div>
                      {resendCount < 2 ? (
                        <>
                          <div className="col-md-9">
                            <span className="code-send-note">Verification code has been sent to your mobile phone.</span>
                          </div>
                          <div className="col-md-3">
                            <span className="code-send-agian" onClick={(e) => onClickSendCodeAgain(e)}>Send code again</span>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {otpVerified ? (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label id="title">Title:</label>
                          <select
                            id="title"
                            onChange={(e) => setTitle(e.target.value)}
                            className="form-control input-field"
                          >
                            <option value="">Please Select Title</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms">Ms</option>
                            <option value="Dr">Dr</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label id="role">Role:</label>
                          <select
                            id="role"
                            onChange={(e) => setRole(e.target.value)}
                            className="form-control"
                          >
                            <option value="">Please Select Role</option>
                            {/* <option value="Project Manager">Project Manager</option>
                                          <option value="User Manager">User Manager</option>
                                          <option value="Master Agent">Master Agent</option> */}
                            <option value="Agent">Agent</option>
                            <option value="Affiliate">Affiliate</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label id="project">Projects:</label>
                          <select
                            id="project"
                            onChange={(e) => setProjectId(e.target.value)}
                            className="form-control"
                          >
                            <option value="0">Register as a Free Agent</option>
                            {
                              projects.length ? (
                                <optgroup label="Or register with one of the following projects">
                                  {projectOptions}
                                </optgroup>
                              ) : null
                            }
                          </select>
                          {/* <Select
                          options={options}
                          onChange={(e) => setProjectId(e.id)}
                          defaultValue={options[0]}
                          formatOptionLabel={formatOptionLabel}
                        /> */}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {otpVerified ? (
                  <>
                    <div className="col-md-12 custom-checkbox">
                      <label className="container">By ticking this box i agree to Metroengine's:
                        <input type="checkbox" onClick={() => setCheckTerms(!checkTerms)} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div
                      className="d-flex align-items-center separated-horizontally"
                      style={{ marginLeft: "37px" }}
                    >
                      <a
                        href="#"
                        onClick={(e) => openPopup("/terms", e)}
                        className="text-white pr-2"
                      >
                        Terms of Service
                      </a>
                      <a
                        href="#"
                        onClick={(e) => openPopup("/privacy", e)}
                        className="text-white px-2"
                      >
                        Privacy Policy
                      </a>
                      <a
                        href="#"
                        onClick={(e) => openPopup("/cookie", e)}
                        className="text-white pl-2"
                      >
                        Cookie Policy
                      </a>
                    </div>
                  </>
                ) : null}

                <div className="form-button">
                  {checkTerms && otpVerified && role && title && (projectId || projectId === '0') ? (
                    <button
                      id="submit"
                      type="submit"
                      className="ibtn"
                      style={{ color: "#263238", marginLeft: "5px" }}
                    >
                      Register
                    </button>
                  ) : (
                    <button
                      id="submit"
                      type="button"
                      disabled={true}
                      className="ibtn"
                      style={{
                        color: "grey",
                        cursor: "default",
                        marginLeft: "5px",
                      }}
                    >
                      Register
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="recaptcha"></div>
    </div>
  );
};

export default Register;
