import { FormControl, InputLabel, makeStyles, MenuItem, Select, Switch, TextField } from '@material-ui/core'

import React from 'react'
import { australianStateList } from '../../../../../DummyData/australianStateList';
import { countryList } from '../../../../../DummyData/countryList';

const useStyles = makeStyles((theme) => ({

    heading: {
        borderBottom: "2px solid rgba(0, 0, 0, 0.12)",
        width: "max-content",
        marginBottom: "0",
        fontWeight: 500,
        color: "#5680e9"
    }

}))

function AdditionalDetailsForm({
    state,
    errors,
    value,
    handleAdditionalDetailsChange,
    project,
    handleSwitchChange
}) {
    
    const classes = useStyles();
    const checkedStatus = project && project.additional_detail[`checked${value}`] !== undefined && project.additional_detail[`checked${value}`] === 0 ? false : state[`checked${value}`]

    return (
        
        <div className="col-md-6">

            <div style={{marginTop:"18px"}} className="additional-details d-flex align-items-center">

                <h5 className={classes.heading}>Additional Details</h5>

                <Switch

                    checked={checkedStatus}

                    onChange={handleSwitchChange}

                    color="primary"

                    name={`checked${value}`}

                    inputProps={{ 'aria-label': 'primary checkbox' }}

                />

            </div>

            {

            checkedStatus ? 

            <React.Fragment>

                <TextField

                    margin="dense"

                    label="Name"

                    name={`additionalName${value}`}

                    defaultValue={project.additional_detail && project.additional_detail[`additionalName${value}`] != 'null' ? project.additional_detail[`additionalName${value}`] : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"


                    label="Function/Authority"

                    placeholder="Investor, Finance Manager"

                    name={`additionalAuthority${value}`}

                    defaultValue={project.additional_detail && project.additional_detail[`additionalAuthority${value}`] != 'null' ? project.additional_detail[`additionalAuthority${value}`] : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    label="Person In Charge"

                    name={`additionalIncharge${value}`}

                    defaultValue={project.additional_detail && project.additional_detail[`additionalIncharge${value}`] != 'null' ? project.additional_detail[`additionalIncharge${value}`] : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    label="Contact Number"

                    name={`additionalMobile${value}`}

                    defaultValue={project.additional_detail && project.additional_detail[`additionalMobile${value}`] != 'null' ? project.additional_detail[`additionalMobile${value}`] : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    label="Email Address"

                    name={`additionalEmail${value}`}

                    defaultValue={project.additional_detail && project.additional_detail[`additionalEmail${value}`] != 'null' ? project.additional_detail[`additionalEmail${value}`] : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    label="Address"

                    name={`additionalAddress${value}`}

                    defaultValue={project.additional_detail && project.additional_detail[`additionalAddress${value}`] != 'null' ? project.additional_detail[`additionalAddress${value}`] : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    label="Postal code"

                    name={`additionalPostalCode${value}`}

                    helperText = {

                        errors.lengthError && project.additional_detail[`additionalPostalCode${value}`] && isNaN(project.additional_detail[`additionalPostalCode${value}`].toString())

                        ?
                        errors.lengthError

                        :
                        errors.emptyError && project.additional_detail[`additionalPostalCode${value}`] && project.additional_detail[`additionalPostalCode${value}`].trim().length === 0

                        ?
                        errors.emptyError

                        :

                        ""

                    }

                    error = {

                        (errors.lengthError && project.additional_detail[`additionalPostalCode${value}`] && isNaN(project.additional_detail[`additionalPostalCode${value}`].toString())) ||

                        (errors.emptyError && project.additional_detail[`additionalPostalCode${value}`] && project.additional_detail[`additionalPostalCode${value}`].trim().length === 0) ||

                        (errors.lengthError && project.additional_detail[`additionalPostalCode${value}`] && isNaN(project.additional_detail[`additionalPostalCode${value}`].toString()))

                        ?
                        
                        true

                        :
                        
                        false

                    }

                    defaultValue={project.additional_detail && project.additional_detail[`additionalPostalCode${value}`] != 'null' ? project.additional_detail[`additionalPostalCode${value}`] : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                
                    <InputLabel
                    
                    
                    >
                    
                        Country
                    
                    </InputLabel>
                    
                    <Select
                    
                    value={project.additional_detail && project.additional_detail[`additionalCountry${value}`] != 'null' ? project.additional_detail[`additionalCountry${value}`] : ""}
                    
                    fullWidth
                    
                    name={`additionalCountry${value}`}

                    onChange={handleAdditionalDetailsChange}
                    
                    >
                    
                    {countryList.map((country => (
                    
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                    
                    )))}
                    
                    </Select>
                
                </FormControl>

                { project.additional_detail && project.additional_detail[`additionalCountry${value}`] !== "Australia" ? 
                
                    <TextField
                        
                        margin="dense"

                        label="State"

                        name={`additionalState${value}`}

                        defaultValue={project.additional_detail && project.additional_detail[`additionalState${value}`] != 'null' ? project.additional_detail[`additionalState${value}`] : ""}

                        onChange={handleAdditionalDetailsChange}

                        type="text"

                        fullWidth

                    />

                    : 

                    <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                        
                        <InputLabel
                                                
                        >
                            State

                        </InputLabel>
        
                        <Select

                            margin="dense"

                            label="State"

                            name={`additionalState${value}`}

                            defaultValue={project.additional_detail && project.additional_detail[`additionalState${value}`] != 'null' ? project.additional_detail[`additionalState${value}`] : ""}

                            onChange={handleAdditionalDetailsChange}

                            type="text"

                            fullWidth

                        >
                            
                            {australianStateList.map((state => (

                                <MenuItem key={state} value={state}>{state}</MenuItem>

                            )))}

                        </Select>

                    </FormControl>

                }

            </React.Fragment>

            : null 
            
            }

        </div>

    )

}

export default AdditionalDetailsForm
