import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'

import React from 'react'
import { australianStateList } from '../../../../../DummyData/australianStateList';
import { countryList } from '../../../../../DummyData/countryList';

const useStyles = makeStyles((theme) => ({

    heading: {
        borderBottom: "2px solid rgba(0, 0, 0, 0.12)",
        width: "max-content",
        marginBottom: "0",
        marginTop: "18px",
        fontWeight: 500,
        color: "#5680e9"
    }

}))

function ArchitectDetailsForm({
    errors,
    project,
    handleChange,
    handleSwitchChange,
    state
}) {

    const classes = useStyles();
    
    return (
        
        <div className="col-md-6">

            <h5 className={classes.heading}>Architect</h5>

            <TextField

                required

                margin="dense"

                helperText= {

                    errors.emptyError && project.architectCompany.trim().length === 0

                    ? errors.emptyError

                    : ""

                }

                error= {

                    errors.emptyError && project.architectCompany.trim().length === 0

                    ? true

                    : false

                }

                id="architect"

                label="Name"

                name="architectCompany"

                defaultValue={project.architectCompany}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="architectIncharge"

                label="Person In Charge"

                name="architectIncharge"

                defaultValue={project.architectIncharge}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="architectMobile"

                label="Contact Number"

                name="architectMobile"

                defaultValue={project.architectMobile}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="architectEmail"

                label="Email Address"

                name="architectEmail"

                defaultValue={project.architectEmail}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="architectAddress"

                label="Address"

                name="architectAddress"

                defaultValue={project.architectAddress}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="architectPostalCode"

                label="Postal code"

                name="architectPostalCode"

                helperText = {

                    errors.lengthError && project.architectPostalCode && isNaN(project.architectPostalCode.toString())

                    ?
                    errors.lengthError

                    :
                    errors.emptyError && project.architectPostalCode && project.architectPostalCode.trim().length === 0

                    ?
                    errors.emptyError

                    :
                    errors.rangeError && project.architectPostalCode && (!isNaN(project.architectPostalCode.toString()) && (Number(project.architectPostalCode.toString()) < 999 || Number(project.architectPostalCode.toString()) > 9999))

                    ?
                    errors.rangeError

                    :
                    ""

                }

                error = {

                    (errors.lengthError && project.architectPostalCode && isNaN(project.architectPostalCode.toString())) ||

                    (errors.emptyError && project.architectPostalCode && project.architectPostalCode.trim().length === 0) ||

                    (errors.lengthError && project.architectPostalCode && isNaN(project.architectPostalCode.toString())) ||

                    (errors.rangeError && project.architectPostalCode && (!isNaN(project.architectPostalCode.toString()) && (Number(project.architectPostalCode.toString()) < 999 || Number(project.architectPostalCode.toString()) > 9999)))

                    ?

                    true

                    :
                    
                    false

                }

                defaultValue={project.architectPostalCode ? project.architectPostalCode : ""}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                
                <InputLabel
                
                    id="demo-simple-select-label"
                
                >
                
                    Country
                
                </InputLabel>
                
                <Select
                
                labelId="demo-simple-select-label"
                
                id="demo-simple-select"
                
                value={project.architectCountry ? project.architectCountry : ""}
                
                fullWidth
                
                name="architectCountry"
                
                onChange={handleChange}
                
                >
                
                {countryList.map((country => (
                
                    <MenuItem key={country} value={country}>{country}</MenuItem>
                
                )))}
                
                </Select>
            
            </FormControl>

            { project.architectCountry !== "Australia" ? 
                
                <TextField
                    
                    margin="dense"

                    id="architectState"

                    label="State"

                    name="architectState"

                    defaultValue={project.architectState ? project.architectState : ""}

                    onChange={handleChange}

                    type="text"

                    fullWidth

                />

                : 

                <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                    
                    <InputLabel
                    
                        id="demo-simple-select-label"
                    
                    >
                        State

                    </InputLabel>
    
                    <Select

                        margin="dense"

                        id="architectState"

                        label="State"

                        name="architectState"

                        defaultValue={project.architectState ? project.architectState : ""}

                        onChange={handleChange}

                        type="text"

                        fullWidth

                    >
                        
                        {australianStateList.map((state => (

                            <MenuItem key={state} value={state}>{state}</MenuItem>

                        )))}

                    </Select>

                </FormControl>

            }

            {/* <TextField

                margin="dense"

                id="architectCountry"

                label="Country"

                name="architectCountry"

                defaultValue={project.architectCountry ? project.architectCountry : ""}

                onChange={handleChange}

                type="text"

                fullWidth

            /> */}

            

        </div>

    )

}

export default ArchitectDetailsForm
