import React from 'react'

import PropTypes from "prop-types";

import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

const headCells = [

  { id: "id", numeric: false, disablePadding: false, label: "#" },

  { id: "assign", numeric: true, disablePadding: false, label: "Assign" },

  { id: "isEnable", numeric: true, disablePadding: false, label: "Available" },

  { id: "firstName", numeric: true, disablePadding: false, label: "First Name" },

  { id: "lastName", numeric: true, disablePadding: false, label: "Last Name" },

  { id: "role", numeric: true, disablePadding: false, label: "Role" },

  { id: "profile", numeric: true, disablePadding: false, label: "Profile" },

  { id: "created_at", numeric: true, disablePadding: false, label: "Date Joined" },

  { id: "address", numeric: true, disablePadding: false, label: "Address" }

];

const EnhancedTableHead = ({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) =>  {

  const createSortHandler = (property) => (event) => {

    onRequestSort(event, property);

  };

  return (

    <TableHead>

      <TableRow>
        
        {headCells.map((headCell,index) => (
    
          headCell.id === 'assign'
    
          ? <TableCell padding="checkbox"> {headCell.label}</TableCell>
    
          :
    
          <TableCell
    
            key={headCell.id}
    
            align={index === 0 || index === 1 ? "center" : "center"}
    
            padding={headCell.disablePadding ? "none" : "default"}
    
            sortDirection={orderBy === headCell.id ? order : false}
    
          >
    
            <TableSortLabel
    
              // active={orderBy === headCell.id}
    
              active={true}
    
              direction={orderBy === headCell.id ? order : "asc"}
    
              onClick={createSortHandler(headCell.id)}
    
            >
    
              {headCell.label}
    
              {orderBy === headCell.id ? 
              
                (
      
                  <span className={classes.visuallyHidden}>
      
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
      
                  </span>
      
                ) 
                
                : 
                
                null 
              
              }
    
            </TableSortLabel>
    
          </TableCell>
    
        ))}
    
      </TableRow>
    
    </TableHead>
 
  );

}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead
