import React from 'react'

import moment from 'moment'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({

    headerTitle: {
        fontSize: "0.9rem",
        textTransform: "uppercase",
        paddingTop: "5px",
        paddingBottom: "5px",
        letterSpacing: "0.1em",
        backgroundColor: "rgb(192,229,226)",
        borderRadius: "6px",
        paddingLeft: "10px",
        fontWeight: 600,
        marginLeft: "auto",
        marginRight: "auto"
    },

    projectDetailsTable: {
        marginLeft: "auto",
        marginRight: "auto"
    },

    projectDescription: {
      height: '70px',
      overflowY: 'auto'
    },


    tableRow: {
        backgroundColor: "white",
        width: "50%"
    },

    tableDescriptionHeading: {
        fontWeight: "bold",
        padding: "2px 10px !important"
    },

    tableDescriptionBody: {
        padding: "2px 10px !important"
    }

}))


function ProjectDetailsTable({
    project
}) {
    
    const classes = useStyles();

    return (
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
            
            <h5 className={classes.headerTitle}>Project Details</h5>

            <table className={`${classes.projectDetailsTable} table table-bordered`}>
              
              <tbody>
                
                <tr className={classes.tableRow}> 
                
                  <td className={classes.tableDescriptionHeading}>Name</td>
                
                  <td className={classes.tableDescriptionBody}>{project.name ? project.name : "-"}</td>
                
                </tr>
                
                <tr className={classes.tableRow}> 
                
                  <td className={classes.tableDescriptionHeading}>Address</td>
                
                  <td className={classes.tableDescriptionBody}>{project.address ? project.address : "-"}</td>
                
                </tr>
                
                <tr className={classes.tableRow}> 
                
                  <td className={classes.tableDescriptionHeading}>Postcode</td>
                
                  <td className={classes.tableDescriptionBody}>{project.postalCode ? project.postalCode : "-"}</td>
                
                </tr>
                
                <tr className={classes.tableRow}> 
                
                  <td className={classes.tableDescriptionHeading}>State</td>
                
                  <td className={classes.tableDescriptionBody}>{project.state ? project.state : "-"}</td>
                
                </tr>
                
                <tr className={classes.tableRow}> 
                
                  <td className={classes.tableDescriptionHeading}>Country</td>
                
                  <td className={classes.tableDescriptionBody}> {project.country ? project.country : "-"} </td>
                
                </tr>
                
                <tr className={classes.tableRow}> 
                
                  <td className={classes.tableDescriptionHeading}>Created On</td>
                
                  <td className={classes.tableDescriptionBody}>{ project.created_at ?  moment(project.created_at).format("DD/MM/YYYY") : "-"}</td>
                
                </tr>
                
                <tr className={classes.tableRow}> 
                
                  <td className={classes.tableDescriptionHeading}>Deployed On</td>
                
                  <td className={classes.tableDescriptionBody}>{ project.deployedAt ? moment(project.deployedAt).format("DD/MM/YYYY") : "-"}</td>
                
                </tr>
                
                <tr className={classes.tableRow}> 
                
                  <td className={classes.tableDescriptionHeading}>Project Description</td>
                
                  <td className={classes.tableDescriptionBody}>
                    <div className={classes.projectDescription}>{ project.projectDescription ? project.projectDescription : "-"}</div>
                  </td>
                
                </tr>
              
              </tbody>
            
            </table>
          
        </div>

    )

}

export default ProjectDetailsTable
