import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';

import React from 'react'
import { australianStateList } from '../../../../../DummyData/australianStateList';
import { countryList } from '../../../../../DummyData/countryList';

const useStyles = makeStyles((theme) => ({

    heading: {
        borderBottom: "2px solid rgba(0, 0, 0, 0.12)",
        width: "max-content",
        marginBottom: "0",
        marginTop: "18px",
        fontWeight: 500,
        color: "#5680e9"
    }

}))

function DeveloperDetailsForm({
    errors,
    project,
    handleChange,
    handleSwitchChange,
    state
}) {

    const classes = useStyles();
    
    return (
        
        <div className="col-md-6">

            <h5 className={classes.heading}>Developer</h5>

            <TextField

                required

                margin="dense"

                helperText= {

                    errors.emptyError && project.developerCompany.trim().length === 0

                    ? errors.emptyError

                    : ""

                }

                error= {

                    errors.emptyError && project.developerCompany.trim().length === 0

                    ? true

                    : false

                }

                id="developer"

                label="Name"

                name="developerCompany"

                defaultValue={project.developerCompany}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="developerIncharge"

                label="Person In Charge"

                name="developerIncharge"

                defaultValue={project.developerIncharge}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="developerMobile"

                label="Contact Number"

                name="developerMobile"

                defaultValue={project.developerMobile}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="developerEmail"

                label="Email Address"

                name="developerEmail"

                defaultValue={project.developerEmail}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="developerAddress"

                label="Address"

                name="developerAddress"

                defaultValue={project.developerAddress}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <TextField

                margin="dense"

                id="developerPostalCode"

                label="Postal code"

                name="developerPostalCode"

                helperText = {

                    errors.lengthError && project.developerPostalCode && isNaN(project.developerPostalCode.toString())

                    ?
                    errors.lengthError

                    :
                    errors.emptyError && project.developerPostalCode && project.developerPostalCode.trim().length === 0

                    ?
                    errors.emptyError

                    :
                    errors.rangeError && project.developerPostalCode && (!isNaN(project.developerPostalCode.toString()) && (Number(project.developerPostalCode.toString()) < 999 || Number(project.developerPostalCode.toString()) > 9999))

                    ?
                    errors.rangeError

                    :
                    ""

                }

                error = {

                    (errors.lengthError && project.developerPostalCode && isNaN(project.developerPostalCode.toString())) ||

                    (errors.emptyError && project.developerPostalCode && project.developerPostalCode.trim().length === 0) ||

                    (errors.lengthError && project.developerPostalCode && isNaN(project.developerPostalCode.toString())) ||

                    (errors.rangeError && project.developerPostalCode && (!isNaN(project.developerPostalCode.toString()) && (Number(project.developerPostalCode.toString()) < 999 || Number(project.developerPostalCode.toString()) > 9999)))

                    ?
                    
                    true

                    :
                    
                    false

                }

                defaultValue={project.developerPostalCode ? project.developerPostalCode : ""}

                onChange={handleChange}

                type="text"

                fullWidth

            />

            <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                
                <InputLabel
                
                    id="demo-simple-select-label"
                
                >
                
                    Country
                
                </InputLabel>
                
                <Select
                
                labelId="demo-simple-select-label"
                
                id="demo-simple-select"
                
                value={project.developerCountry ? project.developerCountry : ""}
                
                fullWidth
                
                name="developerCountry"
                
                onChange={handleChange}
                
                >
                
                {countryList.map((country => (
                
                    <MenuItem key={country} value={country}>{country}</MenuItem>
                
                )))}
                
                </Select>
            
            </FormControl>

            { project.developerCountry !== "Australia" ? 
                
                <TextField
                    
                    margin="dense"

                    id="developerState"

                    label="State"

                    name="developerState"

                    defaultValue={project.developerState ? project.developerState : ""}

                    onChange={handleChange}

                    type="text"

                    fullWidth

                />

                : 

                <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                    
                    <InputLabel
                    
                        id="demo-simple-select-label"
                    
                    >
                        State

                    </InputLabel>
    
                    <Select

                        margin="dense"

                        id="developerState"

                        label="State"

                        name="developerState"

                        defaultValue={project.developerState ? project.developerState : ""}

                        onChange={handleChange}

                        type="text"

                        fullWidth

                    >
                        
                        {australianStateList.map((state => (

                            <MenuItem key={state} value={state}>{state}</MenuItem>

                        )))}

                    </Select>

                </FormControl>

            }

            {/* <Autocomplete
                
                id = "developerCountry"
                
                options={countryList}
                
                getOptionLabel={(option) => option}

                name="developerCountry"

                defaultValue={project.developerCountry ? project.developerCountry : ""}

                onChange = {
                    (e) => {
                        if (!!e.target.innerText) handleChange(e)
                        else {
                            handleChange(e)
                        }
                    }
                }
                
                fullWidth

                size="small"
                
                renderInput={

                    (params) => 

                        <TextField 

                            {...params} 
                            
                            defaultValue={project.developerCountry ? project.developerCountry : ""}                             
                            
                            name="developerCountry" 
                            
                            label="Country" 
                            
                            variant="outlined" 

                        />

                }
            

            /> */}

            {/* <TextField

                margin="dense"

                id="developerCountry"

                label="Country"

                name="developerCountry"

                defaultValue={project.developerCountry ? project.developerCountry : ""}

                onChange={handleChange}

                type="text"

                fullWidth

            /> */}

        

        </div>

    )

}

export default DeveloperDetailsForm
