import { AppBar, Button, Dialog, Divider, FormControlLabel, IconButton, List, ListItem, ListItemText, makeStyles, Radio, RadioGroup, Slide, Toolbar, Tooltip, Typography, withStyles, Collapse } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import { CheckCircle, Close, Delete, Info, KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import { format2Currency } from '../../../../utils';
import { BASE_URL } from '../../../../Axios/api';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#263238',
    paddingRight: "0px !important"
  },
  title: {
    fontSize:"1rem",
    flex: 1,
  },
  assignButton: {
    color: "white",
    padding: "3px 10px",
    fontSize: "0.8rem",
    marginLeft: "10px",
    margin: "10px 0px",
    backgroundColor: "#26A69A",
    '&:hover': {
      backgroundColor: "#26A69A"
    }
  },
  col3: {
    position: "relative",
    width: "100%",
    paddingRight: ".625rem",
    paddingLeft: ".625rem",
    flex: "0 0 25%",
    maxWidth: "25%",
    [theme.breakpoints.down(555)]: {
      flex: "0 0 50% !important",
      maxWidth: "50% !important"
    }
  },
  col9: {
    position: "relative",
    width: "100%",
    paddingRight: ".625rem",
    paddingLeft: ".625rem",
    flex: "0 0 75%",
    maxWidth: "75%",
    [theme.breakpoints.down(555)]: {
      flex: "0 0 50% !important",
      maxWidth: "50% !important"
    }
  },
  table: {
      border: "none !important"
  },
  tableRow: {
      '&:hover': {
          backgroundColor: "#f2f2f2 !important",
          cursor: "pointer"
      }
  },
  activeRow: {
      backgroundColor: "#B1DFDC !important",
      '&:hover': {
          backgroundColor: "#B1DFDC !important",
          cursor: "pointer"
      }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


function UnitNumberDialog({
    unitNumber,
    openUnitNumberDialog,
    onCloseUnitNumberDialog,
    floorPlan,
    prices,
    showDataFor,
    onChangeActiveStatus,
    onUploadFileForFloorPlan,
    onSaveUnitsTableData,
    onDeleteSelectedData,
    setOpenDeleteConfirmationDialog,
    setOpenNewPriceDialog,
    setOpenActivateConfirmationDialog,
    setPriceIdToActivate,
    setFloorPlanIdToActivate
}) {

    const classes = useStyles();
    const [expandedDisplayTitle, setExpandedDisplayTitle] = React.useState(false);
    const [expandedActiveTitle, setExpandedActiveTitle] = React.useState(false);
    const [expandedData, setExpandedData] = React.useState(false);

    const handleExpandDisplayTitleClick = () => {
        setExpandedDisplayTitle(!expandedDisplayTitle);
    };

    const handleExpandActiveTitleClick = () => {
        setExpandedActiveTitle(!expandedActiveTitle);

    }

    const handleExpandDataClick = () => {
        setExpandedData(!expandedData);
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={openUnitNumberDialog} onClose={onCloseUnitNumberDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar style={{paddingLeft:"16px", paddingRight:"0px"}}>
                    <Typography variant="h6" className={classes.title}>
                      Unit Number - {unitNumber} 
                    </Typography>

                    <IconButton edge="start" color="inherit" onClick={onCloseUnitNumberDialog} aria-label="close">
                        <Close />
                    </IconButton>            
                </Toolbar>
            </AppBar>
            <List>
                {
                    showDataFor === "floorPlan" ? 
                        <React.Fragment>
                            <div className="display-data">
                                <ListItem onClick={handleExpandDisplayTitleClick} style={{cursor:"pointer", backgroundColor: "#B1DFDC"}}>
                                    <ListItemText>
                                        <h6 className="font-weight-bold mb-0" style={{padding: "2px", letterSpacing: "0.5px"}}>DISPLAY FLOOR PLAN</h6>
                                        <span>
                                            <KeyboardArrowDown
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: expandedDisplayTitle,
                                                })}
                                                
                                                aria-expanded={expandedDisplayTitle}    
                                            />
                                        </span>
                                        <Info style={{ position:"absolute", right:0, marginRight:"10px", color:"#26A69A" }}/>
                                    </ListItemText>
                                </ListItem>
                                <Collapse in={expandedDisplayTitle} timeout="auto" unmountOnExit>
                                    <ListItem>
                                        <RadioGroup style={{flexDirection:"row"}} name="floorPlan" value={floorPlan.isDisplayed === 1 ? true : false} onChange={onChangeActiveStatus}>
                                            <FormControlLabel value={true} control={<GreenRadio />} label="YES" />
                                            <FormControlLabel value={false} control={<GreenRadio />} label="NO" />
                                        </RadioGroup>
                                    </ListItem>
                                </Collapse>
                            </div>
                            <Divider/>
                            {   floorPlan.data && floorPlan.data.find(plan => plan.isActive) ?
                                <div className="active-data">
                                    <ListItem onClick={handleExpandActiveTitleClick} style={{cursor:"pointer", backgroundColor: "#B1DFDC"}}>
                                        <ListItemText>
                                            <h6 className="font-weight-bold mb-0" style={{padding: "2px", letterSpacing: "0.5px"}}>ACTIVE FLOOR PLAN</h6>
                                            <span>
                                                <KeyboardArrowDown
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: expandedActiveTitle,
                                                    })}
                                                    
                                                    aria-expanded={expandedActiveTitle}    
                                                />
                                            </span>
                                            <Info style={{ position:"absolute", right:0, marginRight:"10px", color:"#26A69A" }}/>
                                        </ListItemText>
                                    </ListItem>
                                    <Collapse in={expandedActiveTitle} timeout="auto" unmountOnExit>
                                        <ListItem>
                                            <ListItemText>
                                                <span className="font-weight-bold">File Name : </span>
                                                <span className="ml-1" style={{cursor: "pointer"}} onClick={() => {window.open(`${BASE_URL}images/unitfiles/${floorPlan.data.find(plan => plan.isActive).file}`)}}>
                                                    { floorPlan.data.find(plan => plan.isActive).file}
                                                </span>
                                            </ListItemText>
                                        </ListItem>
                                    </Collapse>
                                <Divider/>
                                </div>
                                : null
                            }
                        </React.Fragment>  

                    : showDataFor === "prices" ?
                        <React.Fragment>
                            <div className="display-data">
                                <ListItem onClick={handleExpandDisplayTitleClick} style={{cursor:"pointer", backgroundColor: "#B1DFDC"}}>
                                    <ListItemText>
                                        <h6 className="font-weight-bold mb-0" style={{padding: "2px"}}>DISPLAY PRICE</h6>
                                        <span>
                                            <KeyboardArrowDown 
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: expandedDisplayTitle,
                                                })}
                                                aria-expanded={expandedDisplayTitle}
                                            />
                                        </span>
                                        <Info style={{ position:"absolute", right:0, marginRight:"10px", color:"#26A69A" }}/>
                                    </ListItemText>
                                </ListItem>
                                <Collapse in={expandedDisplayTitle} timeout="auto" unmountOnExit>
                                    <ListItem>
                                        <RadioGroup style={{flexDirection:"row"}} name="prices" value={prices.isDisplayed === 1 ? true : false} onChange={onChangeActiveStatus}>
                                            <FormControlLabel value={true} control={<GreenRadio />} label="YES" />
                                            <FormControlLabel value={false} control={<GreenRadio />} label="NO" />
                                        </RadioGroup>
                                    </ListItem>
                                </Collapse>
                            </div>
                            <Divider/>
                            {   prices.data && prices.data.find(pricesData => pricesData.isActive) ?
                                <div className="active-data">
                                    <ListItem onClick={handleExpandActiveTitleClick} style={{cursor:"pointer", backgroundColor: "#B1DFDC"}}>
                                        <ListItemText>
                                            <h6 className="font-weight-bold mb-0" style={{padding: "2px"}}>ACTIVE PRICE</h6>
                                            <span>
                                                <KeyboardArrowDown 
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: expandedActiveTitle,
                                                    })}
                                                    aria-expanded={expandedActiveTitle}
                                                />
                                            </span>
                                            <Info style={{ position:"absolute", right:0, marginRight:"10px", color:"#26A69A" }}/>
                                        </ListItemText>
                                    </ListItem>
                                    <Collapse in={expandedActiveTitle} timeout="auto" unmountOnExit>
                                        <ListItem>
                                            <ListItemText>
                                                <span className="font-weight-bold">Price Value : </span>
                                                <span className="ml-1">{prices.data.find(pricesData => pricesData.isActive).price}</span>
                                            </ListItemText>
                                        </ListItem>
                                    </Collapse>
                                <Divider/>
                                </div>
                                : null
                            }

                        </React.Fragment> 

                    : null
                }
                    
                {
                    showDataFor==="floorPlan" && floorPlan && floorPlan.data.length === 0 ? 
                        <ListItem>
                            <ListItemText primary={`No Floor Plan Found`}/>
                        </ListItem>
                    :

                    showDataFor==="prices" && prices && prices.data.length === 0 ?
                         <ListItem>
                            <ListItemText primary={`No Prices Found`}/>
                        </ListItem>
                    :
                
                    ((floorPlan && floorPlan.data.length > 0) || (prices && prices.data.length > 0)) ?

                        <React.Fragment>
                            <ListItem onClick={handleExpandDataClick} style={{cursor:"pointer", backgroundColor: "#B1DFDC"}}>
                                <ListItemText>
                                    {  showDataFor === "floorPlan" ? 
                                        <h6 className="font-weight-bold mb-0" style={{padding: "2px", letterSpacing: "0.5px"}}>
                                            FLOOR PLAN HISTORY
                                        </h6>
                                        :
                                        <h6 className="font-weight-bold mb-0" style={{padding: "2px", letterSpacing: "0.5px"}}>
                                            PRICES HISTORY
                                        </h6>
                                    }
                                    <span>
                                        <KeyboardArrowDown
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expandedData,
                                            })}
                                            
                                            aria-expanded={expandedData}    
                                        />
                                    </span>
                                    <Info style={{ position:"absolute", right:0, marginRight:"10px", color:"#26A69A" }}/>
                                </ListItemText>
                            </ListItem>
                            <Collapse style={{margin: "15px", border:"1px solid lightgray"}} in={expandedData} timeout="auto" unmountOnExit>
                                <TableContainer>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <div className="d-flex">
                                                        <h6 className="font-weight-bold mb-0" style={{padding: "2px"}}>Entry Made By</h6>
                                                        <span><KeyboardArrowDown/></span>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="d-flex">
                                                        <h6 className="font-weight-bold mb-0" style={{padding: "2px"}}>Input Date</h6>
                                                        <span><KeyboardArrowDown/></span>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="d-flex">
                                                        <h6 className="font-weight-bold mb-0" style={{padding: "2px"}}>
                                                            { showDataFor==="floorPlan" ? "File Name" : "Price" }
                                                        </h6>
                                                        <span><KeyboardArrowDown/></span>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="d-flex">
                                                        <h6 className="font-weight-bold mb-0" style={{padding: "2px"}}>
                                                           Action
                                                        </h6>
                                                        <span><KeyboardArrowDown/></span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        
                                        <TableBody>

                                            {showDataFor==="floorPlan" ?
                                            
                                                floorPlan.data.map((plan) => (
                                                    <TableRow 
                                                        key={plan.id}
                                                        className={`${classes.tableRow} ${plan.isActive && classes.activeRow}`}
                                                        style={{backgroundColor: plan.isActive && "#B1DFDC !important"}}
                                                    >
                                                        
                                                        <TableCell>
                                                            {plan["entryMadeBy"]}
                                                        </TableCell>
                                                        
                                                        <TableCell>
                                                            <div className="d-flex">
                                                                <Tooltip arrow title="Delete File">
                                                                        <Delete onClick={(event) => {
                                                                            event.stopPropagation();                                                                             
                                                                            setOpenDeleteConfirmationDialog(true);
                                                                            setFloorPlanIdToActivate(plan.id);
                                                                            }} 
                                                                            style={{color:"crimson", marginRight:"5px"}}                                                                            
                                                                        /> 
                                                                </Tooltip>
                                                                <h6 className="mb-0"> {new Date(plan.inputDate).toLocaleString().split(",")[0]} </h6>
                                                            </div>
                                                        </TableCell>
                                                        
                                                        <TableCell>
                                                            <span onClick={() => {window.open(`${BASE_URL}images/unitfiles/${plan.file}`)}} style={{textDecoration:"underline", cursor:"pointer"}}> {plan.file} </span>
                                                        </TableCell>

                                                        <TableCell>

                                                            {
                                                                plan.isActive ? 
                                                                <span 
                                                                    className={classes.assignButton} 
                                                                    style={{
                                                                        background: "#D2D015",
                                                                        cursor: "context-menu",
                                                                        borderRadius: "5px",
                                                                        padding: "6px 17px",
                                                                        fontWeight: 600,
                                                                        letterSpacing: "0.03em",
                                                                        boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 
                                                                                    0px 2px 2px 0px rgba(0,0,0,0.14), 
                                                                                    0px 1px 5px 0px rgba(0,0,0,0.12)`
                                                                    }}
                                                                    
                                                                >ACTIVE</span>
                                                                :
                                                                <Button 
                                                                    className={classes.assignButton} 
                                                                    variant="contained" 
                                                                    component="span"
                                                                    onClick = {
                                                                        (event) => {
                                                                            event.stopPropagation();
                                                                            setFloorPlanIdToActivate(plan.id);
                                                                            setOpenActivateConfirmationDialog(true);
                                                                        }
                                                                    }
                                                                >                    
                                                                    Activate
                                                                </Button>
                                                            }

                                                        </TableCell>
                                                    </TableRow>
                                            ))
                                            :
                                            showDataFor==="prices" ?

                                                prices.data.map((priceData) => (

                                                    <TableRow 
                                                        className={`${classes.tableRow} ${priceData.isActive && classes.activeRow}`}                                                    
                                                        style={{backgroundColor: priceData.isActive && "#B1DFDC"}}
                                                        key={priceData.id}
                                                    >
                                                        
                                                        <TableCell>
                                                            {priceData["entryMadeBy"]}
                                                        </TableCell>
                                                        
                                                        <TableCell>
                                                            <div className="d-flex">
                                                                <Tooltip arrow title="Delete Price">
                                                                        <Delete onClick={(event) => {
                                                                            event.stopPropagation();                                                                             
                                                                            setOpenDeleteConfirmationDialog(true);
                                                                            setPriceIdToActivate(priceData.id);
                                                                            }} 
                                                                            style={{color:"crimson", marginRight:"5px"}}                                                                        
                                                                        /> 
                                                                </Tooltip>
                                                                <h6 className="mb-0"> {new Date(priceData.inputDate).toLocaleString().split(",")[0]} </h6>
                                                            </div>
                                                        </TableCell>
                                                        
                                                        <TableCell>
                                                            <span style={{textDecoration:"underline", cursor:"pointer"}}> {format2Currency(priceData.price)} </span>
                                                        </TableCell>

                                                        <TableCell>

                                                            {
                                                                priceData.isActive ? 
                                                                <span 
                                                                    className={classes.assignButton} 
                                                                    style={{
                                                                        background: "#D2D015",
                                                                        cursor: "context-menu",
                                                                        borderRadius: "5px",
                                                                        padding: "6px 17px",
                                                                        fontWeight: 600,
                                                                        letterSpacing: "0.03em",
                                                                        boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 
                                                                                    0px 2px 2px 0px rgba(0,0,0,0.14), 
                                                                                    0px 1px 5px 0px rgba(0,0,0,0.12)`
                                                                    }}
                                                                    
                                                                >ACTIVE</span>
                                                                :
                                                                <Button 
                                                                    className={classes.assignButton} 
                                                                    variant="contained" 
                                                                    component="span"
                                                                    onClick = {
                                                                        (event) => {
                                                                            event.stopPropagation();
                                                                            setPriceIdToActivate(priceData.id);
                                                                            setOpenActivateConfirmationDialog(true);
                                                                        }
                                                                    }
                                                                >                    
                                                                    Activate
                                                                </Button>
                                                            }
                                                            
                                                        </TableCell>
                                                    </TableRow>

                                                ))

                                            : null

                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Collapse>


                        </React.Fragment>
                        
                    : null
                                
                }
                { showDataFor === "floorPlan" ? 
                        <div className="action-button m-2 d-flex align-items-center justify-content-center">
                            <input
                                onChange={(event) => onUploadFileForFloorPlan(event.target.files[0])}
                                className={`${classes.inputFile} d-none`}                    
                                id={`upload-floor-plan-file`}   
                                type="file"                    
                            />

                            <label className="mb-0" htmlFor={`upload-floor-plan-file`}>
                                <Button className={classes.assignButton} variant="contained" component="span">                    
                                    Upload File
                                </Button>
                            </label>

                            {/* <Button
                                className={`${classes.assignButton} mr-3`} 
                                onClick={() => onSaveUnitsTableData("floorPlan")} 
                                variant="contained" 
                                component="span"
                            >
                                SAVE CHANGES
                            </Button> */}
                        </div>
                    :
                    showDataFor === "prices" ?
                        <div className="action-button m-2 d-flex align-items-center justify-content-center">
                        
                            <Button onClick={() => setOpenNewPriceDialog(true)} className={classes.assignButton} variant="contained" component="span">                    
                                Add New Price
                            </Button>

                            {/* <Button
                                className={`${classes.assignButton} mr-3`} 
                                onClick={() => onSaveUnitsTableData("prices")} 
                                variant="contained" 
                                component="span"
                            >
                                SAVE CHANGES
                            </Button> */}
                    </div>

                    : null }

            </List>

        </Dialog>

    )

}

export default UnitNumberDialog
