import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Checkbox, Button, Slide } from "@material-ui/core";
import { useLocation } from 'react-router';
import queryString from 'query-string';
import "../../Assets/css/iofrm-style.css";
import "../../Assets/css/iofrm-theme1.css";
import image1 from "../../Assets/img/graphic2.svg";
import axiosInstance from "../../Axios/axios";
import LoadingSpinner from "../../utils/LoadingSpinner";
import SnackbarToast from '../../utils/SnackbarToast';
import logger from "../../utils/logger";
import { API_VERSION, BASE_URL } from "../../Axios/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const METROENGINE_USER = 'METROENGINE-USER'

const ForgotPassword = ({history}) => {
    // const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [isSuccess, setIsSuccess] = useState(false);
    const otp = queryString.parse(location.search).otp;

    const handleSnackbarClose = () => {
       setOpenSnackbar(false);
       setSnackbarType("");
       setMessageForSnackbar("");
    }

    const handleResetPassword = (e) => {
      let form = document.getElementById('reset-form');
      if (!form.checkValidity()) {
        return;
      }

      e.preventDefault();
      let data = {
        code: otp,
        password: password,
        repeatPassword: confirmpassword
      }

      setIsLoading(true);
      axiosInstance.post('user/updatePasswordNew', data)
      .then(res => {
        logger({
          title:'User Update Password',
          url:window.location.href,
          method:'POST',
          activity: 'updatePasswordNew',
          user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
          api:BASE_URL+ API_VERSION +'user/updatePasswordNew' ,
          session:localStorage.getItem('session'),
          projectId:null,
          unitId:null,
          assignedId:null,
          })
        setIsLoading(false);
        if (res && res.data && res.data.status === 200) {
          setIsSuccess(true)
        }
        else {
          setIsSuccess(false)
          setOpenSnackbar(true);
          setSnackbarType("error");
          setMessageForSnackbar(res.data.message);
        }
      }).catch(e => {
        setIsSuccess(false)
        setIsLoading(false);
        setOpenSnackbar(true);
        setSnackbarType("error");
        setMessageForSnackbar(e.response.data.message);
      });
    }

    return (
    <div className="form-body" style={{backgroundColor: "#0092FF"}}>
      
      <LoadingSpinner isLoading={isLoading} />

      <SnackbarToast
          handleSnackbarClose={handleSnackbarClose}
          openSnackbar={openSnackbar}
          snackbarType={snackbarType}
          message={messageForSnackbar}
      />       

      <div className="row">
        <div className="img-holder" style={{backgroundColor:"#5CBBFF"}}>
          <div className="bg"></div>
          <div className="info-holder">
              <img src={image1} alt="" />
          </div>
        </div>
        {
          !isSuccess ? (
            <div className="form-holder">
            <div className="form-content"  style={{backgroundColor: "#0092FF"}}>
              <div className="form-items" style={{maxWidth: "450px"}}>
                <h3>Sell more and sell faster with Metroengine</h3>
                <p>Access the most powerful pre-sales tool for real estate.</p>
                <form id="reset-form" onSubmit={handleResetPassword}>
                  <label htmlFor="" style={{width:'100%'}}>
                  <input
                    className="form-control input-field"
                    type="password"
                    name="password"
                    placeholder="New Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{marginBottom:5}}
                  />
                  </label>
                  <label htmlFor="" style={{width:'100%'}}>
                  <input
                    className="form-control input-field"
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    required
                    value={confirmpassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{marginBottom:5}}
                  />
                  </label>
                  <div className="form-button">
                    <button id="submit" type="submit" className="ibtn" style={{color:"#263238"}}>
                      Reset Password
                    </button>{" "}
                    <Link to="/login">
                      <span className="text-white" style={{ cursor: "pointer", padding: "10px 6px"}}>Login</span>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          ) :
          (
            <div className="form-holder">
            <div className="form-content"  style={{backgroundColor: "#0092FF"}}>
              <h3>The password has been changed successfully,<Link to="/login" style={{ textDecoration: 'underline', color:'#ffffff' }}><span className="text-white" style={{ cursor: "pointer", padding: "10px 6px"}}>Login Here</span></Link></h3>
            </div>
           </div>
          )
        }
   
      </div>
    </div>
  );
};

export default ForgotPassword;
