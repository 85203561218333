import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Checkbox, FormControlLabel, Slide } from "@material-ui/core";

import "../../Assets/css/iofrm-style.css";
import "../../Assets/css/iofrm-theme1.css";
import image1 from "../../Assets/img/graphic2.svg";
import axiosInstance from "../../Axios/axios";
import { USER_ROLES } from "../../utils/const";
import LoadingSpinner from "../../utils/LoadingSpinner";
import SnackbarToast from '../../utils/SnackbarToast';
import { API_VERSION, BASE_URL } from "../../Axios/api";
import logger from "../../utils/logger";

// const useStyles = makeStyles((theme) => ({
//   primary:{
//     main:'#ffffff'
//   }
// }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const METROENGINE_USER = 'METROENGINE-USER'

const Login = ({setUserState,history}) => {
    // const classes = useStyles();
    const [state, setState] = useState({
        username:'',
        password:'',
        rememberme:false
    })
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [errors, setErrors] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      const code = new URLSearchParams(window.location.search).get('code');
      if(code) {
        
        axiosInstance.get(`/user/verifyEmail?code=${code}`)
          .then(res => {
            logger({
              title:'Verify Email',
              url:window.location.href,
              method:'GET',
              activity: 'verifyEmail',
              user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
              api:BASE_URL+ API_VERSION +'/verifyEmail' ,
              session:localStorage.getItem('session'),
              projectId:null,
              unitId:null,
              assignedId:null,
              })
             setOpenSnackbar(openSnackbar => !openSnackbar);
             setSnackbarType("success");
             setMessageForSnackbar("Email address verified!");
             setTimeout(() => {
               history.push('/login');
             }, 1500)
          })
          .catch(err => {
            if(err.response.data.status === 422) {
              
              setOpenSnackbar(openSnackbar => !openSnackbar);
              setSnackbarType("error");
              setMessageForSnackbar("Invalid code!");
              setTimeout(() => {
                history.push('/login');
              }, 1500)
            }
          })
      }
    }, [])

    const isFormValid = () =>{
      let isValid = true;
      const errorData = {};
      const regexForEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if(!regexForEmail.test(String(state.username).toLowerCase())) {
        errorData.username = "Enter a valid Email Address";
        isValid = false;
      }

      if(state.username === '' || state.username.trim() === ''){
        errorData.username = 'Email is required'
        isValid = false;
      }

      if(state.password === '' || state.password.trim() === ''){
        errorData.password = 'Password is required'
        isValid = false;
      }

      setErrors(errorData);
      return isValid;

    }

    const handleChange=({target})=>{
      if(target.name === 'rememberme'){
        setState({...state,[target.name]:target.checked})
        return;
      }

        setState({...state,[target.name]:target.value})
    }

    const handleLogin = async (e) => {
      e.preventDefault();
      localStorage.removeItem(METROENGINE_USER);
      localStorage.removeItem('token');
      const isValid = isFormValid();
      if(isValid){
        setIsLoading(true);
        const userData = await axiosInstance.post('/login', {
           ...state
          }).catch(err => { 
            if(err.response && err.response.data && err.response.data.status===422) {
                setIsLoading(false); 
                if(err.response.data.payload) {
                  setOpenSnackbar(openSnackbar => !openSnackbar);
                  if (err.response.data.payload.error === "Invalid credentials.") {
                    setSnackbarType("error");
                  } else {
                    setSnackbarType("warning");
                  }
                  setMessageForSnackbar(err.response.data.payload?.error);
                }
              };
          });

          if(userData && userData.data){
            setIsLoading(false);
            localStorage.setItem('token',userData.data.payload && userData.data.payload.token)
            if (userData.data.payload && userData.data.payload.user && userData.data.payload.user.role==="Admin") {
              localStorage.setItem('admin_data', userData.data.payload && JSON.stringify(userData.data.payload.user))
            }
            let user_role = userData.data.payload && userData.data.payload.user.role;
            localStorage.setItem("user_role", user_role);
            let userObj = { id : userData.data.payload.user.id, projectId : userData.data.payload.user.projectId}
            localStorage.setItem("user_data", JSON.stringify(userObj));
            //  if(state.rememberme){
            localStorage.setItem(METROENGINE_USER,userData.data.payload && userData.data.payload.user && userData.data.payload.user.id)
            //  }
            localStorage.setItem('session',userData.data.payload.session)
            setUserState({
              authenticated:true,
              token:userData.data.payload && userData.data.payload.token,
              ...userData.data.payload,
            })
            logger({
              title:'Login',
              url:window.location.href,
              method:'POST',
              activity: 'login',
              user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
              api:BASE_URL+ API_VERSION +'/login' ,
              session:localStorage.getItem('session'),
              projectId:null,
              unitId:null,
              assignedId:null,
              })
            history.push(user_role == USER_ROLES.AFFILIATE ? '/contacts' : user_role == USER_ROLES.UM ? '/users' : '/')
          }
      }
    }

    const handleSnackbarClose = () => {
       setOpenSnackbar(false);
       setSnackbarType("");
       setMessageForSnackbar("");
    }

    const openPopup = (url, e) => {
      e.preventDefault();
      var w = window.screen.width / 2;
      var h = window.screen.height / 2;
      var left = (window.screen.width / 2) - (w / 2);
      var top = (window.screen.height / 2) - (h / 2);
      window.open(url, 'MsgWindow', "width=" + w + ",height=" + h + ",top=" + top + ",left=" + left + ",scrollbars=yes,resizable=no,toolbar=no");
    }

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem(METROENGINE_USER)
    if(token && userId){
     return <Redirect to='/' from='/login' />
    }

    if(isLoading) {
      return <LoadingSpinner isLoading={isLoading}/>
    }

  return (
    <div className="form-body" style={{backgroundColor: "#0092FF"}}>
      
      <LoadingSpinner isLoading={isLoading} />

      <SnackbarToast
          handleSnackbarClose={handleSnackbarClose}
          openSnackbar={openSnackbar}
          snackbarType={snackbarType}
          message={messageForSnackbar}
      />       

      <div className="row">
        <div className="img-holder" style={{backgroundColor:"#5CBBFF"}}>
          <div className="bg"></div>
          <div className="info-holder">
              <img src={image1} alt="" />
          </div>
        </div>
        <div className="form-holder">
          <div className="form-content"  style={{backgroundColor: "#0092FF"}}>
            <div className="form-items" style={{maxWidth: "450px"}}>
              < h3 > Sell more and sell faster with < a href = "https://metroengine.com.au/"
              style = {
                {
                  color: "white",
                  cursor: "pointer",
                  'text-decoration': 'underline'
                }
              }
              target = 'blank' > Metroengine </a></h3>
              <p>Access the most powerful pre-sales tool for real estate.</p>
              <div className="page-links">
                <Link to="/login" className="active">
                  Login
                </Link>
                <Link to="/register">Register</Link>
              </div>
              <form>
                <label htmlFor="" style={{width:'100%'}}>
                <input
                  className="form-control input-field"
                  type="email"
                  name="username"
                  placeholder="E-mail Address"
                  required
                  value={state.username}
                  onChange={handleChange}
                  style={{marginBottom:5}}
                />
                <span style={{color:"darkred", fontWeight: 500, display:'block',minHeight:20}}>{errors && errors.username}</span>
                </label>
                <label htmlFor="" style={{width:'100%'}}>
                <input
                  className="form-control input-field"
                  type="password"
                  name="password"
                  placeholder="Password"
                  required
                  value={state.password}
                  onChange={handleChange}
                  style={{marginBottom:5}}
                />
                <span style={{color:"darkred", fontWeight: 500, display:'block',minHeight:20}}>{errors && errors.password}</span>
                </label>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.rememberme}
                      onChange={handleChange}
                      name="rememberme"
                      style={{
                        color:'#fff'
                      }}
                    />
                  }
                  label="Remember Me"
                />
                <div className="form-button">
                  <button id="submit" type="submit" className="ibtn" style={{color:"#263238"}} onClick={handleLogin}>
                    Login
                  </button>{" "}
                  <Link to="/forgotpassword">
                    <span className="text-white mt-1 d-block" style={{ cursor: "pointer", padding: "10px 6px"}}>Forgot password?</span>
                  </Link>
                </div>
              </form>
              <div className="d-flex align-items-center separated-horizontally">
                <a href="#" onClick={(e) => openPopup('/terms', e)} className="text-white pr-2">Terms of Service</a>
                <a href="#" onClick={(e) => openPopup('/privacy', e)} className="text-white px-2">Privacy Policy</a>
                <a href="#" onClick={(e) => openPopup('/cookie', e)} className="text-white pl-2">Cookie Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
