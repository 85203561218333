import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DoneAll, KeyboardArrowDown } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#263238',
    paddingRight: "0px !important"
  },
  title: {
    fontSize:"1rem",
    flex: 1,
  },
  assignButton: {
    color: "white",
    padding: "3px 10px",
    fontSize: "0.7rem",
    backgroundColor: "#26A69A",
    '&:hover': {
      backgroundColor: "#26A69A"
    }
  },
  col3: {
    position: "relative",
    width: "100%",
    paddingRight: ".625rem",
    paddingLeft: ".625rem",
    flex: "0 0 25%",
    maxWidth: "25%",
    [theme.breakpoints.down(555)]: {
      flex: "0 0 50% !important",
      maxWidth: "50% !important"
    }
  },
  col9: {
    position: "relative",
    width: "100%",
    paddingRight: ".625rem",
    paddingLeft: ".625rem",
    flex: "0 0 75%",
    maxWidth: "75%",
    [theme.breakpoints.down(555)]: {
      flex: "0 0 50% !important",
      maxWidth: "50% !important"
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function AssignProjectDialog({
    openAssignProjectDialog,
    onCloseAssignProjectDialog,
    assignProjectDetails,
    onSelectProjectForUser,
    projectsAssignedToUser,
    onSaveAssignedProjectsToUser,
    // userIds
}) {
  const classes = useStyles();
  return (
      <Dialog maxWidth="sm" fullWidth open={openAssignProjectDialog} onClose={onCloseAssignProjectDialog} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar style={{paddingLeft:"16px", paddingRight:"0px"}}>
            <Typography variant="h6" className={classes.title}>
              Assign Projects 
              { assignProjectDetails.user ? 
                ` to ${assignProjectDetails.user.firstName} ${assignProjectDetails.user.lastName}`  
                // : 
                // userIds ? 
                // ` to Selected Users`
                : null
              }
            </Typography>
            <Tooltip title="Assign Projects" arrow>

              <Button 
                className={`${classes.assignButton} mr-3`} 
                onClick={() => onSaveAssignedProjectsToUser(assignProjectDetails.user)} 
                variant="contained" 
                component="span"
              >
                Assign
              </Button>
            </Tooltip>
            <IconButton edge="start" color="inherit" onClick={onCloseAssignProjectDialog} aria-label="close">
              <CloseIcon />
            </IconButton>            
          </Toolbar>
        </AppBar>
        <List>
            {
              assignProjectDetails.projectsToAssign && assignProjectDetails.projectsToAssign.length===0 ? 
                <ListItem>
                  <ListItemText primary={"No Projects Found"}/>
                </ListItem>
                :
               
                (assignProjectDetails.user && assignProjectDetails.projectsToAssign) ?
                        <React.Fragment>
                          <ListItem>
                            <div className={`${classes.col3}`} style={{borderRight:"1px solid #000"}}>
                            <ListItemText>
                              <span><KeyboardArrowDown/></span>
                              <h6 className="font-weight-bold" style={{padding: "2px"}}>Unique ID</h6>
                            </ListItemText>
                            </div>
                            
                            <div className={`${classes.col9}`}>
                            <ListItemText>
                              <span><KeyboardArrowDown/></span>
                              <h6 className="font-weight-bold" style={{padding: "2px"}}>Project Name</h6>
                            </ListItemText>
                            </div>
                          </ListItem>
                        
                        {assignProjectDetails.projectsToAssign.map((project) => (
                          <React.Fragment>
                              <ListItem 
                                  className="row"
                                  autoFocus 
                                  button 
                                  onClick={() => onSelectProjectForUser(project)} 
                                  key={project.id}
                              >
                                <div className={`${classes.col3}`} style={{borderRight:"1px solid #000"}}>
                                  <ListItemText style={{paddingLeft: "5px", paddingRight: "5px", paddingTop: "13px"}}>
                                    <h6>ID: {project.id}</h6>
                                  </ListItemText>
                                </div>
                                <div className={`${classes.col9} d-flex align-items-center`}>
                                    { projectsAssignedToUser &&
                                      projectsAssignedToUser.length>0 && 
                                      projectsAssignedToUser.findIndex(proj => proj.id === project.id) !== -1 
                                      ? 
                                      <CheckCircleIcon style={{color:"green"}}/> 
                                      : 
                                      null
                                    }
                                    <ListItemText style={{marginLeft:"5px"}} primary={project.name} />
                                </div>
                              </ListItem>
                              <Divider/>
                          </React.Fragment>
                        ))}
                        </React.Fragment>
                    : null
            }

        </List>
      </Dialog>
  );
}