import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDHDJ4Of6N5NBnWwjVpfiZhmkRPY_6clN8",
//   authDomain: "metroengine-crm-4f454.firebaseapp.com",
//   projectId: "metroengine-crm-4f454",
//   storageBucket: "metroengine-crm-4f454.appspot.com",
//   messagingSenderId: "441244266668",
//   appId: "1:441244266668:web:5795d864722a5a76cfaf09",
//   measurementId: "G-0BCM3Q1LG4"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDraje6EcLVmoFUXSIsQJRoXxsJ0Uy9vvA",
  authDomain: "metroengine-crm.firebaseapp.com",
  databaseURL: "https://metroengine-crm-default-rtdb.firebaseio.com",
  projectId: "metroengine-crm",
  storageBucket: "metroengine-crm.appspot.com",
  messagingSenderId: "434944118654",
  appId: "1:434944118654:web:3b962bdc3d361a9ba5ad2f",
  measurementId: "G-5J9R59CLNB"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
