import { FormControl, InputLabel, makeStyles, MenuItem, Select, Switch, TextField } from '@material-ui/core'

import React from 'react'
import { australianStateList } from '../../../../../DummyData/australianStateList';
import { countryList } from '../../../../../DummyData/countryList';

const useStyles = makeStyles((theme) => ({

    heading: {
        borderBottom: "2px solid rgba(0, 0, 0, 0.12)",
        width: "max-content",
        marginBottom: "0",
        fontWeight: 500,
        color: "#5680e9"
    }

}))

function AdditionalDetailsDeveloperForm({
    state,
    errors,
    handleAdditionalDetailsChange,
    project,
    handleSwitchChange
}) {
    
    const classes = useStyles();

    return (
        
        <div className="col-md-6">

            <div style={{marginTop:"18px"}} className="additional-details d-flex align-items-center">

                <h5 className={classes.heading}>Additional Details</h5>

                <Switch

                    checked={state.checkedA}

                    onChange={handleSwitchChange}

                    color="primary"

                    name="checkedA"

                    inputProps={{ 'aria-label': 'primary checkbox' }}

                />

            </div>

            {

            state.checkedA ? 

            <React.Fragment>

                <TextField

                    margin="dense"

                    id="additionalDeveloperName"

                    label="Name"

                    name="additionalDeveloperName"

                    defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperName : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    id="additionalDeveloperAuthority"

                    label="Function/Authority"

                    placeholder="Investor, Finance Manager"

                    name="additionalDeveloperAuthority"

                    defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperAuthority : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    id="additionalDeveloperIncharge"

                    label="Person In Charge"

                    name="additionalDeveloperIncharge"

                    defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperIncharge : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    id="additionalDeveloperMobile"

                    label="Contact Number"

                    name="additionalDeveloperMobile"

                    defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperMobile : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    id="additionalDeveloperEmail"

                    label="Email Address"

                    name="additionalDeveloperEmail"

                    defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperEmail : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    id="additionalDeveloperAddress"

                    label="Address"

                    name="additionalDeveloperAddress"

                    defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperAddress : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <TextField

                    margin="dense"

                    id="additionalDeveloperPostalCode"

                    label="Postal code"

                    name="additionalDeveloperPostalCode"

                    helperText = {

                        errors.lengthError && project.additional_detail.additionalDeveloperPostalCode && isNaN(project.additional_detail.additionalDeveloperPostalCode.toString())

                        ?
                        errors.lengthError

                        :
                        errors.emptyError && project.additional_detail.additionalDeveloperPostalCode && project.additional_detail.additionalDeveloperPostalCode.trim().length === 0

                        ?
                        errors.emptyError

                        :

                        ""

                    }

                    error = {

                        (errors.lengthError && project.additional_detail.additionalDeveloperPostalCode && isNaN(project.additional_detail.additionalDeveloperPostalCode.toString())) ||

                        (errors.emptyError && project.additional_detail.additionalDeveloperPostalCode && project.additional_detail.additionalDeveloperPostalCode.trim().length === 0) ||

                        (errors.lengthError && project.additional_detail.additionalDeveloperPostalCode && isNaN(project.additional_detail.additionalDeveloperPostalCode.toString()))

                        ?
                        
                        true

                        :
                        
                        false

                    }

                    defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperPostalCode : ""}

                    onChange={handleAdditionalDetailsChange}

                    type="text"

                    fullWidth

                />

                <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                
                    <InputLabel
                    
                        id="demo-simple-select-label"
                    
                    >
                    
                        Country
                    
                    </InputLabel>
                    
                    <Select
                    
                    labelId="demo-simple-select-label"
                    
                    id="demo-simple-select"
                    
                    value={project.additional_detail ? project.additional_detail.additionalDeveloperCountry : ""}
                    
                    fullWidth
                    
                    name="additionalDeveloperCountry"
                    
                    onChange={handleAdditionalDetailsChange}
                    
                    >
                    
                    {countryList.map((country => (
                    
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                    
                    )))}
                    
                    </Select>
                
                </FormControl>

                { project.additional_detail && project.additional_detail.additionalDeveloperCountry !== "Australia" ? 
                
                    <TextField
                        
                        margin="dense"

                        id="additionalDeveloperState"

                        label="State"

                        name="additionalDeveloperState"

                        defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperState : ""}

                        onChange={handleAdditionalDetailsChange}

                        type="text"

                        fullWidth

                    />

                    : 

                    <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                        
                        <InputLabel
                        
                            id="demo-simple-select-label"
                        
                        >
                            State

                        </InputLabel>
        
                        <Select

                            margin="dense"

                            id="additionalDeveloperState"

                            label="State"

                            name="additionalDeveloperState"

                            defaultValue={project.additional_detail ? project.additional_detail.additionalDeveloperState : ""}

                            onChange={handleAdditionalDetailsChange}

                            type="text"

                            fullWidth

                        >
                            
                            {australianStateList.map((state => (

                                <MenuItem key={state} value={state}>{state}</MenuItem>

                            )))}

                        </Select>

                    </FormControl>

                }

            </React.Fragment>

            : null 
            
            }

        </div>

    )

}

export default AdditionalDetailsDeveloperForm
