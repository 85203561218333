import { IconButton, Toolbar, Typography, Tooltip, makeStyles, lighten, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React from 'react';
import clsx from 'clsx';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

export default function ContactsTableToolbar (props) {
  
  const classes = useToolbarStyles();
  const { numSelected, setFilteredData, setOpenDeleteContactsDialog } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component={"div"}>
          {numSelected} selected
        </Typography>
      ) : (
        <React.Fragment>
          <TextField
            margin="dense"        
            id="filter-user"        
            label="Filter By Name"        
            type="text"        
            style={{display:"block"}}        
            onChange={(e) => setFilteredData(e.target.value)}
          />   
        </React.Fragment>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={() => setOpenDeleteContactsDialog(true)} aria-label="delete">
            <Delete/>
          </IconButton>
        </Tooltip>
       )}
    </Toolbar>
  );
};