import { AppBar, Button, Dialog, IconButton, List, ListItem, makeStyles, Slide, TextField, Toolbar, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import React from 'react'


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'white',
    color: "black", 
    paddingRight: "0px !important"
  },
  title: {
    fontSize:"1rem",
    flex: 1,
  },
  assignButton: {
    color: "white",
    padding: "3px 10px",
    fontSize: "0.8rem",
    marginLeft: "10px",
    margin: "10px 0px",
    backgroundColor: "#26A69A",
    '&:hover': {
      backgroundColor: "#26A69A"
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function AddNewPriceDialog({
    setNewPrice,
    onSaveNewPrice,
    openNewPriceDialog,
    onCloseNewPriceDialog,
}) {
    const classes = useStyles();
    return (
        <Dialog maxWidth="sm" open={openNewPriceDialog} onClose={onCloseNewPriceDialog} TransitionComponent={Transition}>
             <AppBar className={classes.appBar}>
                <Toolbar style={{paddingLeft:"16px", paddingRight:"0px"}}>
                    <Typography variant="h6" className={classes.title}>
                      Add New Price 
                    </Typography>
                    <Button
                        className={`${classes.assignButton} mr-2`} 
                        onClick={() => onSaveNewPrice()} 
                        variant="contained" 
                        component="span"
                    >
                        Add
                    </Button>
                    <IconButton edge="start" color="inherit" onClick={onCloseNewPriceDialog} aria-label="close">
                        <Close />
                    </IconButton>            
                </Toolbar>
            </AppBar>
            <List>
                <ListItem>
                    <TextField fullWidth type="number" id="standard-basic" label="Price Value" onChange={(event) => setNewPrice(event.target.value)} />
                </ListItem>
            </List>
        </Dialog>
    )
}

export default AddNewPriceDialog
