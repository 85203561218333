import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Chip } from '@material-ui/core';
import HTMLReactParser from 'html-react-parser';
import { Edit } from '@material-ui/icons';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  editButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, setChangeTemplateIntoEditMode, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className="d-flex align-items-center" variant="h6">{children}</Typography>
      {onClose ? (
        <div className="d-flex align-items-center flex-wrap">
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function PreviewTemplateDialog({
    openPreviewTemplateDialog,
    onClosePreviewTemplateDialog,
    templateId,
    previewTemplateContent,
    setChangeTemplateIntoEditMode,
    changeTemplateIntoEditMode,
    setTemplateContent
}) {
  
  return (
      <Dialog fullScreen onClose={onClosePreviewTemplateDialog} aria-labelledby="customized-dialog-title" open={openPreviewTemplateDialog}>
        <DialogTitle id="template-title" setChangeTemplateIntoEditMode={setChangeTemplateIntoEditMode} onClose={onClosePreviewTemplateDialog}>
          Email Template ID : <Chip label={templateId} style={{backgroundColor: "crimson", height: "20px", color: "white", marginLeft: "10px"}}/>
        </DialogTitle>
        <DialogContent>
          <div className="template-content">
            {HTMLReactParser(previewTemplateContent.substring(148, previewTemplateContent.length - 7))}
          </div>
        </DialogContent>
      </Dialog>
  );
}