import { Avatar, Button, Card, CardContent, CardHeader, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import { deepPurple } from '@material-ui/core/colors';
import { Add, MoreVert } from '@material-ui/icons'
import React from 'react'
// import UserTableDetails from './UserTableDetails';
// import UserTableHeading from './UserTableHeading'
// import ProjectListIcon from '@material-ui/icons/PlaylistAddCheck';
import moment from 'moment';
import MELogo from '../../Assets/img/ME-logo.svg';
import { BASE_URL_IMAGE_USER } from '../../Axios/api';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "15px 0px",
        height: "210px",
        overflow: "auto !important"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
    }
}));

function ActivityCard({
    activity
}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenuHandler = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenuHandler = () => {
        setAnchorEl(null);
    };

    // const membershipPlan = () => {
    //     return Math.floor(Math.random() * 3);
    // }

    return (
        <Card className={classes.root}>
                       <CardHeader
                style={{ paddingLeft: "22px" }}
                avatar={
                    <Avatar src={MELogo} className={classes.purple}></Avatar>
                }
                title={
                    <div className="d-flex align-items-center">
                        {activity && activity.firstName && activity.lastName && <Typography style={{ fontSize: "0.9rem", fontWeight: 500 }}>{activity.firstName + ' '+activity.lastName}</Typography>}
                    </div>
                }
                subheader={<span style={{ fontSize: "0.8rem" }}>Created Date: {moment(activity.created_at).format('LL')}</span>}
            />
            <CardContent style={{ paddingTop: "0", paddingLeft: "22px" }}>
                <div className="row">

                    <div className="col-12">
                        {activity && activity.title && <Typography variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Activity Title: </span>
                            <span style={{ color: "slateblue", fontWeight: 500 }}>{activity.title}</span>
                        </Typography>}
                        {activity && activity.created_at && <Typography variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Activity Time: </span>
                            <span style={{ color: "slateblue", fontWeight: 500 }}>{moment(activity.created_at).format('LTS')}</span>
                        </Typography>}
                        {activity && activity.projectName && <Typography variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Project Name: </span>
                            <span style={{ color: "slateblue", fontWeight: 500 }}>{activity.projectName}</span>
                        </Typography>}
                        {activity && activity.session && <Typography variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Session ID: </span>
                            <span style={{ color: "slateblue", fontWeight: 500 }}>{activity.session}</span>
                        </Typography>}
                        {activity && activity.assign_firstName && activity.assign_lastName && <Typography variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Assign Name: </span>
                            <span style={{ color: "slateblue", fontWeight: 500 }}>{activity.assign_firstName + ' '+activity.assign_lastName}</span>
                        </Typography>}
                        {activity && activity.unitNo && <Typography variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Unit No: </span>
                            <span style={{ color: "slateblue", fontWeight: 500 }}>{activity.unitNo}</span>
                        </Typography>}
                        {activity && activity.sold_firstName && activity.sold_lastName && <Typography variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Sold By: </span>
                            <span style={{ color: "slateblue", fontWeight: 500 }}>{activity.sold_firstName + ' '+activity.sold_lastName}</span>
                        </Typography>}
                    </div>
                </div>

            </CardContent>
        </Card>
    )
}

export default ActivityCard
