export const urlToFile = (base64, filename) => {
  
  var arr = base64.split(',');
  if (arr.length <= 1) return null;

  var mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
          
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
      
  return new File([u8arr], filename, {type:mime});
};

export const toBase64 = (file) => {
  let validPattern = /image-*/;
  if (file.type.match(validPattern)) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
};

export function download_from_stream(data, filename)
{
  const blob = new Blob([data]);
  var objectUrl = URL.createObjectURL(blob);
  var element = document.createElement('a');
  element.download = filename;

  element.style.opacity = "0";
  document.body.appendChild(element);
  element.href = objectUrl; //'data:text/plain;charset=utf-8,'+encodeURIComponent(res); //
  element.click();
  document.body.removeChild(element);
}