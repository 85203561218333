import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, makeStyles, Slide, TextField, Toolbar, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#263238',
        paddingRight: "0px !important"
    },
    title: {
        fontSize: "1rem",
        flex: 1,
    },
    assignButton: {
        color: "white",
        padding: "3px 10px",
        fontSize: "0.7rem",
        backgroundColor: "#26A69A",
        '&:hover': {
            backgroundColor: "#26A69A"
        }
    },
    formControl: {
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function AddNewTabDialog({
    openAddNewTabDialog,
    onCloseAddNewTabDialog,
    newTabValue,
    setNewTabValue,
    addNewTabHandler
}) {

    const classes = useStyles();

    return (

        <Dialog maxWidth="sm" fullWidth open={openAddNewTabDialog} onClose={onCloseAddNewTabDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Add New Folder
                    </Typography>
                    
                    <IconButton edge="end" color="inherit" onClick={onCloseAddNewTabDialog} aria-label="close">
                        <Close />
                    </IconButton>            
                </Toolbar>
            </AppBar>

            <DialogContentText>
                <div className="ml-5 mr-5">
                    <TextField
                        required
                        margin="dense"
                        id="tabValue"
                        label="Folder Name"
                        type="text"
                        name="newTabValue"
                        value={newTabValue}
                        onChange={(e)=>setNewTabValue(e.target.value)}
                        fullWidth
                    />
                </div>
            </DialogContentText>

            <DialogActions>
                <Button onClick={addNewTabHandler} color="primary">
                    Add
                </Button>
                <Button onClick={onCloseAddNewTabDialog} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddNewTabDialog
