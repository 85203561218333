import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function SnackbarToast({message, snackbarType, openSnackbar, handleSnackbarClose}) {
    return (
        <Snackbar open={!!openSnackbar} autoHideDuration={5000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarType}>
            {message}
            </Alert>
      </Snackbar>
    )
}

export default SnackbarToast
