import { makeStyles } from '@material-ui/core';

import React from 'react'

const useStyles = makeStyles((theme) => ({

    headerTitle: {
        fontSize: "0.9rem",
        textTransform: "uppercase",
        paddingTop: "5px",
        paddingBottom: "5px",
        letterSpacing: "0.1em",
        backgroundColor: "rgb(192,229,226)",
        borderRadius: "6px",
        paddingLeft: "10px",
        fontWeight: 600,
        marginLeft: "auto",
        marginRight: "auto"
    },

    additionalDetailsTable: {
        marginLeft: "auto",
        marginRight: "auto"
    },

    tableRow: {
        backgroundColor: "white",
        width: "50%"
    },

    tableDescriptionHeading: {
        fontWeight: "bold",
        padding: "2px 10px !important"
    },

    tableDescriptionBody: {
        padding: "2px 10px !important"
    },

    root: {

        marginTop: '1rem',

        [theme.breakpoints.down(1445)]: {
            marginTop: '1rem'
        }
    }

}))

function AdditionalDetailsTable({
    additionalName,
    additionalAuthority,
    additionalIncharge,
    additionalMobile,
    additionalEmail,
    additionalAddress,
    additionalPostalCode,
    additionalState,
    additionalCountry
}) {

    const classes = useStyles();

    return (
        
        <div className={`${classes.root} col-xl-4 col-lg-6 col-md-6 col-sm-6`}>
            
            <h5 className={`${classes.headerTitle}`}>Additional Details</h5>

            <table className={`${classes.additionalDetailsTable} table table-bordered`}>

                <tbody>
            
                    <tr className={classes.tableRow}> 
            
                        <td className={classes.tableDescriptionHeading}>Name</td>
            
                        <td className={classes.tableDescriptionBody}>

                            { additionalName ? additionalName : "-" }
                        
                        </td>

                    </tr>

                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Function/Authority</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { additionalAuthority ? additionalAuthority : "-" }
                    
                        </td>
                    
                    </tr>
                    
                    <tr className={classes.tableRow}> 
                        
                        <td className={classes.tableDescriptionHeading}>Person In Charge</td>
                        
                        <td className={classes.tableDescriptionBody}>
                        
                            { additionalIncharge ? additionalIncharge : "-" }

                        </td>
                    
                    </tr>
                    
                    <tr className={classes.tableRow}> 
                        
                        <td className={classes.tableDescriptionHeading}>Contact Number</td>
                        
                        <td className={classes.tableDescriptionBody}>
                        
                            { additionalMobile ? additionalMobile : "-" }

                        </td>
                    
                    </tr>

                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Email Address</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { additionalEmail ? additionalEmail : "-" }
                    
                        </td>
                    
                    </tr>
                    
                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Address</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { additionalAddress ? additionalAddress : "-" }
                    
                        </td>
                    
                    </tr>
                    
                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Postcode</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { additionalPostalCode ? additionalPostalCode : "-" }
                    
                        </td>
                    
                    </tr>
                    
                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>State</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { additionalState ? additionalState : "-" }
                    
                        </td>

                    </tr>
                    
                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Country</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { additionalCountry ? additionalCountry : "-" }
                    
                        </td>

                    </tr>

                </tbody>
        
            </table>
        
        </div>

    )

}

export default AdditionalDetailsTable
