import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Input, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'

import React, { useState } from 'react'
import ContactsTabs from '../ContactsTabs/ContactsTabs'

const useStyles = makeStyles((theme) => ({

    formControl: {
        width: "100%"
    }

}))

function TabTransitionDialog({
    openTabTransitionDialog,
    onCloseTabTransitionDialog,
    onSwitchToDifferentTab,
    contactsTabs,
    tabValue
}) {

    const classes = useStyles();
    const [switchToTab, setSwitchToTab] = useState(tabValue);

    return (

        <Dialog
        
            open={openTabTransitionDialog}
        
            onClose={onCloseTabTransitionDialog}
        
            aria-labelledby="alert-dialog-title"
        
            aria-describedby="alert-dialog-description"
        
        >
        
            <DialogTitle className="pb-0">Switch to different tab</DialogTitle>
            
            <DialogContent>

                <form className={classes.container}>
                
                    <FormControl className={classes.formControl}>
                    
                        <InputLabel id="demo-dialog-select-label">Tab</InputLabel>
                    
                        <Select
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            value={switchToTab}
                            onChange={(e) => setSwitchToTab(e.target.value)}
                            input={<Input />}
                        >
                    
                            {
                                contactsTabs.map((tab,index) => (
                                    <MenuItem value={index}>{tab}</MenuItem>
                                ))
                            }

                        </Select>
                    
                    </FormControl>

                </form>

            </DialogContent>
        
            <DialogActions>
        
                <Button
        
                onClick={() => onSwitchToDifferentTab(switchToTab)}
        
                color="primary"
        
                >
        
                Switch
        
                </Button>
        
                <Button
        
                onClick={onCloseTabTransitionDialog}
        
                color="primary"
        
                autoFocus
        
                >
        
                Cancel
        
                </Button>
        
            </DialogActions>
        
        </Dialog>
    
    )

}

export default TabTransitionDialog
