import React, { useEffect, useState } from 'react'
import { API_VERSION, BASE_URL } from '../../Axios/api';
import axiosInstance from '../../Axios/axios';
import logger from '../../utils/logger';

function AcceptProject(props) {
    
    useEffect(() => {
        const {inviteCode} = props.match.params

        if(!inviteCode) {
            props.history.push('/login')
        }
        axiosInstance.get('/mangerAcceptProject', {
            params: {
                code: inviteCode
            }
        })
            .then(res => {
                logger({
                    title:'Accept Manger Project',
                    url:window.location.href,
                    method:'GET',
                    activity: 'mangerAcceptProject',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION+'/mangerAcceptProject',
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:null,
                    assignedId:null,
                    })                                
                if(res.data.status === 200) {
                    props.history.push('/login')
                }
            })
            .catch(err => {
                if (err.response.data.status === 422) {
                    alert(err.response.data.message);
                }
                setTimeout(() => {
                    props.history.push('/login')
                }, 500)
            })
    }, [])

    return (
        <div>
            
        </div>
    );
}

export default AcceptProject
