import React, {useState} from 'react'

import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core'

import { ExpandMore } from '@material-ui/icons'

import ProjectDocumentDetails from './ProjectDocumentDetails';

import UpgradeProjectPlan from './UpgradeProjectPlan';

const useStyles = makeStyles((theme) => ({
    
    root: {
        width: "100%"
    },

    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
    },

    expandMoreIcon: {
        color: "white", 
        borderRadius: "50%", 
        border: "2px solid white"
    },

    accordionSummary: {
        background: "#26a69a", 
        color: "white"
    },

    accordionDetails: {
        padding: "16px", 
        display: "block"
    }

}));

function ProjectDocumentsAccordion({
    marketingDocuments,
    userRole,
    deployed,
    document_size,
    total_document_size,
    floorplan_size,
    total_floorplan_size,
    onMarketingDocumentChangeHandler,
    onDocumentDownloadHandler,
    onDocumentDeleteHandler,
    onFloorPlanUpdateSuccess,
    onDocumentUpdateSuccess,
    projectId
}) {

    const classes = useStyles();
    return (
    
        <div className={classes.root}>
        
            <Accordion className="mb-2">
        
                <AccordionSummary
        
                    expandIcon={<ExpandMore className={classes.expandMoreIcon}/>}
            
                    aria-controls="panel1a-content"
            
                    id="panel1a-header"

                    className={classes.accordionSummary}
        
                >
        
                    <Typography className={classes.heading}>{"Project Documents".toUpperCase()}</Typography>
        
                </AccordionSummary>
            
                <AccordionDetails className={classes.accordionDetails}>
            
                    <UpgradeProjectPlan title="Documents" used={document_size} total={total_document_size} onUpdateSuccess={onDocumentUpdateSuccess} projectId={projectId} />
                    <UpgradeProjectPlan title="Floor plans" used={floorplan_size} total={total_floorplan_size} onUpdateSuccess={onFloorPlanUpdateSuccess} projectId={projectId}/>
            
                    <div className="row">                        
            
                        {   marketingDocuments.map(document => (
            
                                <ProjectDocumentDetails
                                    key={document.id}
                                    category_id={document.id}
                                    deployed={deployed}
                                    onMarketingDocumentChangeHandler={onMarketingDocumentChangeHandler}
                                    onDocumentDownloadHandler={onDocumentDownloadHandler}
                                    onDocumentDeleteHandler={onDocumentDeleteHandler}
                                    category_title={document.category_title}
                                    files = {document.files}
                                    userRole={userRole}
                                /> 
            
                            ))
            
                        }                                           
            
                    </div>
            
                </AccordionDetails>
            
            </Accordion>
        
        </div>
    
    )

}

export default ProjectDocumentsAccordion
