import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import React, { useEffect, useState } from "react";

import ImageCrop from "../../../../UI/ImageCrop";

import axiosInstance from "../../../../../Axios/axios";

import { urlToFile } from '../../../../../utils/file';

import ImageUpload from "./ImageUpload";

import MainProjectForm from "./MainProjectForm";
import SnackbarToast from "../../../../../utils/SnackbarToast";
import LoadingSpinner from "../../../../../utils/LoadingSpinner";
import MetroEngineLogo from '../../../../../Assets/img/img1.jpg'
import { australianStateList } from "../../../../../DummyData/australianStateList";
import logger from "../../../../../utils/logger";
import { API_VERSION, BASE_URL } from "../../../../../Axios/api";

const INITIAL_PROJECT_STATE = {
  id: "",
  name: "",
  address: "",
  postalCode: "",
  state: "",
  country: "",
  client: "",
  noOfUnits: "",
  totalUnits: "",
  commercialSpace: "",
  totalCommercialSpace: "",
  threeDUrl: "",
  template_ids: "",
  projectDescription: "",
  size: "",
  totalSize: "",
  developerCompany: "",
  developerPostalCode: "",
  developerIncharge: "",
  developerMobile: "",
  developerEmail: "",
  developerAddress: "",
  architectIncharge: "",
  architectMobile: "",
  architectEmail: "",
  architectAddress: "",
  architectPostalCode: "",
  architectCompany: "",
  additional_detail: {
    additionalDeveloperName: "",
    additionalDeveloperAuthority: "",
    additionalDeveloperIncharge: "",
    additionalDeveloperMobile: "",
    additionalDeveloperEmail: "",
    additionalDeveloperAddress: "",
    additionalDeveloperPostalCode: "",
    additionalDeveloperState: "",
    additionalDeveloperCountry: "",
    additionalArchitectName: "",
    additionalArchitectAuthority: "",
    additionalArchitectIncharge: "",
    additionalArchitectMobile: "",
    additionalArchitectEmail: "",
    additionalArchitectAddress: "",
    additionalArchitectPostalCode: "",
    additionalArchitectState: "",
    additionalArchitectCountry: "",
    additionalName1: "",
    additionalAuthority1: "",
    additionalIncharge1: "",
    additionalMobile1: "",
    additionalEmail1: "",
    additionalAddress1: "",
    additionalPostalCode1: "",
    additionalState1: "",
    additionalCountry1: "",
    additionalName2: "",
    additionalAuthority2: "",
    additionalIncharge2: "",
    additionalMobile2: "",
    additionalEmail2: "",
    additionalAddress2: "",
    additionalPostalCode2: "",
    additionalState2: "",
    additionalCountry2: "",
    additionalName3: "",
    additionalAuthority3: "",
    additionalIncharge3: "",
    additionalMobile3: "",
    additionalEmail3: "",
    additionalAddress3: "",
    additionalPostalCode3: "",
    additionalState3: "",
    additionalCountry3: "",
    additionalName4: "",
    additionalAuthority4: "",
    additionalIncharge4: "",
    additionalMobile4: "",
    additionalEmail4: "",
    additionalAddress4: "",
    additionalPostalCode4: "",
    additionalState4: "",
    additionalCountry4: ""
  },

};

const DEFAULT_IMAGE = MetroEngineLogo;

const ProjectFormDialog = ({
  setCurrentProjectDetails,
  openAddProjectDialog,
  projectsList,
  setProjectsList,
  setOpenAddProjectDialog,
  setOpenDeleteProjectDialog,
  selectedProject,
  setOpen
}) => {

  const [project, setProject] = useState({});

  const [errors, setErrors] = useState({});

  const [imageFile, setImageFile] = useState(null);

  const [openImageCropper, setOpenImageCropper] = useState(false);

  const [setFinalImage] = useState("");

  const [image, setImage] = useState(DEFAULT_IMAGE);

  const [state, setState] = React.useState({

    checkedA: true,

    checkedB: true,

    checked1: true,

    checked2: true,

    checked3: true,

    checked4: true

  });

  const [openSnackbar, setOpenSnackbar] = useState("");

  const [messageForSnackbar, setMessageForSnackbar] = useState("");

  const [snackbarType, setSnackbarType] = useState("");

  const [maxCount] = useState(1500);

  const [charactersLength, setCharactersLength] = useState(0);

  const [maxLimitReached, setMaxLimitReached] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleSwitchChange = (event) => {

    setState({

      ...state,

      [event.target.name]: event.target.checked

    });

    project.additional_detail[event.target.name] = event.target.checked;

    setProject(project);


  };

  useEffect(() => {

    if (openAddProjectDialog) {

      setProject(INITIAL_PROJECT_STATE);

      setImage(DEFAULT_IMAGE);

    }

    if (selectedProject) {

      setProject(selectedProject);

      setImage(selectedProject.image ? selectedProject.image : DEFAULT_IMAGE);

    }

  }, [selectedProject, openAddProjectDialog]);

  const onSaveProjectHandler = async () => {

    // project.additional_detail.checked1= state.checked1,
    // project.additional_detail.checked2= state.checked2,
    // project.additional_detail.checked3= state.checked3,
    // project.additional_detail.checked4= state.checked4
    console.log('newProject')
    console.log(project)


    if (

      (project.name && project.name.length === 0) ||

      (project.address && project.address.length === 0) ||

      (project.country && project.country.length === 0) ||

      (project.postalCode && project.postalCode.length === 0) ||

      (project.client && project.client.length === 0) ||

      (project.noOfUnits && project.noOfUnits.length === 0) ||

      (project.commercialSpace && project.commercialSpace.length === 0) ||

      (project.developerCompany && project.developerCompany.length === 0) ||

      (project.architectCompany && project.architectCompany.length === 0)

    ) {

      setErrors({ emptyError: "This field cannot be empty" });

      return;

    }

    if (project.country === "Australia" && (!project.state || project.state.trim().length === 0)) {

      setErrors({ emptyStateError: "This field cannot be empty" })

    }

    else if (

      !(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(project.threeDUrl))

    ) {

      setErrors({ syntaxError: "Please enter a valid URL" })

      return;

    }

    else if (

      isNaN(project.postalCode) ||

      (project.developerPostalCode && isNaN(project.developerPostalCode)) ||

      (project.architectPostalCode && isNaN(project.architectPostalCode)) ||

      (project.additional_detail.additionalDeveloperPostalCode && isNaN(project.additional_detail.additionalDeveloperPostalCode)) ||

      (project.additional_detail.additionalArchitectPostalCode && isNaN(project.additional_detail.additionalArchitectPostalCode))

    ) {

      setErrors({ lengthError: 'Postal code should be a valid number' })

      return;

    }

    else if (

      isNaN(project.noOfUnits) ||

      isNaN(project.commercialSpace)

    ) {

      setErrors({ typeError: "Enter valid number for this field" });

      return;

    }

    else if (maxLimitReached) {

      setErrors({ limitError: "Maximum limit reached" });

      return;

    }

    else {

      setErrors({});

      // const id = projectsList.length;

      // image,

      const newProject = { ...project };

      delete newProject.image;

      const formData = new FormData();
      console.log('formData: ', formData);
      formData.append('url', window.location.origin);
      if (selectedProject && selectedProject.id !== '') {

        formData.append('id', selectedProject.id);

      }

      if((selectedProject && selectedProject.image !== image) || (!selectedProject && image !== DEFAULT_IMAGE)){

        const imageBlob = await urlToFile(image,'project1','image/png');

        formData.append('image',imageBlob);

      }

      for (let key in project) {

        if (project.hasOwnProperty(key)) {

          if(key === "additional_detail") {

            for (let detail in project[key]) {

              formData.append(`${detail}`, project[key][detail])

            }

            continue;

          }

          if(key !=='id' && key !=='image'){

            if(key==="updated_at") {

              formData.append(`${key}`, new Date().toISOString())

              continue;

            }

            if (key === 'template_ids') {

              console.log(project);
              formData.append(`${key}`, project["template_ids"].split(","));
            }

            // if(project[key] || project[key]===0) {

              formData.append(key, project[key]);

            // }

          }

        }

      }

      if (project.id !== "") {

        setIsLoading(true);

        const token = localStorage.getItem("token");

        if (!token) {

          localStorage.clear();

          window.location.reload();

          return;

        }

        const editProjectRes = await axiosInstance

          .post(`/projectUpdate/${selectedProject.id}`, formData, {

            headers: {

              Authorization: "Bearer " + token

            }

          })
          .then(
            logger({
              title:'Update Project',
              url:window.location.href,
              method:'POST',
              activity: 'projectUpdate',
              user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
              api:BASE_URL+ API_VERSION+`/projectUpdate/${selectedProject.id}`,
              session:localStorage.getItem('session'),
              projectId:selectedProject.id,
              unitId:null,
              assignedId:null,
              })                          
          )
          .catch((err) => {

            if (err.response.data.status === 422) {

              setIsLoading(false);

              setOpenSnackbar(openSnackbar => !openSnackbar);

              setSnackbarType("error");

              if(err.response.data.payload && err.response.data.payload.error) {

                setMessageForSnackbar(err.response.data.payload.error);

              }

              else {

                setMessageForSnackbar(err.response.data.message);

              }

            }

            if (err.response.data.status === 500) {

              setIsLoading(false);

              setOpenSnackbar(openSnackbar => !openSnackbar);

              setSnackbarType("error");

              setMessageForSnackbar(err.response.data.message);

            }

          });

        if (editProjectRes) {

          setIsLoading(false);

          setCurrentProjectDetails({

            id: project.id,

            created_at: project.created_at,

            updated_at: new Date(),

            ...newProject,

            image,

          })

          setOpenSnackbar(true);

          setSnackbarType("success");

          setMessageForSnackbar("Project updated successfully");

          setOpenAddProjectDialog(false);
          setTimeout(()=> {

            window.location.reload();

          }, 500);

        }

      } else {

        setIsLoading(true);

        const token = localStorage.getItem("token");

        if(!token) {

          localStorage.clear();

          window.location.reload();

          return;

        }

        const addProjectRes = await axiosInstance

          .post("/projectCreate", formData, {

            headers: {

              Authorization: "Bearer " +token

            }

          })
          .then(
            logger({
              title:'Create Project',
              url:window.location.href,
              method:'POST',
              activity: 'projectCreate',
              user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
              api:BASE_URL+ API_VERSION+'/projectCreate',
              session:localStorage.getItem('session'),
              projectId:null,
              unitId:null,
              assignedId:null,
              })                          
          )
          .catch((err) => {

            if(err.response.data.status===422) {

              setIsLoading(false);

              setOpenSnackbar(openSnackbar => !openSnackbar);

              setSnackbarType("error");

              if(err.response.data.payload && err.response.data.payload.error) {

                setMessageForSnackbar(err.response.data.payload.error);

              } else {

                setMessageForSnackbar(err.response.data.message);

              }

            }

            if (err.response.data.status === 500) {

              setIsLoading(false);

              setOpenSnackbar(openSnackbar => !openSnackbar);

              setSnackbarType("error");

              setMessageForSnackbar(err.response.data.message);

            }

          });

        if (addProjectRes) {

          setIsLoading(false);

          setOpenAddProjectDialog(false);

          setOpenSnackbar(openSnackbar => !openSnackbar);

          setSnackbarType("success");

          setMessageForSnackbar("Project created successfully");

          setTimeout(()=> {

            window.location.reload();

          }, 500);

          // setProjectsList([

          //   ...projectsList,

          //   {

          //     id: new Date().getTime(),

          //     image,

          //     created_at: new Date(),

          //     updated_at: new Date(),

          //     ...newProject,

          //   },

          // ]);

          setProject(INITIAL_PROJECT_STATE);

          // setOpenDeleteProjectDialog(false);

        }

        // setIsEditModeOn(false);

        // setActions(false)

      }

    }

  };

  const onCloseAddProjectDialogHandler = () => {

    setOpenAddProjectDialog(false);

    setErrors({});

    // resetForm();

    // setOpenDeleteProjectDialog(false);

  };


  const handleImageSave = (newImg) => {

    setImage(newImg);

    setOpenImageCropper(false);

  };

  const handleImageDiscard = (newImg) => {

    setOpenImageCropper(false);

  };

  const handleChange = ({ target }) => {

    if (target.name === "country" && target.value === "Australia" && !australianStateList.includes(project.state)) {

      setProject({ ...project, state: "", country: target.value });

      return;

    };

    if (target.name === "developerCountry" && target.value === "Australia" && !australianStateList.includes(project.developerState)) {

      setProject({ ...project, developerState: "", developerCountry: target.value });

      return;

    };

    if (target.name === "architectCountry" && target.value === "Australia" && !australianStateList.includes(project.architectState)) {

      setProject({ ...project, architectState: "", architectCountry: target.value });

      return;

    };

    if (target.name === "templateIDs") {

      setProject({ ...project, "template_ids": target.value });

      return;

    };

    setProject({ ...project, [target.name]: target.value });

  };

  const handleAdditionalDetailsChange = ({ target }) => {

    if (target.name === "additionalDeveloperCountry" && target.value === "Australia" && !australianStateList.includes(project.additional_detail.additionalDeveloperState)) {

      setProject({ ...project, additional_detail: { ...project.additional_detail, additionalDeveloperState: "", additionalDeveloperCountry: target.value } });

      return;

    };

    if (target.name === "additionalArchitectCountry" && target.value === "Australia" && !australianStateList.includes(project.additional_detail.additionalArchitectState)) {

      setProject({ ...project, additional_detail: { ...project.additional_detail, additionalArchitectState: "", additionalArchitectCountry: target.value } });

      return;

    };


    if (target.name === "additionalCountry1" && target.value === "Australia" && !australianStateList.includes(project.additional_detail.additionalState1)) {

      setProject({ ...project, additional_detail: { ...project.additional_detail, additionalState1: "", additionalCountry1: target.value } });

      return;

    };


    if (target.name === "additionalCountry2" && target.value === "Australia" && !australianStateList.includes(project.additional_detail.additionalState2)) {

      setProject({ ...project, additional_detail: { ...project.additional_detail, additionalState2: "", additionalCountry2: target.value } });

      return;

    };


    if (target.name === "additionalCountry3" && target.value === "Australia" && !australianStateList.includes(project.additional_detail.additionalState3)) {

      setProject({ ...project, additional_detail: { ...project.additional_detail, additionalState3: "", additionalCountry3: target.value } });

      return;

    };


    if (target.name === "additionalCountry4" && target.value === "Australia" && !australianStateList.includes(project.additional_detail.additionalState4)) {

      setProject({ ...project, additional_detail: { ...project.additional_detail, additionalState4: "", additionalCountry4: target.value } });

      return;

    };

    setProject({ ...project, additional_detail: { ...project.additional_detail, [target.name]: target.value } })

  }

  const handleFileSelect = async ({ target: { files } }) => {

    // const convertedFile = await toBase64(files[0])

    setImageFile(files[0]);

    setOpenImageCropper(true);

  }

  const handleSnackbarClose = () => {

    setOpenSnackbar(false);

    setSnackbarType("");

    setMessageForSnackbar("");

  }

  return (

    <React.Fragment>

      <LoadingSpinner isLoading={isLoading} />

      <SnackbarToast

        handleSnackbarClose={handleSnackbarClose}

        openSnackbar={openSnackbar}

        snackbarType={snackbarType}

        message={messageForSnackbar}

      />

      <Dialog

        // style={{zIndex:11111}}

        open={openAddProjectDialog}

        // onClose={onCloseAddProjectDialogHandler}

        aria-labelledby="form-dialog-title"

      >

        <DialogTitle className="text-center" id="form-dialog-title">

          <ImageUpload image={image} handleFileSelect={handleFileSelect} />

        </DialogTitle>

        <Dialog

          open={openImageCropper}

          onClose={() => setOpenImageCropper(false)}

        >

          <DialogContent>

            <ImageCrop

              setFinalImage={setFinalImage}

              aspectRatio={16 / 9}

              imageFile={imageFile}

              handleImageSave={handleImageSave}

              handleImageDiscard={handleImageDiscard}

            />

          </DialogContent>

        </Dialog>

        <DialogContent>

          <MainProjectForm

            errors={errors}

            project={project}

            handleChange={handleChange}

            handleAdditionalDetailsChange={handleAdditionalDetailsChange}

            handleSwitchChange={handleSwitchChange}

            state={state}

            setMaxLimitReached={setMaxLimitReached}

            charactersLength={charactersLength}

            setCharactersLength={setCharactersLength}

            maxCount={maxCount}

          />

        </DialogContent>

        <DialogActions>

          <React.Fragment>

            <Button color="primary" onClick={onSaveProjectHandler}>

              Save

            </Button>

            <Button onClick={onCloseAddProjectDialogHandler} color="primary">

              Cancel

            </Button>

          </React.Fragment>

        </DialogActions>

      </Dialog>

    </React.Fragment>

  );

};



export default ProjectFormDialog;


