import { createContext } from 'react';

export const AuthContext = createContext({
  authenticated:false,
  token:'',
  user:null,
  contactIds: [],
  adminData: {},
  updateUser:()=>{},
  logout:()=>{},
  loadingUser:false,
  fetchUserData:()=>{}  
})