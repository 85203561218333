import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { activityList as activityListData } from '../../DummyData/activityList';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({

    criteria: {
        '&:hover': {
            backgroundColor: "rgb(38, 166, 154)",
            color: "white"
        },
        '&:focus': {
            backgroundColor: "rgb(38, 166, 154)",
            color: "white",
        }
    }

}))

function FilterActivity({
    setActivityUserFilter,
    activityUserFilter,
    setSoldByFilter,
    soldByFilter,
    soldListUsers,
    activityUserFilterList,
    setSoldByActivityFilter,
    soldByActivityFilter,
    setActivityDateFromFilter,
    activityDateFromFilter,
    setActivityDateToFilter,
    activityDateToFilter
}) {
    const classes = useStyles();
    const [startDate, setStartDate] = useState(new Date());

    return (

        <Fragment>
            <FormControl style={{ marginTop: "10px", marginBottom: "1rem", marginRight: "15px" }}>
                <InputLabel

                    id="demo-simple-select-label"
                >
                    Activity User
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={activityUserFilter}
                    style={{ width: 180 }}
                    onChange={(event) => setActivityUserFilter(event.target.value)}
                >
                    <MenuItem className={classes.criteria} value="Show All">Show All</MenuItem>
                    {activityUserFilterList.map(user => (
                        <MenuItem key={user.id} className={classes.criteria} value={user.id}>{user.firstName + ' ' + user.lastName}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl style={{ marginTop: "10px", marginBottom: "1rem", marginRight: "15px" }}>
                <InputLabel

                    id="demo-simple-select-label"
                >
                    Sold By Agent
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={soldByFilter}
                    style={{ width: 180 }}
                    onChange={(event) => setSoldByFilter(event.target.value)}
                >
                    <MenuItem className={classes.criteria} value="Show All">Show All</MenuItem>
                    {soldListUsers.map(user => (
                        <MenuItem key={user.id} className={classes.criteria} value={user.id}>{user.firstName + ' ' + user.lastName}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl style={{ marginTop: "10px", marginBottom: "1rem", marginRight: "15px" }}>
                <InputLabel

                    id="demo-simple-select-label"
                >
                    Activities
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={soldByActivityFilter}
                    style={{ width: 180 }}
                    onChange={(event) => setSoldByActivityFilter(event.target.value)}
                >
                    <MenuItem className={classes.criteria} value="Show All">Show All</MenuItem>
                    {Object.entries(activityListData).map(([key, val], i) => (
                        <MenuItem key={key} className={classes.criteria} value={key}>{val}</MenuItem>
                    ))}
                </Select>
            </FormControl>
                <FormControl style={{ marginTop: "14px", marginBottom: "1rem", marginRight: "15px" }}>
                <DatePicker
                    selected={activityDateFromFilter}
                    onChange={(date) => setActivityDateFromFilter(date)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    isClearable={true}
                    placeholderText="From Date"
                    className="date-picker"

                />
                </FormControl>
                <FormControl style={{ marginTop: "14px", marginBottom: "1rem"  }}>
                <DatePicker
                    selected={activityDateToFilter}
                    onChange={(date) => setActivityDateToFilter(date)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    isClearable={true}
                    placeholderText="To Date"
                    className="date-picker"

                />
                 </FormControl>
        </Fragment>
    )
}

export default FilterActivity
