import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ContactsTableHead from './ContactsTableHead/ContactsTableHead';
import ContactsTableToolbar from './ContactsTableToolbar/ContactsTableToolbar';
import { Edit } from '@material-ui/icons';
import { Chip, Fab, Tooltip } from '@material-ui/core';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    border: "none !important"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ContactsTable({
  contactsLead,
  onOpenEditContactDialog,
  selected,
  setSelected,
  setOpenDeleteContactsDialog
}) {
  
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [filteredData, setFilteredData] = useState('');
    const [copyOfRows, setCopyOfRows] = useState([]);

    useEffect(() => {
  
        if (contactsLead?.length) setCopyOfRows(contactsLead);
        else setCopyOfRows([]);
    
    }, [contactsLead])
    
    useEffect(() => {

        if (filteredData.length > 0) {
            setCopyOfRows(contactsLead);
            const filteredList = contactsLead.filter(row => 
                                                            row.firstName && row.firstName.toString().toLowerCase().includes(filteredData.trim().toLowerCase()) ||
                                                            row.lastName && row.lastName.toString().toLowerCase().includes(filteredData.trim().toLowerCase()))
            setCopyOfRows(filteredList);
        } 
        else if (!contactsLead?.length) {
          
          setCopyOfRows([]);
        }
        
        else setCopyOfRows(contactsLead);

    }, [filteredData])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        
        if (event.target.checked) {

          const newSelecteds = copyOfRows.map((n) => n.id);
          setSelected(newSelecteds);
          return;

        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <ContactsTableToolbar 
                    numSelected={selected.length} 
                    setFilteredData={setFilteredData}
                    setOpenDeleteContactsDialog={setOpenDeleteContactsDialog}
                />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <ContactsTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={copyOfRows.length}
                    />
                    <TableBody>
                    {stableSort(copyOfRows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                            className="contacts-table-rows"
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, row.id)}
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip placement="top" arrow title="Edit">
                                  <Fab onClick={() => onOpenEditContactDialog(row)} style={{width: "35px", height: "35px", backgroundColor: "#6d76d3"}} color="secondary" aria-label="edit">
                                    <Edit style={{fontSize: "1rem"}} />
                                  </Fab>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="center">
                                { row.tags ? 
                                  <Chip 
                                    style={{
                                      backgroundColor: 
                                        `${row.tags.toLowerCase() === "follow up" ? "#83d6b7" :
                                          row.tags.toLowerCase() === "important" ? "#ec6262" :
                                          row.tags.toLowerCase() === "review" ? "#e4a8f9" :
                                          row.tags.toLowerCase() === "tomorrow" ? "#99ea86" :
                                          row.tags.toLowerCase() === "to do" ? "#e1d276" :
                                          null
                                        }`,
                                      marginLeft: -45
                                    }}
                                    label={<span style={{fontSize: "1em", fontWeight: 500}}>{row.tags.toUpperCase()}</span>}
                                  ></Chip>
                                  : '-'
                                }
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                  {row.firstName ? row.firstName : '-'}
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                  {row.lastName ? row.lastName : '-'}
                              </TableCell>                            
                              <TableCell align="left">{row.phoneNumber ? row.phoneNumber : '-'}</TableCell>
                              <TableCell align="left">{row.email ? row.email : '-'}</TableCell>
                              <TableCell align="left">{row.description ? row.description : '-'}</TableCell>
                              <TableCell align="left">{row.leadValue ? row.leadValue : '-'}</TableCell>
                              <TableCell align="left">{row.postalCode ? row.postalCode : '-'}</TableCell>
                              <TableCell align="left">{row.address ? row.address : '-'}</TableCell>
                              <TableCell align="left">{row.state ? row.state : '-'}</TableCell>
                              <TableCell align="left">{row.city ? row.city : '-'}</TableCell>
                              <TableCell align="left">{row.country ? row.country : '-'}</TableCell>
                              <TableCell align="left">{row.website ? row.website : '-'}</TableCell>
                            </TableRow>
                        );
                        })}
                    
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={contactsLead?.length ? contactsLead.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}