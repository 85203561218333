import React from 'react'
import AdditionalDetailsArchitectForm from './AdditionalDetailsArchitectForm'
import AdditionalDetailsDeveloperForm from './AdditionalDetailsDevleoperForm'
import AdditionalDetailsForm from './AdditionalDetailsForm'
import ArchitectDetailsForm from './ArchitectDetailsForm'
import DeveloperDetailsForm from './DeveloperDetailsForm'

import ProjectDetailsForm from './ProjectDetailsForm'

function MainProjectForm({
    errors,
    handleChange,
    handleAdditionalDetailsChange,
    project,
    handleSwitchChange,
    state,
    charactersLength,
    setCharactersLength,
    setMaxLimitReached,
    maxCount
}) {
    const additional = [1, 2, 3, 4];
    return (

        <React.Fragment>

            <div className="row">

                <ProjectDetailsForm

                    errors={errors}

                    project={project}

                    handleChange={handleChange}

                    setMaxLimitReached={setMaxLimitReached}

                    charactersLength={charactersLength}

                    setCharactersLength={setCharactersLength}

                    maxCount={maxCount}

                />

            </div>

            <div className="row">

                <DeveloperDetailsForm

                    errors={errors}

                    project={project}

                    handleChange={handleChange}

                    handleSwitchChange={handleSwitchChange}

                    state={state}

                />

                <ArchitectDetailsForm

                    errors={errors}

                    project={project}

                    handleChange={handleChange}

                    handleSwitchChange={handleSwitchChange}

                    state={state}

                />

                <AdditionalDetailsDeveloperForm

                    errors={errors}

                    project={project}

                    state={state}

                    handleSwitchChange={handleSwitchChange}

                    handleAdditionalDetailsChange={handleAdditionalDetailsChange}

                />

                <AdditionalDetailsArchitectForm

                    errors={errors}

                    project={project}

                    state={state}

                    handleSwitchChange={handleSwitchChange}

                    handleAdditionalDetailsChange={handleAdditionalDetailsChange}

                />

                {additional.map((val, index) => (

                    <AdditionalDetailsForm
                        key={val}

                        errors={errors}

                        value={val}

                        project={project}

                        state={state}

                        handleSwitchChange={handleSwitchChange}

                        handleAdditionalDetailsChange={handleAdditionalDetailsChange}
                    />
                ))}

            </div>

        </React.Fragment>

    )

}

export default MainProjectForm
