import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, List, ListItem, IconButton, ListItemText } from '@material-ui/core';
import axiosInstance from '../../Axios/axios';
import { Close } from '@material-ui/icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import '../../Assets/css/custom.css';

const stripePromise = loadStripe("pk_test_oG9aW1uQYaVeNUY9QSKu65tO0065H5XYfs");

const UpgradeModalContent = ({ modalOpened, userInfo, closeDialog, openPayModal, openDowngradeModal}) => {
  const [plans, setPlans] = useState([]);
  const [expired, setExpired] = useState(false);
  const stripe = useStripe();
  const {plan} = userInfo;
  const token = localStorage.getItem("token");
  const onClickDowngradeMembership = (plan) => {
    closeDialog();
    openDowngradeModal(plan);
  }

  const onClickupgradeMembership = (plan) => {
    closeDialog();
    if (token) {
      // Create PaymentIntent as soon as the page loads
        axiosInstance.post('/stripe-getprice', {
          plan: plan
        }, {
          headers: {
            "Content-Type": "application/json",
            'Authorization': "Bearer " + token
          }
        })
        .then(res => {
          return res;
        })
        .then(res => {
          const price = res.data.payload.price;
          const currency = res.data.payload.currency;
          openPayModal(plan,price);
        });
    } else {
      console.log("Error Stripe Checkout button without JWT");
    }
  }
  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('rgb(0, 84, 166)'),
      backgroundColor: 'rgb(0, 84, 166)',
      '&:hover': {
        backgroundColor: 'rgb(0, 84, 166)',
      },
    },
  }))(Button);

  const _renderMembershipStatus = (plan) => {
    if (plan == 0) {
      return (
        <List>
          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator" id="account-seperator-free">
                <div className="modalbadge" style={{ textAlign: 'left'}}>
                  <div className="account-plan" style={{ borderRadius: "3px", display: "inline-block", background: "#397A17", fontSize: "0.9rem", color: "white" }}>
                    <span className="font-weight-bolder" style={{ padding: "5px 39px", letterSpacing: "0.1em", display: "block", textAlign: "left" }}>FREE</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={9}>
                <div style={{ fontSize: '1rem', fontWeight: '500', marginLeft: '25px', marginTop:'12px' }}>Current Status</div>
              </Grid>
            </Grid>
          </ListItem>

          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator">
                <ColorButton className="upgradebutton_fontsize" style={{ padding: "5px 25px", letterSpacing: "0.04em", display: "block", textAlign: "center" }} variant="contained" color="primary" onClick={() => onClickupgradeMembership(1)}>
                  Upgrade
                </ColorButton>
              </Grid>
              <Grid item xs={3} style={{margin:'auto'}}>
                <div className="plan_description" style={{ fontSize: '0.9rem', marginLeft:'25px'}}>
                  <span style={{ color: "#66ccff", fontSize: '1rem', textAlign: 'center', fontWeight: '500' }}>Monthly</span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="d-flex">
                  <p style={{ marginBottom: '0', fontSize: '1rem', fontWeight: '500' }}>
                    Upgrade your current subscription and get full access to projects
                  </p>
                </div>
              </Grid>
            </Grid>
          </ListItem>

          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator">
                <ColorButton className="upgradebutton_fontsize" style={{ padding: "5px 25px", letterSpacing: "0.04em", display: "block", textAlign: "center" }} variant="contained" color="primary" onClick={() => onClickupgradeMembership(2)}>
                  Upgrade
                </ColorButton>
              </Grid>
              <Grid item xs={3} style={{margin:'auto'}}>
                <div className="plan_description" style={{ fontSize: '0.9rem', marginLeft:'25px'}}>
                  <span style={{ color: "#ff1a1a", fontSize: '1rem', textAlign: 'center', fontWeight: '500' }}>Yearly</span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="d-flex">
                  <p style={{ marginBottom: '0', fontSize: '1rem', fontWeight: '500' }}>Upgrade your current subscription and get full access to projects</p>
                </div>
              </Grid>
            </Grid>
          </ListItem>

        </List>
      )
    } else if (plan == 1) {
      return (
        <List>
          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator">
                <ColorButton style={{ padding: "5px 13px", letterSpacing: "0.04em", display: "block", textAlign: "center" }} variant="contained" color="primary" onClick={() => onClickDowngradeMembership(0)}>
                  Downgrade
                </ColorButton>
              </Grid>
              <Grid item xs={9}>
                <div className="d-flex">
                  <p style={{ margin:'0 50px 0 25px', fontSize: '1rem', fontWeight: '500' }}>Downgrade your subscription to limited access to project's data</p>
                </div>
              </Grid>
            </Grid>
          </ListItem>

          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator">
                <div className="modalbadge" style={{ textAlign: 'left'}}>
                  <div className="account-plan2" id="accountPlan2" style={{ borderRadius: "3px", display: "inline-block", background: "crimson", fontSize: "0.9rem", color: "white" }}>
                    <span className="font-weight-bolder" style={{ padding: "5px 24px", letterSpacing: "0.04em", display: "block", textAlign: "left" }}>PREMIUM</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={{margin:'auto'}}>
                <div className="plan_description" style={{ fontSize: '0.9rem', marginLeft:'25px'}}>
                  <span style={{ color: "#66ccff", fontSize: '1rem', textAlign: 'center', fontWeight: '500' }}>Monthly</span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ fontSize: '1rem', fontWeight: '500', marginLeft: '5px', marginTop:'12px'}}>Current Status</div>
              </Grid>
            </Grid>
          </ListItem>


          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator">
                <ColorButton style={{ padding: "5px 25px", letterSpacing: "0.04em", display: "block", textAlign: "center" }} variant="contained" color="primary" onClick={() => onClickupgradeMembership(2)}>
                  Upgrade
                </ColorButton>
              </Grid>
              <Grid item xs={3} style={{margin:'auto'}}>
                <div className="plan_description" style={{ fontSize: '0.9rem', marginLeft:'25px'}}>
                  <span style={{ color: "#ff1a1a", fontSize: '1rem', textAlign: 'center', fontWeight: '500' }}>Yearly</span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="d-flex">
                  <p style={{ marginBottom:'0', fontSize: '1rem', fontWeight: '500' }}>Upgrade your current subscription and get full access to projects</p>
                </div>
              </Grid>
            </Grid>
          </ListItem>
          
        </List>
      )
    } else if (plan == 2) {
      return (
        <List>
          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator">
                <ColorButton style={{ padding: "5px 13px", letterSpacing: "0.04em", display: "block", textAlign: "center" }} variant="contained" color="primary" onClick={() => onClickDowngradeMembership(0)}>
                  Downgrade
                </ColorButton>
              </Grid>
              <Grid item xs={9}>
                <div className="d-flex">
                  <p style={{ margin:'0 50px 0 25px', fontSize: '1rem', fontWeight: '500' }}>Downgrade your subscription to limited access to project's data</p>
                </div>
              </Grid>
            </Grid>
          </ListItem>

          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator">
                <div className="modalbadge" style={{ textAlign: 'left'}}>
                  <div className="account-plan" style={{ borderRadius: "3px", display: "inline-block", background: "crimson", fontSize: "0.9rem", color: "white" }}>
                    <span className="font-weight-bolder" style={{ padding: "5px 24px", letterSpacing: "0.04em", display: "block", textAlign: "left" }}>PREMIUM</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={{margin:'auto'}}>
                <div className="plan_description" style={{ fontSize: '0.9rem', marginLeft:'25px'}}>
                  <span style={{ color: "#66ccff", fontSize: '1rem', textAlign: 'center', fontWeight: '500' }}>Monthly</span>
                </div>
              </Grid>
              <Grid item xs={6}>
              </Grid>
            </Grid>
          </ListItem>


          <ListItem className="account_listItem" style={{paddingLeft:'20px'}}>
            <Grid container spacing={1}>
              <Grid item xs={3} className="account_seperator">
                <div className="modalbadge" style={{ textAlign: 'left'}}>
                  <div className="account-plan" id="accountPlan2" style={{ borderRadius: "3px", display: "inline-block", background: "#E5A948", fontSize: "0.9rem", color: "white" }}>
                    <span className="font-weight-bolder" style={{ padding: "5px 38px", letterSpacing: "0.1em", display: "block", textAlign: "left" }}>GOLD</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={{margin:'auto'}}>
                <div className="plan_description" style={{ fontSize: '0.9rem', marginLeft:'25px'}}>
                  <span style={{ color: "#ff1a1a", fontSize: '1rem', textAlign: 'center', fontWeight: '500' }}>Yearly</span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ fontSize: '1rem', fontWeight: '500', margin: '10px 0 0 25px' }}>Current Status</div>
              </Grid>
            </Grid>
          </ListItem>
          
        </List>
      )
    }
  }

  return (
    <div>
      {
        _renderMembershipStatus(plan)
      }
    </div>
  );
};

const UpgradeModal = ({ modalOpened, closeDialog, title, userInfo, openPayModal, openDowngradeModal }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Elements stripe={stripePromise}>
      <Dialog fullScreen={fullScreen} onClose={closeDialog} aria-labelledby="simple-dialog-title" open={modalOpened} fullWidth={true}>
        <DialogTitle id="simple-dialog-title" style={{ position:'relative', background: "#263238", color: "#fff" }} ><div>{title}</div>
        <div><IconButton edge="end" color="inherit" style={{position:'absolute', right:'15px', top:'7px'}}onClick={closeDialog} aria-label="close">
              <Close />
            </IconButton></div>
        </DialogTitle>
        <UpgradeModalContent modalOpened={modalOpened} userInfo={userInfo} closeDialog={closeDialog} openPayModal={openPayModal} openDowngradeModal={openDowngradeModal}/>
      </Dialog>
    </Elements>
  )
}

export default UpgradeModal;