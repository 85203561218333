import { Avatar, Button, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../Axios/axios';
import { countryList } from '../../DummyData/countryList';
import LoadingSpinner from '../../utils/LoadingSpinner';
import SnackbarToast from '../../utils/SnackbarToast';
import MELogo from '../../Assets/img/ME-logo.svg';
import { australianStateList } from '../../DummyData/australianStateList';
import { API_VERSION, BASE_URL, BASE_URL_IMAGE_USER } from '../../Axios/api';
import { deepPurple } from '@material-ui/core/colors';
import logger from '../../utils/logger';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "15px 0px",
        height: "210px",
        overflow: "auto !important"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
    }
}));

function UserInvitee(props) {

    const classes = useStyles();
    const [role, setRole] = useState("");
    const [title, setTitle] = useState("");
    const [id, setId] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [projectName, setProjectName] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [image, setImage] = useState(MELogo);

    // const [streetNumber, setStreetNumber] = useState("");
    // const [country, setCountry] = useState("");
    // const [city, setCity] = useState("");
    const [office, setOffice] = useState("");
    const [mobile, setMobile] = useState("");
    const [about, setAbout] = useState("");
    const [errors, setErrors] = useState({});
    const [maxCount] = useState(1200);
    const [charactersLength, setCharactersLength] = useState(0);
    const [maxLimitReached, setMaxLimitReached] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        setIsLoading(true);
        const {inviteCode} = props.match.params

        if(!inviteCode) {
            props.history.push('/login')
            setIsLoading(false);
        }
        axiosInstance.get('/user/verifyInviteCode', {
            params: {
                code: inviteCode
            }
        })
            .then(res => {
                if(res.data.status === 200) {
                    if(res.data.payload && res.data.payload.data) {
                        const userDetails = res.data.payload.data;
                        setId(userDetails.id ? userDetails.id : "")
                        setFirstName(userDetails.firstName ? userDetails.firstName : "");
                        setLastName(userDetails.lastName ? userDetails.lastName : "");
                        setTitle(userDetails.title ? userDetails.title : "");
                        setMobile(userDetails.mobile ? userDetails.mobile : "");
                        setPhone(userDetails.phone ? userDetails.phone : "");
                        setAddress(userDetails.address ? userDetails.address : "");
                        setCountry(userDetails.country ? userDetails.country : "");
                        setState(userDetails.state ? userDetails.state : "");
                        setPostalCode(userDetails.postalCode ? userDetails.postalCode : "");
                        setFax(userDetails.fax ? userDetails.fax : "");
                        setCompanyName(userDetails.companyName ? userDetails.companyName : "");
                        setRole(userDetails.role ? userDetails.role : "");
                        setAbout(userDetails.about ? userDetails.about : "");
                        setEmail(userDetails.email ? userDetails.email : "");
                        setProjectName(userDetails.projectName ? userDetails.projectName : "");
                        setOffice(userDetails.office ? userDetails.office : "");
                        setImage(userDetails.image ? userDetails.image : MELogo);
                        setIsLoading(false);

                        logger({
                            title:'Verify Invite Code',
                            url:window.location.href,
                            method:'GET',
                            activity: 'verifyInviteCode',
                            user_id: userDetails.id,
                            api:BASE_URL+ API_VERSION+'/user/verifyInviteCode',
                            session:localStorage.getItem('session'),
                            projectId:null,
                            unitId:null,
                            assignedId:null,
                            })    
                    }
                }
            })
            .catch(err => {
                if (err.response.data.status === 422) {
                    // setOpenSnackbar(openSnackbar => !openSnackbar);
                    // setSnackbarType("error");
                    // setMessageForSnackbar(err.response.data.message);
                    // setIsLoading(false);
                    alert(err.response.data.message);
                }
                setTimeout(() => {
                    props.history.push('/login')
                }, 500)
            })
    }, [])

    const updateUserDetails = () => {
        if ((!firstName || firstName.trim().length === 0) ||
            (!lastName || lastName.trim().length === 0) ||
            (!mobile || mobile.trim().length === 0) ||
            // (!phone || phone.trim().length === 0) ||
            // streetNumber.trim().length===0 || 
            // city.trim().length===0 || 
            // country.trim().length===0 || 
            (!postalCode || postalCode.trim().length === 0) ||
            // (!state || state.trim().length === 0) ||
            (!country || country.trim().length === 0) ||
            (!address || address.trim().length === 0) ||
            (!role || role.trim().length === 0) ||
            (!title || title.trim().length === 0) ||
            (!email || email.trim().length === 0) || 
            (!password || password.trim().length === 0) ||
            (!confirmPassword || confirmPassword.trim().length === 0)
        ) {
            setErrors({
                emptyError: "This field cannot be empty"
            })
            return;
        } 
        else if (password.trim() !== confirmPassword.trim()) {
            setErrors({
                mismatchError: "Password does not match"
            })
            return;
        } 
        else if (country === "Australia" && (!state || state.trim().length === 0)) {
            setErrors({
                emptyStateError: "This field cannot be empty"
            })
        }
        else if (isNaN(postalCode)) {
            setErrors({
                lengthError: "Postal code should be a valid number"
            })
            return;
        } 
        else if (maxLimitReached) {
            setErrors({
                limitError: "Maximum limit reached"
            })
            return;
        } else {
            setErrors({})
            setIsLoading(true);
            const userDetails = 
                {
                    id,
                    firstName,
                    lastName,
                    companyName,
                    role,
                    address,
                    mobile,
                    phone,
                    title,
                    office,                    
                    email,
                    fax,
                    password,
                    about,
                    state,
                    postalCode,
                    country,
                    // url: process.env.REACT_APP_DOMAIN_URL + '/login'
                    url: window.location.origin + '/login'
                }
            debugger
            axiosInstance.post('/registerInviteUser', userDetails)
                .then(res => {
                    logger({
                        title:'Register Invite User',
                        url:window.location.href,
                        method:'GET',
                        activity: 'registerInviteUser',
                        user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                        api:BASE_URL+ API_VERSION+'/user/registerInviteUser',
                        session:localStorage.getItem('session'),
                        projectId:null,
                        unitId:null,
                        assignedId:null,
                        })                                    
                    if(res.data.status===200) {
                        setIsLoading(false);
                        setOpenSnackbar(openSnackbar => !openSnackbar);
                        setSnackbarType("success");
                        setMessageForSnackbar("Registration process completed! Please check your inbox or spam folder to verify email.");
                    }
                    setTimeout(() => {
                        props.history.push('/login')
                    }, 4000)

                })
                .catch(err => {
                    
                    setIsLoading(false);
                    if(err.response.data.status===422 && err.response.data.payload) {
                        setOpenSnackbar(openSnackbar => !openSnackbar);
                        setSnackbarType("error");
                        setMessageForSnackbar(err.response.data.payload.error);
                    }
                })
        }
    }

    const handleSnackbarClose = () => {
        setOpenSnackbar(openSnackbar => !openSnackbar);
        setSnackbarType("");
        setMessageForSnackbar("");
    }

    return (
        <div className="user_invitee container mt-2 mb-2">
            
            <LoadingSpinner isLoading={isLoading} />

            <SnackbarToast
                handleSnackbarClose={handleSnackbarClose}
                openSnackbar={openSnackbar}
                snackbarType={snackbarType}
                message={messageForSnackbar}
            />
            { !isLoading ? 
            <React.Fragment>
                <div className="text-center m-3">
                    <Avatar src={image ? `${BASE_URL_IMAGE_USER}${image}` : MELogo} className={classes.purple}></Avatar>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextField
                            required                    
                            margin="dense"
                            id="first-name"
                            label="First Name"
                            helperText = {
                                errors.emptyError && (!firstName || firstName.trim().length === 0) ?
                                errors.emptyError :
                                    ""
                            }
                            error = {
                                errors.emptyError && (!firstName || firstName.trim().length === 0) ?
                                true :
                                false
                            }
                            value={firstName}
                            onChange={(event)=> setFirstName(event.target.value)}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            required
                            helperText = {
                                errors.emptyError && (!lastName || lastName.trim().length === 0 ) ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && (!lastName || lastName.trim().length === 0 ) ?
                                true : false
                            }
                            margin="dense"
                            id="last-name"
                            label="Last Name"
                            type="text"
                            value={lastName}
                            onChange={(event)=>setLastName(event.target.value)}
                            fullWidth
                        />
                        <TextField
                            // InputProps={{ readOnly: true }}
                            margin="dense"
                            id="company-name"
                            label="Company Name"
                            type="text"
                            value={companyName}
                            onChange={(event)=> setCompanyName(event.target.value)}
                            fullWidth
                        />

                        <TextField
                            // InputProps={{ readOnly: true }}
                            margin="dense"
                            id="phone-number"
                            label="Phone Number"
                            type="text"
                            value={phone}
                            onChange={(event)=>setPhone(event.target.value)}
                            fullWidth
                        />
                        
                        <TextField
                            // InputProps={{ readOnly: true }}
                            margin="dense"
                            id="fax"
                            label="Fax"
                            type="text"
                            value={fax}
                            onChange={(event)=>setFax(event.target.value)}
                            fullWidth
                        />
                        {/* <h5 style={{marginBottom:"0", marginTop:"13px", fontWeight:500}}>Address:</h5> */}
                        <TextField
                            // style={{marginTop:"0"}}
                            required
                            margin="dense"
                            helperText = {
                                errors.emptyError && (!address || address.trim().length === 0 ) ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && (!address || address.trim().length === 0 ) ?
                                true : false
                            }
                            id="address"
                            label="Address"
                            value={address}
                            onChange={(event)=>setAddress(event.target.value)}
                            type="text"
                            fullWidth
                        />
                        <TextField
                                // style={{marginTop:"0"}}
                            required
                            margin="dense"
                            helperText = {
                                errors.lengthError && isNaN(postalCode.toString())
                                ?
                                errors.lengthError
                                :
                                errors.emptyError && postalCode.trim().length === 0
                                ?
                                errors.emptyError
                                :
                                ""
                            }
                            error = {
                                (errors.lengthError && isNaN(postalCode.toString())) ||
                                (errors.emptyError && postalCode.trim().length === 0) ||
                                (errors.lengthError && isNaN(postalCode.toString())) 
                                ?
                                true
                                : false
                            }
                            id="postalCode"
                            label="Postal Code"
                            value={postalCode}
                            onChange={(event)=>setPostalCode(event.target.value)}
                            type="text"
                            fullWidth
                        />

                        <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                            <InputLabel
                                id="demo-simple-select-label"
                                required
                                helperText = {
                                    errors.emptyError && (!country || country.trim().length === 0) ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && (!country || country.trim().length === 0) ?
                                    true : false
                                }
                            >
                                Country
                            </InputLabel>
            
                            <Select
                            required
                            helperText = {
                                errors.emptyError && (!country || country.trim().length === 0) ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && (!country || country.trim().length === 0) ?
                                true : false
                            }
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={country}
                            fullWidth
                            onChange={(event)=> {
                                    if(event.target.value === "Australia" && !australianStateList.includes(state)){
                                        setState("");
                                    };
                                    setCountry(event.target.value)
                                }}
                            >
                            {countryList.map((country => (
                                <MenuItem key={country} value={country}>{country}</MenuItem>
                            )))}
                            </Select>
                        </FormControl>

                        { country !== "Australia" ? 
                            <TextField
                                // InputProps={{ readOnly: true }}
                                margin="dense"
                                id="state"
                                label="State"
                                value={state}
                                onChange={(event)=>setState(event.target.value)}
                                type="text"
                                fullWidth
                            />
                            : 

                            <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                                <InputLabel
                                    id="demo-simple-select-label"
                                    required
                                    helperText = {
                                        errors.emptyStateError && (!state || state.trim().length === 0) ?
                                        errors.emptyStateError : ""
                                    }
                                    error = {
                                        errors.emptyStateError && (!state || state.trim().length === 0) ?
                                        true : false
                                    }
                                >
                                    State
                                </InputLabel>
                
                                <Select
                                    // inputProps={{ readOnly: true}}
                                    required
                                    helperText = {
                                        errors.emptyStateError && (!state || state.trim().length === 0) ?
                                        errors.emptyStateError : ""
                                    }
                                    error = {
                                        errors.emptyStateError && (!state || state.trim().length === 0) ?
                                        true : false
                                    }
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state}
                                    fullWidth
                                    onChange={(event)=> setState(event.target.value)}
                                >
                                    {australianStateList.map((state => (
                                        <MenuItem key={state} value={state}>{state}</MenuItem>
                                    )))}
                                </Select>

                            </FormControl>
                        }
                        {/* <TextField
                            InputProps={{ readOnly: actions && !isEditModeOn }}
                            required
                            margin="dense"
                            helperText = {
                                errors.emptyError && city.trim().length === 0 ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && city.trim().length === 0 ?
                                true : false
                            }
                            id="city"
                            label="City"
                            value={city}
                            onChange={(event)=>setCity(event.target.value)}
                            type="text"
                            fullWidth
                        />
                        
                        <TextField
                            InputProps={{ readOnly: actions && !isEditModeOn }}
                            required
                            margin="dense"
                            helperText = {
                                errors.emptyError && country.trim().length === 0 ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && country.trim().length === 0 ?
                                true : false
                            }
                            id="country"
                            label="Country"
                            value={country}
                            onChange={(event)=>setCountry(event.target.value)}
                            type="text"
                            fullWidth
                        /> */}
                        
                    </div>
                    <div className="col-md-6">                            
                        <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                            <InputLabel 
                            helperText = {
                                errors.emptyError && (!title || title.trim().length === 0) ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && (!title || title.trim().length === 0) ?
                                true : false
                            }
                            required
                            id="demo-simple-select-label">Select Title</InputLabel>
                            <Select
                            // inputProps={{ readOnly: true }}
                            required
                            helperText = {
                                errors.emptyError && (!title || title.trim().length === 0) ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && (!title || title.trim().length === 0) ?
                                true : false
                            }
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={title}
                            fullWidth
                            onChange={(event)=> setTitle(event.target.value)}
                            >
                            <MenuItem value="Mr">Mr</MenuItem>
                            <MenuItem value="Mrs">Mrs</MenuItem>
                            <MenuItem value="Ms">Ms</MenuItem>
                            <MenuItem value="Dr">Dr</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ readOnly: true }}
                            required
                            helperText = {
                                errors.emptyError && (!email || email.trim().length === 0) ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && (!email || email.trim().length === 0) ?
                                true : false
                            }
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(event)=>setEmail(event.target.value)}
                            fullWidth
                        />
                        <TextField
                            required
                            helperText = {
                                errors.emptyError && (!password || password.trim().length === 0)?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && (!password || password.trim().length === 0) ?
                                true : false
                            }
                            margin="dense"
                            id="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(event)=> setPassword(event.target.value)}
                            fullWidth
                        />
                        <TextField
                            required
                            helperText = {
                                (errors.emptyError && (!confirmPassword || confirmPassword.trim().length === 0) ?
                                errors.emptyError : "") || 
                                (errors.mismatchError && (password.trim() !== confirmPassword.trim()) ?
                                errors.mismatchError : "") 
                            }
                            error = {
                                (errors.emptyError && (!confirmPassword || confirmPassword.trim().length === 0) ?
                                true : false) ||
                                (errors.mismatchError && (password.trim() !== confirmPassword.trim()))
                            }
                            margin="dense"
                            id="confirm-password"
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(event)=> setConfirmPassword(event.target.value)}
                            fullWidth
                        />
                        <TextField
                            required
                            helperText = {
                                errors.emptyError && ( !mobile || mobile.trim().length === 0 ) ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && ( !mobile || mobile.trim().length === 0 ) ?
                                true : false
                            }
                            margin="dense"
                            id="mobile-number"
                            label="Mobile Number"
                            value={mobile}
                            onChange={(event)=>setMobile(event.target.value)}
                            placeholder="+611234567890"
                            type="text"
                            fullWidth
                        />
                        
                        <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                            <InputLabel 
                                helperText = {
                                    errors.emptyError && (!role || role.trim().length === 0) ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && (!role || role.trim().length === 0) ?
                                    true : false
                                }
                                required 
                                id="demo-simple-select-label"
                            >
                                Select Role
                            </InputLabel>
                            <Select
                            inputProps={{ readOnly: true }}
                            required
                            helperText = {
                                errors.emptyError && (!role || role.trim().length === 0) ?
                                errors.emptyError : ""
                            }
                            error = {
                                errors.emptyError && (!role || role.trim().length === 0) ?
                                true : false
                            }
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={role}
                            fullWidth
                            onChange={(event)=> setRole(event.target.value)}
                            >
                            <MenuItem value="Project Manager">Project Manager</MenuItem>
                            <MenuItem value="User Manager">User Manager</MenuItem>
                            <MenuItem value="Master Agent">Master Agent</MenuItem>
                            <MenuItem value="Agent">Agent</MenuItem>
                            <MenuItem value="Affiliate">Affiliate</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            InputProps={{ readOnly: true }}                          
                            margin="dense"
                            id="projectName"
                            label="Project Name"
                            type="text"
                            value={projectName}
                            fullWidth
                        />
                        
                        
                        <div className="about-form">
                            <TextField
                                style={{marginTop:"22px"}}
                                margin="dense"
                                id="about-me"
                                label="About Me"
                                type="text"
                                rows={7}
                                variant="outlined"
                                multiline       
                                helperText={charactersLength>1200 ? "Max Limit Reached" : null}
                                error={charactersLength>1200 ? true: false}                                   
                                value={about}
                                onChange={(event)=>{ 
                                    if(charactersLength>1200){
                                        setMaxLimitReached(true)                                            
                                    }else{
                                        setMaxLimitReached(false)
                                    }
                                    setCharactersLength(event.target.value.length); 
                                    setAbout(event.target.value)
                                }}
                                fullWidth
                            />
                            <span style={{display:"block", textAlign:"right"}} className={`${charactersLength>1200 ? "text-error" : "text-dark"}`}>{charactersLength}/{maxCount}</span>
                        </div>
                    </div>
                    <div className="col-md-12 text-right mt-2 mb-2 action_buttons">
                        <Button onClick={updateUserDetails} variant="contained" color="primary">Update Details</Button>
                    </div>
                </div>
            </React.Fragment> 
            : null }
        </div>
    )
}

export default UserInvitee
