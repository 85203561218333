import { Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react';
import { USER_ROLES } from '../../../utils/const';
import UpgradeProjectPlanModal from './ProjectUpgradeStorage/UpgradeProjectPlanModal';


const useStyles = makeStyles((theme) => ({
    upgradeOptionSection: {
        padding: "6px 16px",
        background: "#00746B",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    upgradeOptionTitle: {
        fontSize: "1rem",
        fontWeight: 500,
        letterSpacing: "0.1em",
        display: "inline-block",
        marginBottom: "0"
    },
    upgradeOptionButtonSection: {
        display: "inline-block"
    },
    upgradeButton: {
        color: "white",
        padding: "3px 10px",
        fontSize: "0.7rem",
        backgroundColor: "#FBAD60",
        '&:hover': {
            backgroundColor: "#FBAD60"
        }
    }
}));

function UpgradeProjectPlan({ used, total, title, onUpdateSuccess, projectId }) {
    const classes = useStyles();
    const userRole = localStorage.getItem('user_role');
    const [openUpgradeProjectPlanModal, setOpenUpgradeProjectPlanModal] = useState(false);
    const closeUpgradeProjectPlanModal = () => {
        setOpenUpgradeProjectPlanModal(false); 
    }
    
    return (
        <div className="row mb-2">
            <div className="col-md-6 col-sm-12">
                <div className={classes.upgradeOptionSection}>
                    <h5 className={classes.upgradeOptionTitle}>File Warehouse: <span style={{textTransform: 'uppercase' }}>{title}</span> {used}MB of {total}MB</h5>
                    {
                        userRole == USER_ROLES.ADMIN || userRole == USER_ROLES.PM
                        ?
                        <div className={classes.upgradeOptionButtonSection}>
                            <Button className={classes.upgradeButton} variant="contained" component="span" onClick={() =>  setOpenUpgradeProjectPlanModal(true) } >Upgrade
                            </Button>
                        </div>
                        
                        :
                        null
                    }
                     <UpgradeProjectPlanModal 
                        openUpgradeProjectPlanModal={openUpgradeProjectPlanModal}  
                        title={title} onUpdateSuccess={onUpdateSuccess} 
                        projectId={projectId} 
                        closeDialog={closeUpgradeProjectPlanModal }
                    />
                </div> 
            </div>
        </div>
    )
}

export default UpgradeProjectPlan;
