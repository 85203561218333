import { AppBar, Button, Dialog, Divider, IconButton, List, ListItem, ListItemText, makeStyles, Slide, Toolbar, Typography, Collapse } from '@material-ui/core';
import { CheckCircle, Close, KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import clsx from 'clsx';
import { lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#263238',
    paddingRight: "0px !important"
  },
  title: {
    fontSize:"1rem",
    flex: 1,
  },
  assignButton: {
    color: "white",
    padding: "3px 10px",
    fontSize: "0.7rem",
    backgroundColor: "#26A69A",
    '&:hover': {
      backgroundColor: "#26A69A"
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//asc, floorPlan, 
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Names :' }
]

function EnhancedTableHead(props) {

  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
       {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span>{headCell.label}</span>

              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

function AssignUnitNumberDialog({
    role,
    unitNumber,
    openAssignUnitNumberDialog,
    onCloseAssignUnitNumberDialog,
    userList,
    onSelectUserForUnitNumber,
    usersAssignedForUnitNumber,
    onAssignForUnitNumberHandler

}) {

    const classes = useStyles();
    const [expandedList, setExpandedList] = React.useState(true);

    const handleExpandedListClick = () => {
        setExpandedList(!expandedList);
    }
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, userList.length - page * rowsPerPage);

    return (
        <Dialog maxWidth="sm" fullWidth open={openAssignUnitNumberDialog} onClose={onCloseAssignUnitNumberDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar style={{paddingLeft:"16px", paddingRight:"0px"}}>
                <Typography variant="h6" className={classes.title}>
                Assign {role==="Agent" ? "Agents" : role} for Unit {unitNumber} 
                </Typography>
                { userList && userList.length > 0 ?
                    <Button
                    className={`${classes.assignButton} mr-3`} 
                    onClick={() => onAssignForUnitNumberHandler()} 
                    variant="contained" 
                    component="span"
                    >
                        Assign
                    </Button>
                : null }
                <IconButton edge="start" color="inherit" onClick={onCloseAssignUnitNumberDialog} aria-label="close">
                    <Close />
                </IconButton>            
            </Toolbar>
            </AppBar>
            <List>
                {
                    userList && userList.length===0 ? 
                        <ListItem>
                            <ListItemText primary={`No ${role} Found`}/>
                        </ListItem>
                    :
                
                    (userList && userList.length > 0) ?
                        <React.Fragment>
                            {/* <div className={`col-12 d-flex align-items-center p-2`}>
                                <span><KeyboardArrowDown/></span>
                                <h6 className="font-weight-bold mb-0" style={{padding: "2px"}}>{role==="Agent" ? "Agents" : role}</h6>
                            </div> */}
                            <ListItem onClick={handleExpandedListClick} style={{cursor:"pointer", backgroundColor: "#B1DFDC"}}>
                                <ListItemText>
                                    <h6 className="font-weight-bold mb-0" style={{padding: "2px"}}>{`List of ${role}s`}</h6>
                                    <span>
                                        <KeyboardArrowDown 
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expandedList,
                                            })}
                                            aria-expanded={expandedList}
                                        />
                                    </span>
                                </ListItemText>
                            </ListItem>
                            <Divider/>

                            <Collapse in={expandedList} timeout="auto" unmountOnExit>
                                {/* {userList.map((user) => ( */}
                                    <React.Fragment>
                                        <TableContainer>
                                            <Table
                                                className={classes.table}
                                                aria-labelledby="tableTitle"
                                                aria-label="enhanced table"
                                            >
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={userList.length}
                                                />
                                                <TableBody>
                                                    {stableSort(userList, getComparator(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                key={row.firstName +" " +row.lastName}
                                                            >
                                                            <TableCell 
                                                                onClick={() => onSelectUserForUnitNumber(row.id)} 
                                                            >
                                                                <div className={`d-flex align-items-center`}>
                                                                    { usersAssignedForUnitNumber &&
                                                                    usersAssignedForUnitNumber.length>0 && 
                                                                    usersAssignedForUnitNumber.findIndex(userId => userId === row.id) !== -1 
                                                                    ? 
                                                                    <CheckCircle className="mr-2" style={{color:"green"}}/> 
                                                                    : 
                                                                    null
                                                                    }

                                                                    <span>{row.firstName +" " +row.lastName}</span>

                                                                </div>
                                                            </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <ListItem 
                                            className="row"
                                            autoFocus 
                                            button 
                                            onClick={() => onSelectUserForUnitNumber(user.id)} 
                                            key={user.id}
                                        >
                                            <div className={`col-12 d-flex align-items-center`}>
                                                { usersAssignedForUnitNumber &&
                                                usersAssignedForUnitNumber.length>0 && 
                                                usersAssignedForUnitNumber.findIndex(user => user.id === user.id) !== -1 
                                                ? 
                                                <CheckCircle style={{color:"green"}}/> 
                                                : 
                                                null
                                                }
                                                <ListItemText style={{marginLeft:"5px"}} primary={user.firstName +" " +user.lastName} />
                                            </div>
                                        </ListItem> */}
                                        {/* <Divider/> */}
                                    </React.Fragment>
                                {/* ))} */}
                            </Collapse>
                        </React.Fragment>
                    : null
                }
            </List>
      </Dialog>
    )
}

export default AssignUnitNumberDialog
