import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(() => ({

    formControl: {
        width: "100%"
    }

}))

function TagSelectionDialog({
    openTagSelectionDialog,
    onCloseTagSelectionDialog,
    onChangeTagValue,
    tagValueToChange,
    setTagValueToChange
}) {

    const classes = useStyles();

    return (

        <Dialog
        
            open={openTagSelectionDialog}
        
            onClose={onCloseTagSelectionDialog}
        
            aria-labelledby="alert-dialog-title"
        
            aria-describedby="alert-dialog-description"
        
        >
        
            <DialogTitle className="pb-0">Change Tag value</DialogTitle>
            
            <DialogContent>

                <form className={classes.container}>
                
                    <FormControl className={classes.formControl}>
                    
                        <InputLabel id="demo-dialog-select-label">Tag</InputLabel>
                    
                        <Select
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            value={tagValueToChange}
                            onChange={(e) => setTagValueToChange(e.target.value)}
                            input={<Input />}
                        >
                    
                            <MenuItem value="Follow up">Follow up</MenuItem>
                            <MenuItem value="Important">Important</MenuItem>
                            <MenuItem value="Review">Review</MenuItem>
                            <MenuItem value="Tomorrow">Tomorrow</MenuItem>
                            <MenuItem value="Todo">Todo</MenuItem>

                        </Select>
                    
                    </FormControl>

                </form>

            </DialogContent>
        
            <DialogActions>
        
                <Button
        
                onClick={() => onChangeTagValue(tagValueToChange)}
        
                color="primary"
        
                >
        
                Save
        
                </Button>
        
                <Button
        
                onClick={onCloseTagSelectionDialog}
        
                color="primary"
        
                autoFocus
        
                >
        
                Cancel
        
                </Button>
        
            </DialogActions>
        
        </Dialog>
    
    )

}

export default TagSelectionDialog
