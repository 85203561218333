import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import { countryList } from '../../DummyData/countryList'

function AddressSelectionDialog({
    addressSelectionType,
    addressSelectionValue,
    openAddressSelectionDialog,
    onCloseAddressSelectionDialog,
    setAddressSelectionValue,
    onConfirmAddressSelection
}) {
    return (
        <Dialog
            open={openAddressSelectionDialog}
            onClose={onCloseAddressSelectionDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{`Select ${addressSelectionType}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            
            {
                addressSelectionType === "Country" ? 
                <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>

                <InputLabel id="demo-simple-select-label">
                    {addressSelectionType}
                </InputLabel>

                <Select                
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addressSelectionValue}
                    fullWidth
                    onChange={(event)=> setAddressSelectionValue(event.target.value)}
                >
                    {countryList.map((country => (
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                    )))}
                </Select>
            
                </FormControl>

                :

                addressSelectionType === "State" ? 

                <TextField
                    // style={{marginTop:"0"}}
                    margin="dense"
                    label="State"
                    value={addressSelectionValue}
                    onChange={(event)=>setAddressSelectionValue(event.target.value)}
                    type="text"
                    fullWidth
                />

                : 

                addressSelectionType === "PostalCode" ? 

                <TextField
                    // style={{marginTop:"0"}}
                    margin="dense"
                    label="PostalCode"
                    value={addressSelectionValue}
                    onChange={(event)=>setAddressSelectionValue(event.target.value)}
                    type="text"
                    fullWidth
                />

                : null
                
            }

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmAddressSelection} color="primary">
            Confirm
          </Button>
          <Button onClick={onCloseAddressSelectionDialog} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export default AddressSelectionDialog
