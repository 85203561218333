export const activityList = {
    "requestResetPassword":"User Request Reset Password",
	"updatePassword":"User Update Password",
	"requestResetPasswordNew":"User Resest Password",
	"verifyEmail":"Verify Email",
	"login":"Login",
	"logout":"Logout",
	"register":"Register",
	"contactDestroy":"Destroy Contact",
	"contactChangeLead":"ChangeLead Contact",
	"contactChangeTag":"Change Tag of Contact",
	"contactUpdate":"Update Contact",
	"contactFolderDestroy":"Destroy Contact Folder",
	"contactFolderCreate":"User Search By Criteria",
	"contactImport":"Contact Import",
	"registerByAdmin":"Register By Admin",
	"userDestroy":"User Destroy",
	"userDestroys":"Multiple Users Destroy",
	"Update User":"Update User Details",
	"userDeactives":"User Deactives",
	"projectAssign":"Project Assign",
	"sendEmailByTemplate":"send Email By Template",
	"editProfile":"Edit Profile",
	"confirmUpgrade":"Confirm Upgrade",
	"projectPanoFilesDelete":"Delete Project PanoFiles",
	"projectPanoFilesExtract":"Extract project PanoFiles",
	"projectPanoFilesUpload":"Upload project PanoFiles",
	"unitCreate":"Create Unit",
	"unitFieldChange":"Change Unit Field",
	"unitUpdateDisplayPrice":"Update Unit Display Price",
	"unitUpdateDisplayFloor":"Update Unit Display Floor",
	"unitPriceCreate":"Create Unit Price",
	"unitIsDisplayedChange":"Change Unit IsDisplayed",
	"unitFloorCreate":"Create Unit Floor",
	"unitFloorDelete":"Delete Unit Floor",
	"deleteDocumentFile":"Delete Document File",
	"unitFloorActivated":"unitFloorActivated",
	"unitPriceActivated":"Activated Unit Price",
	"assign units":"Unit To Assign",
	"projectDestroy":"Destroy Project",
	"projectDeploy":"Deploy Project",
	"uploadDocumentFiles":"Upload Document Files",
	"downloadDocumentFile":"Download Document Files",
	"unitSold":"Sold Unit",
	"managerExitsCheck":"Check Manager Exits",
	"mangerProjectAssign":"Assign Manger Project",
	"projectUpdate":"Update Project",
	"projectCreate":"Create Project",
	"stripe-checkout":"Checkout Stripe",
	"confirmStorageUpgrade":"Confirm Storage Upgrade",
	"verifyInviteCode":"Verify Invite Code",
	"registerInviteUser":"Register Invite User",
	"registerByAdmin":"Register By Admin",
	"unitPriceDelete":"Delete Unit Price",
	"updateUserAvailability":"Update User Availability"
}
