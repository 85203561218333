export let JsonArray = [
    [
        'Sample Data',
        'Sample Data',
        'Sample Data',
        '5fe3d6e6efbd9@example.com',
        'Sample Data',
        'Integer between 1 and 10',
        'Sample Data',
        'Sample Data',
        'Sample Data',
        'Sample Data',
        'Sample Data',
        'Sample Data',
        'Sample Data'
    ]
]

let JsonFields = ["First Name", "Last Name", "Phonenumber", "Email", "Description", "Lead value", "Postal Code", "Address", "State", "City", "Country", "Website", "Tags"]

export function JsonToCSV(jsonArray) {

    let csvStr = JsonFields.join(",") + "\n";

    jsonArray.forEach(element => {

        csvStr += element.join(',');
        csvStr += "\n";

    })

    return csvStr;
};

export function exportCsvForCampaign(jsonArray) {

    let csvStr = ["First Name", "Last Name", "Email"].join(",") + "\n";

    jsonArray.forEach(element => {

        csvStr += element.join(',');
        csvStr += "\n";

    })

    return csvStr;
}
