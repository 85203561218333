import { Tooltip } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import React from 'react'
import { BASE_URL_IMAGE_PROJECT } from '../../../../../Axios/api'

function ImageUpload({
    handleFileSelect,
    image
}) {
    
    return (
        
        <div className="image-upload">
    
          <input
    
            accept="image/*"
    
            onChange={handleFileSelect}
    
            style={{ display: "none" }}
    
            id="project-img"
    
            type="file"
    
          />
    
          <label style={{position:"relative"}} htmlFor="project-img">
       
            <Edit 
              style={{
                    position: "absolute",
                    background: "white",
                    cursor:"pointer",
                    margin: "5px",
                    padding: "2px"
              }}

            />

            <Tooltip title="Add Project Image" arrow>
                    
              <img
    
                style={{
                  cursor: "pointer",
                  width: "150.4px",
                  height: "84.6px",
                  maxWidth: "100%",
                  borderRadius: "6px",
                  border: "1px solid #5680e9",
                  objectFit: "cover",
                }}
    
                alt="Project Img"
    
                src={
    
                  image && image.includes(".") && !image.includes("img1")
    
                    ? `${BASE_URL_IMAGE_PROJECT}${image}`
    
                    : image

                }
    
              />

            </Tooltip>

          </label>

        </div>

    )

}

export default ImageUpload
