import { Button } from '@material-ui/core'
import React from 'react'
import UserBulkActions from './UserBulkActions'

function UserActions({
    setIsSelectModeOn,
    isSelectModeOn,
    onBulkActionSelected,
    onCancelUserSelectionButtonHandler,
    onSelectAllUsersHandler,
    userIdsToDelete
}) {
    return (
        <React.Fragment>
            <div className="d-flex flex-wrap align-items-center justify-content-end">
                {isSelectModeOn ? <UserBulkActions onBulkActionSelected={onBulkActionSelected} userIdsToDelete={userIdsToDelete} /> : null } 
                {!isSelectModeOn ? <Button style={{marginBottom: 5, marginTop: 10}} onClick={() => setIsSelectModeOn(true)} variant="outlined">Select</Button> : null}
                {isSelectModeOn ? <Button style={{marginBottom: 5, marginTop: 10}} className="ml-2" onClick={() => onSelectAllUsersHandler(false)} variant="outlined" color="default">Select All</Button> : null}
                {isSelectModeOn ? <Button style={{marginTop: 5}} className="ml-2" onClick={() => onCancelUserSelectionButtonHandler()} variant="outlined" color="secondary">Cancel</Button> : null}
            </div>
        </React.Fragment>
    )
}

export default UserActions
