import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@material-ui/core'

function DeleteUserDialog({
    userIdToDelete,
    userIdsToDelete,
    openDeleteUserDialog,
    handleDeleteUserDialog,
    deleteUserHandler,
    deleteMultipleUsersHandler
}) {
    return (
        <Dialog
            open={openDeleteUserDialog}
            onClose={handleDeleteUserDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >       
            <DialogContent>                                    
            <DialogContentText id="alert-dialog-description">
                {userIdsToDelete.length > 1 && !userIdToDelete ? "Do you want to delete these users? This action cannot be undone." : "Do you want to delete this user? This action cannot be undone." }
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            
            <Button onClick={handleDeleteUserDialog} color="primary">
                No
            </Button>
            { userIdsToDelete.length > 0 && !userIdToDelete? 
                <Button onClick={()=>deleteMultipleUsersHandler(userIdsToDelete)} color="primary" autoFocus>
                    Yes
                </Button> :
                <Button onClick={()=>deleteUserHandler(userIdToDelete)} color="primary" autoFocus>
                    Yes
                </Button>
            }
            </DialogActions>
        </Dialog>
    )
}

export default DeleteUserDialog
