import React from 'react'

const ProjectsHeader = () => {

  return (

    <div className="page-header page-header-light">

      <div className="page-header-content header-elements-md-inline">
    
        <div className="page-title d-flex">
    
          <h4>
    
            <i className="icon-arrow-left52 mr-2"></i>{" "}
    
            <span className="font-weight-semibold">Home</span> - Projects
    
          </h4>
    
        </div>

      </div>

      <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">

        <div className="d-flex">

          <div className="breadcrumb">

            <a href=" " className="breadcrumb-item">

              <i className="icon-home2 mr-2"></i> Home

            </a>

            <span className="breadcrumb-item active">Projects</span>

          </div>

          <a

            href=" "

            className="header-elements-toggle text-default d-md-none"

          >

            <i className="icon-more"></i>

          </a>

        </div>

      </div>

    </div>

  )

}

export default ProjectsHeader
