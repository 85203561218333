import React from 'react'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Chip, IconButton, makeStyles, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core'
import { deepPurple } from '@material-ui/core/colors';
import { Add, Done, DoneAll, MoreVert } from '@material-ui/icons'
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginRight: 30,
    marginBottom: 20
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center'
  },
  pos: {
    marginBottom: 12,
  },
  templateName: {
      fontSize: 18
  },
  templateTable: {
      padding: "8px 5px !important"
  }
});

function EmailTemplate({
    templateId,
    templateName,
    templateAuthor,
    categories,
    purposes,
    onOpenPreviewTemplateDialog,
    onSelectTemplateHandler,
    selectedTemplateId
}) {

    const classes = useStyles();

    return (
                
        <Card className={classes.root} variant="outlined">
            
            <CardContent style={{padding: 0}}>
                
                    <table style={{tableLayout: "fixed", border: "none", width: 275}} className="table">
                        <tbody>
                            <tr className="text-center">
                                <td className={classes.templateTable}>Template ID :</td>
                                <td className={classes.templateTable}>
                                    <Chip style={{backgroundColor: "crimson", color: "white", height: "20px"}} label={templateId}/>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className={classes.templateTable}>Template Name :</td>
                                <td className={classes.templateTable}>
                                    {templateName.length > 12 ? templateName.toUpperCase().substring(0, 12) + "..." : templateName.toUpperCase() }
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className={classes.templateTable}>Author :</td>
                                <td className={classes.templateTable}>
                                    {templateAuthor.toUpperCase()}
                                </td>
                            </tr>
                            { categories.length > 0 ?
                                <tr className="text-center">
                                    <td className={classes.templateTable}>{categories.length === 1 ? "Category :" : "Categories :"} </td>
                                    <td className={classes.templateTable}>
                                        {
                                            categories.map((categoryName, index) => (
                                                <Chip key={categoryName+"-"+index} style={{backgroundColor: "#26A69A", color: "white", height: "20px"}} label={categoryName.toUpperCase()}/>
                                            ))
                                        } 
                                    </td>
                                </tr>
                                : 
                                <tr className="text-center">
                                    <td className={classes.templateTable}>{categories.length === 1 ? "Category :" : "Categories :"} </td>
                                    <td className={classes.templateTable}>
                                        -
                                    </td>
                                </tr>
                            }
                            { purposes.length > 0 &&
                                <tr className="text-center">
                                    <td className={classes.templateTable}>{purposes.length === 1 ? "Purpose :" : "Purposes :"} </td>
                                    <td className={classes.templateTable}>
                                        {
                                            purposes.map((purposeName, index) => (
                                                <Chip key={purposeName+'-'+index} style={{backgroundColor: "#26A69A", color: "white", height: "20px"}} label={purposeName.toUpperCase()}/>
                                            ))
                                        } 
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>

            </CardContent>
            
            <CardActions className="justify-content-between">
                
                { selectedTemplateId !== templateId ? 
                    <Button onClick={() => onSelectTemplateHandler(templateId)} size="small">
                        <span>Choose</span>
                        <Done style={{marginTop: "-3px", fontSize: "1.2rem"}} className="ml-1"/>
                    </Button>
                    : 
                    <Button style={{backgroundColor: "darkgreen", color: "white"}} size="small">
                        <span>Choosen</span>
                        <DoneAll style={{marginTop: "-3px", fontSize: "1.2rem"}} className="ml-1"/>
                    </Button>
                }

                <Tooltip arrow title="Preview Template">
                    <IconButton onClick={() => onOpenPreviewTemplateDialog(templateId)} size="small">
                        <VisibilityIcon/>
                    </IconButton>
                </Tooltip>

            </CardActions>

        </Card>

    )
}

export default EmailTemplate
