import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, TextField, Checkbox} from '@material-ui/core';
import CheckoutForm from '../Stripe/CheckoutForm';
const stripePromise = loadStripe("pk_test_oG9aW1uQYaVeNUY9QSKu65tO0065H5XYfs");

// const stripePromise = loadStripe("pk_live_RMCgpPvF3dnQnR2guiYJmvBr000z7xcv9i");

// const CheckoutForm = ({items, closeDialog, modalOpened, onPaySuccess}) => {
//   const [error, setError] = useState(null);
//   const [processing, setProcessing] = useState('');
//   const [disabled, setDisabled] = useState(true);
//   const [email, setEmail] = useState('');
//   const [clientSecret, setClientSecret] = useState('');
//   const [amount, setAmount] = useState(0);
//   const stripe = useStripe();
//   const elements = useElements();

//   useEffect(() => {
//     if (modalOpened) {
//       axiosInstance.post("/user/startUpgrade", { items })
//       .then(res => {
//         if (res && res.data && res.data.status === 200) {
//           const data = res.data.payload?.data;
//           if (data) {
//             setClientSecret(data.clientSecret);
//             setAmount(data.amount);
//           }
//         }
//       })
//     }
//   }, [modalOpened]);

//   const cardStyle = {
//     style: {
//       base: {
//         color: "#32325d",
//         fontFamily: 'Arial, sans-serif',
//         fontSmoothing: "antialiased",
//         fontSize: "16px",
//         "::placeholder": {
//           color: "#32325d"
//         }
//       },
//       invalid: {
//         color: "#fa755a",
//         iconColor: "#fa755a"
//       }
//     }
//   };
//   const handleChange = async (event) => {
//     // Listen for changes in the CardElement
//     // and display any errors as the customer types their card details
//     setDisabled(event.empty);
//     setError(event.error ? event.error.message : "");
//   };
//   const handleSubmit = async ev => {
//     ev.preventDefault();
//     setProcessing(true);
//     const payload = await stripe.confirmCardPayment(clientSecret, {
//       payment_method: {
//         card: elements.getElement(CardElement)
//       }
//     });
//     if (payload.error) {
//       setError(`Payment failed. ${payload.error.message}`);
//       setProcessing(false);
//     } else {
//       setError(null);
//       setProcessing(false);
//       onPaySuccess();
//     }
//   };

//   return (
//     <form id="payment-form" onSubmit={handleSubmit}>
//       {/* <TextField
//         required margin="dense" id="email" label="Email"
//         value={email} onChange={(event)=>setEmail(event.target.value)}
//         type="email" fullWidth
//       /> */}
//       <div style={{ padding: "16px 6px", background: '#f2f2f2' }}>
//         <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
//       </div>

//       {/* Show any error that happens when processing the payment */}
//       {error && (
//         <div style={{ color: 'crimson' }} role="alert">
//           {error}
//         </div>
//       )}

//       <div className="mt-2">
//         <Checkbox required />
//         By checking this box you agree to our <Link to="#">Privacy Policy</Link> and <Link to="#">Terms and Conditions.</Link>
//       </div>

//       <Button type="submit" color="primary" disabled={!stripe || disabled || processing}
//       style={{ float: 'right', backgroundColor: '#397a17', color: 'white', marginTop: 16, marginBottom: 16 }}>
//         { processing ? 'Processing...' : `Pay $${(amount/100).toFixed(2)}`}
//       </Button>

//       <Button type="button" color="primary" onClick={() => closeDialog() }
//       style={{ float: 'right', marginTop: 16, marginBottom: 16, marginRight: 6 }}>
//         Cancel
//       </Button>
//     </form>
//   );
// }

const PayModal = ({ modalOpened, closeDialog, plan, setOpenUpgradeAccountDialog, email, planPrice, currency }) => {

  return (
    <Elements stripe={stripePromise}>
      <Dialog disableBackdropClick aria-labelledby="simple-dialog-title" open={modalOpened} >
        <DialogTitle id="simple-dialog-title" style={{background:'rgb(38, 50, 56)', color:'white'}}>Upgrade your account</DialogTitle>
        <DialogContent style={{ minWidth: 320 }} >
          <CheckoutForm plan={plan} email={email} closeDialog={closeDialog} planPrice={planPrice} currency={currency} modalOpened={modalOpened} setOpenUpgradeAccountDialog ={setOpenUpgradeAccountDialog}/>
        </DialogContent>
      </Dialog>
    </Elements>
  );
};

export default PayModal;

