import { FormControl, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import { australianStateList } from '../../../../../DummyData/australianStateList'
import { countryList } from '../../../../../DummyData/countryList'
function ProjectDetailsForm({
    errors,
    project,
    handleChange,
    charactersLength,
    setCharactersLength,
    setMaxLimitReached,
    maxCount
    
}) {
    const userRole = localStorage.getItem('user_role');
    return (
        <div className="col-md-12">
            <TextField
                required
                margin="dense" id="project-name" label="Project Name" name="name"
                helperText={
                    errors.emptyError && project.name.trim().length === 0
                    ? errors.emptyError
                    : ""
                }
                error={
                    errors.emptyError && project.name.trim().length === 0
                    ? true
                    : false
                }
                defaultValue={project.name}
                onChange={handleChange}
                type="text"
                fullWidth
            />
            <TextField
                required
                helperText={
                    errors.emptyError && project.address.trim().length === 0
                    ? errors.emptyError
                    : ""
                }
                error={
                    errors.emptyError && project.address.trim().length === 0
                    ? true
                    : false
                }
                margin="dense"
                id="address"
                label="Project Location"
                type="text"
                name="address"
                defaultValue={project.address}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                required
                helperText={
                    errors.lengthError && isNaN(project.postalCode.toString())
                    ? errors.lengthError
                    : errors.emptyError && project.postalCode.trim().length === 0
                    ? errors.emptyError
                    
                    : ""
                }
                error={
                    (errors.lengthError && isNaN(project.postalCode.toString())) ||
                    (errors.emptyError && project.postalCode.trim().length === 0) || 
                    (errors.lengthError && isNaN(project.postalCode.toString())) 
                    
                    ? true
                    : false
                }
                margin="dense"
                id="postalCode"
                label="Postal Code"
                type="text"
                name="postalCode"
                defaultValue={project.postalCode}
                onChange={handleChange}
                fullWidth
            />
            <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                
                <InputLabel
                
                    id="demo-simple-select-label"
                    helperText = {
                        errors.emptyError && (!project.country || project.country.trim().length === 0) ?
                        errors.emptyError : ""
                    }
                    error = {
                        errors.emptyError && (!project.country || project.country.trim().length === 0) ?
                        true : false
                    }
                    required
                
                >
                    Country
                </InputLabel>

                <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                helperText = {
                    errors.emptyError && (!project.country || project.country.trim().length === 0) ?
                    errors.emptyError : ""
                }
                error = {
                    errors.emptyError && (!project.country || project.country.trim().length === 0) ?
                    true : false
                }
                value={project.country ? project.country : ""}
                fullWidth
                name="country"
                onChange={handleChange}
                >
                
                {countryList.map((country => (
                
                    <MenuItem key={country} value={country}>{country}</MenuItem>
                
                )))}
                
                </Select>
            
            </FormControl>
            { project.country !== "Australia" ? 
                
                <TextField
                    
                    margin="dense"
                    id="state"
                    label="State"
                    type="text"
                    name="state"
                    defaultValue={project.state}
                    onChange={handleChange}
                    fullWidth
                />
                : 
                <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                    
                    <InputLabel
                    
                        id="demo-simple-select-label"
                    
                        required
                    
                        helperText = {
                            
                            errors.emptyStateError && project.state.trim().length === 0
                            ? errors.emptyStateError
                            : ""
                        
                        }
                    
                        error = {
                    
                            errors.emptyStateError && project.state.trim().length === 0 ?
                    
                            true : false
                    
                        }
                    
                    >
                        State
                    </InputLabel>
    
                    <Select
                        required
                        
                        helperText={
                            errors.emptyStateError && project.state.trim().length === 0
                            ? errors.emptyStateError
                            : ""
                        }
                        error={
                            errors.emptyStateError && project.state.trim().length === 0
                            ? true
                            : false
                        }
                        margin="dense"
                        id="state"
                        label="State"
                        type="text"
                        name="state"
                        defaultValue={project.state}
                        onChange={handleChange}
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        
                        {australianStateList.map((state => (
                            <MenuItem key={state} value={state}>{state}</MenuItem>
                        )))}
                    </Select>
                </FormControl>
            }
            <TextField
                required
                helperText={
                    errors.emptyError && project.client.trim().length === 0
                    ? errors.emptyError
                    : ""
                }
                error={
                    errors.emptyError && project.client.trim().length === 0
                    ? true
                    : false
                }
                margin="dense"
                id="client"
                label="Client"
                type="text"
                name="client"
                defaultValue={project.client}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                required
                helperText={
                    errors.typeError && isNaN(project.noOfUnits.toString())
                    ? errors.typeError
                    : errors.emptyError && project.noOfUnits.toString().trim().length === 0
                    ? errors.emptyError
                    : ""
                }
                error={
                    (errors.typeError && isNaN(project.noOfUnits.toString())) || 
                    (errors.emptyError && (project.noOfUnits.toString().trim().length ===0))
                    ? true
                    : false
                }
                margin="dense"
                id="noOfUnits"
                label="Number of Units"
                type="text"
                name="noOfUnits"
                defaultValue={project.noOfUnits}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                required
                margin="dense"
                helperText={
                    // errors.emptyError && isNaN(project.commercialSpace.toString())
                    // ? errors.emptyError
                    errors.typeError && isNaN(project.commercialSpace.toString())
                    ? errors.typeError
                    : errors.emptyError && project.commercialSpace.toString().trim().length === 0
                    ? errors.emptyError
                    : ""
                }
                error={
                    // (errors.emptyError && isNaN(project.commercialSpace.toString())) ||
                    (errors.typeError && isNaN(project.commercialSpace.toString())) ||
                    (errors.emptyError && (project.commercialSpace.toString().trim().length === 0))
                    ? true
                    : false
                }
                id="commercialSpace"
                label="Commercial Spaces"
                name="commercialSpace"
                defaultValue={project.commercialSpace}
                onChange={handleChange}
                type="text"
                fullWidth
            />
            {/* <FormControl className={classes.formControl}>
                <InputLabel id="template-selector">Name</InputLabel>
                <Select
                labelId="template-selector"
                id="multiple=template-id-selector"
                multiple
                defaultValue={project.template_ids}
                onChange={handleChange}
                input={<Input />}
                MenuProps={MenuProps}
                >
                {names.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                    {name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl> */}
            <TextField
                InputProps={{readOnly: ["Admin", "Project Manager"].includes(userRole) ? false : true}}
                margin="dense"
                id="templateIDs"
                label="Email Template IDs"
                placeholder="Eg. 2312967,2353562"
                name="templateIDs"
                defaultValue={project.template_ids}
                onChange={handleChange}
                type="text"
                fullWidth
            />
            <TextField
                InputProps={{readOnly: userRole !== "Admin" }}
                required
                margin="dense"
                helperText= {
                    errors.syntaxError && !(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(project.threeDUrl))
                    ? errors.syntaxError
                    : errors.emptyError && project.threeDUrl.toString().trim().length === 0
                    ? errors.emptyError
                    : ""
                }
                error={
                    (errors.syntaxError && !(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(project.threeDUrl))) ||
                    (errors.emptyError && (project.threeDUrl.trim().length === 0))
                    ? true
                    : false
                }
                id="threeDUrl"
                label="3D URL"
                name="threeDUrl"
                defaultValue={project.threeDUrl}
                onChange={handleChange}
                type="text"
                fullWidth
            />
            <div className="project-description">
                <TextField
                    style={{marginTop:"22px"}}
                    margin="dense"
                    id="about-project"
                    label="Project Description"
                    name="projectDescription"
                    defaultValue={project.projectDescription}
                    type="text"
                    rows={5}
                    variant="outlined"
                    multiline       
                    fullWidth
                    helperText={charactersLength>1500 ? "Max Limit Reached" : null}
                                
                    error={charactersLength>1500 ? true: false}  
                    onChange = {
                        (event) => {
                            if (charactersLength > 1500) {
                                setMaxLimitReached(true)
                            } else {
                                setMaxLimitReached(false)
                            }
                            setCharactersLength(event.target.value.length);
                            handleChange(event)
                        
                        }
                    }
                />
                <span style={{display:"block", textAlign:"right"}} className={`${charactersLength>1500 ? "text-error" : "text-dark"}`}>{charactersLength}/{maxCount}</span>
            </div>
        </div>
    )
    
}
export default ProjectDetailsForm
