import React, { useEffect } from "react";

import PropTypes from "prop-types";

import clsx from "clsx";

import { createMuiTheme, lighten, makeStyles, ThemeProvider } from "@material-ui/core/styles";

import ListOptionIcon from '@material-ui/icons/PlaylistPlay';

import { FileCopy, AddCircle, CheckCircle, Share } from '@material-ui/icons';
import { BASE_URL, BASE_URL_IMAGE_PANOVIEW } from '../../../Axios/api';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  TextField,
  Menu,
  MenuItem,
  Divider,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditAppartmentDialog from "./EditAppartmentDialog";
import { purple } from "@material-ui/core/colors";
import { format2Currency } from "../../../utils";
import ShareModal from "../ProjectDetailsAccordion/ProjectActions/ProjectForm/ShareModal/ShareModal";
import UnitShareModal from "../ProjectDetailsAccordion/ProjectActions/ProjectForm/ShareModal/UnitShareModel";
import ShareIcon from '../../../Assets/img/share_black_24dp.svg';

function descendingComparator(a, b, orderBy) {
  if (orderBy === "floorPlan") {
    let data_a = a[orderBy].data;
    let data_b = b[orderBy].data;

    if (data_a && data_b) {
      if (data_b.find(row => row.isActive)?.file < data_a.find(row => row.isActive)?.file) {
        return -1;
      }

      if (data_b.find(row => row.isActive)?.file > data_a.find(row => row.isActive)?.file) {
        return 1;
      }
    } else if (data_a) return 1;
    else if (data_b) return -1;

    return 0;
  }

  if (orderBy === "price") {

    if (b[orderBy].data.find(row => row.isActive)?.price < a[orderBy].data.find(row => row.isActive)?.price) {
      return -1;
    }

    if (b[orderBy].data.find(row => row.isActive)?.price > a[orderBy].data.find(row => row.isActive)?.price) {

      return 1;

    }

    return 0;

  }

  if (b[orderBy] < a[orderBy]) {

    return -1;

  }

  if (b[orderBy] > a[orderBy]) {

    return 1;

  }

  return 0;

}

function getComparator(order, orderBy) {

  return order === "desc"

  ? (a, b) => descendingComparator(a, b, orderBy)

  : (a, b) => -descendingComparator(a, b, orderBy);

}

function stableSort(array, comparator) {
  
  const stabilizedThis = array.map((el, index) => [el, index]);
  
  stabilizedThis.sort((a, b) => {
  
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];

  });

  return stabilizedThis.map((el) => {return el[0]});

}

const headCells = [
  { id: "masterUnitNo", numeric: false, disablePadding: true, label: "Master Unit No" },
  { id: "unitNo", numeric: false, disablePadding: true, label: "Unit No" },
  { id: "floor", numeric: false, disablePadding: false, label: "Floor" },
  { id: "beds", numeric: true, disablePadding: false, label: "Beds" },
  { id: "baths", numeric: true, disablePadding: false, label: "Baths" },
  { id: "cars", numeric: true, disablePadding: false, label: "Cars" },
  { id: "storage", numeric: true, disablePadding: false, label: "Storage" },
  { id: "internalArea", numeric: true, disablePadding: false, label: "Internal Area" },
  { id: "externalArea", numeric: true, disablePadding: false, label: "External Area" },
  { id: "totalArea", numeric: true, disablePadding: false, label: "Total Area" },
  { id: "orientation", numberic: false, disablePadding: false, label: "Orientation" },
  { id: "floorPlan", numeric: true, disablePadding: false, label: "Floor Plan" },
  { id: "price", numeric: true, disablePadding: false, label: "Price" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "publish", numeric: false, disablePadding: false, label: "Publish" },
  { id: "exchanged", numeric: false, disablePadding: false, label: "Exchanged" },
  { id: "agent", numeric: true, disablePadding: false, label: "Agent" },
  { id: "affiliates", numeric: true, disablePadding: false, label: "Affiliates" },
  { id: "stats", numberic: false, disablePadding: false, label: "Stats" },
  { id: "share", numberic: false, disablePadding: false, label: "Share" },
  { id: "unitNo", numeric: false, disablePadding: true, label: "Unit No" },
];

// const headCells = rowTitles.map(row => {
  
// })

function EnhancedTableHead(props) {

  const {

    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    onPerformBulkOperation,

  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItems, setMenuItems] = React.useState([]);

  const onOpenMenu = (event, cellId) => {
    if(cellId === "status") {
      setMenuItems(
        [
          {actionFor: cellId, status: "Sold", bgColor: "#F62221"},
          {actionFor: cellId, status: "Reserved", bgColor: "#D2D015"},
          {actionFor: cellId, status: "Available", bgColor: "#26A59A"}  
        ]
      )
    }
    if(cellId === "publish" || cellId === "exchanged") {
      setMenuItems(
        [
          {actionFor: cellId, status: "Yes", bgColor: "#26A59A"},
          {actionFor: cellId, status: "No", bgColor: "#F62221"}
        ]
      )
    }
    if(cellId === "floorPlan" || cellId === "price") {
      setMenuItems(
        [
          {actionFor: cellId, status: "Display All", color:"black", bgColor: "#B1DFDC"},
          {actionFor: cellId, status: "Display None", color:"black", bgColor: "#B1DFDC"}
        ]
      )
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuItems([]);
  };

  const createSortHandler = (property) => (event) => {

    // if (property === 'floorPlan' || property === 'price') return;
  
    onRequestSort(event, property);
  
  };

  return (
  
    <TableHead>

      <TableRow>
    
        <TableCell style={{padding:"0 15px"}}>
    
          <Checkbox
    
            indeterminate={numSelected > 0 && numSelected < rowCount}
    
            checked={rowCount > 0 && numSelected === rowCount}
    
            onChange={onSelectAllClick}
    
            inputProps={{ "aria-label": "select all desserts" }}
    
          />
    
        </TableCell>
    
        {headCells.map((headCell, index) => (
    
          <TableCell

            className="table-headings"
        
            key={headCell.id+'_'+index}
          
            padding={headCell.disablePadding ? "none" : "default"}
    
            sortDirection={orderBy === headCell.id ? order : false}
    
          >
    
            <TableSortLabel
    
              active={orderBy === headCell.id}
    
              direction={orderBy === headCell.id ? order : "asc"}
    
              onClick={createSortHandler(headCell.id)}
    
            >

              <span>{headCell.label}</span>
              { 
                ["floorPlan", "exchanged", "publish", "price", "status"].includes(headCell.id) && localStorage.getItem("user_role") !== 'Agent' && localStorage.getItem("user_role") !== 'Affiliate' ? 
                <ListOptionIcon onClick={(event) => {event.preventDefault(); event.stopPropagation(); onOpenMenu(event, headCell.id)}} style={{marginLeft:"10px"}}/> 
                : null 
              }

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="unit-table-menu"
              >
                
                {
                  menuItems.map((item,index) => (
                    <MenuItem 
                      key={index} 
                      style={{
                        padding:"0 !important", 
                        backgroundColor: item.bgColor, 
                        color: item.color ? item.color :"white"
                      }}
                      onClick={(e) => { e.stopPropagation(); onPerformBulkOperation(item.actionFor, item.status); handleClose(); }}
 >
                      {item.status.toUpperCase()}
                    </MenuItem>
                  ))
                }

             </Menu>
    
              {orderBy === headCell.id ? (
    
                <span className={classes.visuallyHidden}>
    
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
    
                </span>
    
              ) : null}
    
            </TableSortLabel>
    
          </TableCell>
    
        ))}
    
      </TableRow>

    </TableHead>

  );

}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({

  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  

  title: {
    flex: "1 1 100%",
  },

  assignButton: {
    color: "white",
    padding: "3px 10px",
    fontSize: "0.7rem",
    backgroundColor: "#26A69A",
    '&:hover': {
      backgroundColor: "#26A69A"
    }
  }


}));

const EnhancedTableToolbar = (props) => {

  const classes = useToolbarStyles();

  const { numSelected } = props;

  return (

    <Toolbar
    
      style={{padding:"0"}}
    
      className={clsx(classes.root, {
    
        [classes.highlight]: numSelected > 0,
    
      })}
    
    >
    
      {numSelected > 0 ? 
      
        (
    
        <Typography
    
          className={classes.title}
    
          color="inherit"
    
          variant="subtitle1"
    
          component="div"
    
        >
    
          {numSelected} selected
    
        </Typography>
    
        ) : 
        
        (
    
        <TextField
    
          margin="dense"
    
          id="filter-user"
    
          label="Filter By Unit No."
    
          type="text"
    
          style={{display:"block"}}
    
          onChange={(e) => props.setFilteredData(e.target.value)}
    
        />
    
        )
    
      }

      {numSelected > 0 ? (
      
        <Tooltip title="Delete">
      
          <IconButton aria-label="delete">
      
            <DeleteIcon />
      
          </IconButton>
      
        </Tooltip>
      
      ) : null }
    
    </Toolbar>
  
  );

};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  
  root: {
    width: "100%",
  },
  
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  
  table: {
    minWidth: 750,
  },
  
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  assignButton: {
    color: "white",
    padding: "2px 10px",
    fontSize: "0.8rem",
    fontWeight: "normal",
    backgroundColor: "#26A69A",
    '&:hover': {
      backgroundColor: "#26A69A"
    }
  },
  shareIcon: {
    fontSize:"1.2rem",
    backgroundColor:"white",
    color:"#458CCC",
    borderRadius:"3px",
    marginLeft:"5px",
    marginRight: "10px",
    padding:"2px"
  }

}));

export default function UnitsTable({
  projectUnits,
  onOpenAssignUnitNumberDialog,
  onOpenUnitNumberDialog,
  onPerformBulkOperation,
  setOpenNewPriceDialog,
  onUploadFileForFloorPlan,
  setUnitNumberToAssign,
  setUnitIdToAssign,
  onPerformActionForSelectedRowField,
  readonly,
  projectId
}) {

  const classes = useStyles();
  
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filteredData, setFilteredData] = React.useState([]);
  const [rowsData, setRowsData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItems, setMenuItems] = React.useState([]);
  const [shareUrl, setShareUrl] = React.useState(null);
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const [openUnitShareModal, setOpenUnitShareModal] = React.useState(false);
  const [floorPlanData, setFloorPlanDataModal] = React.useState("");
  const [interiorData, setInteriorDataModal] = React.useState("");
  const [openEditAppartmentDialog,setEditAppartmentDialog]= React.useState(false);
  const [soldUnitDetails , setSoldUnitDetails] = React.useState()
  const onOpenMenuForSelectedRowField = (event, unitRowId, unitNo, fieldName) => {

    if (fieldName === "status") {

      setMenuItems(
        [{
            unitRowId,
            unitNo,
            actionFor: fieldName,
            status: "Sold",
            bgColor: "#F62221"
          },
          {
            unitRowId,
            unitNo,
            actionFor: fieldName,
            status: "Reserved",
            bgColor: "#D2D015"
          },
          {
            unitRowId,
            unitNo,
            actionFor: fieldName,
            status: "Available",
            bgColor: "#26A59A"
          }
        ]
      )
    }

    if (fieldName === "publish" || fieldName === "exchanged") {

      setMenuItems(
        [{
            unitRowId,
            unitNo,
            actionFor: fieldName,
            status: "Yes",
            bgColor: "#26A59A"
          },
          {
            unitRowId,
            unitNo,
            actionFor: fieldName,
            status: "No",
            bgColor: "#F62221"
          }
        ]
      )
    }

    setAnchorEl(event.currentTarget);

  };

  const onCloseMenuForSelectedRowField = () => {

    setAnchorEl(null);
    setMenuItems([]);

  };

  
  useEffect(() => {
  
    if(projectUnits.length > 0) {
  
      setRowsData(projectUnits);
  
    } else {
  
      setRowsData([]);
  
    }
  
  }, [projectUnits])

  useEffect(() => {

    if (filteredData.length > 0) {
  
      setRowsData(projectUnits);
  
      const filteredList = projectUnits.filter(row => row.unitNo && row.unitNo.toString().toLowerCase().includes(filteredData.trim().toLowerCase()))
  
      setRowsData(filteredList);
  
    } else {
  
      setRowsData(projectUnits);
  
    }

  }, [filteredData])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
  
    setOrder(isAsc ? "desc" : "asc");
  
    setOrderBy(property);
  
  };

  const handleSelectAllClick = (event) => {
  
    if (event.target.checked) {
  
      const newSelecteds = rowsData.map((n) => n.id);
  
      setSelected(newSelecteds);
  
      return;
  
    }
  
    setSelected([]);
  
  };

  const handleClick = (event, unitId) => {
    const selectedIndex = selected.indexOf(unitId);
  
    let newSelected = [];

    if (selectedIndex === -1) {
  
      newSelected = newSelected.concat(selected, unitId);
  
    } else if (selectedIndex === 0) {
  
      newSelected = newSelected.concat(selected.slice(1));
  
    } else if (selectedIndex === selected.length - 1) {
  
      newSelected = newSelected.concat(selected.slice(0, -1));
  
    } else if (selectedIndex > 0) {
  
      newSelected = newSelected.concat(
  
        selected.slice(0, selectedIndex),
  
        selected.slice(selectedIndex + 1)
  
      );
  
    }

    setSelected(newSelected);
  
  };

  const handleChangePage = (event, newPage) => {
  
    setPage(newPage);
  
  };

  const handleChangeRowsPerPage = (event) => {
  
    setRowsPerPage(parseInt(event.target.value, 10));
  
    setPage(0);
  
  };

  // const handleChangeDense = (event) => {
  
  //   setDense(event.target.checked);
  
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowsData.length - page * rowsPerPage);

  const theme = createMuiTheme({
    
    palette: {
      
      primary: {
        // Purple and green play nicely together.
        main: purple[500],
      },
      
      secondary: {
        // This is green.A700 as hex.
        main: '#2196f3',
      }
      
    }

  });
  const closeEditAppartmentDialog = ()=>{
    setEditAppartmentDialog(false)
  }
  const onSelectAppartment = (appartment) =>{
    console.log(appartment);
    closeEditAppartmentDialog()
  }

  return (
    
    <div className={classes.root}>
    
      <EnhancedTableToolbar setFilteredData={setFilteredData} numSelected={selected.length} />
    
        <TableContainer>
    
          <Table
    
            style={{border: "none"}}
    
            className={classes.table}
    
            aria-labelledby="tableTitle"
    
            size={dense ? "small" : "medium"}
    
            aria-label="enhanced table"
    
            stickyHeader={true}
    
          >
    
            <EnhancedTableHead
    
              classes={classes}

              onPerformBulkOperation={onPerformBulkOperation}
    
              numSelected={selected.length}
    
              order={order}
    
              orderBy={orderBy}
    
              onSelectAllClick={handleSelectAllClick}
    
              onRequestSort={handleRequestSort}
    
              rowCount={rowsData.length}
    
            />
    
            <TableBody>
    
              {stableSort(rowsData, getComparator(order, orderBy))
    
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    
                .map((row, index) => {
    
                  const isItemSelected = isSelected(row.id);
    
                  const labelId = `enhanced-table-checkbox-${index}`;

                  let plan = ''
                  if(row.floorPlan && row.floorPlan.data && row.floorPlan.data.length > 0 ){
                    if(row.floorPlan.data.findIndex(data => data.isActive) !== -1){
                      plan  = row.floorPlan.data[row.floorPlan.data.findIndex(data => data.isActive)].file;
                     
                    }
                  }

                  return (

                    <ThemeProvider theme={theme} key={row.id}>

                    <TableRow

                      hover

                      role="checkbox"

                      aria-checked={isItemSelected}

                      tabIndex={-1}

                      selected={isItemSelected}

                    >

                      <TableCell style={{padding:"0 15px"}}>

                        <Checkbox

                          onClick={(event) => handleClick(event, row.id)}

                          checked={isItemSelected}

                          inputProps={{ "aria-labelledby": labelId }}

                        />

                      </TableCell>

                      <TableCell

                        id={labelId}

                        scope="row"

                        padding="none"

                        align="left"

                      >

                        {row.masterUnitNo}

                      </TableCell>

                      <TableCell

                        id={labelId}

                        scope="row"

                        padding="none"

                        align="left"

                      >

                        {row.unitNo !== null && row.unitNo !== undefined ? row.unitNo : "-"}

                      </TableCell>

                      <TableCell align="left">{row.floor ? row.floor : "-"}</TableCell>

                      <TableCell align="left">{row.beds ? row.beds : "-"}</TableCell>

                      <TableCell align="left">{row.baths ? row.baths : "-"}</TableCell>

                      <TableCell align="left">{row.cars ? row.cars : "-" }</TableCell>

                      <TableCell align="left">{row.storage ? row.storage : "-"}</TableCell>

                      <TableCell align="left">{row.internalArea ? row.internalArea : "-"}</TableCell>

                      <TableCell align="left">{row.externalArea ? row.externalArea : "-"}</TableCell>

                      <TableCell align="left">{row.totalArea ? row.totalArea.toFixed(2) : "-"}</TableCell>

                      <TableCell align="left" style={{ textTransform: "uppercase" }}>{row.orientation ? row.orientation : "-"}</TableCell>

                      <TableCell align="left">
                      
                        {
                          row.masterUnitNo ?

                          <div className="d-flex align-items-center">
                          { 
                            row.floorPlan && row.floorPlan.data && row.floorPlan.data.length > 0 
                            ?
                            <>
                              {
                                row.floorPlan.data.findIndex(data => data.isActive) !== -1 ? 
                                <span 
                                  className="mr-1" 
                                  style={{ cursor: "pointer", textDecoration: "underline" }}
                                  onClick={() => {window.open(`${BASE_URL}images/unitfiles/${row.floorPlan.data[row.floorPlan.data.findIndex(data => data.isActive)].file}`)}}
                                >
                                  {row.floorPlan.data[row.floorPlan.data.findIndex(data => data.isActive)].file}
                                </span>
                                :
                                <span className="mr-1">No Active Plan</span>
                              }

                              { !readonly ?
                                <ListOptionIcon onClick={(event) => {event.stopPropagation(); onOpenUnitNumberDialog("floorPlan", row) }} style={{cursor: "pointer"}}/>
                                : null
                              }

                            </>
                            : 
                            row.masterUnitNo && !readonly ? 
                            <span className="mr-1">

                                <input
                                    onChange={(event) => {onUploadFileForFloorPlan(event.target.files[0])}}
                                    className={`${classes.inputFile} d-none`}                    
                                    id={`upload-floor-plan-file`}   
                                    type="file"                    
                                />

                                <label className="mb-0" htmlFor={`upload-floor-plan-file`}>
                                    <Tooltip title="Add File" arrow>                                
                                      <AddCircle onClick={() => { setUnitNumberToAssign(row.unitNo && row.unitNo !== '0' ? row.unitNo : row.masterUnitNo); setUnitIdToAssign(row.id); }}
                                      style={{ cursor: "pointer", color: "green"}} />
                                    </Tooltip>
                                </label>
                            
                            </span> 
                            :
                            <span className="mr-1">-</span> 
                            }

                            { row.floorPlan && row.floorPlan.isDisplayed ? <CheckCircle style={{fontSize: "1.1rem", color:"green"}} /> : null }
                          </div>
                         
                          : <span className="mr-1">-</span>
                        }
                      
                      </TableCell>

                      <TableCell align="left" className="prices-row" style={{padding: "0px !important"}}>
                        
                        {
                          row.price ?

                          <div className="d-flex align-items-center">

                            { row.masterUnitNo && row.price.data && row.price.data.length > 0 ?
                            
                            <>
                              <span 
                                className="mr-1" 
                                style={{ cursor: "context-menu" }}
                              >

                                { row.price.data.findIndex(data => data.isActive) !== -1 ? 
                                  format2Currency(row.price.data[row.price.data.findIndex(data => data.isActive)].price) : "No Active Price"
                                }

                              </span>

                              { row.masterUnitNo && !readonly ?
                                <ListOptionIcon onClick={(event) => {event.stopPropagation(); onOpenUnitNumberDialog("prices", row) }} style={{cursor: "pointer"}}/>
                                : null
                              }

                            </>
                              
                              : 
                              
                              row.masterUnitNo && !readonly ? 
                                <span className="mr-1">
                                  <Tooltip title="Add Price" arrow>
                                    <AddCircle onClick={() => {setUnitNumberToAssign(row.unitNo && row.unitNo !== '0' ? row.unitNo : row.masterUnitNo); setUnitIdToAssign(row.id); setOpenNewPriceDialog(true); }} style={{ cursor: "pointer", color: "green"}} />
                                  </Tooltip>
                                </span> : <span className="mr-1">-</span> 
                              
                            }
                              
                              { row.price.isDisplayed ? <CheckCircle style={{fontSize:"1.1rem", color:"green"}} /> : null }

                          </div>
                         
                          : <span className="mr-1">-</span>
                        }

                      </TableCell>

                      <TableCell align="left" className="status-row">
                      
                        { row.status ? 
                          
                          <>
                          <span 

                            style={{
                              backgroundColor: row.status.toLowerCase() === "available" ? "#26A59A" 
                                             : row.status.toLowerCase() === "sold" ? "#F62221"
                                             : row.status.toLowerCase() === "reserved" ? "#D2D015"
                                             : null,
                              color: "white",
                              padding: "5px 10px",
                              borderRadius: "3px",
                              textTransform: "uppercase",
                              fontSize: "0.8rem",
                              boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                            }}

                          >{row.status}</span> 

                          { row.masterUnitNo && !readonly ?
                            <ListOptionIcon onClick={(event) => {event.stopPropagation(); onOpenMenuForSelectedRowField(event, row.id, row.unitNo, "status") }} style={{marginLeft:"10px", cursor: "pointer"}}/>
                            : null
                          }
                          </>
                          
                          : "-"
                        
                        }
                      
                      </TableCell>

                      <TableCell>

                        { row.publish ? 
                          
                          <>
                          <span 

                            style={{
                              backgroundColor: row.publish.toLowerCase() === "yes" ? "#26A59A"
                                             : row.publish.toLowerCase() === "no" ? "#F62221"
                                             : null,
                              color: "white",
                              padding: "5px 15px",
                              borderRadius: "3px",
                              fontSize: "0.8rem",
                              textTransform: "uppercase",
                              boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                            }}

                          >{row.publish}</span>

                          { row.masterUnitNo && !readonly ?
                            <ListOptionIcon onClick={(event) => {event.stopPropagation(); onOpenMenuForSelectedRowField(event, row.id, row.unitNo, "publish")}} style={{marginLeft:"10px", cursor: "pointer"}}/>
                            : null
                          }

                          </>
                          : "-"
                        
                        }

                      </TableCell>

                      <TableCell>

                        { row.exchanged ? 
                          
                          <>
                          <span 

                            style={{
                              backgroundColor: row.exchanged.toLowerCase() === "yes" ? "#26A59A"
                                             : row.exchanged.toLowerCase() === "no" ? "#F62221"
                                             : null,
                              color: "white",
                              padding: "5px 15px",
                              textTransform: "uppercase",
                              borderRadius: "3px",
                              fontSize: "0.8rem",
                              boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                            }}

                          >{row.exchanged}</span> 
                          {
                            row.masterUnitNo && !readonly ?                          
                          <ListOptionIcon onClick={(event) => {event.stopPropagation(); onOpenMenuForSelectedRowField(event, row.id, row.unitNo, "exchanged")}} style={{marginLeft:"10px", cursor: "pointer"}}/>
                          : null
                          }
                          </>
                          : "-"
                        
                        }


                      </TableCell>

                      <TableCell align="left">
                        
                        { row.masterUnitNo && !readonly ?

                          <Button
                          
                              className={`${classes.assignButton}`} 
                          
                              onClick={() => onOpenAssignUnitNumberDialog("Agent", row.unitNo && row.unitNo !== '0' ? row.unitNo : row.masterUnitNo, row.id)} 
                          
                              variant="contained" 
                          
                              component="span"
                          
                          >
                          
                              Assign
                          
                          </Button>

                          : "-"

                        }
                      
                      </TableCell>

                      <TableCell align="left">
                        
                        { 
                          
                          row.masterUnitNo && !readonly ?

                          <Button
                          
                              className={`${classes.assignButton}`} 
                          
                              onClick={() => onOpenAssignUnitNumberDialog("Affiliate", row.unitNo && row.unitNo !== '0' ? row.unitNo : row.masterUnitNo, row.id)} 
                          
                              variant="contained" 
                          
                              component="span"
                          
                          >
                          
                              Assign
                          
                          </Button>

                          : "-"

                        }

                      </TableCell>

                      <TableCell align="left">
                        <Button 
                          className={`${classes.assignButton}`} 
                          // onClick={() => onOpenAssignUnitNumberDialog("Affiliates", row.unitNo && row.unitNo !== '0' ? row.unitNo : row.masterUnitNo, row.id)} 
                          variant="contained" 
                          component="span">
                          Fetch
                        </Button>   
                      </TableCell>

                      <TableCell align="left">
                        {
                         <div onClick={() => { setShareUrl(row.share); setOpenUnitShareModal(true); setFloorPlanDataModal(`${BASE_URL}images/unitfiles/${plan}`); setInteriorDataModal(`${BASE_URL_IMAGE_PANOVIEW}?token=${new Buffer(`projectId=${row.projectId}&unitId=${row.unitNo}`).toString('base64')}`) }}>
                            <span className="mt-1" style={{ cursor: 'pointer' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="20px" fill="#878787"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"/></svg>
                            </span>
                        </div>
                        }
                      </TableCell>

                      <TableCell

                        id={labelId}

                        scope="row"

                        padding="none"

                        align="left"

                      >

                        {row.unitNo !== null && row.unitNo !== undefined ? row.unitNo : "-"}

                      </TableCell>

                    </TableRow>

                    </ThemeProvider>

                  );

                })}

              {/* {emptyRows > 0 && (

                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>

                  <TableCell colSpan={6} />

                </TableRow>

              )} */}

            </TableBody>

          </Table>

        </TableContainer>

        <TablePagination

          rowsPerPageOptions={[5, 10, 25, 50]}

          component="div"

          count={rowsData.length}

          rowsPerPage={rowsPerPage}

          page={page}

          onChangePage={handleChangePage}

          onChangeRowsPerPage={handleChangeRowsPerPage}

        />

        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onCloseMenuForSelectedRowField}
            className="unit-table-menu"
          >
            
            {
              menuItems.map((item,index) =>{
                  return item.status ==="Sold" ?
                  <MenuItem 
                    key={item.unitRowId+'_'+item.status} 
                    style={{
                      padding:"0 !important", 
                      backgroundColor: item.bgColor, 
                      color: item.color ? item.color :"white"
                    }}
                    onClick={() => { 
                      setSoldUnitDetails(item)
                      console.log('item',item);
                      setEditAppartmentDialog(true)
                      onCloseMenuForSelectedRowField();
                    }}
                  >
                  {item.status.toUpperCase()}
                </MenuItem> 
                   : <MenuItem 
                      key={item.unitRowId+'_'+item.status} 
                      style={{
                        padding:"0 !important", 
                        backgroundColor: item.bgColor, 
                        color: item.color ? item.color :"white"
                      }}
                      onClick={() => { onPerformActionForSelectedRowField(item.unitRowId, item.unitNo, item.status, item.actionFor); onCloseMenuForSelectedRowField(); }}
                    >
                  {item.status.toUpperCase()}
                </MenuItem>
              })
            }

          </Menu>
          <ShareModal 
              openShareModal={openShareModal}
              setOpenShareModal={setOpenShareModal}
              url={shareUrl}
          />
          <UnitShareModal 
              openUnitShareModal={openUnitShareModal}
              setOpenUnitShareModal={setOpenUnitShareModal}
              floorPlanData={floorPlanData}
              url={shareUrl}
              interiorData={interiorData}
          />
          { openEditAppartmentDialog && <EditAppartmentDialog 
            openEditAppartmentDialog={openEditAppartmentDialog}
            closeEditAppartmentDialog={closeEditAppartmentDialog}
            onPerformActionForSelectedRowField={onPerformActionForSelectedRowField}
            soldUnitDetails={soldUnitDetails}
            projectId={projectId}
            />
          }
    </div>

  );

}
