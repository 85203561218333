import { Button, DialogActions } from "@material-ui/core";

import React, { useEffect, useState } from "react";

import ReactCrop from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

const ImageCrop = ({ aspectRatio, imageFile, handleImageSave,handleImageDiscard }) => {

  const [file, setFile] = useState('');

  const [image, setImage] = useState(null);

  const [crop, setCrop] = useState({ aspect: aspectRatio || 16 / 9,height:72,width:128 });

  useEffect(() => {
  
    if(typeof imageFile === 'object') {
  
      setFile(URL.createObjectURL(imageFile));
  
    } else {
  
      setFile(imageFile);
  
      if(aspectRatio) {
  
        setCrop({aspect:aspectRatio,height: aspectRatio === 1 ? 80:72,width:aspectRatio === 1 ? 80 : 128});
  
      }
  
    }
  
  }, [aspectRatio,imageFile])

  function getCroppedImg() {
  
    const canvas = document.createElement("canvas");
  
    const scaleX = image.naturalWidth / image.width;
  
    const scaleY = image.naturalHeight / image.height;
  
    const pixelRatio = window.devicePixelRatio || 1;
  
    canvas.width = crop.width;
  
    canvas.height = crop.height;
  
    const ctx = canvas.getContext("2d");
  
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  
    ctx.imageSmoothingQuality = "high";
  
    ctx.drawImage(
  
      image,
  
      crop.x * scaleX,
  
      crop.y * scaleY,
  
      crop.width * scaleX,
  
      crop.height * scaleY,
  
      0,
  
      0,
  
      crop.width,
  
      crop.height
  
      );


    //As Base64 string
    
     const base64Image = canvas.toDataURL("image/png");
    
     console.log(base64Image);
    
     // setFinalImage(base64Image);
    
     handleImageSave(base64Image)
    
     // As a blob
    
     // return new Promise((resolve, reject) => {
    
     //   canvas.toBlob(blob => {
    
     //     blob.name = fileName;
    
     //     resolve(blob);
    
     //   }, 'image/jpeg', 1);
    
     // });

  }
  
  return (
  
  <div>
  
      {file && (
  
        <div style={{width:300,display:'flex',justifyContent:'center'}}>
  
        <ReactCrop
  
          src={file}
  
          imageStyle={{ maxHeight: 300, maxWidth: 300,objectFit:'contain'}}
  
          onImageLoaded={setImage}
  
          crop={crop}
  
          onChange={(newCrop) => setCrop(newCrop)}
  
        />
  
        </div>
  
      )}
  
      <DialogActions>
  
        <React.Fragment>
  
          <Button
  
            color="primary"
  
            onClick={() => getCroppedImg()}
  
          >
  
            Save
  
          </Button>
  
          <Button
  
            onClick={handleImageDiscard}
  
            color="primary"
  
          >
  
            Discard
  
          </Button>
  
        </React.Fragment>
  
      </DialogActions>
  
    </div>
  
  );

};

export default ImageCrop;
