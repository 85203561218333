import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Slide } from '@material-ui/core';

import { deepPurple } from '@material-ui/core/colors';

import React from 'react';

import { BASE_URL_IMAGE_USER } from '../../../Axios/api';

const Transition = React.forwardRef(function Transition(props, ref) {

  return <Slide direction="left" ref={ref} {...props} />;

});

const useStyles = makeStyles((theme)=>({

  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: 60,
		height: 60		
  }
  
}))

const ProfileDialog = ({selectedUserProfile,setSelectedUserProfile,setAssignModal,assignModal}) => {
  
  const classes = useStyles();

  return (

      <Dialog
      
        open={selectedUserProfile || false}
      
        TransitionComponent={Transition}
      
        maxWidth='md'
      
        onClose={()=>{setAssignModal({...assignModal,status:true});setSelectedUserProfile(false)}}
      
        aria-labelledby="alert-dialog-slide-title"
      
        aria-describedby="alert-dialog-slide-description"
      
      >
      
        <DialogTitle id="alert-dialog-slide-title">User Profile</DialogTitle>
      
        <DialogContent>
      
          <DialogContentText id="alert-dialog-slide-description">
      
          <div style={{minWidth:600}}>

            <div style={{display:'flex',justifyContent:'center'}}>
            
                <Avatar 
                  
                  className={`${classes.purple}`} 
                  
                  src={selectedUserProfile && selectedUserProfile.image ? `${BASE_URL_IMAGE_USER}${selectedUserProfile.image}` : null} 
                 
                  alt={selectedUserProfile.firstName +" " +selectedUserProfile.lastName}>
                  
                  {selectedUserProfile && (selectedUserProfile.firstName[0].toUpperCase() +selectedUserProfile?.lastName[0].toUpperCase())}
                
                </Avatar>
                {/* <img
              
                  src={ selectedUserProfile && selectedUserProfile.image ? `${BASE_URL_IMAGE_USER}${selectedUserProfile.image}` : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSxHWFss7T4f3QifjwCTUJ-VGqffPBBDI1VlQ&usqp=CAU"}
              
                  alt=" "
              
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit:'contain',
                    borderRadius:50
                  }}
              
                /> */}
            
            </div>

            <div className="col-md-12 col-sm-12 mt-4">

              <table>

                <tbody className='profileDialog_tbl'>
    
                  <tr>
      
                    <td style={{ fontWeight: 'bold', padding:12 }}>First Name</td>
      
                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.firstName ? selectedUserProfile.firstName : "-" }</td>
    
                  </tr>
    
                  <tr>
    
                    <td style={{ fontWeight: 'bold', padding:12 }}>Last Name</td>
      
                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.lastName ? selectedUserProfile.lastName : "-" }</td>
    
                  </tr>
      
                  <tr>
        
                    <td style={{ fontWeight: 'bold', padding:12 }}>Company Name</td>
                
                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.companyName && selectedUserProfile.companyName !== "null" ? selectedUserProfile.companyName : "-" }</td>
        
                  </tr>

                  <tr>

                    <td style={{ fontWeight: 'bold', padding:12 }}>Address</td>

                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.address ? selectedUserProfile.address : "-" }</td>

                  </tr>

                  <tr>

                    <td style={{ fontWeight: 'bold', padding:12 }}>Title</td>

                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.title ? selectedUserProfile.title : "-" }</td>

                  </tr>

                  <tr>

                    <td style={{ fontWeight: 'bold', padding:12 }}>Role</td>

                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.role ? selectedUserProfile.role : "-" }</td>

                  </tr>

                  <tr>

                    <td style={{ fontWeight: 'bold', padding:12 }}>Email</td>

                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.email ? selectedUserProfile.email : "-" }</td>

                  </tr>

                  <tr>

                    <td style={{ fontWeight: 'bold', padding:12 }}>Mobile</td>

                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.mobile ? selectedUserProfile.mobile : "-" }</td>

                  </tr>

                  <tr>

                    <td style={{ fontWeight: 'bold', padding:12 }}>About</td>

                    <td style={{textAlign:'right'}}>{selectedUserProfile && selectedUserProfile.about && selectedUserProfile.about !== "null" ? selectedUserProfile.about : "-" }</td>

                  </tr>

                </tbody>

              </table>

            </div>

          </div>
        
        </DialogContentText>
        
      </DialogContent>
      
      <DialogActions>
      
        <Button onClick={()=>{setAssignModal({...assignModal,status:true});setSelectedUserProfile(false)}} color="primary">
      
            Close
      
          </Button>
      
      </DialogActions>
    
    </Dialog>
  
  );

}

export default ProfileDialog;