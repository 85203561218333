import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ActivateConfirmationDialog({
    openActivateConfirmationDialog,
    setOpenActivateConfirmationDialog,
    onActivateSelectedData,
    showDataFor
}) {

    return (
        <div>
            <Dialog
                open={openActivateConfirmationDialog}
                onClose={() => setOpenActivateConfirmationDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Do you want to activate this ${showDataFor==="prices" ? 'Price' : 'Floor Plan'}?`}</DialogTitle>
                {/* <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending anonymous location data to
                    Google, even when no apps are running.
                </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                <Button onClick={() => setOpenActivateConfirmationDialog(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {
                        if(showDataFor === "floorPlan") onActivateSelectedData("floorPlan")
                        else onActivateSelectedData("prices")
                    }} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}