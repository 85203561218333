export function format2Currency(num) {
  return '$' + (num*1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function beautifyFileSize(size) {
  if (size >= 1048576)
    return (size/1048576).toFixed(1) + 'MB';
  else if (size >= 1024)
    return (size/1024).toFixed(1) + 'KB';
  else
    return size + 'B';
}