import React, { useEffect, useRef, useState } from 'react'
import Menubar from '../Menubar/Menubar'
import ContactsLeadActions from './ContactsLeadActions/ContactsLeadActions';
import {ExcelRenderer} from 'react-excel-renderer';
import AddNewLeadDialog from './AddNewLeadDialog/AddNewLeadDialog';
import SnackbarToast from '../../utils/SnackbarToast';
import { exportCsvForCampaign, JsonArray, JsonToCSV } from '../../DummyData/sampleContactsFile';
import ContactsTabs from './ContactsTabs/ContactsTabs';
import AddNewTabDialog from './AddNewTabDialog/AddNewTabDialog';
import DeleteTabDialog from './DeleteTabDialog/DeleteTabDialog';
import TabTransitionDialog from './TabTransitionDialog/TabTransitionDialog';
import TagSelectionDialog from './TagSelectionDialog/TagSelectionDialog';
import LeadChangeDialog from './LeadChangeDialog/LeadChangeDialog';
import { v4 as uuidv4 } from 'uuid';
import DeleteContactsDialog from './DeleteContactsDialog/DeleteContactsDialog';
import $ from 'jquery';
import axiosInstance from '../../Axios/axios';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { API_VERSION, BASE_URL } from '../../Axios/api';
import logger from '../../utils/logger';

function createData(id, firstName, lastName, phoneNumber, email, description, leadValue, postalCode, address, state, city, country, website, tags) {
  return {id, firstName, lastName, phoneNumber, email, description, leadValue, postalCode, address, state, city, country, website, tags };
}

// const rows = [
//     createData(uuidv4(), 'Meet', 'Parikh', '9601134559', 'meet@gmail.com', 'Developer', 10, 3900, '12 Street Avenue', 'Gujarat', 'Vadodara', 'India', 'www.meetthedev.me', 'Follow up'),
//     createData(uuidv4(), 'John', 'Doe', '9601134559', 'john@gmail.com', 'Developer', 10, 3900, '12 Street Avenue', 'Gujarat', 'Vadodara', 'India', 'www.johnthedev.me', 'Follow up'),
//     createData(uuidv4(), 'Meet', 'Parikh', '9601134559', 'meet@gmail.com', 'Developer', 10, 3900, '12 Street Avenue', 'Gujarat', 'Vadodara', 'India', 'www.meetthedev.me', 'Follow up'),
//     createData(uuidv4(), 'John', 'Doe', '9601134559', 'john@gmail.com', 'Developer', 10, 3900, '12 Street Avenue', 'Gujarat', 'Vadodara', 'India', 'www.johnthedev.me', 'Follow up'),
//     createData(uuidv4(), 'Meet', 'Parikh', '9601134559', 'meet@gmail.com', 'Developer', 10, 3900, '12 Street Avenue', 'Gujarat', 'Vadodara', 'India', 'www.meetthedev.me', 'Follow up'),
//     createData(uuidv4(), 'John', 'Doe', '9601134559', 'john@gmail.com', 'Developer', 10, 3900, '12 Street Avenue', 'Gujarat', 'Vadodara', 'India', 'www.johnthedev.me', 'Follow up')
// ];

const Contacts = ({setContactIds, history}) => {
    
    const [contactsLead, setContactsLead] = useState([]);
    const [openAddNewLeadDialog, setOpenAddNewLeadDialog] = useState(false);
    const [openAddNewTabDialog, setOpenAddNewTabDialog] = useState(false);
    const [leadDetails, setLeadDetails] = useState({
        id: '',
        tab: '',
        // source: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        description: '',
        leadValue: '',
        postalCode: '',
        address: '',
        state: '',
        city: '',
        country: '',
        website: '',
        tags: '',
    });
    const [openSnackbar, setOpenSnackbar] = useState("");
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [sampleContactsFile, setSampleContactsFile] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [contactsTabs, setContactsTabs] = useState([]);
    const [newTabValue, setNewTabValue] = useState("");
    const [selectedTabValueToPerformAction, setSelectedTabValueToPerformAction] = useState(0);
    const [openDeleteTabDialog, setOpenDeleteTabDialog] = useState(false);
    const [contactsLeadForTabs, setContactsLeadForTabs] = useState({});
    const [openMenuForSelectedTab, setOpenMenuForSelectedTab] = useState(false);
    const [openTabTransitionDialog, setOpenTabTransitionDialog] = useState(false);
    const [openTagSelectionDialog, setOpenTagSelectionDialog] = useState(false);
    const [tagValueToChange, setTagValueToChange] = useState('');
    const [openLeadChangeDialog, setOpenLeadChangeDialog] = useState(false);
    const [leadValueToChange, setLeadValueToChange] = useState(false);
    const [contactToEdit, setContactToEdit] = useState(null);
    const [openEditContactDialog, setOpenEditContactDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState([]);
    const [openDeleteContactsDialog, setOpenDeleteContactsDialog] = useState(false);
    const [folderData, setFolderData] = useState([]);
    const [currentContactData, setCurrentContactData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const importContactsRef = useRef(null);

    useEffect(() => {
        if (window.mainInitAll) {
            window.mainInitAll();
        }
        
        onToggleMobileSidebar();

        $('.content-wrapper').on('click', function (e) {

            onToggleMobileSidebar();

        });

        setSampleContactsFile(JsonToCSV(JsonArray));
    
        const getContactList = async () => {

            const token = localStorage.getItem("token");

            if (token) {

                setIsLoading(true);
                const response = await axiosInstance.get('/contactFolderLists', {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }).catch(err => {

                    if (err.response && err.response.data.status === 401) {

                        setIsLoading(false);
                        history.push('/login');
                        localStorage.clear();
                    }

                    if (err.response && err.response.data.status === 500) {

                        onShowSnackbarWithDetails("error", err.response.data.message, true);
                    }

                    return;
                });

                if (response && response.data.status === 200) {

                    let tabsData = response.data.payload.data;
                
                    setFolderData(tabsData);

                    if (tabsData.length > 0) {

                        for (let i=0; i<tabsData.length; i++) {

                            let tabsName = tabsData.map(({folderName}) => folderName);
                            setContactsTabs(tabsName);

                            let contactsLeadData = tabsData[i].contacts;
                    
                            if (tabsData[i].contacts.length > 0) setCurrentContactData({[i]: contactsLeadData});
                            
                            else setCurrentContactData({[i]: []});
                        }
                    }

                    setIsLoading(false);
                }
            }

            else {

                history.push('/login');
                localStorage.clear();
                setIsLoading(false);
                return;
            }
        }

        getContactList();

    }, []);

    useEffect(() => setContactIds([...selected]), [selected]);

    useEffect(() => {

        if (currentContactData) {

            setContactsLeadForTabs({...contactsLeadForTabs, ...currentContactData});                                                                     
        }
        
    }, [currentContactData]);

    const onToggleMobileSidebar = () => {

        if ($('.sidebar-main').width() == 56) {

            $('.content-wrapper').addClass('content-wrapper-collapse');
        }

        if ($('body').hasClass('sidebar-mobile-main')) {

            $('body').removeClass('sidebar-mobile-main');
        }

        $('.sidebar-main').removeClass('sidebar-expand-toggle');
    }

    const onDeleteSelectedContacts = async () => {

        let filteredContacts = [...contactsLeadForTabs[tabValue]];

        const token = localStorage.getItem("token");

        if (token) {

            setIsLoading(true);
            const response = await axiosInstance.post('/contactDestroy', {contact_ids: selected}, {
                headers: {
                    Authorization: "Bearer " +token
                }
            })
            .catch(err => {

                if (err.response && err.response.data.data.status === 401) {

                    setIsLoading(false);
                    history.push('/login');
                    localStorage.clear();
                }

                return;
            })
            .then(
                logger({
                    title:'Destroy Contact',
                    url:window.location.href,
                    method:'POST',
                    activity: 'contactDestroy',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +'/contactDestroy' ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:null,
                    assignedId:null,
                    })      
            )

            if (response && response.data.status === 200) {

                for (let contactId of selected) {

                    filteredContacts = filteredContacts.filter(contact => contact.id !== contactId);
                }

                setContactsLeadForTabs({...contactsLeadForTabs, [tabValue] : filteredContacts});
                setOpenDeleteContactsDialog(false);
                setSelected([]);
                onShowSnackbarWithDetails("success", "Contact(s) Deleted!", true);
                setIsLoading(false);
            } 
        }  
        
        else {

            history.push('/login');
            setIsLoading(false);
            localStorage.clear();
            return;
        }
    }

    const onExportSelectedTabContactsForCampaign = () => {

        const isAnyContactSelected = isAnyContactSelectedFromSelectedTab();
        let contactsArrayForSelectedTab = [];

        if (!isAnyContactSelected) {
            contactsArrayForSelectedTab = [];
            onShowSnackbarWithDetails("warning", "Select atleast one Contact to proceed further!", true);
            return;
        }

        else {
            
            let contactsLeadsCopy = JSON.parse(JSON.stringify(contactsLeadForTabs));
            contactsLeadsCopy = contactsLeadsCopy[selectedTabValueToPerformAction].map(({id, firstName, lastName, email}) => {
                
                if (selected.includes(id)) {

                    return ({firstName, lastName, email});
                }
            });

            contactsLeadsCopy = contactsLeadsCopy.filter(data => !!(data) !== false)

            for (let contactsLeads of contactsLeadsCopy) {

                let row = [];

                for (let data of Object.values(contactsLeads)) {

                    if (data) { row.push(data); }

                }

                contactsArrayForSelectedTab.push(row);        
            }
        }

        const contactsCSVFile = exportCsvForCampaign(contactsArrayForSelectedTab);
        let hiddenAnchorElement = document.createElement('a');

        hiddenAnchorElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(contactsCSVFile);
        hiddenAnchorElement.target = '_blank';
        hiddenAnchorElement.download = `Contacts_${contactsTabs.find((tab,index) => index === selectedTabValueToPerformAction)}_${new Date().getTime()}.csv`;
        hiddenAnchorElement.click();
        setSelected([]);
        onCloseMenuForSelectedTab();
    }

    const onChangeLeadValue = async (leadValue) => {

        if (leadValue > 10 || leadValue < 1) {

            onShowSnackbarWithDetails("warning", "Lead value should be between 1 to 10!", true);
            return;
        }

        const token = localStorage.getItem("token");

        if (token) {

            setIsLoading(true);
            const response = await axiosInstance.post('/contactChangeLead', {
                contact_ids: selected,
                leadValue
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).catch(err => {

                if (err.response && err.response.data.status === 401) {

                    setIsLoading(false);
                    history.push('/login');
                    localStorage.clear();
                }

                return;
            })
            .then(
                logger({
                    title:'ChangeLead Contact',
                    url:window.location.href,
                    method:'POST',
                    activity: 'contactChangeLead',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +'/contactChangeLead' ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:null,
                    assignedId:null,
                    })      
            )

            if (response && response.data.status === 200) {

                const contactsLeadsCopyForSelectedTab = contactsLeadForTabs[selectedTabValueToPerformAction];
                
                for (let contact of contactsLeadsCopyForSelectedTab) {

                    if (selected.includes(contact.id)) contact.leadValue = leadValue;
                    
                }

                setContactsLeadForTabs({...contactsLeadForTabs, [selectedTabValueToPerformAction]: contactsLeadsCopyForSelectedTab});
                onCloseLeadChangeDialog();
                onCloseMenuForSelectedTab();
                setSelected([]);
                onShowSnackbarWithDetails("success", "Lead Value Changed!", true);
                setIsLoading(false);
                return;
            }
        }

        else {

            history.push('/login');
            localStorage.clear();
            setIsLoading(false);
            return;
        }
    }

    const onOpenLeadChangeDialog = () => {

        const isAnyContactSelected = isAnyContactSelectedFromSelectedTab();

        if (isAnyContactSelected) setOpenLeadChangeDialog(true);

        else {

            onShowSnackbarWithDetails("warning", "No contact selected from chosen tab.", true);
            return;
        }
    }

    const onOpenTagSelectionDialog = () => {

        const isAnyContactSelected = isAnyContactSelectedFromSelectedTab();

        if (isAnyContactSelected) setOpenTagSelectionDialog(true);

        else {

            onShowSnackbarWithDetails("warning", "No contact selected from chosen tab.", true);
            return;
        }
    }

    const isAnyContactSelectedFromSelectedTab = () => {
        
        if (selected.length > 0) {

            const index = contactsLeadForTabs[selectedTabValueToPerformAction].findIndex(contactLead => contactLead.id === selected[0]);

            if (index === -1) return false;
            else return true;
        }

        else return false;
    }

    const onCloseLeadChangeDialog = () => {
        
        setOpenLeadChangeDialog(false);
        onCloseMenuForSelectedTab();
        setLeadValueToChange('');
    }

    const onChangeTagValue = async (tagValue) => {

        const token = localStorage.getItem("token");

        if (token) {

            setIsLoading(true);
            const response = await axiosInstance.post('/contactChangeTag', {
                contact_ids: selected,
                tag_value: tagValue
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).catch(err => {

                if (err.response && err.response.data.status === 401) {

                    setIsLoading(false);
                    history.push('/login');
                    localStorage.clear();
                }

                return;
            })
            .then(
                logger({
                    title:'Change Tag of Contact',
                    url:window.location.href,
                    method:'POST',
                    activity: 'contactChangeTag',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +'/contactChangeTag' ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:null,
                    assignedId:null,
                    })      
            )

            if (response && response.data.status === 200) {

                const contactsLeadsCopyForSelectedTab = contactsLeadForTabs[selectedTabValueToPerformAction];

                for (let contact of contactsLeadsCopyForSelectedTab) {

                    if (selected.includes(contact.id)) contact.tags = tagValue;

                }

                setContactsLeadForTabs({
                    ...contactsLeadForTabs,
                    [selectedTabValueToPerformAction]: contactsLeadsCopyForSelectedTab
                });
                onCloseTagSelectionDialog();
                onCloseMenuForSelectedTab();
                setSelected([]);
                onShowSnackbarWithDetails("success", "Tag Changed!", true);
                setIsLoading(false);
            }
        }

        else {

            history.push('/login');
            localStorage.clear();
            setIsLoading(false);
            return;
        }
    }

    const onCloseTagSelectionDialog = () => {
        
        setOpenTagSelectionDialog(false);
        onCloseMenuForSelectedTab();
        setTagValueToChange('');
    }
    
    const onCloseEditLeadDialog = () => {

        setOpenEditContactDialog(false);
        setContactToEdit(null);
        resetAddNewLeadForm();
    }

    const onOpenEditContactDialog = (contact) => {

        setContactToEdit(contact);
        setLeadDetails({
            id: contact.id,
            tab: contact.tab ? contact.tab : contactsTabs.find((tab,index) => index === tabValue),
            // source: '',
            tags: contact.tags,
            firstName: contact.firstName,
            lastName: contact.lastName,
            address: contact.address,
            city: contact.city,
            email: contact.email,
            state: contact.state,
            website: contact.website,
            country: contact.country,
            phoneNumber: contact.phoneNumber,
            postalCode: contact.postalCode,
            leadValue: contact.leadValue,
            description: contact.description
        })
        setOpenEditContactDialog(true);
    }

    const editLeadHandler = async () => {

        if (isNewLeadFormValid()) {

            const contactIndex = contactsLeadForTabs[tabValue].findIndex(contact => contact.id === contactToEdit.id);
            
            if (contactIndex !== -1) {

                const token = localStorage.getItem("token");

                if (token) {

                    setIsLoading(true);
                    const contactId = leadDetails.id;
                    const response = await axiosInstance.post(`/contactUpdate/${contactId}`, {
                        ...leadDetails,
                        folder_id: folderData[tabValue].id
                    }, {
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    })
                    .catch(err => {

                        if (err.response && err.response.data.status === 401) {

                            setIsLoading(false);
                            history.push('/login');
                            localStorage.clear();
                        }

                        else if (err.response && err.response.data?.status === 422 && err.response.data?.payload?.error) {

                            setIsLoading(false);
                            onShowSnackbarWithDetails("error", err.response.data.payload.error, true);
                        }

                        return;
                    })
                    .then(
                        logger({
                            title:'Update Contact',
                            url:window.location.href,
                            method:'POST',
                            activity: 'contactUpdate',
                            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                            api:BASE_URL+ API_VERSION +`/contactUpdate/${contactId}` ,
                            session:localStorage.getItem('session'),
                            projectId:null,
                            unitId:null,
                            assignedId:null,
                            })      
                    )        

                    if (response && response.data.status === 200) {

                        const contactsLeadObj = {...contactsLeadForTabs};
                        contactsLeadObj[tabValue][contactIndex] = leadDetails;
                        setContactsLeadForTabs({...contactsLeadObj});
                        setOpenEditContactDialog(false);
                        setContactToEdit(null);
                        onShowSnackbarWithDetails("success", "Contact Details Updated!", true);
                        onCloseAddNewLeadDialog();
                        setIsLoading(false);
                        return; 
                    }
                }

                else {

                    history.push('/login');
                    localStorage.clear();
                    setIsLoading(false);
                    return;
                }
            }

            else {

                onShowSnackbarWithDetails("warning", leadDetails.tab + " Tab does not exist.", true);
                setContactToEdit(null);
                return;
            }
        }
    }

    const onSwitchToDifferentTab = (tabIndex) => {

        setTabValue(tabIndex);
        onCloseTabTransitionDialog();
    }
    
    const onCloseTabTransitionDialog = () => {
        
        setOpenTabTransitionDialog(false);
        onCloseMenuForSelectedTab();
    }

    const onDeleteSelectedTab = async () => {
      
        const tab = folderData[selectedTabValueToPerformAction];
        
        if (tab) {

            setIsLoading(true);
            const token = localStorage.getItem("token");

            if (token) {

                const response = await axiosInstance.delete(`/contactFolderDestroy/${tab.id}`, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                })
                .catch(err => {

                    if (err.response && err.response.data.status === 401) {

                        history.push('/login');
                        localStorage.clear();
                        setIsLoading(false);
                    }

                    return;
                })
                .then(
                    logger({
                        title:'Destroy Contact Folder',
                        url:window.location.href,
                        method:'DELETE',
                        activity: 'contactFolderDestroy',
                        user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                        api:BASE_URL+ API_VERSION +`/contactFolderDestroy/${tab.id}`,
                        session:localStorage.getItem('session'),
                        projectId:null,
                        unitId:null,
                        assignedId:null,
                        })      
                )    

                if (response && response.data.status === 200) {

                    setContactsTabs(contactsTabs => contactsTabs.filter((tab,index) => index !== selectedTabValueToPerformAction));
                    setFolderData(folderData => folderData.filter((tab, index) => index !== selectedTabValueToPerformAction));
                    
                    if (tabValue > selectedTabValueToPerformAction) setTabValue(tabValue => tabValue - 1);
                    if (tabValue === selectedTabValueToPerformAction) { setTabValue(0); }
                    
                    onCloseDeleteTabDialog();
                    onShowSnackbarWithDetails("success", "Folder Deleted!", true);
                    setIsLoading(false);
                }
            }

            else {

                history.push('/login');
                localStorage.clear();
                setIsLoading(false);
                return;
            }
        }
    }

    const onCloseDeleteTabDialog = () => {
        
        setOpenDeleteTabDialog(false);
        onCloseMenuForSelectedTab();
    }

    const onOpenMenuForSelectedTab = (event, index) => {
        
        setOpenMenuForSelectedTab(true);
        setAnchorEl(event.currentTarget);
        setSelectedTabValueToPerformAction(index);

    }

    const onCloseMenuForSelectedTab = () => {

        setAnchorEl(null);
        setOpenMenuForSelectedTab(false);
        setSelectedTabValueToPerformAction(0);
    }

    const resetAddNewLeadForm = () => {

        setLeadDetails({
            id: '',
            tab: '',
            // source: '',
            tags: '',
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            email: '',
            state: '',
            website: '',
            country: '',
            phoneNumber: '',
            postalCode: '',
            leadValue: '',
            description: ''
        })
        
    }

    const isNewLeadFormValid = () => {

        for (let key of Object.keys(leadDetails)) {

            if (!contactToEdit) {
                
                if (key !== "id" && ["firstName", "lastName", "email", "tags"].includes(key) && (typeof leadDetails[key] === "string") && !leadDetails[key].trim()) {

                    if (key === "firstName") {

                        onShowSnackbarWithDetails("warning", `First name is required to add Contact!`, true);
                        return false;
                    }

                    else if (key === "lastName") {

                        onShowSnackbarWithDetails("warning", `Last name is required to add Contact!`, true);
                        return false;
                    }

                    onShowSnackbarWithDetails("warning", `${key} is required to add Contact!`, true);
                    return false;
                }

                else if (["firstName", "lastName"].includes(key) && !(/^[a-zA-Z\s]*$/.test(leadDetails[key]))) {
                
                    onShowSnackbarWithDetails("warning", `First name and Last name should only consist of alphabets!`, true);
                    return false;

                }

                else if ((leadDetails["leadValue"] === 0 || leadDetails["leadValue"]) && (leadDetails["leadValue"] > 10 || leadDetails["leadValue"] < 1)) {

                    onShowSnackbarWithDetails("warning", "Lead value should be between 1 to 10!", true);
                    return false;
                }
            }

            else if (contactToEdit) {

                if (["firstName", "lastName", "email", "tags"].includes(key) && (typeof leadDetails[key] === "string") && !leadDetails[key].trim()) {

                    if (key === "firstName") {

                        onShowSnackbarWithDetails("warning", `First name is required to edit Contact!`, true);
                        return false;
                    }

                    else if (key === "lastName") {

                        onShowSnackbarWithDetails("warning", `Last name is required to edit Contact!`, true);
                        return false;
                    }

                    onShowSnackbarWithDetails("warning", `${key} is required to edit Contact!`, true);
                    return false;
                }

                else if (["firstName", "lastName"].includes(key) && !(/^[a-zA-Z\s]*$/.test(leadDetails[key]))) {

                    onShowSnackbarWithDetails("warning", `First name and Last name should only consist of alphabets!`, true);
                    return false;

                }

                else if ((leadDetails["leadValue"] === 0 || leadDetails["leadValue"]) && (leadDetails["leadValue"] > 10 || leadDetails["leadValue"] < 1)) {

                    onShowSnackbarWithDetails("warning", "Lead value should be between 1 to 10!", true);
                    return false;
                }
            }
        }

        return true;
    }

    const addNewLeadHandler = async () => {

        if (isNewLeadFormValid()) {

            const indexOfTab = contactsTabs.findIndex(tabName => tabName.toLowerCase() === leadDetails.tab.toLowerCase());
            
            if (indexOfTab !== -1) {

                const token = localStorage.getItem("token");

                if (token) {

                    setIsLoading(true);
                    const response = await axiosInstance.post('/contactCreate',{
                        ...leadDetails,
                        folder_id: folderData[indexOfTab].id
                    }, {
                        headers: {
                            Authorization: "Bearer " +token
                        }
                    })
                    .catch(err => {

                        if (err.response && err.response.data.status === 401) {

                            history.push('/login');
                            localStorage.clear();
                            setIsLoading(false);
                        }

                        else if (err.response && err.response.data?.status === 422 && err.response.data?.payload?.error) {

                            setIsLoading(false);
                            onShowSnackbarWithDetails("error", err.response.data.payload.error, true);
                        }

                        return;
                    })
                    .then(
                        logger({
                            title:'Create Contact Folder',
                            url:window.location.href,
                            method:'POST',
                            activity: 'contactCreate',
                            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                            api:BASE_URL+ API_VERSION +'/contactCreate' ,
                            session:localStorage.getItem('session'),
                            projectId:null,
                            unitId:null,
                            assignedId:null,
                            })      
                    )        

                    if (response && response.data.status === 200) {

                        const leadDetailsCopy = {...leadDetails};
                        leadDetailsCopy.id = response.data.payload.data.id;
                        setContactsLeadForTabs({...contactsLeadForTabs, [indexOfTab]: contactsLeadForTabs[indexOfTab].concat(leadDetailsCopy)});
                        onCloseAddNewLeadDialog();
                        setIsLoading(false);
                        onShowSnackbarWithDetails("success", "Contact Added!", true);
                        return; 
                    }
                }

                else {

                    history.push('/login');
                    localStorage.clear();
                    setIsLoading(false);
                    return;
                }                
            }

            else {

                onShowSnackbarWithDetails("warning", leadDetails.tab + " Tab does not exist.", true);
                return;
            }
        }
    }

    const addNewTabHandler = async () => {

        if (newTabValue.trim()) {

            if (contactsTabs.findIndex(tabName => tabName.toLowerCase() === newTabValue.toLowerCase()) !== -1) {

                onShowSnackbarWithDetails("warning", newTabValue + " tab already exist!", true);
                return;
            }

            else {

                const token = localStorage.getItem("token");

                if (token) {

                    setIsLoading(true);
                    const response = await axiosInstance.post('/contactFolderCreate', {folder_name: newTabValue}, {
                        headers: {
                            Authorization : "Bearer " +token
                        }
                    })
                    .catch(err => {

                        if (err.response && err.response.data.status === 401) {

                            history.push('/login');
                            localStorage.clear();
                            setIsLoading(false);
                        }

                        return;
                    })
                    .then(
                        logger({
                            title:'User Search By Criteria',
                            url:window.location.href,
                            method:'POST',
                            activity: 'contactFolderCreate',
                            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                            api:BASE_URL+ API_VERSION +'/contactFolderCreate' ,
                            session:localStorage.getItem('session'),
                            projectId:null,
                            unitId:null,
                            assignedId:null,
                            })      
                    )        

                    if (response && response.data.status === 200) {

                        setContactsTabs(contactsTabs => contactsTabs.concat(newTabValue));
                        setFolderData(folderData => folderData.concat({...response.data?.payload?.data, contacts: []}))
                        setNewTabValue('');
                        setContactsLeadForTabs({...contactsLeadForTabs, [contactsTabs.length]: []})
                        onCloseAddNewTabDialog();
                        setIsLoading(false);
                        onShowSnackbarWithDetails("success", "Folder Added!", true);                        
                    }
                }

                else {

                    history.push('/login');
                    localStorage.clear();
                    setIsLoading(false);
                    return;
                }
            }
        }

        else {

            onShowSnackbarWithDetails("warning", "Tab name cannot be empty!", true);
            return;
        }        
    }

    const changeLeadDetailsHandler = (event) => setLeadDetails({...leadDetails, [event.target.name] : event.target.value})

    const onOpenAddNewLeadDialog = () => setOpenAddNewLeadDialog(true);
    
    const onOpenAddNewTabDialog = () => setOpenAddNewTabDialog(true);

    const onCloseAddNewLeadDialog = () => {
        
        setOpenAddNewLeadDialog(false);
        resetAddNewLeadForm();
    }

    const onCloseAddNewTabDialog = () => {

        setOpenAddNewTabDialog(false);
        setNewTabValue('');
    }

    const onImportLeadsFile = (event) => {
        let selFolder = folderData[tabValue];
        if (!selFolder) {
            onShowSnackbarWithDetails("error", "You need to create a folder to import contacts", true);     
            return;
        }

        const fileObj = event.target.files[0];

        const contactsLeadData = [];

        ExcelRenderer(fileObj, async (err, response) => {

            if (err) console.log(err);

            else {

                const rowTitles = response.rows[0].map(title => {

                    const modifiedTitle = (title.trim()[0].toLowerCase() + title.trim().substring(1)).replace(/\s/g, "")
                    if (modifiedTitle === "leadvalue") return modifiedTitle.replace('v', 'V');
                    if (modifiedTitle === "phonenumber") return modifiedTitle.replace('number', 'Number');
                    else return modifiedTitle;

                });

                const rowData = response.rows.slice(1);
            
                for (let i=0; i<rowData.length; i++) {

                    let data = {};
                    
                    for (let j=0; j<rowTitles.length; j++) {

                        data[rowTitles[j]] = rowData[i][j] ? rowData[i][j] : "";

                    }

                    data.id = uuidv4();
                    contactsLeadData.push(data);
                }

                importContactsRef.current.value = null;

                const token = localStorage.getItem("token");

                if (token) {

                    setIsLoading(true);
                    const response = await axiosInstance.post('/contactImport', {
                        folder_id : folderData[tabValue].id,
                        contacts: contactsLeadData
                    }, {
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).catch(err => {

                        if (err.response && err.response.data.status === 401) {

                            setIsLoading(false);
                            history.push('/login');
                            localStorage.clear();
                        }

                        return;
                    })
                    .then(
                        logger({
                            title:'Contact Import',
                            url:window.location.href,
                            method:'POST',
                            activity: 'contactImport',
                            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                            api:BASE_URL+ API_VERSION +'/contactImport' ,
                            session:localStorage.getItem('session'),
                            projectId:null,
                            unitId:null,
                            assignedId:null,
                            })      
                    )


                    if (response && response.data.status === 200) {

                        setContactsLeadForTabs({...contactsLeadForTabs, [tabValue]: [...contactsLeadForTabs[tabValue], ...response.data.payload.data]});
                        setIsLoading(false);
                        onShowSnackbarWithDetails("success", "Contacts Imported!", true);
                    }
                }

                else {

                    history.push('/login');
                    localStorage.clear();
                    setIsLoading(false);
                    return;
                }
            }
        });
    }

    const onShowSnackbarWithDetails = (snackbarType, snackbarMessage, isSnackbarOpen) => {

        setSnackbarType(snackbarType);
        setOpenSnackbar(isSnackbarOpen);
        setMessageForSnackbar(snackbarMessage);
    }

    const handleSnackbarClose = () => {

        setOpenSnackbar(false);
        setSnackbarType("");
        setMessageForSnackbar("");
    }

    const handleTabChange = (event, newValue) => {
        
        setTabValue(newValue);
        setContactsLead(contactsLeadForTabs[newValue]);
        setSelected([]);
    }

    return (
        <div className="page-content">
            <Menubar/>
            <div className="content-wrapper">
                <div className="page-header page-header-light">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Home</span> - Contacts</h4>
                        </div>
                    </div>

                    <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                        <div className="d-flex">
                            <div className="breadcrumb">
                                <a href=" " className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</a>
                                <span className="breadcrumb-item active">Contacts</span>
                            </div>

                            <a href=" " className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="row m-0">

                        <ContactsLeadActions 
                            importContactsRef={importContactsRef}
                            onImportLeadsFile={onImportLeadsFile}
                            onOpenAddNewLeadDialog={onOpenAddNewLeadDialog}
                            sampleContactsFile={sampleContactsFile}
                            setSampleContactsFile={setSampleContactsFile}
                            onOpenAddNewTabDialog={onOpenAddNewTabDialog}
                        />

                        <AddNewLeadDialog 
                            openAddNewLeadDialog={openAddNewLeadDialog}
                            onCloseAddNewLeadDialog={onCloseAddNewLeadDialog}
                            changeLeadDetailsHandler={changeLeadDetailsHandler}
                            leadDetails={leadDetails}
                            contactsTabs={contactsTabs}
                            addNewLeadHandler={addNewLeadHandler}
                            contactToEdit={contactToEdit}
                            onCloseEditLeadDialog={onCloseEditLeadDialog}
                            editLeadHandler={editLeadHandler}
                            openEditContactDialog={openEditContactDialog}
                        />

                        <AddNewTabDialog
                            addNewTabHandler={addNewTabHandler}
                            openAddNewTabDialog={openAddNewTabDialog}
                            onCloseAddNewTabDialog={onCloseAddNewTabDialog}
                            newTabValue={newTabValue}
                            setNewTabValue={setNewTabValue}
                            addNewTabHandler={addNewTabHandler}
                            onOpenAddNewTabDialog={onOpenAddNewTabDialog}
                        />

                        <ContactsTabs
                            tabValue={tabValue}
                            handleTabChange={handleTabChange}
                            contactsLead={contactsLeadForTabs[tabValue]}
                            contactsTabs={contactsTabs}
                            onOpenMenuForSelectedTab={onOpenMenuForSelectedTab}
                            onCloseMenuForSelectedTab={onCloseMenuForSelectedTab}
                            anchorEl={anchorEl}
                            setOpenDeleteTabDialog={setOpenDeleteTabDialog}
                            selectedTabValueToPerformAction={selectedTabValueToPerformAction}
                            setOpenTabTransitionDialog={setOpenTabTransitionDialog}
                            onOpenEditContactDialog={onOpenEditContactDialog}
                            onOpenLeadChangeDialog={onOpenLeadChangeDialog}
                            onOpenTagSelectionDialog={onOpenTagSelectionDialog}
                            onExportSelectedTabContactsForCampaign={onExportSelectedTabContactsForCampaign}
                            selected={selected}
                            setSelected={setSelected}
                            setOpenDeleteContactsDialog={setOpenDeleteContactsDialog}
                        />

                        <SnackbarToast
                            handleSnackbarClose={handleSnackbarClose}
                            openSnackbar={openSnackbar}
                            snackbarType={snackbarType}
                            message={messageForSnackbar}
                        />

                        <DeleteTabDialog
                            openDeleteTabDialog={openDeleteTabDialog}
                            onCloseDeleteTabDialog={onCloseDeleteTabDialog}
                            onDeleteSelectedTab={onDeleteSelectedTab}
                        />

                        <TabTransitionDialog
                            openTabTransitionDialog={openTabTransitionDialog}
                            onCloseTabTransitionDialog={onCloseTabTransitionDialog}
                            onSwitchToDifferentTab={onSwitchToDifferentTab}
                            contactsTabs={contactsTabs}
                            tabValue={tabValue}
                        />

                        <TagSelectionDialog
                            openTagSelectionDialog={openTagSelectionDialog}
                            onCloseTagSelectionDialog={onCloseTagSelectionDialog}
                            onChangeTagValue={onChangeTagValue}
                            tagValueToChange={tagValueToChange}
                            setTagValueToChange={setTagValueToChange}
                        />

                        <LeadChangeDialog
                            openLeadChangeDialog={openLeadChangeDialog}
                            onCloseLeadChangeDialog={onCloseLeadChangeDialog}
                            onChangeLeadValue={onChangeLeadValue}
                            leadValueToChange={leadValueToChange}
                            setLeadValueToChange={setLeadValueToChange}
                        />

                        <DeleteContactsDialog
                            openDeleteContactsDialog={openDeleteContactsDialog}
                            setOpenDeleteContactsDialog={setOpenDeleteContactsDialog}
                            onDeleteSelectedContacts = {onDeleteSelectedContacts}
                        />

                        <LoadingSpinner isLoading={isLoading} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;
