import { AddCircle } from '@material-ui/icons';
import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { AuthContext } from '../../../Context/userContext';
import ProjectFormDialog from '../../Projects/ProjectDetailsAccordion/ProjectActions/ProjectForm/ProjectFormDialog';

function AdminSidebar({allProjectsDetails}) {

    const [openAddProjectDialog, setOpenAddProjectDialog] = useState(false);

    return (
        <div className="card card-sidebar-mobile">
            <ul className="nav nav-sidebar" data-nav-type="accordion">

                <li className="nav-item-header"><div className="text-uppercase font-size-xs line-height-xs">Main</div> <i className="icon-menu" title="Main"></i></li>
                <li className="nav-item">
                    <NavLink to="/" exact className="nav-link">
                        <i className="icon-home4"></i>
                        <span>
                            Dashboard
                        </span>
                    </NavLink>
                </li>				
                {/* <li className="nav-item">
                    <Accordion className="mb-2">
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ background: "#26a69a", color: "white" }}
                    >
                    <div className="nav-link"><i className="icon-color-sampler"></i> <span>Projects</span></div>
                    </AccordionSummary>
                    <AccordionDetails style={{padding:"16px"}}>
                        {userContextData.user && userContextData.user.projects && userContextData.user.projects.map(project => 
                            <li key={project.id} className="nav-item">
                                <NavLink to={`/project/${project.id}`} className="nav-link">{project.name}</NavLink>
                            </li>								
                        )}
                    </AccordionDetails>
                </Accordion>
                </li> */}

                 <li className="nav-item nav-item-submenu">
                    <NavLink to="/users" className="nav-link"><i className="icon-people"></i> <span>Users</span></NavLink>
                </li>

                <li id="projects" style={{cursor:"pointer"}} className="nav-item nav-item-submenu">
                
                    <a onClick={() => {
                        document.getElementById("projects").classList.toggle("nav-item-open")
                        document.getElementById("projects-dropdown").classList.toggle("d-block")
                    }} className="nav-link"><i className="icon-color-sampler"></i> <span>Projects</span></a>

                    <ul id="projects-dropdown" className="nav nav-group-sub" data-submenu-title="Projects">
                        
                            <li className="nav-item m-0">
                            <span className="d-flex align-items-center nav-link" onClick={() => setOpenAddProjectDialog(true)} style={{color: "white", cursor:"pointer"}}>
                                <AddCircle style={{fontSize:"1.2rem"}}/> <span style={{paddingLeft:"5px"}}>Add Project</span>
                            </span>

                            <ProjectFormDialog                               

                                setOpenAddProjectDialog={setOpenAddProjectDialog}

                                setOpen={openAddProjectDialog}

                                openAddProjectDialog={openAddProjectDialog} 

                            />

                            </li>

                        {allProjectsDetails && allProjectsDetails.length > 0 ? 
                            allProjectsDetails.map(project => 
                                <li key={project.id} className="nav-item">
                                    <NavLink to={`/project/${project.id}`} className="nav-link" style={{color: !project.deployedAt ? "gray" : "white"}}>{project.name}
                                        { !project.deployedAt ? <span className="badge bg-transparent align-self-center ml-auto">DRAFT</span> : null }
                                    </NavLink>
                                </li>								
                            )
                        : 
                            <li className="nav-item m-0">
                                <span className="nav-link m-0 mb-0" style={{color: "gray"}}>NO PROJECTS FOUND</span>
                            </li>
                        }
                    
                    </ul>
                </li>

                <li className="nav-item nav-item-submenu">
                    <NavLink to="/contacts" className="nav-link"><i className="icon-stack"></i> <span>Contacts</span></NavLink>
                </li>

                <li className="nav-item nav-item-submenu">
                    <NavLink to="/email-campaign" className="nav-link"><i className="icon-bubbles4"></i> <span>Email Campaign</span></NavLink>
                </li>
            </ul>
        </div>
    )
}

export default AdminSidebar
