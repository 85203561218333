import React, {useContext} from 'react';
import { AuthContext } from '../../Context/userContext';
import axiosInstance from '../../Axios/axios';
import { Button, Dialog, DialogContent, DialogTitle,  Checkbox} from '@material-ui/core';
import logger from '../../utils/logger';
import { API_VERSION, BASE_URL } from '../../Axios/api';

const DowngradeModal = ({ modalOpened, closeDialog, plan, presentPlan }) => {
    const userContextData = useContext(AuthContext);
    const token = localStorage.getItem("token");
    const downgradeAccount = () => {
        if (token) {
            // Create PaymentIntent as soon as the page loads
              axiosInstance.post('/user/confirmDowngrade', {
                plan: plan
              },)
              .then(res => {
                logger({
                  title:'Confirm Upgrade',
                  url:window.location.href,
                  method:'POST',
                  activity: 'confirmUpgrade',
                  user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                  api:BASE_URL+ API_VERSION +'/user/confirmUpgrade' ,
                  session:localStorage.getItem('session'),
                  projectId:null,
                  unitId:null,
                  assignedId:null,
                  })                
                return res;
              })
              .then(res => {
                if (userContextData && userContextData.user) {
                    userContextData.updateUser({ ...userContextData.user, plan: plan});
                  }
              });
          } else {
            console.log("Error Stripe Checkout button without JWT");
          }
    }
    const openPopup = (url, e) => {
        e.preventDefault();
        var w = window.screen.width / 2;
        var h = window.screen.height / 2;
        var left = (window.screen.width / 2) - (w / 2);
        var top = (window.screen.height / 2) - (h / 2);
        window.open(url, 'MsgWindow', "width=" + w + ",height=" + h + ",top=" + top + ",left=" + left + ",scrollbars=yes,resizable=no,toolbar=no");
      }
    return (
        <Dialog disableBackdropClick aria-labelledby="simple-dialog-title" open={modalOpened} closeDialog={closeDialog} presentPlan={presentPlan}>
          <DialogTitle id="simple-dialog-title" style={{background:'rgb(38, 50, 56)', color:'white'}}>Downgrade your account</DialogTitle>
          <DialogContent style={{ minWidth: 320 }} >
          <div className="updateResult">
            <div className="planPrice">
                $0
            </div>
            <div className="resultMessage">
                <h1 className="downgradeMessage">Warning!</h1>
                <div className="plansdescription">
                <h4 class="">You are downgrading your {presentPlan ==1 &&  "Premium"} {presentPlan ==2 &&  "Gold"} subscription to Free.</h4>
                <p>*Downgrading to Free will limit your access to projects</p>
                <p>*All your data may be lost</p>
                </div>
            </div>
            </div>
            <div className="mt-2">
                <Checkbox required />
                    By checking this box you agree to our <a href="#" onClick={(e) => openPopup('/privacy', e)}>Privacy Policy</a> and <a href="#" onClick={(e) => openPopup('/terms', e)}>Terms and Conditions.</a>
            </div>
            <div>
                <Button type="submit" color="primary" 
                    style={{ float: 'right', backgroundColor: '#397a17', color: 'white', marginTop: 16, marginBottom: 16 }} onClick={() => { downgradeAccount(); closeDialog();}}>
                        Process
                </Button>
                <Button type="button" color="primary" onClick={() => { closeDialog();}} style={{ float: 'right', marginTop: 16, marginBottom: 16, marginRight: 6 }}>
                    Cancel
                </Button>
            </div>
          </DialogContent>
        </Dialog>
    );
  };
  
  export default DowngradeModal;