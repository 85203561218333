import React from 'react'

import { Button, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { USER_ROLES } from '../../../utils/const';
import { ReactComponent as DownloadSvg } from '../../../Assets/img/file_download.svg';
import { Delete, Share } from '@material-ui/icons';
import { beautifyFileSize } from '../../../utils';
import ShareModal from '../ProjectDetailsAccordion/ProjectActions/ProjectForm/ShareModal/ShareModal';
import { BASE_URL } from '../../../Axios/api';
import ShareIcon from '../../../Assets/img/share_black_24dp.svg';

const useStyles = makeStyles((theme) => ({
    
    root: {
        width: "100%"
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

    demo: {
        backgroundColor: theme.palette.background.paper,
    },

    title: {
        margin: theme.spacing(4, 0, 2),
    },

    listRoot: {
        flexGrow: 1,
        maxWidth: 752,
    },

    documentHeading: {
        padding: "6px 16px",
        // background: "#263238",
        background:"rgb(192,229,226)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    documentTitle: {
        fontSize: "0.9rem",
        letterSpacing: "0.1em",
        display: "inline-block",
        marginBottom: "0",
        fontWeight: 600,
    },

    uploadDocument: {
        display: "inline-block"
    },

    inputFile: {
        display: "none"
    },

    inputFileLabel: {
        marginBottom: "0"
    },

    uploadButton: {
        color: "white",
        padding: "3px 10px",
        fontSize: "0.7rem",
        backgroundColor:"#26A69A",
        '&:hover': {
            backgroundColor:"#26A69A"
        }

    },
    downloadButton: {
        padding: "0px",
        borderRadius: '4px',
        backgroundColor:"transparent",
        cursor: 'pointer',
        marginRight: '4px',
        height: 'fit-content',
        '&:hover': {
            backgroundColor:"#f0f0f0"
        }

    },
    documentList: {
        height: "200px",
        overflowY: "scroll",
        padding: "0"
    },

    documentDetails: {
        backgroundColor: "ghostwhite",
        paddingTop: "0"
    },

    fileName: {
        fontWeight: 500
    },

    fileSize: {
        marginBottom: 0
    },

    shareIcon: {
        fontSize:"1.2rem",
        backgroundColor:"white",
        color:"#458CCC",
        borderRadius:"3px",
        marginLeft:"5px",
        marginRight: "10px",
        padding:"2px"
      }

}));

function ProjectDocumentDetails({
    category_id,
    category_title,
    files,
    deployed,
    userRole,
    onMarketingDocumentChangeHandler,
    onDocumentDeleteHandler,
    onDocumentDownloadHandler,
}) {

    const classes = useStyles();
    const [openShareModal, setOpenShareModal] = React.useState(false);
    const [shareUrl, setShareUrl] = React.useState(null);

    return (
    
        <div className="col-lg-4 col-md-6 col-sm-12 mb-3" style={{height:"min-content"}}>
            <div className={classes.documentDetails}>
                <div className={classes.documentHeading}>
                    <h5 className={classes.documentTitle}>{category_title.toUpperCase()}</h5>                            
                    {
                        [USER_ROLES.ADMIN, USER_ROLES.PM, USER_ROLES.MA].indexOf(userRole) !== -1
                        ?
                        <div className={classes.uploadDocument}>
                            <input
                                onChange={(event) => onMarketingDocumentChangeHandler(event)}
                                className={`${classes.inputFile} d-none`}
                                id={`upload-file-${category_id}`}
                                multiple
                                type="file"
                                // accept=".pdf,.png,.jpg,.jpeg"
                            />
            
                            <label className={classes.inputFileLabel} htmlFor={`upload-file-${category_id}`}>
                                <Button className={classes.uploadButton} variant="contained" component="span">
                                Upload
                                </Button>
                            </label>                                            
                        </div>
                        : null
                    }
                </div>
        
                <List className={classes.documentList} dense={true}>
                    { files.length > 0 ? 
                        files.map(file => (
                            <ListItem key={file.file_name}>
                                <ListItemText>
                                    {
                                        [USER_ROLES.AFFILIATE].indexOf(userRole) === -1
                                        ?
                                        <div className={classes.downloadButton}
                                        onClick={() => onDocumentDownloadHandler(file.id, file.originalName)}>
                                            <DownloadSvg fill="#878787"/>
                                        </div>
                                        : null
                                    }
                                    <span className={classes.fileName}>{file.originalName}</span>
                                    <p className={classes.fileSize} style={{ marginLeft: 6 }}>{beautifyFileSize(file.fileSize)}</p>
                                    {
                                        [USER_ROLES.ADMIN, USER_ROLES.PM].indexOf(userRole) !== -1
                                        ?
                                        <div className={classes.downloadButton} style={{ marginLeft: 'auto' }}
                                        onClick={() => onDocumentDeleteHandler(file.id, category_id)}>
                                            <Delete htmlColor="#878787" />
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        <div onClick={() => {setShareUrl(`${BASE_URL}public/projectDocuments/${file.filename}`); setOpenShareModal(true);}}>
                                        <span className="mt-1" style={{ cursor: 'pointer' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="20px" fill="#878787"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"/></svg>
                                        </span>
                                        </div>
                                    }
                                </ListItemText>
                            </ListItem>
                        ))
                        : 
                        <ListItem>
                            <ListItemText>
                                <span className={classes.fileName}>No Document Present</span>
                            </ListItemText>
                        </ListItem>
                    }
                </List>
            </div> 
            <ShareModal 
              openShareModal={openShareModal}
              setOpenShareModal={setOpenShareModal}
              url={shareUrl}
          />
        </div>
    )
}

export default ProjectDocumentDetails
