import React, { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis, Tooltip } from 'recharts';
import Menubar from "../Menubar/Menubar";
import $ from 'jquery';
import { USER_ROLES } from "../../utils/const";
import axiosInstance from "../../Axios/axios";
import '../../Assets/css/iframe.css';

function Dashboard() {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (window.mainInitAll) {
      window.mainInitAll();
    }

    let user_role = localStorage.getItem("user_role");
    if (([USER_ROLES.AFFILIATE].includes(user_role))) {
        window.location.href = '/contacts';
        return;
    }

    if (([USER_ROLES.UM].includes(user_role))) {
        window.location.href = '/users';
        return;
    }
    
    onToggleMobileSidebar();
    
    $('.content-wrapper').on('click', function (e) {
      onToggleMobileSidebar();
    });

    fetchChartData();    
  }, []);

  const onToggleMobileSidebar = () => {
    if ($('.sidebar-main').width() == 56) {
      $('.content-wrapper').addClass('content-wrapper-collapse');
    }

    if ($('body').hasClass('sidebar-mobile-main')) {
      $('body').removeClass('sidebar-mobile-main');
      // document.querySelector('.sidebar-main').classList.toggle('sidebar-expand-toggle');
    }

    $('.sidebar-main').removeClass('sidebar-expand-toggle');
  }

  const fetchChartData = () => {
    const token = localStorage.getItem("token");

    // axiosInstance.get('/analytics', {
    //   headers: {
    //       Authorization: "Bearer " + token
    //   }
    // })
    // .then(response => {
    //   if (response && response.data.status == 200) {
    //     setChartData(response.data.payload?.data);
    //   }
    // }).catch(err => {
    //   console.log(err);
    // });
  }
  
  return (
    <div className="page-content">
      <Menubar />
      <div className="content-wrapper">
        <div className="page-header page-header-light">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-arrow-left52 mr-2"></i>{" "}
                <span className="font-weight-semibold">Home</span> - Dashboard
              </h4>
            </div>            
          </div>

          <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
            <div className="d-flex">
              <div className="breadcrumb">
                <a href=" " className="breadcrumb-item">
                  <i className="icon-home2 mr-2"></i> Home
                </a>
                <span className="breadcrumb-item active">Dashboard</span>
              </div>

              <a
                href=" "
                className="header-elements-toggle text-default d-md-none"
              >
                <i className="icon-more"></i>
              </a>
            </div>

            <div className="header-elements d-none">
              <div className="breadcrumb justify-content-center">
                {/* <a href=" " className="breadcrumb-elements-item">
								<i className="icon-comment-discussion mr-2"></i>
								Support
							</a> */}

                {/* <div className="breadcrumb-elements-item dropdown p-0">
								<a href=" " className="breadcrumb-elements-item dropdown-toggle" data-toggle="dropdown">
									<i className="icon-gear mr-2"></i>
									Settings
								</a>

								
							</div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="content" style={{ padding: "0" }}>
          <div className="iframe-container">
          </div>
        </div>

        {/* <!-- Footer -->
			<div className="navbar navbar-expand-lg navbar-light">
				<div className="text-center d-lg-none w-100">
					<button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
						<i className="icon-unfold mr-2"></i>
						Footer
					</button>
				</div>

				<div className="navbar-collapse collapse" id="navbar-footer">
					<span className="navbar-text">
						© 2015 - 2018. <a href=" ">Limitless Web App Kit</a> by <a href="http://themeforest.net/user/Kopyov" target="_blank">Eugene Kopyov</a>
					</span>

					<ul className="navbar-nav ml-lg-auto">
						<li className="nav-item"><a href="https://kopyov.ticksy.com/" className="navbar-nav-link" target="_blank"><i className="icon-lifebuoy mr-2"></i> Support</a></li>
						<li className="nav-item"><a href="http://demo.interface.club/limitless/docs/" className="navbar-nav-link" target="_blank"><i className="icon-file-text2 mr-2"></i> Docs</a></li>
						<li className="nav-item"><a href="https://themeforest.net/item/limitless-responsive-web-application-kit/13080328?ref=kopyov" className="navbar-nav-link font-weight-semibold"><span className="text-pink-400"><i className="icon-cart2 mr-2"></i> Purchase</span></a></li>
					</ul>
				</div>
			</div>
			<!-- /footer --> */}
      </div>
    </div>
  );
}

export default Dashboard;
