import React,{ useState ,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DoneAll, KeyboardArrowDown } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import axiosInstance from '../../../Axios/axios';
import logger from '../../../utils/logger';
import { API_VERSION, BASE_URL } from '../../../Axios/api';
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#263238',
    paddingRight: "0px !important"
  },
  title: {
    fontSize:"1rem",
    flex: 1,
  },
  assignButton: {
    color: "white",
    padding: "3px 10px",
    fontSize: "0.7rem",
    backgroundColor: "#26A69A",
    '&:hover': {
      backgroundColor: "#26A69A"
    }
  },
  col3: {
    position: "relative",
    width: "100%",
    paddingRight: ".625rem",
    paddingLeft: ".625rem",
    flex: "0 0 25%",
    maxWidth: "25%",
    [theme.breakpoints.down(555)]: {
      flex: "0 0 50% !important",
      maxWidth: "50% !important"
    }
  },
  col9: {
    position: "relative",
    width: "100%",
    paddingRight: ".625rem",
    paddingLeft: ".625rem",
    flex: "0 0 75%",
    maxWidth: "75%",
    [theme.breakpoints.down(555)]: {
      flex: "0 0 50% !important",
      maxWidth: "50% !important"
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function EditAppartmentDialog({
    openEditAppartmentDialog,
    closeEditAppartmentDialog,
    projectId,
    soldUnitDetails,
    onPerformActionForSelectedRowField,
    // userIds
}) {
  const classes = useStyles();
  const [userList , setUserList]= useState([]);
  const [selectedUser , setSelecteUser]= useState();

  useEffect(()=>{
      if(userList.length === 0){
            axiosInstance.get(`getAssignProjectUsers/${projectId}`)
            .then((res)=>{
                console.log(res.data.payload.data);
                setUserList(res.data.payload.data)
            })
            .catch((err)=>{
                console.log(err);
            })
        }
  })
    const onSelectUser = (user) =>{
        setSelecteUser(user)
    }
    const onSoldClick = () =>{
        
        axiosInstance.put(`unitSold/${soldUnitDetails.unitRowId}`,{userId :selectedUser.id})
        .then((res)=>{
            logger({
                api:BASE_URL+ API_VERSION +`/unitSold/${soldUnitDetails.unitRowId}` ,
                title:'Sold Unit',
                url:window.location.href,
                method:'PUT',
                activity: 'unitSold',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                session:localStorage.getItem('session'),
                projectId:projectId,
                unitId:soldUnitDetails.unitRowId,
                sold_by:selectedUser.id,
                })    
            onPerformActionForSelectedRowField(soldUnitDetails.unitRowId, soldUnitDetails.unitNo, soldUnitDetails.status, soldUnitDetails.actionFor);
            closeEditAppartmentDialog()
                    })
        .catch((err)=>{
            console.log(err);
        })
}
  return (
      <Dialog maxWidth="sm" fullWidth open={openEditAppartmentDialog} onClose={closeEditAppartmentDialog} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar style={{paddingLeft:"16px", paddingRight:"0px"}}>
            <Typography variant="h6" className={classes.title}>
              Sold Unit 
            </Typography>
            <Tooltip title="Assign Projects" arrow>

              <Button 
                className={`${classes.assignButton} mr-3`} 
                onClick={() => onSoldClick()} 
                variant="contained" 
                component="span"
              >
                SOLD
              </Button>
            </Tooltip>
            <IconButton edge="start" color="inherit" onClick={closeEditAppartmentDialog} aria-label="close">
              <CloseIcon />
            </IconButton>            
          </Toolbar>
        </AppBar>
        <List>
            {
              userList && userList.length === 0 ? 
                <ListItem>
                  <ListItemText primary={"No Projects Found"}/>
                </ListItem>
                :
               
                (userList && userList.length) ?
                        <React.Fragment>
                          <ListItem>
                            <div className={`${classes.col3}`} style={{borderRight:"1px solid #000"}}>
                            <ListItemText>
                              <span><KeyboardArrowDown/></span>
                              <h6 className="font-weight-bold" style={{padding: "2px"}}>User ID</h6>
                            </ListItemText>
                            </div>
                            
                            <div className={`${classes.col9}`}>
                            <ListItemText>
                              <span><KeyboardArrowDown/></span>
                              <h6 className="font-weight-bold" style={{padding: "2px"}}>User Name</h6>
                            </ListItemText>
                            </div>
                          </ListItem>
                        
                        {userList.map((user) => (
                          <React.Fragment>
                              <ListItem 
                                  className="row"
                                  autoFocus 
                                  button 
                                  onClick={() => onSelectUser(user)} 
                                  key={user.id}
                              >
                                <div className={`${classes.col3}`} style={{borderRight:"1px solid #000"}}>
                                  <ListItemText style={{paddingLeft: "5px", paddingRight: "5px", paddingTop: "13px"}}>
                                    <h6>ID: {user.id}</h6>
                                  </ListItemText>
                                </div>
                                <div className={`${classes.col9} d-flex align-items-center`}>
                                    { selectedUser && selectedUser.id === user.id ? 
                                      <CheckCircleIcon style={{color:"green"}}/> 
                                      : 
                                      null
                                    }
                                    <ListItemText style={{marginLeft:"5px"}} primary={user.firstName + ' '+ user.lastName} />
                                </div>
                              </ListItem>
                              <Divider/>
                          </React.Fragment>
                        ))}
                        </React.Fragment>
                    : null
            }

        </List>
      </Dialog>
  );
}