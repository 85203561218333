import React, { useEffect, useState } from 'react';
import Navbar from './Components/Navbar/Navbar';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import './Assets/css/bootstrap.css';
import './Assets/css/bootstrap_limitless.min.css';
import './Assets/css/layout.css';
import './Assets/css/components.min.css';
import './Assets/css/colors.min.css';
import './Assets/css/units-table.css';
import './global_assets/css/icons/icomoon/styles.min.css';
import './Assets/js/Main';
// import './global_assets/js/main/bootstrap.bundle.min.js';
// import './global_assets/js/plugins/loaders/blockui.min.js';
// import './global_assets/js/demo_charts/pages/dashboard/light/streamgraph.js';
import Dashboard from './Components/Dashboard/Dashboard';
import './App.css'
import UserDetails from './Components/Users/Users';
import Projects from './Components/Projects/Projects';
import Login, { METROENGINE_USER } from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import ForgotPassword from './Components/Auth/ForgotPassword';
import { AuthContext } from './Context/userContext';
import axiosInstance from './Axios/axios';
import ProtectedRoute from './Components/Auth/ProtectedRoute';
import UserInvitee from './Components/Users/UserInvitee';
import Contacts from './Components/Contacts/Contacts';
import EmailCampaign from './Components/EmailCampaign/EmailCampaign';
import Terms from './Components/Docs/terms';
import Cookie from './Components/Docs/cookie';
import Privacy from './Components/Docs/privacy';
import ForgotPasswordNew from './Components/Auth/ForgotPasswordNew';
import ResetPassword from './Components/Auth/ResetPassword';
import AcceptProject from './Components/Users/AcceptProject';
import axios from 'axios';
import { BASE_URL, API_VERSION } from './Axios/api';
import logger from './utils/logger';
import Activity from './Components/activity/activity';

function App() {

  const [state, setState] = useState({
    authenticated:false,
    token:'',
    user:null,
  })
  const [loadingUser, setLoadingUser] = useState(false);

  const updateUser = (user) => {
    setState({...state,user});
  } 

  const [contactIds, setContactIds] = useState([]);

  useEffect(() => {
    
    fetchUserData();

  }, [state.token, state.authenticated])

  const fetchUserData = async () => {
    setLoadingUser(true);
    let user = null;
    const token = localStorage.getItem('token');
    const userId = (state.user && state.user.id) || localStorage.getItem('METROENGINE-USER');
    if ((userId != null && userId !== '') && (state.token || token)) {
      axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
      user = await axiosInstance.get(`/me`).catch(err => {
        if(err && !err.response) {
          alert(err);
          setLoadingUser(false);
          return;
        }
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem(METROENGINE_USER);
          window.location.href = "/login"
          setLoadingUser(false);
        }
      })

      if (user && user.data && user.data.payload && user.data.payload.user) {
        updateUser(user.data.payload.user);
        setLoadingUser(false);
      }
    }
  }

  const logout=()=>{
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const apiLogPayload = {
      title:"Logout",
      session:localStorage.getItem('session'),
      activity: "logout",
      url:window.location.href,
      method:'POST',
      user_id:userData ? userData.id : null,
      api:`${BASE_URL}${API_VERSION}/logout`,
    }  
    // Api Log:
    const token = localStorage.getItem('token')
    const axiosLogout = axios.create({
      baseURL:`${BASE_URL}${API_VERSION}`,
      headers: {'Authorization': `bearer ${token}`}
    })
    axiosLogout.post('/logout');
    axiosLogout.post('/addToLog',apiLogPayload)
    localStorage.clear();
    setState({authenticated:false,token:'',user:null});
  }

  return (
    <React.Fragment>
    <AuthContext.Provider value={{...state,updateUser,logout,loadingUser,fetchUserData, contactIds}}>
      <Router>  
        { localStorage.getItem("token") ? <Route component={Navbar} /> : null}
        <ProtectedRoute exact path='/' component={Dashboard} />
        <ProtectedRoute exact path='/activity' component={Activity} />
        <ProtectedRoute exact path="/users" component={UserDetails}/>
        <ProtectedRoute exact path="/project/:projectId" component={Projects}/>
        <ProtectedRoute exact path="/contacts" setContactIds={setContactIds} component={Contacts}/>
        <ProtectedRoute exact path="/email-campaign" setContactIds={setContactIds} component={EmailCampaign}/>
        <Route exact path="/invite/:inviteCode" component={UserInvitee}/>
        <Route exact path="/acceptProject/:inviteCode" component={AcceptProject}/>
        <Route exact path="/login" render={props => <Login setUserState={setState} {...props} />}/>
        <Route exact path="/register" component={Register}/>
       {/* <Route exact path="/forgotpassword" component={ForgotPassword}/> */}
       <Route exact path="/forgotpassword" component={ForgotPasswordNew}/>
        <Route exact path="/resetPassword" component={ResetPassword}/>
        <Route path="/terms" component={Terms} />
        <Route path="/cookie" component={Cookie} />
        <Route path="/privacy" component={Privacy} />
      </Router>
    </AuthContext.Provider>
    </React.Fragment>
  );
}

export default App;
