import React, { useContext } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import UnitsTable from './UnitsTable';

import ExcelFileUploader from '../../../utils/ExcelFileUploader';

import {AuthContext} from '../../../Context/userContext';
import { USER_ROLES } from '../../../utils/const';

const useStyles = makeStyles((theme) => ({
    
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
    },

    expandMoreIcon: {
        color: "white",
        borderRadius: "50%",
        border: "2px solid white"
    },

    accordionSummary: {
        background: "#26a69a",
        color: "white" 
    }

}));

function AppartmentAccordion({
    projectUnits,
    onOpenAssignUnitNumberDialog,
    onOpenUnitNumberDialog,
    onPerformBulkOperation,
    onUploadExcelFileForUnitsData,
    setOpenNewPriceDialog,
    onUploadFileForFloorPlan,
    setUnitNumberToAssign,
    setUnitIdToAssign,
    onPerformActionForSelectedRowField,
    userRole,
    projectId,
}) {

    const classes = useStyles();
    const authenticatedUser = useContext(AuthContext);

    return (

        <Accordion>
            
            <AccordionSummary
                
                expandIcon={<ExpandMore className={classes.expandMoreIcon}/>}
                
                aria-controls="panel1a-content"
                
                id="panel1a-header"
                
                className={classes.accordionSummary}
            
            >
            
                <Typography className={classes.heading}>{"Units / Apartments".toUpperCase()}</Typography>
            
            </AccordionSummary>

            <AccordionDetails>
            
                <div className="mt-2 col-sm-12">

                    {
                        authenticatedUser && authenticatedUser.user && authenticatedUser.user.role === "Admin" && 
                        <ExcelFileUploader onUploadExcelFileForUnitsData={onUploadExcelFileForUnitsData} />
                    }
            
                    <UnitsTable 
                        onOpenAssignUnitNumberDialog={onOpenAssignUnitNumberDialog}
                        projectUnits={projectUnits} 
                        projectId={projectId}
                        onOpenUnitNumberDialog={onOpenUnitNumberDialog}
                        onPerformBulkOperation={onPerformBulkOperation}
                        onUploadFileForFloorPlan={onUploadFileForFloorPlan}
                        setOpenNewPriceDialog={setOpenNewPriceDialog}
                        setUnitNumberToAssign={setUnitNumberToAssign}
                        setUnitIdToAssign={setUnitIdToAssign}
                        onPerformActionForSelectedRowField={onPerformActionForSelectedRowField}
                        readonly={[USER_ROLES.ADMIN, USER_ROLES.PM, USER_ROLES.MA].indexOf(userRole) === -1}
                    />
            
                </div>
            
            </AccordionDetails>

        </Accordion>
                
    )

}

export default AppartmentAccordion
