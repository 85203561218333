import { FormControl,  InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({

  criteria: {
    '&:hover': {
      backgroundColor: "rgb(38, 166, 154)",
      color: "white"
    },
    '&:focus': {
      backgroundColor: "rgb(38, 166, 154)",
      color: "white",
    }
  }

}))

function UserBulkActions({
  bulkAction,
  onBulkActionSelected,
  userIdsToDelete
}) {

    const classes = useStyles();
    return (
      <>  
      {
        userIdsToDelete && userIdsToDelete.length > 0 ?
        <FormControl style={{marginTop:"-3px", marginBottom: "10px"}} className="mr-2">
          <InputLabel
              
              id="demo-simple-select-label"
          >
              Bulk Action
          </InputLabel>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={bulkAction}
              style={{width: 180}}
              onChange={(event)=> onBulkActionSelected(event.target.value)}
          >
              <MenuItem className={classes.criteria} value="No Action">No Action</MenuItem>
              {/* <MenuItem className={classes.criteria}  value="Assign Projects">Assign Projects</MenuItem> */}
              <MenuItem className={classes.criteria}  value="Send Notification">Send Notification</MenuItem>
              <MenuItem className={classes.criteria}  value="Deactivate">Deactivate</MenuItem>              
              <MenuItem className={classes.criteria}  value="Delete">Delete</MenuItem>
          </Select>
          {/* <Tooltip arrow placement="top" title="Delete User/s">
            <IconButton onClick={handleDeleteMultipleUserDialog} className="mr-2" color="secondary" aria-label="delete-users" component="span">
                <Delete />
            </IconButton> 
        </Tooltip> */}
      </FormControl>

        : null 
      }
      </>
    )

}

export default UserBulkActions
