import { makeStyles } from '@material-ui/core'

import React from 'react'

const useStyles = makeStyles((theme) => ({

    headerTitle: {
        fontSize: "0.9rem",
        textTransform: "uppercase",
        paddingTop: "5px",
        paddingBottom: "5px",
        letterSpacing: "0.1em",
        backgroundColor: "rgb(192,229,226)",
        borderRadius: "6px",
        paddingLeft: "10px",
        fontWeight: 600,
        marginLeft: "auto",
        marginRight: "auto"
    },

    developerDetailsTable: {
        marginLeft: "auto",
        marginRight: "auto"
    },

    tableRow: {
        backgroundColor: "white",
        width: "50%"
    },

    tableDescriptionHeading: {
        fontWeight: "bold",
        padding: "2px 10px !important"
    },

    tableDescriptionBody: {
        padding: "2px 10px !important"
    },

    col: {
        [theme.breakpoints.down(1200)]: {
            marginTop: '1.6rem'
        }
    },

    root: {
        [theme.breakpoints.down(1200)]: {
            marginTop: '1rem'
        }
    }

}))


function DeveloperDetailsTable({
    project
}) {

    const classes = useStyles();

    return (
    
        <div className={`${classes.root} col-xl-4 col-lg-6 col-md-6 col-sm-6`}>
        
            <h5 className={classes.headerTitle}>Developer</h5>

            <table className={`${classes.developerDetailsTable} table table-bordered`}>
            
                <tbody>
            
                    <tr className={classes.tableRow}> 
                        
                        <td className={classes.tableDescriptionHeading}>Name</td>
                        
                        <td className={classes.tableDescriptionBody}>
                        
                            { project.developerCompany ? project.developerCompany : "-"}

                        </td>
                    
                    </tr>
              
                    <tr className={classes.tableRow}> 
                        
                        <td className={classes.tableDescriptionHeading}>Person in charge</td>
                        
                        <td className={classes.tableDescriptionBody}>
                        
                            { project.developerIncharge ? project.developerIncharge : "-" }
                        
                        </td>
                    
                    </tr>
              
                    <tr className={classes.tableRow}> 
                        
                        <td className={classes.tableDescriptionHeading}>Contact Number</td>
                        
                        <td className={classes.tableDescriptionBody}>
                        
                            { project.developerMobile ? project.developerMobile : "-" }

                        </td>
                    
                    </tr>

                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Email Address</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { project.developerEmail ? project.developerEmail : "-" }
                    
                        </td>
                    
                    </tr>
              
                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Address</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { project.developerAddress ? project.developerAddress : "-" }
                    
                        </td>
                    
                    </tr>
              
                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Postcode</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { project.developerPostalCode ? project.developerPostalCode : "-" }
                    
                        </td>
                    
                    </tr>
              
                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>State</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { project.developerState ? project.developerState : "-" }
                    
                        </td>

                    </tr>
              
                    <tr className={classes.tableRow}> 
                    
                        <td className={classes.tableDescriptionHeading}>Country</td>
                    
                        <td className={classes.tableDescriptionBody}>
                    
                            { project.developerCountry ? project.developerCountry : "-" }

                        </td>

                    </tr>

              </tbody>

            </table>

        </div>

    )

}

export default DeveloperDetailsTable
