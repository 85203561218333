import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from "@material-ui/core";

import { ExpandMore } from "@material-ui/icons";

import { deepPurple } from "@material-ui/core/colors";

import ProjectActions from "./ProjectActions/ProjectActions";

import ProjectHeaderImage from "./ProjectHeaderImage";

import ProjectDetailsTable from "./ProjectDetailsTable";

import ArchitectDetailsTable from "./ArchitectDetailsTable";

import DeveloperDetailsTable from "./DeveloperDetailsTable";

import '../../../Assets/css/projectsTable.css';
import AdditionalDetailsTable from "./AdditionalDetailsTable";
import ProjectLocation from "./ProjectLocation";

const useStyles = makeStyles((theme) => ({

  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: deepPurple[500],
  },

  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightMedium,
  },

  accordionDetails: {
    padding: "2%",
    background: "white",
    color: "black"
  },

  expandMoreIcon: {
    color: "white",
    borderRadius: "50%",
    border: "2px solid white"
  },

  accordionHeading: {
    background: "#26a69a",
    color: "white"
  }

}));

const ProjectDetailsAccordion = ({
  setAssignModal,
  setOpenAddProjectDialog,
  setSelectedProject,
  project,
  onOpenUploadPanoDialog,
  onOpenDeployProjectDialogHandler,
  onOpenDeleteProjectDialogHandler,
  setOpenAssignManagerProject
}) => {

  const classes = useStyles();

  return (

    <Accordion key={project.id} className={`mb-2 ${classes.accordionHeading}`}>

      <AccordionSummary

        expandIcon={<ExpandMore className={classes.expandMoreIcon} />}

        aria-controls="panel1a-content"

        id="panel1a-header"

      >

        <Typography className={classes.heading}> {project.name && project.name.toUpperCase()} </Typography>

      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>

        <div className="row" style={{ marginBottom: "25px" }}>

          <ProjectActions
            onOpenUploadPanoDialog={onOpenUploadPanoDialog}
            setOpenAddProjectDialog={setOpenAddProjectDialog}
            setSelectedProject={setSelectedProject}
            setAssignModal={setAssignModal}
            onOpenDeployProjectDialogHandler={onOpenDeployProjectDialogHandler}
            onOpenDeleteProjectDialogHandler={onOpenDeleteProjectDialogHandler}
            project={project}
            setOpenAssignManagerProject={setOpenAssignManagerProject}
          />

          <ProjectHeaderImage project={project} />

          <ProjectDetailsTable project={project} />

          <DeveloperDetailsTable project={project} />

          <ArchitectDetailsTable project={project} />

          {

            project && project.additional_detail &&

              (
                project.additional_detail.additionalDeveloperName.trim().length > 0 ||
                project.additional_detail.additionalDeveloperAuthority.trim().length > 0 ||
                project.additional_detail.additionalDeveloperIncharge.trim().length > 0 ||
                project.additional_detail.additionalDeveloperMobile.trim().length > 0 ||
                project.additional_detail.additionalDeveloperEmail.trim().length > 0 ||
                project.additional_detail.additionalDeveloperAddress.trim().length > 0 ||
                project.additional_detail.additionalDeveloperPostalCode.trim().length > 0 ||
                project.additional_detail.additionalDeveloperState.trim().length > 0 ||
                project.additional_detail.additionalDeveloperCountry.trim().length > 0
              )

              ?

              <AdditionalDetailsTable

                additionalName={project && project.additional_detail ? project.additional_detail.additionalDeveloperName : "-"}

                additionalAuthority={project && project.additional_detail ? project.additional_detail.additionalDeveloperAuthority : "-"}

                additionalIncharge={project && project.additional_detail ? project.additional_detail.additionalDeveloperIncharge : "-"}

                additionalMobile={project && project.additional_detail ? project.additional_detail.additionalDeveloperMobile : "-"}

                additionalEmail={project && project.additional_detail ? project.additional_detail.additionalDeveloperEmail : "-"}

                additionalAddress={project && project.additional_detail ? project.additional_detail.additionalDeveloperAddress : "-"}

                additionalPostalCode={project && project.additional_detail ? project.additional_detail.additionalDeveloperPostalCode : "-"}

                additionalState={project && project.additional_detail ? project.additional_detail.additionalDeveloperState : "-"}

                additionalCountry={project && project.additional_detail ? project.additional_detail.additionalDeveloperCountry : "-"}

              />

              : null

          }

          {

            project && project.additional_detail &&

              (
                project.additional_detail.additionalArchitectName.trim().length > 0 ||
                project.additional_detail.additionalArchitectAuthority.trim().length > 0 ||
                project.additional_detail.additionalArchitectIncharge.trim().length > 0 ||
                project.additional_detail.additionalArchitectMobile.trim().length > 0 ||
                project.additional_detail.additionalArchitectEmail.trim().length > 0 ||
                project.additional_detail.additionalArchitectAddress.trim().length > 0 ||
                project.additional_detail.additionalArchitectPostalCode.trim().length > 0 ||
                project.additional_detail.additionalArchitectState.trim().length > 0 ||
                project.additional_detail.additionalArchitectCountry.trim().length > 0
              )

              ?

              <AdditionalDetailsTable

                additionalName={project && project.additional_detail ? project.additional_detail.additionalArchitectName : "-"}

                additionalAuthority={project && project.additional_detail ? project.additional_detail.additionalArchitectAuthority : "-"}

                additionalIncharge={project && project.additional_detail ? project.additional_detail.additionalArchitectIncharge : "-"}

                additionalMobile={project && project.additional_detail ? project.additional_detail.additionalArchitectMobile : "-"}

                additionalEmail={project && project.additional_detail ? project.additional_detail.additionalArchitectEmail : "-"}

                additionalAddress={project && project.additional_detail ? project.additional_detail.additionalArchitectAddress : "-"}

                additionalPostalCode={project && project.additional_detail ? project.additional_detail.additionalArchitectPostalCode : "-"}

                additionalState={project && project.additional_detail ? project.additional_detail.additionalArchitectState : "-"}

                additionalCountry={project && project.additional_detail ? project.additional_detail.additionalArchitectCountry : "-"}

              />

              : null

          }

          {

            project && project.additional_detail && project.additional_detail.checked1 === 1
              ?

              <AdditionalDetailsTable

                additionalName={project && project.additional_detail && project.additional_detail.additionalName1 != 'null' ? project.additional_detail.additionalName1 : "-"}

                additionalAuthority={project && project.additional_detail && project.additional_detail.additionalAuthority1 != 'null' ? project.additional_detail.additionalAuthority1 : "-"}

                additionalIncharge={project && project.additional_detail && project.additional_detail.additionalIncharge1 != 'null' ? project.additional_detail.additionalIncharge1 : "-"}

                additionalMobile={project && project.additional_detail && project.additional_detail.additionalMobile1 != 'null' ? project.additional_detail.additionalMobile1 : "-"}

                additionalEmail={project && project.additional_detail && project.additional_detail.additionalEmail1 != 'null' ? project.additional_detail.additionalEmail1 : "-"}

                additionalAddress={project && project.additional_detail && project.additional_detail.additionalAddress1 != 'null' ? project.additional_detail.additionalAddress1 : "-"}

                additionalPostalCode={project && project.additional_detail && project.additional_detail.additionalPostalCode1 != 'null' ? project.additional_detail.additionalPostalCode1 : "-"}

                additionalState={project && project.additional_detail && project.additional_detail.additionalState1 != 'null' ? project.additional_detail.additionalState1 : "-"}

                additionalCountry={project && project.additional_detail && project.additional_detail.additionalCountry1 != 'null' ? project.additional_detail.additionalCountry1 : "-"}

              />

              : null

          }

          {

            project && project.additional_detail && project.additional_detail.checked2 === 1
              ?

              <AdditionalDetailsTable

                additionalName={project && project.additional_detail && project.additional_detail.additionalName2 != 'null' ? project.additional_detail.additionalName2 : "-"}

                additionalAuthority={project && project.additional_detail && project.additional_detail.additionalAuthority2 != 'null' ? project.additional_detail.additionalAuthority2 : "-"}

                additionalIncharge={project && project.additional_detail && project.additional_detail.additionalIncharge2 != 'null' ? project.additional_detail.additionalIncharge2 : "-"}

                additionalMobile={project && project.additional_detail && project.additional_detail.additionalMobile2 != 'null' ? project.additional_detail.additionalMobile2 : "-"}

                additionalEmail={project && project.additional_detail && project.additional_detail.additionalEmail2 != 'null' ? project.additional_detail.additionalEmail2 : "-"}

                additionalAddress={project && project.additional_detail && project.additional_detail.additionalAddress2 != 'null' ? project.additional_detail.additionalAddress2 : "-"}

                additionalPostalCode={project && project.additional_detail && project.additional_detail.additionalPostalCode2 != 'null' ? project.additional_detail.additionalPostalCode2 : "-"}

                additionalState={project && project.additional_detail && project.additional_detail.additionalState2 != 'null' ? project.additional_detail.additionalState2 : "-"}

                additionalCountry={project && project.additional_detail && project.additional_detail.additionalCountry2 != 'null' ? project.additional_detail.additionalCountry2 : "-"}

              />

              : null

          }

          {

            project && project.additional_detail && project.additional_detail.checked3 === 1
              ?

              <AdditionalDetailsTable

                additionalName={project && project.additional_detail && project.additional_detail.additionalName3 != 'null' ? project.additional_detail.additionalName3 : "-"}

                additionalAuthority={project && project.additional_detail && project.additional_detail.additionalAuthority3 != 'null' ? project.additional_detail.additionalAuthority3 : "-"}

                additionalIncharge={project && project.additional_detail && project.additional_detail.additionalIncharge3 != 'null' ? project.additional_detail.additionalIncharge3 : "-"}

                additionalMobile={project && project.additional_detail && project.additional_detail.additionalMobile3 != 'null' ? project.additional_detail.additionalMobile3 : "-"}

                additionalEmail={project && project.additional_detail && project.additional_detail.additionalEmail3 != 'null' ? project.additional_detail.additionalEmail3 : "-"}

                additionalAddress={project && project.additional_detail && project.additional_detail.additionalAddress3 != 'null' ? project.additional_detail.additionalAddress3 : "-"}

                additionalPostalCode={project && project.additional_detail && project.additional_detail.additionalPostalCode3 != 'null' ? project.additional_detail.additionalPostalCode3 : "-"}

                additionalState={project && project.additional_detail && project.additional_detail.additionalState3 != 'null' ? project.additional_detail.additionalState3 : "-"}

                additionalCountry={project && project.additional_detail && project.additional_detail.additionalCountry3 != 'null' ? project.additional_detail.additionalCountry3 : "-"}

              />

              : null

          }

          {

            project && project.additional_detail && project.additional_detail.checked4 === 1
              ?

              <AdditionalDetailsTable

                additionalName={project && project.additional_detail && project.additional_detail.additionalName4 != 'null' ? project.additional_detail.additionalName4 : "-"}

                additionalAuthority={project && project.additional_detail && project.additional_detail.additionalAuthority4 != 'null' ? project.additional_detail.additionalAuthority4 : "-"}

                additionalIncharge={project && project.additional_detail && project.additional_detail.additionalIncharge4 != 'null' ? project.additional_detail.additionalIncharge4 : "-"}

                additionalMobile={project && project.additional_detail && project.additional_detail.additionalMobile4 != 'null' ? project.additional_detail.additionalMobile4 : "-"}

                additionalEmail={project && project.additional_detail && project.additional_detail.additionalEmail4 != 'null' ? project.additional_detail.additionalEmail4 : "-"}

                additionalAddress={project && project.additional_detail && project.additional_detail.additionalAddress4 != 'null' ? project.additional_detail.additionalAddress4 : "-"}

                additionalPostalCode={project && project.additional_detail && project.additional_detail.additionalPostalCode4 != 'null' ? project.additional_detail.additionalPostalCode4 : "-"}

                additionalState={project && project.additional_detail && project.additional_detail.additionalState4 != 'null' ? project.additional_detail.additionalState4 : "-"}

                additionalCountry={project && project.additional_detail && project.additional_detail.additionalCountry4 != 'null' ? project.additional_detail.additionalCountry4 : "-"}

              />

              : null

          }



          <ProjectLocation project={project} />

        </div>

      </AccordionDetails>

    </Accordion>

  );

};

export default ProjectDetailsAccordion;
