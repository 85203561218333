import React from 'react';

const Privacy = () => (
    <div style={{ margin: "24px 12px"}}>
        <h1>Privacy Policy</h1>
        <p>Effective starting: 1 March, 2021</p>
        <p>Metroengine, “us”, “we”, or “our” refers to <strong>Metroengine Pty Ltd</strong> and any of our corporate affiliates. We operate the <strong>Metroengine Interactive Solution</strong> cloud service, CRM Metroengine Cloud Software and the Metroengine website (the “Service”).</p>
        <p>We are committed to providing you with the best possible experience, which includes the security, privacy and integrity of your personal data. Our Service complies with the requirements of the following regulations and acts: </p>
        <table >
            <tr>
                <td style={{ border: '1px solid black', padding: '0 4px'}}>For Users in the EU:</td>
                <td style={{ border: '1px solid black', padding: '0 4px'}}>The EU General Data Protection Regulation (GDPR) as set out in the Regulation (EU) 2016/679 of the European Parliament and of the Council, of 27 April 2016</td>
            </tr>
            <tr>
                <td style={{ border: '1px solid black', padding: '0 4px'}}>For Users in California:</td>
                <td style={{ border: '1px solid black', padding: '0 4px'}}>The California Consumer Privacy Act (CCPA)</td>
            </tr>
            <tr>
                <td style={{ border: '1px solid black', padding: '0 4px'}}>For Users in Australia:</td>
                <td style={{ border: '1px solid black', padding: '0 4px'}}>The Australian Privacy Principles as set out in the Privacy Act 1988 (Cth)</td>
            </tr>
        </table>
 
        <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
        <p>We use your data to provide and improve the Service. By using the Service, you accept this Privacy Policy, and agree to the collection and use of information as described. If you do not accept this Privacy Policy, you may not use the Service.</p>

        <h2>Definitions</h2>
        <p>Unless otherwise defined in this Privacy Policy, terms used have the same meanings as in our Terms and Conditions.</p>
        <p>“<strong>Personal Data</strong>” means data about a living individual who can be identified from that data. It does not include data where the identity has been removed (such as anonymous or deidentified data).</p>
        <p>“<strong>Usage Data</strong>” is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
        <p>“<strong>Cookies</strong>” are small pieces of data stored on a User’s device.</p>
        <p>“<strong>Data Controller</strong>” means a person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed.</p>
        <p>For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
        <p>“<strong>Data Processor</strong>” (or “<strong>Service Provider</strong>”) means any person (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller.</p>
        <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
        <p>“<strong>Data Subject</strong>” is any living individual who is the subject of Personal Data.</p>
        <p>The “<strong>User</strong>” is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
        
        <h2>Information collection and use</h2>
        <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
        <h4>Types of data collected</h4>
        <h5>Personal Data</h5>
        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information includes:</p>
        <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Financial/billing information (not stored by us)</li>
        </ul>

        <p>We use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send. Marketing consent is not required as a condition of use of the Service.</p>
        <h5>Usage Data</h5>
        <p>We may also collect information on how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s IP address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
        <h5>Tracking Cookies Data</h5>
        <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
        <p>Cookies are files with a small amount of data, stored on your device that may uniquely identify your browser and collect certain anonymous information about you, and which are necessary to provide the functionality of our Service and websites (such as for authentication) or, with your opt-in consent, help us analyze our web page flow, customize our Service, measure promotional effectiveness, and promote trust and safety. Certain features are only available through the use of cookies. </p>
        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
        <p>Examples of Cookies we use:</p>
        <ul>
            <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
            <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
            <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
        </ul>
        <p>View our Cookie Policy page which provides further detail on what information we gather, how we use it and why we sometimes need to store these cookies.</p>
        
        <h2>Use of data</h2>
        <p>We use the collected data for various purposes:</p>
        <ul>
            <li>To provide and maintain our Service</li>
            <li>To notify you about changes to our Service</li>
            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve our Service</li>
            <li>To monitor the usage of our Service</li>
            <li>To detect, prevent and address technical issues</li>
            <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li>
        </ul>
        <p>We are not in the business of selling personal data to third parties, in the course of providing the Service or otherwise.</p>
        
        <h2>Retention of data</h2>
        <p>We will retain your Personal Data only for as long as we have a legitimate business or legal need to do so. Our retention periods depend on the type of data and its purpose. </p>
        <p>We will retain your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), to resolve disputes, and to enforce our legal agreements and policies. We will not retain your Personal Data for business purposes for more than 45 days past the end of a Service contract.</p>
        <p>We will retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
        
        <h2>Transfer of data</h2>
        <p>Your information, including Personal Data, may be transferred to— and maintained on— computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
        <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
        <p>We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

        <h2>Disclosure of data</h2>
        <h4>Business transaction</h4>
        <p>If we are involved in a merger, acquisition or asset sale (“Transaction”), your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy. In the eventuality of this event taking place you will be informed about the Transaction and new Privacy Policy as required by law at the time of the Transaction.</p>
        <h4>Legal requirements</h4>
        <p>We may disclose your Personal Data in the good faith belief that such action is necessary:</p>
        <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend our rights or property</li>
            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>To protect the personal safety of users of the Service or the public</li>
            <li>To protect against legal liability</li>
        </ul>
        
        <h2>Security of data</h2>
        <p>The security of your data is important to us, but remember that no method of transmission over the internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable, best practice, and state-of-the-art means to protect your Personal Data, we cannot guarantee its absolute security.</p>
        
        <h2>Your rights</h2>
        <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
        <p>Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to change your Personal Data, please contact us to make the required changes.</p>
        <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
        <p>You can submit a justified request:</p>
        <ul>
            <li>To access and receive a copy of the Personal Data we hold about you</li>
            <li>To rectify any Personal Data held about you that is inaccurate</li>
            <li>To request the deletion of Personal Data held about you</li>
        </ul>
        <p>You have the right to data portability for the information you provide to us. You can request to obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.</p>
        <p>You may exercise your right to object, if no opt-out mechanism is otherwise made available to you (including objecting to the basis of use for the purpose of our legitimate interest), by sending an email to business@metroengine.com.au with Subject My Rights. </p>
        <p>Please note that we may ask you to verify your identity before responding to such requests.</p>

        <h2>Service Providers</h2>
        <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analysing how our Service is used.</p>
        <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
        <h4>Analytics</h4>
        <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
        <h4>Behavioral remarketing</h4>
        <p>We may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.</p>
        <h4>Image manipulation</h4>
        <p>We may use third-party software Service Providers to manipulate images and PDFs that you may upload to the Service. Images and PDFs provided to our third-party image manipulation Service Providers could include Personal Data. Their processing of your Personal Data is governed by a Data Processing Addendum between us and them.</p>
        <h4>Messaging</h4>
        <p>We may use third-party software Service Providers for sending and receiving email, SMS, push messages and support interactions. Messages provided to our third-party messaging Service Providers could include Personal Data. Their processing of your Personal Data is governed by a Data Processing Addendum between us and them.</p>
        <h4>Payments</h4>
        <p>We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing.</p>
        <p>We will not store your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>

        <h2>Links to other sites</h2>
        <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
        
        <h2>Children’s privacy</h2>
        <p>Our Service does not address anyone under the age of 16 (“Children” or “Child”). Additionally, if you are under the age of majority in your jurisdiction (most commonly, 18 years of age), you represent that your parent or legal guardian has reviewed and agreed to this Policy.</p>
        <p>We do not knowingly collect personally identifiable information from anyone under the age of 16. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>

        <h2>Changes to this Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <h2>Contact us</h2>
        <p>If you have any questions or requests in relation to this Privacy Policy, please contact us by email at business@metroengine.com.au .</p>
 
        <h2>California Privacy Notices</h2>
        <p>If you are a California resident, California law may provide you with certain rights regarding our use of your personal information.</p>
        <ol>
            <li><strong>“Do Not Track” under the California Online Privacy Protection Act.</strong> We do not support Do Not Track (“DNT”). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.  We are not aware of any processes for others collecting personal information about your activities on our websites over time and across third party websites, apps, or other online services, nor do we knowingly collect information about your activities over time across third party sites and services.</li>
            <li><strong>California’s “Shine the Light” law (Civil Code Section § 1798.83).</strong> This law permits users of our website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to us as set forth in the Contact us section above.</li>
            <li><strong>California Consumer Privacy Act (CCPA).</strong> This part (C) serves as a privacy notice under the CCPA for California residents and applies solely to all visitors, users, and others who reside in the State of California. This part is effective upon the effective date of enforcement of the CCPA. Terms defined in the CCPA have the same meaning when used in this notice. Note that provision of this CCPA notice is not an admission on our part that we are a “business” within the meaning of the CCPA, and nothing in this Policy may be construed as such an admission.</li>
        </ol>
        <h4>Personal information we collect</h4>
        <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular person (“personal information”) that falls within the following categories of personal information, and have done so within the last 12 months:</p>
        <table>
            <thead>
                <tr>
                    <td style={{ border: '1px solid black', padding: '0 4px', fontWeight: 500 }}>Category</td>
                    <td style={{ border: '1px solid black', padding: '0 4px', fontWeight: 500 }}>Information Collected</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ border: '1px solid black', padding: '0 4px' }}>Identifiers</td>
                    <td style={{ border: '1px solid black', padding: '0 4px' }}>A real name, postal address, unique identifier, online identifier, Internet Protocol address, email address, and account name.</td>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '0 4px' }}>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</td>
                    <td style={{ border: '1px solid black', padding: '0 4px' }}>A name, address, telephone number, financial and business information.</td>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '0 4px' }}>Commercial information</td>
                    <td style={{ border: '1px solid black', padding: '0 4px' }}>Records of services purchased, obtained, or considered, or other transaction histories.</td>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '0 4px' }}>Internet or other similar network activity</td>
                    <td style={{ border: '1px solid black', padding: '0 4px' }}>A consumer’s interaction with a website.</td>
                </tr>
            </tbody>
        </table>
        <p>Personal information does not include: (a) publicly available information from government records; (b) deidentified information or aggregate consumer information; (c) information excluded from the CCPA’s scope; and (d) personal information covered by certain sector-specific privacy laws.</p>
        <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
        <ul>
            <li>directly from you or publicly available sources. For example, from forms you complete or products and services you purchase or sell, or from information you choose to display in publicly accessible social media accounts.</li>
            <li>indirectly when you use our services (eg cookies when using our website).</li>
        </ul>
        <h4>Our use of personal information</h4>
        <p>We may use or disclose the personal information we collect for the purposes set forth in this Policy, and one or more of the following business purposes:</p>
        <ul>
            <li>to fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our products or services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate delivery.</li>
            <li>to provide, support, personalize, and develop our website, products, and services.</li>
            <li>to create, maintain, customize, and secure your account with us.</li>
            <li>to process your requests, purchases, transactions, and payments and prevent transactional fraud.</li>
            <li>to provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
            <li>to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our sites, third-party sites, and via email or text message (with your consent, where required by law).</li>
            <li>to help maintain the safety, security, and integrity of our website, products and services, databases and other technology assets, and business.</li>
            <li>for testing, research, analysis, and product development.</li>
            <li>to respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
            <li>as described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
            <li>auditing related to a current interactions and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance with this specification and other standards.</li>
            <li>detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.</li>
            <li>debugging to identify and repair errors that impair existing intended functionality.</li>
            <li>to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</li>
        </ul>
        <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice. We will not sell personal data (other than in connection with a sale of business or merger as explained above).</p>
        <h4>Sharing personal information</h4>
        <p>We may disclose any or all of the categories above of your personal information to a third party for a business purpose, as set forth in the Service Providers section above, and we have done so in the last 12 months. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract. The CCPA prohibits third parties who purchase the personal information we hold from reselling it unless you have received explicit notice and an opportunity to opt-out of further sales.</p>
        <p>We disclose your personal information for a business purpose to the following categories of third parties:</p>
        <ul>
            <li>third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Policy. </li>
            <li>any other third parties to whom you have permitted us to disclose your personal information.</li>
            <li>as set forth in this Policy.</li>
        </ul>
        <h4>Your rights and choices</h4>
        <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
        <ul>
            <li>the categories of personal information we collected about you.</li>
            <li>the categories of sources for the personal information we collected about you.</li>
            <li>our business or commercial purpose for collecting or selling that personal information.</li>
            <li>the categories of third parties with whom we share that personal information.</li>
            <li>the specific pieces of personal information we collected about you (also called a data portability request).</li>
            <li>if we sold or disclosed your personal information for a business purpose, two separate lists disclosing: (a) sales, identifying the personal information categories that each category of recipient purchased; and (b) disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
        </ul>
        <h4>Deletion request rights</h4>
        <p>California residents have the right under the CCPA to request that we delete any of their personal information that we have collected and retained, subject to certain exceptions. Once we receive and confirm a verifiable consumer request (see below), we will delete (and direct our service providers to delete) relevant personal information from our records, unless an exception applies.</p>
        <p>We may deny California residents’ deletion request if retaining the information is necessary for us or our service provider(s) to:</p>
        <ul>
            <li>complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with the requesting individual, or otherwise perform our contract with a requesting individual. </li>
            <li>detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
            <li>debug products to identify and repair errors that impair existing intended functionality.</li>
            <li>exercise free speech, ensure the right of another to exercise their free speech rights, or exercise another right provided for by law.</li>
            <li>enable solely internal uses that are reasonably aligned with consumer expectations.</li>
            <li>comply with a legal obligation.</li>
            <li>make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
        </ul>
        <h4>Verifiable consumer request</h4>
        <p>To exercise the access, data portability, and deletion rights under the CCPA described above, please submit to us a verifiable consumer request by email as set forth in the Contact us section above.</p>
        <p>Only a California resident, or a person registered with the California Secretary of State that a California resident has authorized to act on their behalf, may make a verifiable consumer request related to their personal information. A California resident may also make a verifiable consumer request on behalf of their minor child.</p>
        <p>A verifiable consumer request for access or data portability can only be made twice within a 12-month period. The verifiable consumer request must provide sufficient information that allows us to reasonably verify the California resident about whom we collected personal information or an authorized representative, and contain sufficient detail that allows us to properly understand, evaluate, and respond to it. We cannot respond to your request or provide personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.</p>
        <p>Making a verifiable consumer request does not require you to create an account on our website. However, we do consider requests made through your password protected account sufficiently verified when the request relates to personal information associated with that specific account. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</p>
        <p>We will endeavour to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically.</p>
        <p>Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
        <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
        <h4>Non-discrimination</h4>
        <p>We will not discriminate against you for exercising any of your CCPA rights. Except as permitted by the CCPA, we will not:</p>
        <ul>
            <li>deny you goods or services.</li>
            <li>charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
            <li>provide you a different level or quality of goods or services.</li>
            <li>suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
        </ul>
    </div>
)

export default Privacy;