import React, { Fragment } from "react";
import MetroEngineLogo from "../../Assets/img/ME-logo.svg";
import $ from 'jquery';
import { Link } from "react-router-dom";

const Navbar = ({ location: { pathname } }) => {
  //   const classNamees = useStyles();

  const onToggleMobileSidebar = (e) => {

    $('body').toggleClass('sidebar-mobile-main');
    document.querySelector('.sidebar-main').classList.toggle('sidebar-expand-toggle');
  }

  const onSidebarCollapse = (e) => {
    e.preventDefault();
    // if (!$('body').hasClass('sidebar-xs')) {
    //   $('body').addClass('sidebar-xs');
    // }
    // else {
    //   $('body').removeClass('sidebar-xs');
    // }

    // if ($('.content-wrapper').hasClass('content-wrapper-collapse')) {
    //   $('.content-wrapper').removeClass('content-wrapper-collapse');
    // }
    // else {
    //   $('.content-wrapper').addClass('content-wrapper-collapse');
    // }
  }

  return (
    <div className="navbar navbar-expand-md navbar-dark">
      <div className="navbar-brand">
        <Link to="/" className="d-flex align-items-center" style={{ fontSize: "0.5rem" }}>
          <img
            style={{ width:"35px", height:"auto" }}
            src={MetroEngineLogo}
            alt=""
          />
          <span
            style={{
              fontWeight: 500,
              color: "white",
              fontSize: "0.9rem",
              marginLeft: "15px",
            }}
          >
            METROENGINE
          </span>
        </Link>
      </div>
      {pathname === "/login" ? null : (
        <Fragment>
          <div className="d-md-none">
            <button
              onClick={onToggleMobileSidebar}
              className="navbar-toggler sidebar-mobile-main-toggle"
              type="button"
              style={{ marginTop: "10px" }}
            >
              <i className="icon-paragraph-justify3"></i>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbar-mobile">
            <ul className="navbar-nav">
              <li className="nav-item">
                <button
                  style={{
                    background: "none",
                    cursor: "pointer",
                    border: "none",
                  }}
                  type="button"
                  onClick={onSidebarCollapse}
                  className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
                >
                  <i className="icon-paragraph-justify3"></i>
                </button>
              </li>
            </ul>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Navbar;
