import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import React, { Fragment } from 'react'
import { USER_ROLES } from '../../utils/const';

const useStyles = makeStyles((theme) => ({

    criteria: {
        '&:hover': {
            backgroundColor: "rgb(38, 166, 154)",
            color:"white"
        },
        '&:focus': {
            backgroundColor: "rgb(38, 166, 154)",
            color: "white",
        }
    }
    
}))

function FilterUsers({
    setFilterText,
    projectForCriteria,
    setProjectForCriteria,
    availableCriteria,
    setAvailableCriteria,
    projectListForCriteria
}) {

    const classes = useStyles();
    const userRole = localStorage.getItem('user_role');
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const needToShow = ((userRole === 'Agent' || userRole === 'Affiliate') && userData.projectId === '0') ? false : true;
    return (
        
        <Fragment>
            <TextField
                className="mr-3"
                margin="dense"
                size="small"
                id="filter-user"
                label="Filter By Name"
                type="text"
                style={{display:"inline-block", margin:"0", marginTop: "13px", width:180}}
                onChange={(e) => setFilterText(e.target.value)}
            />
            {needToShow ? (
                <FormControl style={{marginTop:"10px"}} className="mr-3">
                    <InputLabel 
                        
                        id="demo-simple-select-label"
                    >
                        Available Criteria
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={availableCriteria}
                        style={{width: 180}}
                        onChange={(event)=> setAvailableCriteria(event.target.value)}
                    >
                        <MenuItem className={classes.criteria} value="No Action">No Action</MenuItem>
                        {
                            [USER_ROLES.ADMIN].indexOf(userRole) !== -1 ?
                            <MenuItem className={classes.criteria}  value="Master Agent">Master Agents</MenuItem>
                            : null
                        }
                        <MenuItem className={classes.criteria}  value="Active Agent">Active Agents</MenuItem>
                        <MenuItem className={classes.criteria}  value="Deactivated Agent">Deactivated Agents</MenuItem>
                        
                        {/* <MenuItem className={classes.criteria}  value="Free Agent">Free Agents</MenuItem> */}
                        <MenuItem className={classes.criteria} value="Affiliate">Affiliates</MenuItem>
                        {
                            [USER_ROLES.ADMIN].indexOf(userRole) !== -1 ?
                            <MenuItem className={classes.criteria} value="Project Manager">Project Managers</MenuItem>
                            : null
                        }
                        <MenuItem className={classes.criteria} value="State">By State</MenuItem>
                        <MenuItem className={classes.criteria} value="Country">By Country</MenuItem>
                        <MenuItem className={classes.criteria} value="PostalCode">By Postal Code</MenuItem>
                    </Select>
                </FormControl>
            ) : null}

            <FormControl style={{marginTop:"10px", marginBottom:"1rem"}}>
                <InputLabel 
                    
                    id="demo-simple-select-label"
                >
                    Project List
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={projectForCriteria}
                    style={{width:180}}
                    onChange={(event)=> setProjectForCriteria(event.target.value)}
                >
                    <MenuItem className={classes.criteria} value="Show All">Show All</MenuItem>
                    {projectListForCriteria.map(project => (
                        <MenuItem key={project.id} className={classes.criteria} value={project.name +"--" +project.id}>{project.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Fragment>     
    )
}

export default FilterUsers
