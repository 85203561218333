import { Avatar, Button, Card, CardContent, CardHeader, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import { deepPurple } from '@material-ui/core/colors';
import { Add, MoreVert } from '@material-ui/icons'
import React from 'react'
// import UserTableDetails from './UserTableDetails';
// import UserTableHeading from './UserTableHeading'
// import ProjectListIcon from '@material-ui/icons/PlaylistAddCheck';
import moment from 'moment';
import MELogo from '../../Assets/img/ME-logo.svg';
import { BASE_URL_IMAGE_USER } from '../../Axios/api';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "15px 0px",
        height: "210px",
        overflow: "auto !important"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
    }
}));

function UserCard({
    checkForPermission,
    userData,
    user,
    showUserDetailsHandler,
    // isSingleUserDeleted,
    setSingleUserIdToDelete,
    handleDeleteUserDialog,
    isSelectModeOn,
    handleCheckboxForDeletingUser,
    handleCheckboxForAvailabilityOfUser,
    // onOpenUserProjectListDialog,
    onOpenAssignProjectDialog
}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenuHandler = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenuHandler = () => {
        setAnchorEl(null);
    };

    // const membershipPlan = () => {
    //     return Math.floor(Math.random() * 3);
    // }

    return (
        <Card className={classes.root}>
            <CardHeader
                style={{ paddingLeft: "22px" }}
                avatar={
                    <Avatar src={user.image ? (user.image.includes("data") ? user.image : `${BASE_URL_IMAGE_USER}${user.image}`) : MELogo} className={classes.purple}></Avatar>
                }
                action={
                    !isSelectModeOn ?
                        <>
                            <IconButton aria-label="settings" onClick={openMenuHandler}>
                                {(checkForPermission() || userData && user && userData.id === user.id) ? <MoreVert /> : null}
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={closeMenuHandler}
                            >
                                <MenuItem onClick={() => { showUserDetailsHandler(user.id); closeMenuHandler(); }}><span>Profile</span></MenuItem>
                                {
                                    localStorage.getItem("user_role") === "Admin" ||
                                        localStorage.getItem("user_role") === "User Manager" ?
                                        <MenuItem onClick={() => { setSingleUserIdToDelete(user.id); handleDeleteUserDialog(); closeMenuHandler(); }}><span>Delete User</span></MenuItem>
                                        : null
                                }
                            </Menu>
                        </>
                        :
                        <>
                            <IconButton aria-label="settings">
                                <input
                                    style={{ cursor: "pointer" }}
                                    className="delete-user"
                                    type="checkbox"
                                    onChange={(e) => handleCheckboxForDeletingUser(e, user)}
                                    id={`${user.id}-delete-user`}
                                />
                            </IconButton>

                        </>
                }
                title={
                    <div className="d-flex align-items-center">
                        <Typography style={{ fontSize: "0.9rem", fontWeight: 500 }}>{user.firstName + " " + user.lastName}</Typography>
                        {
                            user.plan == 0 &&
                            <div className="account-plan ml-2" style={{ borderRadius: "3px", left: "80px", display: "inline-block", background: "#397A17", marginTop: "-2px", fontSize: "0.6rem", padding: "2px 0px", width: "55px", color: "white" }}>
                                <span className="font-weight-bolder" style={{ letterSpacing: "0.04em", display: "block", textAlign: "center" }}>FREE</span>
                            </div>
                        }
                        {
                            user.plan == 1 &&
                            <div className="account-plan ml-2" style={{ borderRadius: "3px", left: "80px", display: "inline-block", background: "crimson", marginTop: "-2px", fontSize: "0.6rem", padding: "2px 0px", width: "55px", color: "white" }}>
                                <span className="font-weight-bolder" style={{ letterSpacing: "0.04em", display: "block", textAlign: "center" }}>PREMIUM</span>
                            </div>
                        }
                        {
                            user.plan == 2 &&
                            <div className="account-plan ml-2" style={{ borderRadius: "3px", left: "80px", display: "inline-block", background: "#E5A948", marginTop: "-2px", fontSize: "0.6rem", padding: "2px 0px", width: "55px", color: "white" }}>
                                <span className="font-weight-bolder" style={{ letterSpacing: "0.04em", display: "block", textAlign: "center" }}>GOLD</span>
                            </div>
                        }
                    </div>
                }
                subheader={<span style={{ fontSize: "0.8rem" }}>Joining Date: {moment(user.created_at).format('LL')}</span>}
            />
            <CardContent style={{ paddingTop: "0", paddingLeft: "22px" }}>
                <div className="row">
                    <div className="col-12">
                        <Typography variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Role: </span>
                            <span style={{ color: "slateblue", fontWeight: 500 }}>{user.role}</span>
                        </Typography>
                        {checkForPermission() ?
                            <Typography className="mt-1" variant="body2" color="textSecondary" component="p">
                                <span className="font-weight-bold">Active: </span>
                                <input
                                    style={{ cursor: "pointer", display: "inline-block", position: "relative", top: "0.9px", left: "2px" }}
                                    type="checkbox"
                                    id={`${user.id}-is-available`}
                                    onChange={(e) => handleCheckboxForAvailabilityOfUser(e, user.id)}
                                />
                            </Typography>
                            : null
                        }
                        <Typography className="mt-1" variant="body2" color="textSecondary" component="p">
                            <span className="font-weight-bold">Address: </span>
                            <span style={{ color: "cadetblue" }}>
                                {user.address ? user.address.length > 20 ? user.address.substring(0, 20) + "..." : user.address : "--"}
                            </span>
                        </Typography>
                    </div>
                    <div className="col-12">
                        {!isSelectModeOn && checkForPermission() ?
                            <Button className="d-flex align-item-center" onClick={() => onOpenAssignProjectDialog(user)} style={{ fontSize: "1.2rem !important", padding: "5px 10px", backgroundColor: "slategray", marginTop: "10px" }} variant="contained" color="primary">
                                <Add style={{ fontSize: "0.8rem" }} /> <span style={{ fontSize: "0.75rem", paddingLeft: "5px" }}>Assign Projects</span>
                            </Button>
                            : null}
                    </div>
                </div>

            </CardContent>
        </Card>
    )
}

export default UserCard
