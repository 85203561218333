import axios from 'axios';
import { BASE_URL, API_VERSION } from './api';

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL:`${BASE_URL}${API_VERSION}`,
  headers: {'Authorization': `bearer ${token}`}
})

export default axiosInstance;


// user http://crmbackend.metroengine.com.au/public/v1.0/user/:id
// project http://crmbackend.metroengine.com.au/public/v1.0/project/:id
// registerApi =  {
//   captcha:'',
//   firstName:'',
//   lastName:'',
//   password:'',
//   confirmPassword:'',
//   role:'',
//   title:''
// }

// loginApi = {
//   username:'',
//   password:'',
//   rememberme:false
// }

