import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DeleteConfirmationDialog({
    openDeleteConfirmationDialog,
    setOpenDeleteConfirmationDialog,
    onDeleteSelectedData,
    showDataFor
}) {

    return (
        <div>
            <Dialog
                open={openDeleteConfirmationDialog}
                onClose={() => setOpenDeleteConfirmationDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Do you want to delete this ${showDataFor==="prices" ? 'Price' : showDataFor==="document" ? 'document' : 'Floor Plan'}?`}</DialogTitle>
                    <DialogActions>
                    <Button onClick={() => setOpenDeleteConfirmationDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={ () => onDeleteSelectedData(showDataFor) } color="primary" autoFocus>
                        Confirm
                    </Button>
                    </DialogActions>
            </Dialog>
        </div>
    );
}