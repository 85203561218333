import { makeStyles } from '@material-ui/core'

import React from 'react'

import { BASE_URL_IMAGE_PROJECT } from '../../../Axios/api'

const useStyles = makeStyles((theme) => ({

    headerTitle: {
        fontSize: "0.9rem",
        textTransform:"uppercase",
        paddingTop:"5px",
        paddingBottom:"5px",
        letterSpacing: "0.1em",
        backgroundColor: "rgb(192,229,226)",
        borderRadius:"6px",
        paddingLeft:"10px",
        fontWeight: 600,
        marginLeft:"auto",
        marginRight:"auto"
    },

    headerImage: {
        objectFit:"contain",
        width: "inherit",
        maxHeight: "200px",
        maxWidth: "100%",
    }

}))


function ProjectHeaderImage({
    project
}) {

    const classes = useStyles();

    return (
        
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3">

            {project.image ? 

                <React.Fragment>  
                
                    <h5 className={classes.headerTitle}>Header Image</h5>
                    
                    <img
                        src={
    
                            project.image && project.image.length > 100 
            
                            ? `${project.image}`
            
                            : `${BASE_URL_IMAGE_PROJECT}${project.image}`

                        }
                        alt=" "
                        className={classes.headerImage}
                    />

                </React.Fragment>

                : 
                
                <h5 className={classes.headerTitle}>No Image Uploaded.</h5> 
                
            }

        </div>

    )
    
}

export default ProjectHeaderImage
