import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ContactsTable from '../ContactsTable/ContactsTable';
import ListOptionIcon from '@material-ui/icons/PlaylistPlay';
import { Menu, MenuItem } from '@material-ui/core';
import ContactInfo from './ContactInfo';
import '../../../Assets/css/custom.css'
import { USER_ROLES } from '../../../utils/const';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ContactsTabs({
    contactsLead,
    handleTabChange,
    tabValue,
    contactsTabs,
    onOpenMenuForSelectedTab,
    anchorEl,
    onCloseMenuForSelectedTab,
    setOpenDeleteTabDialog,
    selectedTabValueToPerformAction,
    setOpenTabTransitionDialog,
    onOpenEditContactDialog,
    onOpenLeadChangeDialog,
    onOpenTagSelectionDialog,
    onExportSelectedTabContactsForCampaign,
    selected,
    setSelected,
    setOpenDeleteContactsDialog
}) {
  const classes = useStyles();
  const expressionTab ="EXPRESSION OF INTEREST";
  const userRole = localStorage.getItem("user_role")
  return (
    
    <div className={classes.root}>
      
        <AppBar style={{backgroundColor: "#324148"}} position="static">
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs">
            {
                contactsTabs.map((tabNames,index) => (
                  <Tab key={tabNames+'_'+index} label={tabNames.length > 15 ? tabNames.substring(0,15) + "..." : tabNames} {...a11yProps(index)} icon={<ListOptionIcon onClick={(e) => { e.stopPropagation(); onOpenMenuForSelectedTab(e, index); }} className="mb-0 ml-2"/>} />
                ))   
            }
            {
              
                <Tab label={expressionTab.length > 15 ? expressionTab.substring(0,15) + "..." : expressionTab} {...a11yProps(3)} id="expression">{expressionTab}</Tab>

            }
                
            </Tabs>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onCloseMenuForSelectedTab}
              >
                <MenuItem onClick={() => setOpenTabTransitionDialog(true)}>Move to different tab</MenuItem>
                { selectedTabValueToPerformAction === tabValue && 
                  <MenuItem onClick={onExportSelectedTabContactsForCampaign}>Export to campaign</MenuItem>
                }
                <MenuItem onClick={onOpenLeadChangeDialog}>Change Lead value</MenuItem>
                <MenuItem onClick={onOpenTagSelectionDialog}>Change Tag value</MenuItem>
                { selectedTabValueToPerformAction !== 0 && <MenuItem onClick={() => setOpenDeleteTabDialog(true)}>Delete</MenuItem> }
            </Menu>
        </AppBar>

        { 
            contactsTabs.map((tabNames, index) => (
                  <TabPanel key={index} value={tabValue} index={index}>
                    <ContactsTable 
                        contactsLead={contactsLead}
                        onOpenEditContactDialog={onOpenEditContactDialog}
                        selected={selected}
                        setSelected={setSelected}
                        setOpenDeleteContactsDialog={setOpenDeleteContactsDialog}
                    />
                </TabPanel>
            ))
        } 
        {

        [USER_ROLES.ADMIN, USER_ROLES.PM, USER_ROLES.MA].indexOf(userRole) !== -1
        ?
         <TabPanel  value={tabValue} index={contactsTabs.length}>
            <ContactInfo />
          </TabPanel>
        :
        null
        }  
       
    </div>
  );
}
