import { Button, makeStyles } from '@material-ui/core';
import { Share } from '@material-ui/icons';

import React, { useContext } from 'react'
import { AuthContext } from '../../../../Context/userContext';
import { USER_ROLES } from '../../../../utils/const';

const useStyles = makeStyles((theme) => ({
    
    actionButton: {
        backgroundColor:"#26a69a", 
        color:"white",
        '&:hover': {
            backgroundColor: "#26a69a",
            color:"white"
        }
    },
    
    alertButton: {
        backgroundColor: "crimson",
        color:"white",
        '&:hover': {
            backgroundColor: "crimson",
            color: "white"
        }
    },

    uploadPanoButton: {
        backgroundColor: "#6D76D3",
        color:"white",
        '&:hover': {
            backgroundColor: "#6D76D3",
            color: "white"
        }
    }

}))

function ProjectActions({
    setOpenAddProjectDialog,
    setSelectedProject,
    setAssignModal,
    onOpenDeployProjectDialogHandler,
    onOpenDeleteProjectDialogHandler,
    project,
    onOpenUploadPanoDialog,
    setOpenAssignManagerProject
}) {
    
    const classes = useStyles();
    
    const authenticatedUser = useContext(AuthContext);

    return (
        
        <div className="project-actions d-flex flex-wrap align-items-center col-sm-12">
            
            { authenticatedUser && authenticatedUser.user && authenticatedUser.user.role === "Admin" ||
              authenticatedUser && authenticatedUser.user && authenticatedUser.user.role === "Project Manager" ?
                <Button
                    className={`${classes.actionButton} mr-2 mb-4`}
                    variant="contained"
                    onClick={() => {
                    setOpenAddProjectDialog(true);
                    setSelectedProject(project);
                    }}
                >
                    Edit Project
                </Button>
                : null
            }
            { authenticatedUser && authenticatedUser.user && ([USER_ROLES.ADMIN, USER_ROLES.PM, USER_ROLES.MA].indexOf(authenticatedUser.user.role) !== -1) ?
                <Button
                    className={`${classes.actionButton} mr-2 mb-4`}
                    variant="contained"
                    onClick={() => {
                    setSelectedProject(project);
                    setAssignModal({ status: true, type: 1 })
                    }}
                >
                    Asign Staff
                </Button>
                : null
            }
{/* 
            { authenticatedUser && authenticatedUser.user && (authenticatedUser.user.role === "Admin" || authenticatedUser.user.role === "Project Manager") ?
            
                <Button
                    className={`${classes.actionButton} mr-2 mb-4`}
                    variant="contained"
                    onClick={() => {
                    setSelectedProject(project);
                    setAssignModal({ status: true, type: 2 })
                    }}
                >
                    Assign Master Agent
                </Button>
                : null 
            } */}

            { authenticatedUser && authenticatedUser.user && authenticatedUser.user.role === "Admin" ?

                <Button

                    className={`${classes.alertButton} mr-2 mb-4`}
                    onClick={()=>onOpenDeleteProjectDialogHandler(project)}
                    variant="contained"

                >
                
                    Delete Project
                
                </Button>

                : null 

            }

            {(authenticatedUser && authenticatedUser.user && (authenticatedUser.user.role === USER_ROLES.ADMIN || authenticatedUser.user.role === USER_ROLES.PM)) &&
            
                !project.deployedAt ? 

                    <Button

                        className={`${classes.alertButton} mr-2 mb-4`}
                        onClick={()=>onOpenDeployProjectDialogHandler(project)}
                        variant="contained"
                        
                    >
                    
                        Deploy
                    
                    </Button>

                : null

            }

            {   authenticatedUser && authenticatedUser.user && authenticatedUser.user.role === USER_ROLES.ADMIN &&            
            
                <Button

                    className={`${classes.uploadPanoButton} mr-2 mb-4`}
                    onClick={()=>onOpenUploadPanoDialog(project)}
                    variant="contained"
                    
                >
                
                    Upload 360 Pano
                
                </Button>

            }

            {   authenticatedUser && authenticatedUser.user && authenticatedUser.user.role === USER_ROLES.ADMIN &&            
            
                <Button
                    className={`${classes.uploadPanoButton} mr-2 mb-4`}
                    // onClick={()=>onOpenDeleteProjectDialogHandler(project)}
                    variant="contained"
                >
                    Interlinking
                </Button>

            }

        {   
        authenticatedUser && authenticatedUser.user && authenticatedUser.user.role === USER_ROLES.ADMIN && <Button
                className={`${classes.uploadPanoButton} mr-2 mb-4`}
                onClick={()=>setOpenAssignManagerProject(true)}
                variant="contained"
            >
                Invite User
            </Button>
        }   


        </div>

    )

}

export default ProjectActions
