import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(() => ({

    formControl: {
        width: "100%"
    }

}))

function LeadChangeDialog({
    openLeadChangeDialog,
    onCloseLeadChangeDialog,
    onChangeLeadValue,
    leadValueToChange,
    setLeadValueToChange
}) {

    const classes = useStyles();

    return (

        <Dialog
        
            open={openLeadChangeDialog}
        
            onClose={onCloseLeadChangeDialog}
        
            aria-labelledby="alert-dialog-title"
        
            aria-describedby="alert-dialog-description"
        
        >
        
            <DialogTitle className="pb-0">Change Lead value</DialogTitle>
            
            <DialogContent>

                <form className={classes.container}>
                
                    <TextField
                            required
                            margin="dense"
                            id="leadValue"
                            label="Lead Value"
                            placeholder="Integer between 1 to 10"
                            type="number"
                            name="leadValue"
                            value={leadValueToChange}
                            onChange={(e) => setLeadValueToChange(e.target.value)}
                            fullWidth
                        />

                </form>

            </DialogContent>
        
            <DialogActions>
        
                <Button
        
                onClick={() => onChangeLeadValue(leadValueToChange)}
        
                color="primary"
        
                >
        
                Save
        
                </Button>
        
                <Button
        
                onClick={onCloseLeadChangeDialog}
        
                color="primary"
        
                autoFocus
        
                >
        
                Cancel
        
                </Button>
        
            </DialogActions>
        
        </Dialog>
    
    )

}

export default LeadChangeDialog
