import React, { useEffect } from 'react';

import {GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow} from 'react-google-maps';

function Map({lat,lng, project}) {
    
    const [openInfoWindow, setOpenInfoWindow] = React.useState(false);

    return (

        <GoogleMap
        
            defaultZoom={10}
        
            defaultCenter = {
                {
                    lat: lat,
                    lng: lng
                }
            }
        
        >
        
        <Marker
        
            key={project && project.id}
        
            position={{
        
                lat: lat,
                lng: lng
        
            }}
        
            onClick={() => setOpenInfoWindow(true)}
        
        />

        
        {openInfoWindow && (
        
            <InfoWindow
        
                position={{
        
                    lat: lat,
                    lng: lng
        
                }}
        
                onCloseClick={() => {
        
                    setOpenInfoWindow(false);
        
                }}
        
            >
        
                <h5>{project && project.address}</h5>
        
            </InfoWindow>
        
        )}
        
        </GoogleMap>
    
    )

}

export const GoogleMapWrapper = withScriptjs(withGoogleMap(Map));
