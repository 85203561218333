import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, List, ListItem, ListItemText, makeStyles, Menu, MenuItem, Select, TextField, Tooltip, CircularProgress,Box} from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PersonIcon from '@material-ui/icons/Person';
import { deepPurple } from '@material-ui/core/colors';
import ImageCrop from '../UI/ImageCrop';
import { AuthContext } from '../../Context/userContext';
import { API_VERSION, BASE_URL, BASE_URL_IMAGE_USER } from '../../Axios/api';
import axiosInstance from '../../Axios/axios';
import { urlToFile } from '../../utils/file';
import UserManagerSidebar from './Sidebar/UserManagerSidebar';
import ProjectManagerSidebar from './Sidebar/ProjectManagerSidebar';
import AdminSidebar from './Sidebar/AdminSidebar';
import Sidebar from './Sidebar';
import { countryList } from '../../DummyData/countryList';
import SnackbarToast from '../../utils/SnackbarToast';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { australianStateList } from '../../DummyData/australianStateList';
import { projects } from '../../DummyData/projects';
import $ from 'jquery';
import MELogo from '../../Assets/img/ME-logo.svg'
import PayModal from '../Modals/PayModal';
import UpgradeModal from '../Modals/UpgradeModal';
import { TurnedIn } from '@material-ui/icons';
import DowngradeModal from '../Modals/DowngradeModal';
import logger from '../../utils/logger';
const useStyles = makeStyles((theme) => ({
	large: {
		width: 50,
		height: 50,
		objectFit:'contain'
	},
	profileImage: {
		objectFit:'contain'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	}
}));

function Menubar() {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openProfileDialog, setOpenProfileDialog] = React.useState(false);
	const [role, setRole] = useState("");
	const [title, setTitle] = useState("");
	const [id, setId] = useState();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [fax, setFax] = useState("");
	const [address, setAddress] = useState("");
	// const [streetNumber, setStreetNumber] = useState("");
	const [country, setCountry] = useState("");
	const [state, setState] = useState("");
	const [postalCode, setPostalCode] = useState("");
	// const [city, setCity] = useState("");
	const [office, setOffice] = useState("");
	const [mobile, setMobile] = useState("");
	const [about, setAbout] = useState("");
	const [errors, setErrors] = useState({});
	const [maxCount] = useState(1200);
	const [charactersLength, setCharactersLength] = useState(about ? about.length : 0);
	const [maxLimitReached, setMaxLimitReached] = useState(false);
	const [image, setImage] = useState(MELogo);
	const [openUpgradeAccountDialog, setOpenUpgradeAccountDialog] = useState(false);
	const [accountPlan, setAccountPlan] = useState(0);
	const [finalImage, setFinalImage] = useState(MELogo);
	const [openImageCropper, setOpenImageCropper] = useState(false);
	const [imageFile, setImageFile] = useState(MELogo);
	const [projectImage, setProjectImage] = useState('')
	const [areAllProjectsFetched, setAreAllProjectsFetched] = useState(false);
	const [allProjectsDetails, setAllProjectsDetails] = useState([]);
	const [openSnackbar, setOpenSnackbar] = useState("");
	const [messageForSnackbar, setMessageForSnackbar] = useState("");
	const [snackbarType, setSnackbarType] = useState("");
	const [isLoading, setIsLoading] = useState("");
	const userContextData = useContext(AuthContext);
	const [updatePlan, setUpdatePlan] = useState(0);
	const [openPayModal, setOpenPayModal] = useState(false);
	const [targetPlan, setTargetPlan] = useState(0);
	const [downgradeModal, openDowngradeModal] = useState(false)
	const [currency, setCurrency] = useState('')
	const [userProfileDetails, setUserProfileDetails] = React.useState({
		id: "",
		role: "",
		title: "",
		firstName: "",
		lastName: "",
		address: "",
		postalCode: "",
		state: "",
		image: "",
		country: "",
		// address: {streetNumber: "",city: "",country}: "",
		office: "",
		mobile: "",
		phone: "",
		email: "",
		companyName: "",
		fax: "",
		about : ""
	})
	const [planPrice, setPlanPrice] = useState('');
	useEffect(() => {
		if(userContextData && userContextData.user){
			
			const { user } = userContextData;
			setId(user.id ? user.id : "")
			setFirstName(user.firstName ? user.firstName : "");
			setLastName(user.lastName ? user.lastName : "");
			setTitle(user.title ? user.title : "");
			setMobile(user.mobile ? user.mobile : "");
			setPhone(user.phone ? user.phone : "");
			setAddress(user.address ? user.address : "");
			setCountry(user.country ? user.country : "");
			setState(user.state ? user.state : "");
			setPostalCode(user.postalCode ? user.postalCode : "");
			setFax(user.fax ? user.fax : "");
			setCompanyName(user.companyName ? user.companyName : "");
			setRole(user.role ? user.role : "");
			setAbout(user.about ? user.about : "");
			setEmail(user.email ? user.email : "");
			setOffice(user.office ? user.office : "");
			setImage(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : image);
			setImageFile(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : image);
			setFinalImage(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : image);
			setAccountPlan(user.plan ? user.plan : 0);
			// setRole(user.role);
			// setId(user.id);
			// setTitle(user.title);
			// setFirstName(user.firstName);
			// setLastName(user.lastName);
			// setCompanyName(user.companyName);
			// setEmail(user.email);
			// setPhone(user.phone);
			// setOffice(user.office);
			// setFax(user.fax);
			// setCity(user.address && user.address.split(',')[1]);
			// setCountry(user.address && user.address.split(',')[2]);
			// setStreetNumber(user.address && user.address.split(',')[0]);
			// setMobileNumber(user.mobile);
			// setAbout(user.about);
			// setImage(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : image);
			// setProjectImage(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : image);
			setUserProfileDetails({...user});
		}

	}, [userContextData, image])

	useEffect(() => {
		// setAllProjectsDetails(projects);
		setAccountPlan(userContextData?.user?.plan);

		if(userContextData && userContextData.user && !areAllProjectsFetched) {
			setIsLoading(true);
			setAreAllProjectsFetched(false);
			const fetchAllProjects = async () => {
				const token = localStorage.getItem("token");
				if (token) {
					const projectResponse = await axiosInstance.get('/projectLists?', {
						headers: {
							Authorization: "Bearer " + token
						}
					}).catch(err => console.log(err))
					if (projectResponse && projectResponse.data.status === 200) {
						localStorage.setItem("project_list", projectResponse.data.payload && JSON.stringify(projectResponse.data.payload.data))
						setAreAllProjectsFetched(true);
						setAllProjectsDetails(projectResponse.data.payload.data);
						setIsLoading(false);
					}
				}
			}
			fetchAllProjects();
		}
	}, [userContextData])

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const closeProfileDialog = () => {
		
		setOpenProfileDialog(false);
		resetForm();
	}

	const resetForm = () => {

		if (userContextData && userContextData.user) {

			const {user} = userContextData;
			
			setId(user.id ? user.id : "")
			setFirstName(user.firstName ? user.firstName : "");
			setLastName(user.lastName ? user.lastName : "");
			setTitle(user.title ? user.title : "");
			setMobile(user.mobile ? user.mobile : "");
			setPhone(user.phone ? user.phone : "");
			setAddress(user.address ? user.address : "");
			setCountry(user.country ? user.country : "");
			setState(user.state ? user.state : "");
			setPostalCode(user.postalCode ? user.postalCode : "");
			setFax(user.fax ? user.fax : "");
			setCompanyName(user.companyName ? user.companyName : "");
			setRole(user.role ? user.role : "");
			setAbout(user.about ? user.about : "");
			setEmail(user.email ? user.email : "");
			setOffice(user.office ? user.office : "");
			setImage(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : image);
			setImageFile(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : MELogo);
			setFinalImage(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : MELogo);

			// setRole(user.role);
			// setId(user.id);
			// setTitle(user.title);
			// setFirstName(user.firstName);
			// setLastName(user.lastName);
			// setCompanyName(user.companyName);
			// setEmail(user.email);
			// setPhone(user.phone);
			// setOffice(user.office);
			// setFax(user.fax);
			// setCity(user.address && user.address.split(',')[1]);
			// setCountry(user.address && user.address.split(',')[2]);
			// setStreetNumber(user.address && user.address.split(',')[0]);
			// setMobileNumber(user.mobile);
			// setAbout(user.about);
			// setImage(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : image);
			// setProjectImage(user.image ? `${BASE_URL_IMAGE_USER}${user.image}` : image);
			setUserProfileDetails({
				...user
			});
		}
	}

	const updateUserDetailsHandler = async () => {
		if ( 
            (!firstName || firstName.trim().length === 0) || 
            (!lastName || lastName.trim().length === 0) || 
            (!mobile || mobile.trim().length === 0) ||  
            // (!phone || phone.trim().length === 0) ||
            (!address || address.trim().length ===0) ||
            (!postalCode || postalCode.trim().length === 0) ||
            (country.trim().length === 0) ||
            // streetNumber.trim().length === 0 || 
            // city.trim().length === 0 || 
            // country.trim().length === 0 || 
            (!role || role.trim().length === 0) ||
            (!title || title.trim().length === 0) ||
            (!email || email.trim().length === 0 )
        ) {

            setErrors({emptyError: "This field cannot be empty"})
            return;

		} 
		
		else if(country==="Australia" && (!state || state.trim().length === 0)) {
			setErrors({emptyStateError: "This field cannot be empty"})
		}

        else if (isNaN(postalCode)) {
            setErrors({
                lengthError: "Postal code should be a valid number"
            })
            return;
        } 
        
        else if(maxLimitReached) {
            setErrors({limitError:"Maximum limit reached"})
            return;
		}
		
		else{
			
			setErrors({})

			setIsLoading(true);
			let retrivedFile;

			if (imageFile){

				setImage(imageFile);
				retrivedFile = urlToFile(imageFile,`user${Math.random()}`);
				console.log(retrivedFile);
			}

			const formData = new FormData();
			formData.append('id', id);
			formData.append('role', role);
			formData.append('title', title);
			formData.append('firstName', firstName);
			formData.append('lastName', lastName);
			formData.append('address', address);
			formData.append('postalCode', postalCode);
			formData.append('state', state);
			formData.append('country', country);
			formData.append('office', office);
			formData.append('mobile', mobile);
			formData.append('phone', phone);
			formData.append('email', email);
			formData.append('companyName', companyName);

			if (retrivedFile) formData.append('image', retrivedFile);
			
			formData.append('fax', fax);
			formData.append('about', about);

			const updateProfileRes = await axiosInstance.post('/user/editProfile', formData)
				.then(
                    logger({
                        title:'Edit Profile',
                        url:window.location.href,
                        method:'POST',
                        activity: 'editProfile',
                        user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                        api:BASE_URL+ API_VERSION +'/user/editProfile' ,
                        session:localStorage.getItem('session'),
                        projectId:null,
                        unitId:null,
                        assignedId:null,
                        })      
                )
			.catch(err => {
				setIsLoading(false);
				if (err.response.data.status === 422) {
					setOpenSnackbar(false);
					setSnackbarType("error");
					setMessageForSnackbar(err.response.data.payload.error);
				}
				if (err.response.data.status === 500) {
					setOpenSnackbar(false);
					setSnackbarType("error");
					setMessageForSnackbar(err.response.data.message);
				}

			});

			if(updateProfileRes){
				setIsLoading(false);
				setOpenSnackbar(true);
				setMessageForSnackbar("User details updated successfully!");
				setSnackbarType("success");
				userContextData.fetchUserData();
				setUserProfileDetails({
					id,
					role,
					title,
					firstName,
					lastName,
					address,
					postalCode,
					state,
					image,
					country,
					// address: {streetNumber,city,country},
					office,
					mobile,
					phone,
					email,
					companyName,
					fax,
					about
				})
				setOpenProfileDialog(false);
			}
            // const index = usersList.findIndex(user=>user.id===id)
            // usersList[index] = {id,role,title,firstName,lastName,address: {streetNumber,city,country},office,mobileNumber,phoneNumber,email,companyName,fax,about}
        }
	}

	const closeUpgradeAccountDialog = () => {
		setOpenUpgradeAccountDialog(false);
	}


	// const fileChangedHandler = event => {
        
    //     const fileList = event.target.files[0];
	// 	convertIntoBase64(fileList)                
    // }

	// const convertIntoBase64 = (file)=> {
    //     let validPattern = /image-*/
    //     let fileReader = new FileReader();
    //     let flag = false;
	// 	if (file.type.match(validPattern)) {
	// 		flag = true;
	// 	}else{
	// 		flag=false;
	// 	}
	
	// 	if(flag){
    //         fileReader.onloadend = fileReaderLoaded.bind(this);
    //         fileReader.readAsDataURL(file);
    //     }else{
    //         alert('You can only upload specific image!');
    //         return;
    //     }
	// }
	
    const fileReaderLoaded = (e) => {
        let reader = e.target;
        let base64image = reader.result;
				setImage(base64image);            
	}

	const handleImageSave = (newImg) => {

		setImageFile(newImg);
		setFinalImage(newImg);
		setOpenImageCropper(false);
	}
	const setOpenDowngradeModal = (plan, price) => {
		openDowngradeModal(true);
		setUpdatePlan(plan);
		setTargetPlan(plan);
		setPlanPrice(price)
	}
	const closeOpenDowngradeModal = () => {
		openDowngradeModal(false);
	}
	const handleImageDiscard = () => {

		setOpenImageCropper(false);
		setFinalImage(imageFile);
	}

	const handleSnackbarClose = () => {
		setOpenSnackbar(false);
		setSnackbarType("");
		setMessageForSnackbar("");
	}
	const processSetAccountPlan = (plan, price, currency ) => {
		setUpdatePlan(plan);
		setTargetPlan(plan);
		setOpenPayModal(true);
		setPlanPrice(price);
		setCurrency(currency)
	}

	const handleSuccessfulPayForUpgrade = () => {
		setOpenPayModal(false);
		setIsLoading(true);

		axiosInstance.post('/user/confirmUpgrade', { plan: targetPlan })
		.then(res => {
			logger({
				title:'Confirm Upgrade',
				url:window.location.href,
				method:'POST',
				activity: 'confirmUpgrade',
				user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
				api:BASE_URL+ API_VERSION +'/user/confirmUpgrade' ,
				session:localStorage.getItem('session'),
				projectId:null,
				unitId:null,
				assignedId:null,
				})      
			setIsLoading(false);
			setOpenSnackbar(true);
			if (res && res.data && res.data.status === 200) {
				setSnackbarType("success");
				setMessageForSnackbar(`Upgrade success! You are now ${targetPlan === 1 ? 'Premium' : 'Gold'} user.`);	
				if (userContextData && userContextData.user) {
					userContextData.updateUser({...userContextData.user, plan: targetPlan});
				}
			}
			else {
				setSnackbarType("error");
				setMessageForSnackbar(`Failed to save your transaction. Please contact admin to resolve this issue.`);
			}
		}).catch(e => {
			console.log(e);
			setIsLoading(false);
			setOpenSnackbar(true);
			setSnackbarType("error");
			setMessageForSnackbar(`Failed to save your transaction. Please contact admin to resolve this issue.`);
		})
	}

	const onToggleMobileSidebar = (e) => {

		e.preventDefault();
		$('body').toggleClass('sidebar-mobile-main');
		document.querySelector('.sidebar-main').classList.toggle('sidebar-expand-toggle');
	}

    return (
        <div className="sidebar sidebar-dark sidebar-main sidebar-expand-md">

			<div className="sidebar-mobile-toggler text-center d-flex justify-content-start">
				<a href=" " onClick={onToggleMobileSidebar} className="sidebar-mobile-main-toggle">
					<i className="icon-arrow-left8"></i>
				</a>
				<span style={{marginLeft:"35px"}}>
					METRO ENGINE
				</span>
			</div>


			<div className="sidebar-content">

				<SnackbarToast
					handleSnackbarClose={handleSnackbarClose}
					openSnackbar={openSnackbar}
					snackbarType={snackbarType}
					message={messageForSnackbar}
				/>

				 <LoadingSpinner isLoading={isLoading} />

				<div className="sidebar-user">
					<div className="card-body" style={{position:"relative"}}>
						<div className="media d-flex align-items-center">
							<div className="mr-3">
								<Avatar src={image} width="38" height="38" alt={userProfileDetails.firstName +" " +userProfileDetails.lastName} className={classes.profileImage} /> 
									{/* {firstName.split("")[0] + lastName.split("")[0]}</Avatar></a> */}
								
							</div>

							<div className="media-body">
								{	
										<Fragment>
											<div className="media-title font-weight-semibold">{userProfileDetails.firstName +" " +userProfileDetails.lastName}</div>
											{ userProfileDetails.address ? 
												<div className="font-size-xs opacity-50">
													<i className="icon-pin font-size-sm"></i> &nbsp;{ userProfileDetails.address }
												</div>

												: null
											}
										</Fragment>
										
								}
								
								{
									localStorage.getItem("user_role") !== "Admin" ? 
								
									accountPlan===0 ? 
									<div className="account-plan" style={{position:"absolute", borderRadius: "3px", left:"80px", display:"inline-block", background: "#397A17", marginTop:"5px", fontSize:"0.6rem", padding:"2px 0px", width:"55px", color:"white"}}>
										<span className="font-weight-bolder" style={{letterSpacing:"0.04em", display:"block", textAlign:"center"}}>FREE</span>
									</div>
									:
									
									accountPlan===1 ? 
										<div className="account-plan" style={{position:"absolute", borderRadius: "3px", left:"80px", display:"inline-block", background: "crimson", marginTop:"5px", fontSize:"0.6rem", padding:"2px 0px", width:"55px", color:"white"}}>
											<span className="font-weight-bolder" style={{letterSpacing:"0.04em", display:"block", textAlign:"center"}}>PREMIUM</span>
										</div> 
									: 

									accountPlan===2 ? 

									<div className="account-plan" style={{position:"absolute", borderRadius:"3px", left:"80px", display:"inline-block", background:"#E5A948", marginTop:"5px", fontSize:"0.6rem", padding:"2px 0px", width:"55px", color:"white"}}>
										<span className="font-weight-bolder" style={{letterSpacing:"0.04em", display:"block", textAlign:"center"}}>GOLD</span>
									</div>

									: null

									: null

								}
								
							</div>

							<div className="ml-3 align-self-center p-0">
								<div className="text-white" aria-controls="simple-menu" aria-haspopup="true">
									<i style={{cursor:"pointer"}} onClick={handleClick} className="icon-cog3"/>
								</div>
								<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
								>
								<MenuItem onClick={()=> {setOpenProfileDialog(true); handleClose();}}>
									<PersonIcon style={{fontSize:"1rem"}} className="mr-2"/>
									<span style={{fontSize:"0.9rem"}}>My Profile</span>
								</MenuItem>

								{ localStorage.getItem("user_role") !== "Admin" && accountPlan < 3 ? 
									<MenuItem onClick={()=> {setOpenUpgradeAccountDialog(true); handleClose();}}>
										<LocalOfferIcon style={{fontSize:"0.9rem"}} className="mr-2"/>
										<span style={{fontSize:"0.9rem"}}>Manage Account</span>
									</MenuItem>
								: null 
								}

								<MenuItem 
									onClick={() => {
										userContextData.logout();
										handleClose()
									}}
								>
									<ExitToAppIcon style={{fontSize:"0.9rem"}} className="mr-2"/>
									<span style={{fontSize:"0.9rem"}} >Logout</span>
								</MenuItem>
								<div className="dropdown-divider"></div>
								</Menu>
							</div>
						</div>
					</div>
				</div>
				{
					userContextData.user
					?
					<Sidebar userRole={userContextData.user.role} allProjectsDetails={allProjectsDetails} />
					:
					null
				}
				{/* { userContextData.user && 
				userContextData.user.role === "User Manager" ? 

					<UserManagerSidebar allProjectsDetails={allProjectsDetails}/> 
				: 

				userContextData.user && 
				userContextData.user.role==="Project Manager" ?

					<ProjectManagerSidebar 
						allProjectsDetails={allProjectsDetails}
					/>  
				:

				localStorage.getItem('admin_data') ?
				
					<AdminSidebar allProjectsDetails={allProjectsDetails}/> 
				
				:
					null
				} */}
			</div>

			<React.Fragment>
				<Dialog open={openProfileDialog} onClose={closeProfileDialog} aria-labelledby="form-dialog-title">
					<DialogTitle className="text-center" id="form-dialog-title">
						<div className="image-upload">
							<input accept="image/*" onChange={(e)=>{setFinalImage(URL.createObjectURL(e.target.files[0]));setOpenImageCropper(true);}} style={{display: "none"}} id="icon-button-file" type="file" />
							<label htmlFor="icon-button-file">
								<Tooltip title="Change Profile Photo" arrow>
									<IconButton color="primary" aria-label="upload picture" component="span">
										<Avatar src={imageFile} className={classes.large} />
									</IconButton>
								</Tooltip>
							</label>
						</div>
					</DialogTitle>
					<Dialog
					open={openImageCropper}
					onClose={()=>setOpenImageCropper(false)}
					>
						<DialogContent>
							<ImageCrop setFinalImage={setFinalImage} aspectRatio={1} imageFile={finalImage} handleImageSave={handleImageSave} handleImageDiscard={handleImageDiscard} />
						</DialogContent>

					</Dialog>
					<DialogContent>
					<div className="row">
					<div className="col-md-6">

						<TextField
							
							required
							margin="dense"
							id="first-name"
							label="First Name"
							helperText = {
								errors.emptyError && (!firstName || firstName.trim().length === 0) ?
								errors.emptyError :
									""
							}
							error = {
								errors.emptyError && (!firstName || firstName.trim().length === 0) ?
								true :
								false
							}
							value={firstName}
							onChange={(event)=> setFirstName(event.target.value)}
							type="text"
							fullWidth
						/>
						<TextField
							
							required
							helperText = {
								errors.emptyError && (!lastName || lastName.trim().length === 0 ) ?
								errors.emptyError : ""
							}
							error = {
								errors.emptyError && (!lastName || lastName.trim().length === 0 ) ?
								true : false
							}
							margin="dense"
							id="last-name"
							label="Last Name"
							type="text"
							value={lastName}
							onChange={(event)=>setLastName(event.target.value)}
							fullWidth
						/>
						<TextField
							
							margin="dense"
							id="company-name"
							label="Company Name"
							type="text"
							value={companyName}
							onChange={(event)=> setCompanyName(event.target.value)}
							fullWidth
						/>
						<TextField
							                                
							margin="dense"
							id="fax"
							label="Fax"
							type="text"
							value={fax}
							onChange={(event)=>setFax(event.target.value)}
							fullWidth
						/>
						{/* <h5 style={{marginBottom:"0", marginTop:"13px", fontWeight:500}}>Address:</h5> */}
						<TextField
							// style={{marginTop:"0"}}
							
							required
							margin="dense"
							helperText = {
								errors.emptyError && (!address || address.trim().length === 0 ) ?
								errors.emptyError : ""
							}
							error = {
								errors.emptyError && (!address || address.trim().length === 0 ) ?
								true : false
							}
							id="address"
							label="Address"
							value={address}
							onChange={(event)=>setAddress(event.target.value)}
							type="text"
							fullWidth
						/>

						<TextField
							// style={{marginTop:"0"}}
							
							required
							margin="dense"
							helperText = {
								errors.lengthError && isNaN(postalCode.toString())
								?
								errors.lengthError
								:
								errors.emptyError && postalCode.trim().length === 0
								?
								errors.emptyError
								:
								""
							}
							error = {
								(errors.lengthError && isNaN(postalCode.toString())) ||
								(errors.emptyError && postalCode.trim().length === 0) ||
								(errors.lengthError && isNaN(postalCode.toString()))
								?
								true
								: false
							}
							id="postalCode"
							label="Postal Code"
							value={postalCode}
							onChange={(event)=>setPostalCode(event.target.value)}
							type="text"
							fullWidth
						/>

						<FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
							<InputLabel
								id="demo-simple-select-label"
								required
								helperText = {
									errors.emptyError && (!country || country.trim().length === 0) ?
									errors.emptyError : ""
								}
								error = {
									errors.emptyError && (!country || country.trim().length === 0) ?
									true : false
								}
							>
								Country
							</InputLabel>
			
							<Select							
								required
								helperText = {
									errors.emptyError && (!country || country.trim().length === 0) ?
									errors.emptyError : ""
								}
								error = {
									errors.emptyError && (!country || country.trim().length === 0) ?
									true : false
								}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={country}
								fullWidth
								onChange = {
									(event) => {
										if (event.target.value === "Australia" && !australianStateList.includes(state)) {
											setState("");
										};
										setCountry(event.target.value)
									}
								}
							>
							{countryList.map((country => (
								<MenuItem key={country} value={country}>{country}</MenuItem>
							)))}
							</Select>
						</FormControl>	

							{ country !== "Australia" ? 
                                <TextField
                                    // style={{marginTop:"0"}}
                                    margin="dense"
									id="state"
									label="State"
									value={state}
									onChange={(event)=>setState(event.target.value)}
									type="text"
									fullWidth
                                />
                                : 

                                <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        required
                                        helperText = {
                                            errors.emptyStateError && (!state || state.trim().length === 0) ?
                                            errors.emptyStateError : ""
                                        }
                                        error = {
                                            errors.emptyStateError && (!state || state.trim().length === 0) ?
                                            true : false
                                        }
                                    >
                                        State
                                    </InputLabel>
                    
                                    <Select
										required
										helperText = {
											errors.emptyStateError && (!state || state.trim().length === 0) ?
											errors.emptyStateError : ""
										}
										error = {
											errors.emptyStateError && (!state || state.trim().length === 0) ?
											true : false
										}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={state}
										fullWidth
										onChange={(event)=> setState(event.target.value)}
									>
										{australianStateList.map((state => (
											<MenuItem key={state} value={state}>{state}</MenuItem>
										)))}
                                    </Select>

                                </FormControl>
                            }
					</div>
					<div className="col-md-6">                            
						<TextField
							inputProps={{readOnly: true}}
							required
							helperText = {
								errors.emptyError && (!title || title.trim().length === 0) ?
								errors.emptyError : ""
							}
							error = {
								errors.emptyError && (!title || title.trim().length === 0) ?
								true : false
							}
							margin="dense"
							id="title"
							label="Title"
							type="text"
							value={title}
							onChange={(event)=>setTitle(event.target.value)}
							fullWidth
						/>
						<TextField
							inputProps={{readOnly: true}}
							required
							helperText = {
								errors.emptyError && (!email || email.trim().length === 0) ?
								errors.emptyError : ""
							}
							error = {
								errors.emptyError && (!email || email.trim().length === 0) ?
								true : false
							}
							margin="dense"
							id="email"
							label="Email"
							type="email"
							value={email}
							onChange={(event)=>setEmail(event.target.value)}
							fullWidth
						/>
						<TextField
							
							required
							helperText = {
								errors.emptyError && ( !mobile || mobile.trim().length === 0 ) ?
								errors.emptyError : ""
							}
							error = {
								errors.emptyError && ( !mobile || mobile.trim().length === 0 ) ?
								true : false
							}
							margin="dense"
							id="mobile-number"
							label="Mobile Number"
							value={mobile}
							onChange={(event)=>setMobile(event.target.value)}
							
							type="text"
							fullWidth
						/>
						
						<TextField
							inputProps={{readOnly: true}}
							margin="dense"
							id="role"
							label="Role"
							type="text"
							value={role}
							onChange={(event)=>setRole(event.target.value)}
							fullWidth
						/>
						<TextField
							
							margin="dense"
							id="phone-number"
							label="Phone Number"
							type="text"
							value={phone}
							onChange={(event)=>setPhone(event.target.value)}
							fullWidth
						/>
						
						<div className="about-form">
							<TextField
								style={{marginTop:"22px"}}
								                                
								margin="dense"
								id="about-me"
								label="About Me"
								type="text"
								rows={7}
								variant="outlined"
								multiline       
								helperText={charactersLength>1200 ? "Max Limit Reached" : null}
								error={charactersLength>1200 ? true: false}                                   
								value={about}
								onChange={(event)=>{ 
									if(event.target.value.length>1200){
										setMaxLimitReached(true)                                            
									}else{
										setMaxLimitReached(false)
									}
									setCharactersLength(event.target.value.length); 
									setAbout(event.target.value)
								}}
								fullWidth
							/>
							<span style={{display:"block", textAlign:"right"}} className={`${charactersLength>1200 ? "text-error" : "text-dark"}`}>{charactersLength}/{maxCount}</span>
						</div>
					</div>
					</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=> updateUserDetailsHandler()} color="primary">
							Confirm
						</Button>
						<Button onClick={closeProfileDialog} color="primary">
						Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>

			<UpgradeModal accountPlan={accountPlan} userContextData={userContextData} modalOpened={openUpgradeAccountDialog} openPayModal={processSetAccountPlan} title="Manage your account"
			closeDialog={closeUpgradeAccountDialog} userInfo={userContextData?.user} openDowngradeModal={setOpenDowngradeModal}
			// onChoose={processSetAccountPlan}
			// onSuccess={() => {processSetAccountPlan(1); setOpenUpgradeAccountDialog(false) }}   
			/>
			<DowngradeModal modalOpened={downgradeModal} closeDialog={closeOpenDowngradeModal} plan={updatePlan}  presentPlan={accountPlan}/>
			<PayModal modalOpened={openPayModal}  planPrice={planPrice} userInfo={userContextData?.user} currency={currency} closeDialog={() => setOpenPayModal(false)} plan={updatePlan} setOpenUpgradeAccountDialog = {setOpenUpgradeAccountDialog}
			/> 
		</div>
    )
}

export default Menubar
