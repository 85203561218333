import { Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@material-ui/core'

import React, { useState } from 'react'

function DeleteProjectDialog({
    openDeleteProjectDialog,
    onCloseDeleteProjectDialogHandler,
    onDeleteProjectHandler,
    selectedProject
}) {

    const [deleteButtonEnable, setDeleteButtonEnable] = useState(true);

    const validateField = (value) => {
        if(value && value.length > 0 && value.trim() === 'permanently_delete'){
            setDeleteButtonEnable(false)
        }else{
            setDeleteButtonEnable(true)
        }
      }
    return (

        <Dialog
        
            open={openDeleteProjectDialog}
        
            onClose={onCloseDeleteProjectDialogHandler}
        
            aria-labelledby="alert-dialog-title"
        
            aria-describedby="alert-dialog-description"
        
        >
        
            <DialogContent>
        
                <DialogContentText id="alert-dialog-description">
        
                Do you want to delete this project? This action cannot be
                undone.
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                To confirm deletion, type permanently_delete in the text input field.
        
                </DialogContentText>

                <DialogContentText>
                <div className="ml-5 mr-5">
                    <TextField
                        margin="dense"
                        id="tabValue"
                        label="Permanently delete"
                        type="text"
                        name="deleteProjectText"
                        onChange={(e) => validateField(e.target.value)}
                        fullWidth
                    />
                </div>
            </DialogContentText>
        
            </DialogContent>
        
            <DialogActions>
        
                <Button
        
                onClick={onCloseDeleteProjectDialogHandler}
        
                color="primary"
        
                >
        
                No
        
                </Button>
        
                <Button
        
                onClick={() => onDeleteProjectHandler(selectedProject.id)}
        
                color="primary"
        
                autoFocus

                disabled={deleteButtonEnable}
        
                >
        
                Yes
        
                </Button>
        
            </DialogActions>
        
        </Dialog>
    
    )

}

export default DeleteProjectDialog
