import { FormControl, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import SnackbarToast from '../../../../utils/SnackbarToast';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import axiosInstance from '../../../../Axios/axios';
import logger from '../../../../utils/logger';
import { API_VERSION, BASE_URL } from '../../../../Axios/api';
function AssignRoleProjectDialog({
    project,
    setOpenAssignManagerProject,
    openAssignManagerProject
    
}) {

    
  const [openSnackbar, setOpenSnackbar] = useState("");

  const [messageForSnackbar, setMessageForSnackbar] = useState("");
  
  const [snackbarType, setSnackbarType] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [projectManagerEmail, setProjectManagerEmail] = useState("");

  const [masterAgentEmail, setMasterAgentEmail] = useState("");

  const [userManagerEmail, setUserManagerEmail] = useState("");

  const [errors, setErrors] = useState({});

  const [errorsEmail, setErrorsEmail] = useState({
    projectManagerEmail: '',
    masterAgentEmail: '',
    userManagerEmail: ''
  });

    const onCloseAddProjectDialogHandler = () => {
    
        setErrors({});
        resetForm();
        setOpenAssignManagerProject(false);
      
      };

      const resetForm = () => {
        setProjectManagerEmail("");
        setMasterAgentEmail();
        setUserManagerEmail("");
        errorsEmail.projectManagerEmail = ''
        errorsEmail.masterAgentEmail = ''
        errorsEmail.userManagerEmail = ''
    }


    const handleSnackbarClose = () => {

        setOpenSnackbar(false);
    
        setSnackbarType("");
        
        setMessageForSnackbar("");
      
      }

      const managerExitsCheck = async (value, field, errorFiled) => {
        setErrors({})
        errorsEmail[errorFiled] = '';;
        setErrorsEmail(errorsEmail)
        setIsLoading(true)
        if(value){
  
          const token = localStorage.getItem("token");
  
          if(!token) {
  
            localStorage.clear();
  
            window.location.reload();
  
            return;
  
          }
  
          const formData = new FormData();
          formData.append('email', value);
          formData.append('type', field);
          const managerExitsCheck = await axiosInstance
      
          .post("/managerExitsCheck", formData, {
  
            headers: {
  
              Authorization: "Bearer " +token
  
            }
  
          })
            .then(
              logger({
                title:'Check Manager Exits',
                url:window.location.href,
                method:'POST',
                activity: 'managerExitsCheck',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION+'/managerExitsCheck',
                session:localStorage.getItem('session'),
                projectId:null,
                unitId:null,
                assignedId:null,
                })                          
            )
          .catch((err) => {
  
            if(err.response.data.status===422) {
              setIsLoading(false);
              errorsEmail[errorFiled] = err.response.data.message
              setErrorsEmail(errorsEmail)
              console.log('errorsEmail: ', errorsEmail);
            }
  
           
            if (err.response.data.status === 500) {
  
              setIsLoading(false);
  
              setOpenSnackbar(openSnackbar => !openSnackbar);
  
              setSnackbarType("error");
  
              setMessageForSnackbar(err.response.data.message);
  
            }
  
          });

          if (managerExitsCheck) {
            errorsEmail[errorFiled] = ''
            setIsLoading(false);
          }
        }else{
          setIsLoading(false);
        }
  
       
      }

      const onSaveProjectHandler = async ()  => {
   
        if ((!projectManagerEmail || projectManagerEmail.trim().length === 0) &&
            (!masterAgentEmail || masterAgentEmail.trim().length === 0) &&
            (!userManagerEmail || userManagerEmail.trim().length === 0)
        ) {
            setErrors({
                emptyError: "This field cannot be empty"
            })
            return;
        } else{
            setErrors({})
            setIsLoading(true);
    
            const token = localStorage.getItem("token");
    
            if(!token) {
    
              localStorage.clear();
    
              window.location.reload();
    
              return;
    
            }
            const formData = new FormData();
            if(projectManagerEmail && projectManagerEmail.trim().length > 0){
              formData.append('projectManagerEmail', projectManagerEmail);
            }
            if(masterAgentEmail && masterAgentEmail.trim().length > 0){
              formData.append('masterAgentEmail', masterAgentEmail);
            }
            if(userManagerEmail && userManagerEmail.trim().length > 0){
              formData.append('userManagerEmail', userManagerEmail);
            }
            formData.append('url', window.location.origin);
            formData.append('projectId', project.id);
            formData.append('projectName', project.name);
            // return false
            const addProjectRes = await axiosInstance
    
              .post("/mangerProjectAssign", formData, {
    
                headers: {
    
                  Authorization: "Bearer " +token
    
                }
    
              })
              .then(
                logger({
                  title:'Assign Manger Project',
                  url:window.location.href,
                  method:'POST',
                  activity: 'mangerProjectAssign',
                  user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                  api:BASE_URL+ API_VERSION+'/mangerProjectAssign',
                  session:localStorage.getItem('session'),
                  projectId: project.id,
                  unitId:null,
                  assignedId:null,
                  })                          
              )  
              .catch((err) => {
    
                if(err.response.data.status===422) {
    
                  setIsLoading(false);
    
                  setOpenSnackbar(openSnackbar => !openSnackbar);
    
                  setSnackbarType("error");
    
                  if(err.response.data.payload && err.response.data.payload.error) {
      
                    setMessageForSnackbar(err.response.data.payload.error);
      
                  } else {
      
                    setMessageForSnackbar(err.response.data.message);
    
                  }
    
                }
    
                if (err.response.data.status === 500) {
    
                  setIsLoading(false);
    
                  setOpenSnackbar(openSnackbar => !openSnackbar);
    
                  setSnackbarType("error");
    
                  setMessageForSnackbar(err.response.data.message);
    
                }
    
              });
          
            if (addProjectRes) {

              resetForm();
    
              setIsLoading(false);
    
              setOpenAssignManagerProject(false);
    
              setOpenSnackbar(openSnackbar => !openSnackbar);
    
              setSnackbarType("success");
    
              setMessageForSnackbar("Project assign successfully");
            }
        }
     
      
      };
    return (
        <React.Fragment>
            <LoadingSpinner isLoading={isLoading} />
            <SnackbarToast
          
          handleSnackbarClose={handleSnackbarClose}

          openSnackbar={openSnackbar}
          
          snackbarType={snackbarType}
          
          message={messageForSnackbar}  
      
      />
     
    <Dialog
    
    // style={{zIndex:11111}}

    open={openAssignManagerProject}
    
    // onClose={onCloseAddProjectDialogHandler}
  
    aria-labelledby="form-dialog-title"
  
  >
  

      <DialogContent>

      <div className="col-md-12">
            <TextField
                helperText = {
                    errorsEmail.projectManagerEmail.length > 0 ?
                    errorsEmail.projectManagerEmail : 
                    ''
                }
                error = {
                    errorsEmail.projectManagerEmail.length > 0 ?
                    true : 
                    false
                }
                margin="dense"
                id="projectManagerEmail"
                label="Project Manager"
                type="text"
                value={projectManagerEmail}
                onChange={(event)=> setProjectManagerEmail(event.target.value)}
                // onBlur={(e) => managerExitsCheck(e.target.value, 'Project Manager', 'projectManagerEmail')}
                name="projectManagerEmail"
                fullWidth
            />
            <TextField
                helperText = {
                    errorsEmail.userManagerEmail.length > 0 ?
                    errorsEmail.userManagerEmail : 
                    ''
                }
                error = {
                    errorsEmail.userManagerEmail.length > 0 ?
                    true : 
                    false
                }
                margin="dense"
                id="userManagerEmail"
                label="User Manager"
                type="text"
                value={userManagerEmail}
                onChange={(event)=> setUserManagerEmail(event.target.value)}
                // onBlur={(e) => managerExitsCheck(e.target.value, 'User Manager','userManagerEmail')}
                name="userManagerEmail"
                fullWidth
            />
            <TextField
                helperText = {
                    errorsEmail.masterAgentEmail.length > 0 ?
                    errorsEmail.masterAgentEmail : 
                    ''
                }
                error = {
                    errorsEmail.masterAgentEmail.length > 0 ?
                    true : 
                    false
                }
                margin="dense"
                id="masterAgentEmail"
                label="Master Agent"
                type="text"
                value={masterAgentEmail}
                onChange={(event)=> setMasterAgentEmail(event.target.value)}
                // onBlur={(e) => managerExitsCheck(e.target.value, 'Master Agent','masterAgentEmail')}
                name="masterAgentEmail"
                fullWidth
            />
        </div>
          </DialogContent>
          
          <DialogActions>

        <React.Fragment>

          <Button disabled={isLoading} color="primary" onClick={onSaveProjectHandler}>

            Save

          </Button>

          <Button onClick={onCloseAddProjectDialogHandler} color="primary">

            Cancel

          </Button>

        </React.Fragment>

      </DialogActions>
      </Dialog>

        </React.Fragment>

    )
    
}
export default AssignRoleProjectDialog
