import React, { useContext, useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Checkbox } from '@material-ui/core'
import { AuthContext } from '../../../../Context/userContext';
import axiosInstance from '../../../../Axios/axios';
import '../../../../Assets/css/StripeElements.css';
import logger from '../../../../utils/logger';
import { API_VERSION, BASE_URL } from '../../../../Axios/api';

export default function UpgradeProjectPlanStripeCheckoutForm({ title, onUpdateSuccess, projectId, closeDialog }) {

  const userContextData = useContext(AuthContext);

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      // Create PaymentIntent as soon as the page loads
        axiosInstance.post('/stripe-checkout', {
          project_id : projectId,
          title : title
        }, {
          headers: {
            "Content-Type": "application/json",
            'Authorization': "Bearer " + token
          }
        })
        .then(res => {
          logger({
            title:'Checkout Stripe',
            url:window.location.href,
            method:'POST',
            activity: 'stripe-checkout',
            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
            api:BASE_URL+ API_VERSION+'/stripe-checkout',
            session:localStorage.getItem('session'),
            projectId:projectId,
            unitId:null,
            assignedId:null,
            })                          
          return res;
        })
        .then(data => {
          setClientSecret(data.data.payload.clientSecret);
        });
    } else {
      console.log("Error Stripe Checkout button without JWT");
    }

  }, []);

  const cardStyle = {
    hidePostalCode: true,
    style: {

      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const openPopup = (url, e) => {
    e.preventDefault();
    var w = window.screen.width / 2;
    var h = window.screen.height / 2;
    var left = (window.screen.width / 2) - (w / 2);
    var top = (window.screen.height / 2) - (h / 2);
    window.open(url, 'MsgWindow', "width=" + w + ",height=" + h + ",top=" + top + ",left=" + left + ",scrollbars=yes,resizable=no,toolbar=no");
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        axiosInstance.post('/confirmStorageUpgrade', {
          project_id : projectId,
          title: title
        }, {
          headers: {
            'Authorization': "Bearer " + token
          }
        })
          .then(res => {
            logger({
              title:'Confirm Storage Upgrade',
              url:window.location.href,
              method:'POST',
              activity: 'confirmStorageUpgrade',
              user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
              api:BASE_URL+ API_VERSION+'/confirmStorageUpgrade',
              session:localStorage.getItem('session'),
              projectId:projectId,
              unitId:null,
              assignedId:null,
              })                          
            return res;
          })
          .then(res => {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            onUpdateSuccess(res.data.payload.updateStorageSpace);
           });

      } else {
        console.log("Error Pay now button without JWT");
      }

    }   
  };

    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        <div className="mt-2">
          <Checkbox required />
            By checking this box you agree to our <a href="#" onClick={(e) => openPopup('/privacy', e)}>Privacy Policy</a> and <a href="#" onClick={(e) => openPopup('/terms', e)}>Terms and Conditions.</a>
        </div>
        {succeeded &&       
          <Button type="submit" color="primary" onClick={closeDialog} style={{ float: 'right', backgroundColor: '#397a17', color: 'white', marginTop: 16, marginBottom: 16 }}>
            OK
          </Button>
          }

        { !succeeded &&   
        <div>
          <Button type="submit" color="primary" disabled={!stripe || disabled || processing} 
            style={{ float: 'right', backgroundColor: '#397a17', color: 'white', marginTop: 16, marginBottom: 16 }}>
            {processing ? 'Processing...' : `Pay Now`}
          </Button>
          <Button type="button" color="primary" onClick={closeDialog} style={{ float: 'right', marginTop: 16, marginBottom: 16, marginRight: 6 }}>
            Cancel
          </Button>
        </div>
        }
        {/* Show any error that happens when processing the payment */}
        { error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? "result-message" : "result-message hidden"}>
          Payment succeeded, your storage is upgraded
        </p>
      </form>
    );
  }

