import { Avatar, Dialog, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography, withStyles } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import React from 'react'
import ProjectListIcon from '@material-ui/icons/PlaylistAddCheck';
import { blue } from '@material-ui/core/colors';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function UserProjectListDialog({
    openUserProjectListDialog,
    onCloseUserProjectListDialog,
    userProjectList,
    onOpenAssignProjectDialog
}) {

    const classes = useStyles();
    
    // const handleListItemClick = (value) => {
    //     onClose(value);
    // };

    return (
        <Dialog 
            fullWidth={true}
            maxWidth='sm' 
            onClose={onCloseUserProjectListDialog} 
            aria-labelledby="simple-dialog-title" 
            open={openUserProjectListDialog}
        >
            <DialogTitle className="mb-0 pb-0" id="customized-dialog-title" onClose={onCloseUserProjectListDialog}>
                Projects List
            </DialogTitle>
            <List>
            {   userProjectList && 
                userProjectList.projects && 
                userProjectList.projects.length > 0 ?
                userProjectList.projects.map((project) => (
                    <ListItem key={project.id}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                            <ProjectListIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={project.name} />
                    </ListItem>
                ))

            :
                <ListItem style={{paddingLeft:"24px", paddingRight:"24px"}}>                    
                    <ListItemText primary={"No Projects Found"} />
                </ListItem>
            }

            <ListItem autoFocus button onClick={onOpenAssignProjectDialog}>
                <ListItemAvatar>
                <Avatar>
                    <Add/>
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Assign Projects"/>
            </ListItem>
            </List>
        </Dialog>
    )
}

export default UserProjectListDialog
