import { makeStyles } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import Menubar from '../Menubar/Menubar'
import AddUser from './AddUser';
import DeleteUserDialog from './DeleteUserDialog';
import FilterUsers from './FilterUsers';
import UserCard from './UserCard';
import UserActions from './UserActions';
import axiosInstance from '../../Axios/axios';
import SnackbarToast from '../../utils/SnackbarToast';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { usersProjectList } from '../../DummyData/userProjectList';
import AssignProjectDialog from './AssignProjectDialog';
import UserProjectListDialog from './UserProjectListDialog';
import AddressSelectionDialog from './AddressSelectionDialog';
import $ from 'jquery';
import MELogo from '../../Assets/img/ME-logo.svg'
import { urlToFile } from '../../utils/file';
import { API_VERSION, BASE_URL, BASE_URL_IMAGE_USER } from '../../Axios/api';
import { AuthContext } from '../../Context/userContext';
import { USER_ROLES } from '../../utils/const';
import logger from '../../utils/logger';

const useStyles = makeStyles((theme) => ({

    actionEdit: {
        textAlign: "right",
        [theme.breakpoints.down(576)]: {
            textAlign: "center"
        }
    },

    actionDelete: {
        textAlign: "left",
        [theme.breakpoints.down(576)]: {
            textAlign: "center"
        }
    },

    userActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }

}));

function Users(props) {
    const classes = useStyles();
    const userContextData = useContext(AuthContext);
    const [imageFile, setImageFile] = useState(MELogo);
    const [finalImage, setFinalImage] = useState(MELogo);
    const [openImageCropper, setOpenImageCropper] = useState(false);
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [role, setRole] = useState("");
    const [title, setTitle] = useState("");
    const [id, setId] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [address, setAddress] = useState("");
    // const [streetNumber, setStreetNumber] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [postalCode, setPostalCode] = useState("");
    // const [city, setCity] = useState("");
    const [office, setOffice] = useState("");
    const [mobile, setMobile] = useState("");
    const [about, setAbout] = useState("");
    const [actions, setActions] = useState(false);
    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [copyOfUsersList, setCopyOfUsersList] = useState([]);
    const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const [maxCount] = useState(1200);
    const [charactersLength, setCharactersLength] = useState(0);
    const [maxLimitReached, setMaxLimitReached] = useState(false);
    const [filterText, setFilterText] = useState(false);
    // const [created_at, setCreated_at] = useState('');
    // const [updated_at, setUpdated_at] = useState('');
    const [userIdsToDelete, setUserIdsToDelete] = useState([]);
    const [singleUserIdToDelete, setSingleUserIdToDelete] = useState("");
    const [isSelectModeOn, setIsSelectModeOn] = useState("");
    // const [image, setImage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState("");
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openUserProjectListDialog, setOpenUserProjectListDialog] = useState(false);
    const [openAssignProjectDialog, setOpenAssignProjectDialog] = useState(false);
    const [userProjectList, setUserProjectList] = useState({
        user: {},
        projects: []
    });
    const [assignProjectDetails, setAssignProjectDetails] = useState({});
    const [projectsAssignedToUser, setProjectsAssignedToUser] = useState([]);
    const [availableCriteria, setAvailableCriteria] = useState("");
    const [projectForCriteria, setProjectForCriteria] = useState("");
    const [projectListForCriteria, setProjectListForCriteria] = useState([]);
    const [bulkAction, setBulkAction] = useState("");
    const [originalUsersList, setOriginalUsersList] = useState([]);
    const [addressSelectionValue, setAddressSelectionValue] = useState("");
    const [addressSelectionType, setAddressSelectionType] = useState("");
    const [openAddressSelectionDialog, setOpenAddressSelectionDialog] = useState(false);

    // const [currentPage, setCurrentPage] = useState(1);
    // const [usersPerPage, setUsersPerPage] = useState(6);
    // const [totalUsers, setTotalUsers] = useState(0);
    // const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {

        if ((availableCriteria && availableCriteria !== "No Action") || (projectForCriteria && projectForCriteria !== "Show All")) {

            if (!projectForCriteria || projectForCriteria === "Show All") {

                if (["PostalCode", "State", "Country"].includes(availableCriteria)) {
                    setOpenAddressSelectionDialog(true);
                    setAddressSelectionType(availableCriteria);
                }

                else if (["Free Agent", "Active Agent", "Deactivated Agent"].includes(availableCriteria)) {
                    console.log("a1")
                    setIsLoading(true);
                    axiosInstance.post('/userSearchByCriteria', {
                        criteria: "role",
                        criteria_value: "agent",
                        flag: availableCriteria === "Active Agent" ? 1 : availableCriteria === "Deactivated Agent" ? 0 : "free"
                    })
                        .then(res => {
                            setIsLoading(false);
                            setUsersList(res.data.payload && res.data.payload.data);
                            setCopyOfUsersList(res.data.payload && res.data.payload.data);
                            isUserAvailable(res.data.payload.data);
                            setFilterText("");
                            document.getElementById("filter-user").value = ""
                        })
                        .catch(err => {
                            setSnackbarType("error");
                            setOpenSnackbar(true);
                            setMessageForSnackbar("Something went wrong!")
                        })
                }

                else {
                    console.log("a2")
                    setIsLoading(true);
                    axiosInstance.post('/userSearchByCriteria', {
                        criteria: "role",
                        criteria_value: availableCriteria
                    })
                        .then(res => {
                            setIsLoading(false);
                            setUsersList(res.data.payload && res.data.payload.data);
                            setCopyOfUsersList(res.data.payload && res.data.payload.data);
                            isUserAvailable(res.data.payload.data);
                            setFilterText("");
                            document.getElementById("filter-user").value = ""
                        })
                        .catch(err => {

                            setSnackbarType("error");
                            setOpenSnackbar(true);
                            setMessageForSnackbar("Something went wrong!")
                        })
                }
            }

            else if ((availableCriteria && availableCriteria !== "No Action") && (projectForCriteria && projectForCriteria !== "Show All")) {
                console.log("a3")
                setIsLoading(true)
                axiosInstance.post('/userSearchByCriteria', {
                    criteria: "role",
                    criteria_value: availableCriteria,
                    project_id: projectForCriteria.split("--")[1]
                })
                    .then(res => {
                        setIsLoading(false);
                        setUsersList(res.data.payload && res.data.payload.data);
                        setCopyOfUsersList(res.data.payload && res.data.payload.data);
                        isUserAvailable(res.data.payload.data);
                        setFilterText("");
                        document.getElementById("filter-user").value = ""
                    })
                    .catch(err => {

                        setSnackbarType("error");
                        setOpenSnackbar(true);
                        setMessageForSnackbar("Something went wrong!")
                    })
            }

            else if ((projectForCriteria && projectForCriteria !== "Show All") && (!availableCriteria || availableCriteria === "No Action")) {
                console.log("a4")
                setIsLoading(true);
                axiosInstance.post('/userSearchByCriteria', {
                    project_id: projectForCriteria.split("--")[1]
                })
                    .then(res => {
                        setIsLoading(false);
                        setUsersList(res.data.payload && res.data.payload.data);
                        setCopyOfUsersList(res.data.payload && res.data.payload.data);
                        isUserAvailable(res.data.payload.data);
                        setFilterText("");
                        document.getElementById("filter-user").value = ""
                    })
                    .catch(err => {

                        setSnackbarType("error");
                        setOpenSnackbar(true);
                        setMessageForSnackbar("Something went wrong!")
                    })
            }
            // setTimeout(()=> {
            //     setUsersList([]);
            //     console.log(projectForCriteria);
            //     setIsLoading(false);
            // }, 500)
        }

        else if (availableCriteria === "No Action" && (projectForCriteria === "Show All" || projectForCriteria === "") ||
            ((availableCriteria === "No Action" || availableCriteria === "") && projectForCriteria === "Show All")) {
            setUsersList([...originalUsersList]);
            setCopyOfUsersList([...originalUsersList]);
            setFilterText("");
            document.getElementById("filter-user").value = ""
        }

    }, [projectForCriteria, availableCriteria])

    useEffect(() => {
        isUserAvailable([...originalUsersList]);
    }, [usersList])

    useEffect(() => {
        if (window.mainInitAll) {
            window.mainInitAll();
        }

        onToggleMobileSidebar();

        $('.content-wrapper').on('click', function (e) {

            onToggleMobileSidebar();

        });

        const project_list = localStorage.getItem("project_list") ? JSON.parse(localStorage.getItem("project_list")) : []
        if (project_list) {
            setAssignProjectDetails({ projectsToAssign: project_list })
            setProjectListForCriteria(project_list);
        }
        setIsLoading(true);
        const token = localStorage.getItem("token");
        if (token) {
            axiosInstance.post('/userLists', {
                records: 'all'
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
                .then(res => {
                    if (res.data.status === 200) {
                        setUsersList(res.data.payload.data);
                        setOriginalUsersList(res.data.payload.data);
                        setCopyOfUsersList(res.data.payload.data);
                        // calculateTotalPages(res.data.payload.data);
                        // setTotalUsers(res.data.payload.data.length);
                        setIsLoading(false);
                        isUserAvailable(res.data.payload.data);
                    }
                })
                .catch(err => {

                    setIsLoading(false);
                    if (err.response.data.status === 422) {
                        setOpenSnackbar(openSnackbar => !openSnackbar);
                        setSnackbarType("error");
                        setMessageForSnackbar(err.response.data.message);
                    }
                    if (err.response.data.status === 500) {
                        setOpenSnackbar(openSnackbar => !openSnackbar);
                        setSnackbarType("error");
                        setMessageForSnackbar(err.response.data.message);
                    }
                })
        } else {
            props.history.push('/login')
            localStorage.clear();
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        if (usersList.length === 0) {
            setUserIdsToDelete([]);
        }
    }, [usersList])

    useEffect(() => {
        if (filterText.length > 0) {
            setUsersList(copyOfUsersList);
            const filteredUsersList = copyOfUsersList
                .filter(user =>
                    (user.firstName && user.firstName.toLowerCase().includes(filterText.trim().toLowerCase())) ||
                    (user.lastName && user.lastName.toLowerCase().includes(filterText.trim().toLowerCase()))
                    // (user.role && user.role.toLowerCase().includes(filterText.trim().toLowerCase())) ||
                    // (user.created_at && user.created_at.toLowerCase().includes(filterText.trim().toLowerCase()))
                )
            setUsersList(filteredUsersList);
        } else {
            setUsersList(copyOfUsersList);
        }
    }, [filterText])

    const onToggleMobileSidebar = () => {

        if ($('.sidebar-main').width() == 56) {

            $('.content-wrapper').addClass('content-wrapper-collapse');
        }

        if ($('body').hasClass('sidebar-mobile-main')) {
            $('body').removeClass('sidebar-mobile-main');
        }

        $('.sidebar-main').removeClass('sidebar-expand-toggle');
    }

    const isUserAvailable = (usersList) => {

        for (let i = 0; i < usersList.length; i++) {
            if (usersList[i].isEnable) {
                const userAvailabilityEl = document.getElementById(`${usersList[i].id}-is-available`)
                if (userAvailabilityEl !== null) {
                    userAvailabilityEl.checked = 1;
                }
            }
        }
    }

    const handleSnackbarClose = () => {
        setOpenSnackbar(openSnackbar => !openSnackbar);
        setSnackbarType("");
        setMessageForSnackbar("");
    }

    const handleOpenAddUserDialog = () => {
        setOpenAddUserDialog(true);
    };

    const handleCloseAddUserDialog = () => {
        resetForm();
    };

    const resetForm = () => {
        setRole("");
        setId();
        setTitle("");
        setFirstName("");
        setFinalImage(MELogo);
        setImageFile(MELogo);
        setCharactersLength(0);
        setLastName("");
        setCompanyName("");
        setEmail("");
        setCountry("");
        setState("");
        setPostalCode("");
        // setStreetNumber("");
        // setCountry("");
        // setCity("");
        setPhone("");;
        setFax("");
        setAddress("");
        setOffice("");
        setMobile("");
        setAbout("");
        setErrors({});
        setOpenDeleteUserDialog(false);
        setActions(false);
        setIsEditModeOn(false);
        setOpenAddUserDialog(false);
    }

    const addUserHandler = () => {
        if ((!firstName || firstName.trim().length === 0) ||
            (!lastName || lastName.trim().length === 0) ||
            (!mobile || mobile.trim().length === 0) ||
            // (!phone || phone.trim().length === 0) || 
            // streetNumber.trim().length===0 || 
            (!postalCode || postalCode.trim().length === 0) ||
            (country.trim().length === 0) ||
            (!address || address.trim().length === 0) ||
            (!role || role.trim().length === 0) ||
            (!title || title.trim().length === 0) ||
            (!email || email.trim().length === 0)
        ) {
            setErrors({ emptyError: "This field cannot be empty" })
            return;
        }

        else if (country === "Australia" && (!state || state.trim().length === 0)) {
            setErrors({ emptyStateError: "This field cannot be empty" })
        }

        else if (isNaN(postalCode)) {
            setErrors({ lengthError: "Postal code should be a valid number" })
            return;
        }

        else if (maxLimitReached) {
            setErrors({ limitError: "Maximum limit reached" });
            return;
        }

        else {
            setErrors({})
            setIsLoading(true);

            const formData = new FormData();

            let retrivedFile;

            if (imageFile) {

                retrivedFile = urlToFile(imageFile, `user${Math.random()}`);
            }

            if (retrivedFile) formData.append('image', retrivedFile);

            // formData.append('url', process.env.REACT_APP_DOMAIN_URL);
            formData.append('url', window.location.origin);
            formData.append('role', role);
            formData.append('title', title);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('address', address);
            formData.append('postalCode', postalCode);
            formData.append('state', state);
            formData.append('country', country);
            formData.append('office', office);
            formData.append('mobile', mobile);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('companyName', companyName);
            formData.append('fax', fax);
            formData.append('about', about);

            const token = localStorage.getItem("token");
            if (token) {
                axiosInstance.post('/registerByAdmin', formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem("token")
                    }
                })
                    .then(res => {
                        logger({
                            title:'Register By Admin',
                            url:window.location.href,
                            method:'POST',
                            activity: 'registerByAdmin',
                            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                            api:BASE_URL+ API_VERSION +'/registerByAdmin' ,
                            session:localStorage.getItem('session'),
                            projectId:null,
                            unitId:null,
                            assignedId:null,
                            })      
                        if (res.data.status === 200) {
                            setIsLoading(false);
                            setOpenSnackbar(openSnackbar => !openSnackbar);
                            setSnackbarType("success");
                            setMessageForSnackbar("Invitation link sent to user!");
                            resetForm();
                            // setUsersList(
                            //     [...usersList,
                            //         {
                            //             id: new Date().getTime(),
                            //             created_at: new Date().toLocaleString(),
                            //             firstName,
                            //             lastName,
                            //             companyName,
                            //             email,
                            //             phone,
                            //             mobile,
                            //             fax,
                            //             title,
                            //             role,
                            //             about,
                            //             office,
                            //             address
                            //             // address: {streetNumber, city, country}
                            //         }
                            //     ]
                            // )
                        }
                    })
                    .catch(err => {
                        if (err.response.data.status === 422) {
                            setIsLoading(false);
                            setOpenSnackbar(openSnackbar => !openSnackbar);
                            setSnackbarType("error");
                            setMessageForSnackbar(err.response.data && err.response.data?.payload?.error);
                        }
                        if (err.response.data.status === 401) {
                            setIsLoading(false);
                            setOpenSnackbar(openSnackbar => !openSnackbar);
                            setSnackbarType("error");
                            setMessageForSnackbar(err.response.data.message);
                            // setTimeout(()=> {
                            //     localStorage.clear();
                            //     props.history.push('/login');
                            // }, 2000);
                        }
                    })
            } else {
                props.history.push('/login')
            }
        }
    }

    const showUserDetailsHandler = (id) => {
        const userRole = localStorage.getItem('user_role');
        const userData = JSON.parse(localStorage.getItem('user_data'));
    
        const currentUser = usersList.find(user => user.id === id)
        if (userRole !== 'Admin') {
            if(userData.id === currentUser.id) {
                setIsEditModeOn(true);
            }
        }
        setRole(currentUser.role);
        setId(currentUser.id);
        setTitle(currentUser.title);
        setFirstName(currentUser.firstName);
        setLastName(currentUser.lastName);
        setCompanyName(currentUser.companyName);
        setEmail(currentUser.email);
        setPhone(currentUser.phone);
        setFax(currentUser.fax);
        setAddress(currentUser.address);
        setCountry(currentUser.country);
        setState(currentUser.state);
        setPostalCode(currentUser.postalCode);
        setImageFile(currentUser.image ? `${BASE_URL_IMAGE_USER}${currentUser.image}` : MELogo);
        setFinalImage(currentUser.image ? `${BASE_URL_IMAGE_USER}${currentUser.image}` : MELogo);
        // setStreetNumber(currentUser.address.streetNumber);
        // setCountry(currentUser.address.country);
        // setCity(currentUser.address.city);
        setOffice(currentUser.office);
        setMobile(currentUser.mobile);
        setAbout(currentUser.about);
        setOpenAddUserDialog(true);
        setActions(true);
        if (currentUser.about) {
            setCharactersLength(currentUser.about.length);
        } else {
            setCharactersLength(0);
        }

    }

    const deleteUserHandler = async (userId) => {

        setIsLoading(true);
        const deleteUserResponse =
            await axiosInstance.delete(`/userDestroy/${userId}`)
            .then(
                logger({
                    title:'Destroy User',
                    url:window.location.href,
                    method:'DELETE',
                    activity: 'userDestroy',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +`/userDestroy/${userId}` ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:null,
                    assignedId:null,
                    })      
            )
                .catch(err => {
                    setIsLoading(false);
                    setOpenSnackbar(true);
                    setMessageForSnackbar('Unable to delete user!')
                    setSnackbarType("error");
                    return;
                })

        if (deleteUserResponse && deleteUserResponse.data.status === 200) {
            setIsLoading(false);
            setOpenSnackbar(true);
            setMessageForSnackbar('User deleted successfully!')
            setSnackbarType("success");
            let updatedUsersList = [...usersList];
            updatedUsersList = updatedUsersList.filter(userDetails => userDetails.id !== userId);
            setUsersList(updatedUsersList);
            setSingleUserIdToDelete("");
            setIsSelectModeOn(false);
            setUserIdsToDelete([]);
            resetForm();
        }
    }

    const deleteMultipleUsersHandler = async (userIdsToDelete) => {
        setIsLoading(true);
        const deleteUserResponse =
            await axiosInstance.post(`/userDestroys`, { user_id: userIdsToDelete })
            .then(
                logger({
                    title:'Destroys User',
                    url:window.location.href,
                    method:'POST',
                    activity: 'userDestroys',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +`/userDestroys` ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:null,
                    assignedId:null,
                    })      
            )
                .catch(err => {
                    setIsLoading(false);
                    setOpenSnackbar(true);
                    setMessageForSnackbar('Unable to delete users!')
                    setSnackbarType("error");
                    return;
                })
        if (deleteUserResponse && deleteUserResponse.data.status === 200) {

            let updatedUsersList = [...usersList];
            for (let i = 0; i < userIdsToDelete.length; i++) {
                updatedUsersList = updatedUsersList.filter(user => user.id !== userIdsToDelete[i]);
            }
            setUsersList(updatedUsersList);
            setUserIdsToDelete([]);
            resetForm();
            setIsSelectModeOn(false);
            setIsLoading(false);
            setOpenSnackbar(true);
            setMessageForSnackbar('Users deleted successfully')
            setSnackbarType("success");

        }
    }

    const updateUserDetailsHandler = id => {
        if (
            (!firstName || firstName.trim().length === 0) ||
            (!lastName || lastName.trim().length === 0) ||
            (!mobile || mobile.trim().length === 0) ||
            // (!phone || phone.trim().length === 0) ||
            (!address || address.trim().length === 0) ||
            (!postalCode || postalCode.trim().length === 0) ||
            (country.trim().length === 0) ||
            // streetNumber.trim().length === 0 || 
            // city.trim().length === 0 || 
            // country.trim().length === 0 || 
            (!role || role.trim().length === 0) ||
            (!title || title.trim().length === 0) ||
            (!email || email.trim().length === 0)
        ) {

            setErrors({ emptyError: "This field cannot be empty" })
            return;

        }

        else if (country === "Australia" && (!state || state.trim().length === 0)) {
            setErrors({
                emptyStateError: "This field cannot be empty"
            })
        }


        else if (isNaN(postalCode)) {
            setErrors({
                lengthError: "Postal code should be a valid number"
            })
            return;
        }

        else if (maxLimitReached) {
            setErrors({ limitError: "Maximum limit reached" })
            return;
        }

        else {
            setErrors({})
            const token = localStorage.getItem("token");
            if (token) {
                setIsLoading(true);
                const index = usersList.findIndex(user => user.id === id)
                usersList[index] = {
                    id,
                    role,
                    title,
                    firstName,
                    lastName,
                    address,
                    postalCode,
                    state,
                    country,
                    // address: {streetNumber,city,country},
                    office,
                    mobile,
                    phone,
                    email,
                    image: imageFile,
                    companyName,
                    fax,
                    about
                }
                const updatedUserDetailsForm = new FormData();

                let retrivedFile;

                if (imageFile) {
                    retrivedFile = urlToFile(imageFile, `user${Math.random()}`);
                }

                if (retrivedFile) updatedUserDetailsForm.append('image', retrivedFile);

                for (let key in usersList[index]) {

                    if (usersList[index][key] && usersList[index][key] !== "null") {

                        if (key === "phone" && !(!!usersList[index][key])) {
                            continue;
                        } else if (key !== 'image') {
                            updatedUserDetailsForm.append(key, usersList[index][key]);
                        }
                    }
                }

                axiosInstance.post(`/user/${id}`, updatedUserDetailsForm, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                })
                    .then(res => {
                        logger({
                            title:'Update User',
                            url:window.location.href,
                            method:'POST',
                            activity: 'Update User',
                            user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                            api:BASE_URL+ API_VERSION +`/user/${id}`,
                            session:localStorage.getItem('session'),
                            projectId:null,
                            unitId:null,
                            assignedId:null,
                            })              
                        setIsLoading(false);
                        setOpenSnackbar(true);
                        setMessageForSnackbar("User details updated successfully!");
                        setSnackbarType("success");
                        setUsersList(usersList);
                        setIsEditModeOn(false);
                        resetForm();
                        setOpenAddUserDialog(false);

                    })
                    .catch(err => {
                        if (err.response.data.status === 422) {
                            setIsLoading(false);
                            setOpenSnackbar(openSnackbar => !openSnackbar);
                            setSnackbarType("error");
                            setMessageForSnackbar(err.response.data && err.response.data?.payload?.error);
                        }
                        if (err.response.data.status === 500) {
                            setIsLoading(false);
                            setOpenSnackbar(openSnackbar => !openSnackbar);
                            setSnackbarType("error");
                            setMessageForSnackbar(err.response.data.message);
                        }
                    })
            } else {
                props.history.push('/login')
            }
        }
    }

    const handleDeleteMultipleUserDialog = () => {
        setOpenDeleteUserDialog(deleteDialog => !deleteDialog)
    }

    const handleDeleteUserDialog = () => {
        setOpenDeleteUserDialog(deleteDialog => !deleteDialog)
    }

    // const handleAllCheckboxesForDeletingUser = (event) => {
    //     const allCheckboxElements = document.querySelectorAll('.delete-user');
    //     if(event.target.checked){
    //         let userIds = [];
    //         allCheckboxElements.forEach((checkboxElement,index) => {
    //             checkboxElement.checked = true
    //             userIds.push(usersList[index].id);
    //         })
    //         setUserIdsToDelete(userIds);

    //     }else{
    //         for (let i = 0; i < allCheckboxElements.length; i++) {
    //             allCheckboxElements[i].checked = false
    //         }
    //         setUserIdsToDelete([]);
    //     }
    // }

    const handleCheckboxForDeletingUser = (event, user) => {
        if (event.target.checked) {
            setUserIdsToDelete([...userIdsToDelete, user.id]);
        } else {
            const updatedListOfUserIdsToDelete = userIdsToDelete.filter(id => id !== user.id)
            setUserIdsToDelete(updatedListOfUserIdsToDelete);
        }
    }

    const onSelectAllUsersHandler = () => {
        const allCheckboxElements = document.querySelectorAll('.delete-user');
        let userIds = [];
        allCheckboxElements.forEach((checkboxElement, index) => {
            checkboxElement.checked = true
            userIds.push(usersList[index].id);
        })
        setUserIdsToDelete(userIds);
    }

    const onCancelUserSelectionButtonHandler = () => {
        const allCheckboxElements = document.querySelectorAll('.delete-user');
        for (let i = 0; i < allCheckboxElements.length; i++) {
            allCheckboxElements[i].checked = false
        }
        setUserIdsToDelete([]);
        setIsSelectModeOn(false);
    }

    const handleCheckboxForAvailabilityOfUser = (e, userId) => {
        const userIndex = usersList.findIndex(user => user.id === userId);
        if (userIndex !== -1) {
            if (e.target.checked) {
                setIsLoading(true);
                usersList[userIndex].isEnable = 1;
                setUsersList(usersList);
                updateUserAvailability(1, userId);
            }
            else {
                setIsLoading(true);
                usersList[userIndex].isEnable = 0;
                setUsersList(usersList);
                updateUserAvailability(0, userId);
            }
        }
    }

    const updateUserAvailability = (isEnable, userId) => {
        const token = localStorage.getItem("token");
        if (token) {
            setIsLoading(true);
            axiosInstance.post(`/user/${userId}`, {
                isEnable
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
                .then(res => {
                    logger({
                        title:'Update User Availability',
                        url:window.location.href,
                        method:'POST',
                        activity: 'updateUserAvailability',
                        user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                        api:BASE_URL+ API_VERSION +`/user/${id}`,
                        session:localStorage.getItem('session'),
                        projectId:null,
                        unitId:null,
                        assignedId:null,
                        })              
                    setIsLoading(false);
                    setOpenSnackbar(true);
                    setMessageForSnackbar("Active status updated successfully!");
                    setSnackbarType("success");
                })
                .catch(err => {
                    setIsLoading(false);
                    setOpenSnackbar(true);
                    setMessageForSnackbar("Something went wrong while updating user active status!");
                    setSnackbarType("error");
                })
        } else {
            props.history.push('/login')
        }
    }

    const onDeactiveSelectedUsers = async () => {

        const token = localStorage.getItem("token");
        if (token) {
            setIsLoading(true);
            const response = await
                axiosInstance.post('/userDeactives', { user_ids: userIdsToDelete })
                .then(
                    logger({
                        title:'Deactives User',
                        url:window.location.href,
                        method:'POST',
                        activity: 'userDeactives',
                        user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                        api:BASE_URL+ API_VERSION +`/userDeactives`,
                        session:localStorage.getItem('session'),
                        projectId:null,
                        unitId:null,
                        assignedId:null,
                        })              
                )
                    .catch(err => {
                        setIsLoading(false);
                        setOpenSnackbar(true);
                        setMessageForSnackbar("Something went wrong while de-activating users!");
                        setSnackbarType("error");
                        setSingleUserIdToDelete("");
                        setIsSelectModeOn(false);
                        setUserIdsToDelete([]);
                        resetForm();
                        setBulkAction("");

                    })

            if (response) {
                const users = [...usersList];

                for (let userId of userIdsToDelete) {

                    const userIndex = users.findIndex(user => user.id === userId);
                    if (userIndex !== -1) {
                        const el = document.getElementById(`${userId}-is-available`)
                        if (el.checked) {
                            el.checked = false;
                            users[userIndex].isEnable = 0;
                        }
                    }
                }

                setUsersList(users);
                setIsLoading(false);
                setOpenSnackbar(true);
                setMessageForSnackbar("Users deactivated successfully!");
                setSnackbarType("success");
                setSingleUserIdToDelete("");
                setIsSelectModeOn(false);
                setUserIdsToDelete([]);
                resetForm();
                setBulkAction("");

            }
        }
        else {
            localStorage.clear();
            props.history.push('/login');
        }
    }

    const onOpenUserProjectListDialog = (user) => {
        setAssignProjectDetails({ ...assignProjectDetails, user })
        setUserProjectList(usersProjectList.find(project => project.userId === user.id));
        setOpenUserProjectListDialog(true);

    }

    const onCloseUserProjectListDialog = () => {
        setOpenUserProjectListDialog(false);
        setUserProjectList({});
        const updatedProjectAssignDetails = { ...assignProjectDetails }
        delete updatedProjectAssignDetails.user
        setAssignProjectDetails({ updatedProjectAssignDetails })
    }

    const onOpenAssignProjectDialog = (user) => {
        console.log("user", user)
        setAssignProjectDetails({ ...assignProjectDetails, user })
        const projectsAlreadyAssignedToUser = [];

        for (let project of assignProjectDetails.projectsToAssign) {
            let tempAssignedProject = {};
            for (let assigned of user.assigned) {
                tempAssignedProject = { ...project }
                if (assigned.projectId === project.id) {
                    projectsAlreadyAssignedToUser.push(tempAssignedProject)
                    break;
                }
            }
        }
        setProjectsAssignedToUser(projectsAlreadyAssignedToUser);
        // setTimeout(() => {
        setOpenAssignProjectDialog(true);
        // }, 500);
    }

    const onCloseAssignProjectDialog = () => {
        setOpenAssignProjectDialog(false);
        setProjectsAssignedToUser([]);
        setSingleUserIdToDelete("");
        setIsSelectModeOn(false);
        setUserIdsToDelete([]);
        resetForm();
        setBulkAction("");
    }

    const onSelectProjectForUser = (project) => {
        if (projectsAssignedToUser.findIndex(projectDetails => projectDetails.id === project.id) === -1) {
            setProjectsAssignedToUser([...projectsAssignedToUser, project])
        } else {
            setProjectsAssignedToUser(projectsAssignedToUser.filter(projectDetails => projectDetails.id !== project.id));
        }
    }

    const onSaveAssignedProjectsToUser = async (assignProjectUserDetails) => {
        const assignedProjectIds = projectsAssignedToUser.map(({ id }) => id);
        let originalAssignedIds = assignProjectUserDetails.assigned.map(({ projectId }) => projectId);
        let newAssignedIds = assignedProjectIds.filter(id => originalAssignedIds.indexOf(id) === -1);
        let cancelledIds = originalAssignedIds.filter(id => assignedProjectIds.indexOf(id) === -1);

        if (newAssignedIds.length !== 0 || cancelledIds.length !== 0) {
            setIsLoading(true);

            const response = await axiosInstance.post('/projectAssign', {
                user_ids: [assignProjectUserDetails.id],
                project_ids: newAssignedIds,
                cancelled_ids: cancelledIds
            })
            .then(
                logger({
                    title:'Assign Project',
                    url:window.location.href,
                    method:'POST',
                    activity: 'projectAssign',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +`/projectAssign`,
                    session:localStorage.getItem('session'),
                    projectId:newAssignedIds[0],
                    unitId:null,
                    assignedId:assignProjectUserDetails.id,
                    })              
            )
                .catch(err => {
                    setIsLoading(false);
                    if (err.response.data.status === 422) {
                        setOpenSnackbar(openSnackbar => !openSnackbar);
                        setSnackbarType("error");
                        setMessageForSnackbar(err.response.data.message);
                    }
                })
            if (response && response.data.status === 200) {
                setIsLoading(false);
                setOpenAssignProjectDialog(false);
                // const tempUserProjectList = JSON.parse(JSON.stringify(userProjectList));
                // tempUserProjectList.projects = [...projectsAssignedToUser];
                setOpenSnackbar(openSnackbar => !openSnackbar);
                setSnackbarType("success");
                setMessageForSnackbar(response.data.message);
                // setUserProjectList(tempUserProjectList);
                setProjectsAssignedToUser([]);
                let userslist = [...usersList];
                let cidx = userslist.findIndex(user => user.id === assignProjectUserDetails.id);
                if (cidx >= 0) {
                    let assigned = response.data.payload?.data || [[]];
                    userslist[cidx].assigned = assigned[0];
                    setUsersList(userslist);
                }
            }
        } else {
            // no changes
            // setOpenSnackbar(true);
            // setSnackbarType("info");
            // setMessageForSnackbar("Select atleast one project to assign!");
        }
    }

    const onBulkActionSelected = bulkAction => {
        setBulkAction(bulkAction);
        switch (bulkAction) {
            case "Delete":
                handleDeleteMultipleUserDialog();
                break;

            case "Assign Projects":
                setAssignProjectDetails({ ...assignProjectDetails });
                setOpenAssignProjectDialog(true);
                break;

            case "Deactivate":
                onDeactiveSelectedUsers();
                break;

            case "No Action":
                setIsSelectModeOn(false);
                setUserIdsToDelete([]);
                break;
            default:
                // alert(bulkAction)
                break;
        }
    }

    const onCloseAddressSelectionDialog = () => {
        setOpenAddressSelectionDialog(false);
        setAddressSelectionValue("");
        setAddressSelectionType("");
    }

    const onConfirmAddressSelection = () => {

        if (addressSelectionValue.trim().length === 0) {
            setSnackbarType("info");
            setOpenSnackbar(true);
            setMessageForSnackbar(`Please enter valid ${addressSelectionType}!`)
            return;
        }

        setIsLoading(true);
        axiosInstance.post('/userSearchByCriteria', {
            criteria: addressSelectionType,
            criteria_value: addressSelectionValue
        })
            .then(res => {
                setIsLoading(false);
                setUsersList(res.data.payload && res.data.payload.data);
                setCopyOfUsersList(res.data.payload && res.data.payload.data);
                setFilterText("");
                setOpenAddressSelectionDialog(false);
                setAddressSelectionValue("");
                setAddressSelectionType("");
                document.getElementById("filter-user").value = ""
            })
            .catch(err => {
                setSnackbarType("error");
                setOpenSnackbar(true);
                setMessageForSnackbar("Something went wrong!")
            })
    }

    const handleImageSave = (newImg) => {

        setImageFile(newImg);
        setFinalImage(newImg);
        setOpenImageCropper(false);
    }

    const handleImageDiscard = () => {

        setOpenImageCropper(false);
        setFinalImage(imageFile);
    }

    const checkForPermission = () => {
        return userContextData.user && userContextData.user.role && [USER_ROLES.ADMIN, USER_ROLES.PM, USER_ROLES.UM, USER_ROLES.MA].indexOf(userContextData.user.role) != -1
    }

    return (
        <div className="page-content">
            <Menubar />
            <div className="content-wrapper">
                <div className="page-header page-header-light">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Home</span> - Agents</h4>
                        </div>
                    </div>

                    <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                        <div className="d-flex">
                            <div className="breadcrumb">
                                <a href=" " className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</a>
                                <span className="breadcrumb-item active">Agents</span>
                            </div>

                            <a href=" " className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="row">
                                <AddUser
                                    handleOpenAddUserDialog={handleOpenAddUserDialog}
                                    errors={errors}
                                    openAddUserDialog={openAddUserDialog}
                                    handleCloseAddUserDialog={handleCloseAddUserDialog}
                                    id={id}
                                    handleImageDiscard={handleImageDiscard}
                                    handleImageSave={handleImageSave}
                                    email={email}
                                    setImageFile={setImageFile}
                                    setFinalImage={setFinalImage}
                                    setOpenImageCropper={setOpenImageCropper}
                                    openImageCropper={openImageCropper}
                                    imageFile={imageFile}
                                    finalImage={finalImage}
                                    setEmail={setEmail}
                                    firstName={firstName}
                                    lastName={lastName}
                                    companyName={companyName || ""}
                                    role={role}
                                    phone={phone || ""}
                                    mobile={mobile}
                                    fax={fax || ""}
                                    title={title}
                                    address={address || ""}
                                    country={country || ""}
                                    state={state || ""}
                                    postalCode={postalCode || ""}
                                    // city={city}
                                    // streetNumber={streetNumber}
                                    about={about}
                                    setFirstName={setFirstName}
                                    setLastName={setLastName}
                                    setCompanyName={setCompanyName}
                                    setRole={setRole}
                                    setPhone={setPhone}
                                    setMobile={setMobile}
                                    setFax={setFax}
                                    setTitle={setTitle}
                                    setAddress={setAddress}
                                    setCountry={setCountry}
                                    setState={setState}
                                    setPostalCode={setPostalCode}
                                    // setCity={setCity}
                                    // setStreetNumber={setStreetNumber}
                                    setAbout={setAbout}
                                    actions={actions}
                                    isEditModeOn={isEditModeOn}
                                    setIsEditModeOn={setIsEditModeOn}
                                    addUserHandler={addUserHandler}
                                    updateUserDetailsHandler={updateUserDetailsHandler}
                                    charactersLength={charactersLength}
                                    setCharactersLength={setCharactersLength}
                                    setMaxLimitReached={setMaxLimitReached}
                                    maxCount={maxCount}
                                    onOpenUserProjectListDialog={onOpenUserProjectListDialog}
                                />
                         

                        {/* <div className="col-12 d-flex justify-content-center">                        
                            <Pagination 
                                onChange={onPageChange}
                                count={10} 
                                color="primary" 

                            />                                    
                        </div> */}

                        <div className="col-12 table-container" style={{ overflowX: "auto" }}>

                            <div className="row">

                                <div className="col-md-6">

                                    <FilterUsers
                                        setFilterText={setFilterText}
                                        availableCriteria={availableCriteria}
                                        setAvailableCriteria={setAvailableCriteria}
                                        projectForCriteria={projectForCriteria}
                                        setProjectForCriteria={setProjectForCriteria}
                                        projectListForCriteria={projectListForCriteria}
                                    />

                                </div>

                                <div className="col-md-6">
                                    {
                                        usersList && usersList.length > 0 && checkForPermission() ?

                                            <UserActions
                                                setIsSelectModeOn={setIsSelectModeOn}
                                                isSelectModeOn={isSelectModeOn}
                                                onBulkActionSelected={onBulkActionSelected}
                                                userIdsToDelete={userIdsToDelete}
                                                deleteMultipleUsersHandler={deleteMultipleUsersHandler}
                                                onCancelUserSelectionButtonHandler={onCancelUserSelectionButtonHandler}
                                                onSelectAllUsersHandler={onSelectAllUsersHandler}
                                            />

                                            :

                                            null
                                    }

                                </div>

                            </div>

                            <div className="row">
                                {usersList.map(user => (
                                    <div className="col-lg-4 col-md-6 col-sm-6" key={user.id}>
                                        <UserCard
                                            checkForPermission={() => checkForPermission()}
                                            userData={userContextData.user}
                                            key={user.id}
                                            user={user}
                                            setSingleUserIdToDelete={setSingleUserIdToDelete}
                                            showUserDetailsHandler={showUserDetailsHandler}
                                            handleDeleteUserDialog={handleDeleteUserDialog}
                                            setIsSelectModeOn={setIsEditModeOn}
                                            isSelectModeOn={isSelectModeOn}
                                            handleCheckboxForDeletingUser={handleCheckboxForDeletingUser}
                                            handleCheckboxForAvailabilityOfUser={handleCheckboxForAvailabilityOfUser}
                                            onOpenUserProjectListDialog={onOpenUserProjectListDialog}
                                            onOpenAssignProjectDialog={onOpenAssignProjectDialog}
                                        />
                                    </div>
                                ))
                                }
                            </div>

                            <DeleteUserDialog
                                userIdsToDelete={userIdsToDelete}
                                userIdToDelete={singleUserIdToDelete}
                                openDeleteUserDialog={openDeleteUserDialog}
                                handleDeleteUserDialog={handleDeleteUserDialog}
                                deleteMultipleUsersHandler={deleteMultipleUsersHandler}
                                deleteUserHandler={deleteUserHandler}
                            />

                            <UserProjectListDialog
                                openUserProjectListDialog={openUserProjectListDialog}
                                onCloseUserProjectListDialog={onCloseUserProjectListDialog}
                                userProjectList={userProjectList}
                                onOpenAssignProjectDialog={onOpenAssignProjectDialog}
                            />

                            <AssignProjectDialog
                                openAssignProjectDialog={openAssignProjectDialog}
                                onCloseAssignProjectDialog={onCloseAssignProjectDialog}
                                assignProjectDetails={assignProjectDetails}
                                projectsAssignedToUser={projectsAssignedToUser}
                                onSelectProjectForUser={onSelectProjectForUser}
                                onSaveAssignedProjectsToUser={onSaveAssignedProjectsToUser}
                                userIds={userIdsToDelete}
                            />

                            <AddressSelectionDialog
                                addressSelectionType={addressSelectionType}
                                addressSelectionValue={addressSelectionValue}
                                openAddressSelectionDialog={openAddressSelectionDialog}
                                onCloseAddressSelectionDialog={onCloseAddressSelectionDialog}
                                setAddressSelectionValue={setAddressSelectionValue}
                                onConfirmAddressSelection={onConfirmAddressSelection}
                            />

                            <SnackbarToast
                                handleSnackbarClose={handleSnackbarClose}
                                openSnackbar={openSnackbar}
                                snackbarType={snackbarType}
                                message={messageForSnackbar}
                            />

                            <LoadingSpinner isLoading={isLoading} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users
