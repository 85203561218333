import { AppBar, Button, Dialog, Divider, IconButton, List, ListItem, ListItemText, makeStyles, Slide, Toolbar, Typography, Collapse, TextField, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';
import { CheckCircle, Close, KeyboardArrowDown } from '@material-ui/icons';
import React, { useState } from 'react';
import clsx from 'clsx';
import { lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#263238',
        paddingRight: "0px !important"
    },
    title: {
        fontSize:"1rem",
        flex: 1,
    },
    assignButton: {
        color: "white",
        padding: "3px 10px",
        fontSize: "0.7rem",
        backgroundColor: "#26A69A",
        '&:hover': {
        backgroundColor: "#26A69A"
        }
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: "100%",
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//asc, floorPlan, 
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [

    { id: 'image.substring(21)', numeric: false, disablePadding: true, label: 'File Name' },
    { id: 'created_at', numeric: false, disablePadding: true, label: 'Upload Date' },
    { id: 'fileSize', numeric: false, disablePadding: true, label: 'Size' },
    
]

function EnhancedTableHead(props) {

  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
       {headCells.map((headCell) => (
          <TableCell
            style={{background: "#B1DFDC"}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            > */}
              <span>{headCell.label}</span>

              {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}

        <TableCell align="right" style={{padding:"0 15px", background: "#B1DFDC"}}>
    
          <Checkbox
    
            indeterminate={numSelected > 0 && numSelected < rowCount}
    
            checked={rowCount > 0 && numSelected === rowCount}
    
            onChange={onSelectAllClick}
    
            inputProps={{ "aria-label": "select all desserts" }}
    
          />
    
        </TableCell>

      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {

  const classes = useToolbarStyles();

  const [openDeletePanoramaDialog, setOpenDeletePanoramaDialog] = useState(false);
  const { numSelected, selected, onDeletePanoFilesHandler,onExtractPanoFilesHandler, setSelected } = props;

  return (

    <Toolbar
    
      className={clsx(classes.root, {
    
        [classes.highlight]: numSelected > 0,
    
      })}
    
    >
    
      {numSelected > 0 ? 
      
        (
    
        <Typography
    
          className={classes.title}
    
          color="inherit"
    
          variant="subtitle1"
    
          component="div"
    
        >
    
          {numSelected} selected
    
        </Typography>
    
        ) : 
        
        null
    
      }

      {numSelected > 0 ? (
      
        <React.Fragment>

        <Button 

          onClick={() => { onExtractPanoFilesHandler(selected); setSelected([]); }}

          className={` mr-3`} 

          variant="contained" 

          component="span"
  
          autoFocus>

        Extract

        </Button>

            <Tooltip arrow title="Delete">
        
            <IconButton onClick={() => setOpenDeletePanoramaDialog(true)} aria-label="delete">
        
                <DeleteIcon />
        
            </IconButton>
        
            </Tooltip>

            <Dialog
        
                open={openDeletePanoramaDialog}
            
                onClose={() => setOpenDeletePanoramaDialog(false)}
            
                aria-labelledby="alert-dialog-title"
            
                aria-describedby="alert-dialog-description"
        
            >

                <DialogTitle>Delete Panorama File/s</DialogTitle>
                <DialogContent>
            
                    <DialogContentText id="alert-dialog-description">
            
                    Do you want to perform this action? It cannot be undone.
            
                    </DialogContentText>
            
                </DialogContent>
            
                <DialogActions>
            
                    <Button
            
                    onClick={() => setOpenDeletePanoramaDialog(false)}
            
                    color="primary"
            
                    >
            
                    No
            
                    </Button>
            
                    <Button
            
                    onClick={() => { onDeletePanoFilesHandler(selected); setSelected([]); setOpenDeletePanoramaDialog(false); }}

                    color="primary"
            
                    autoFocus
            
                    >
            
                    Yes
            
                    </Button>
            
                </DialogActions>
            
            </Dialog>

        </React.Fragment>
        
      
      ) : null }
    
    </Toolbar>
  
  );

};

function UploadPanoDialog({
    toggleUploadPanoDialog,
    onCloseUploadPanoDialog,
    panoramaList,
    onUploadPanoFile,
    onDeletePanoFilesHandler,
    onExtractPanoFilesHandler
}) {

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = panoramaList.map((n) => n.id);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, panoramaList.length - page * rowsPerPage);

    return (
        <Dialog maxWidth="sm" fullWidth open={toggleUploadPanoDialog} onClose={onCloseUploadPanoDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar style={{paddingLeft:"16px", paddingRight:"0px"}}>
                <Typography variant="h6" className={classes.title}>
                360 PANORAMAS 
                </Typography>
                { panoramaList && selected.length === 0 ?
                    <>

                      <input multiple onChange={(e) => onUploadPanoFile(e,true)} style={{display: "none"}} type="file" accept=".jpg" id="uploadPanoramasave"/>
                        <label style={{marginBottom: "0px"}} htmlFor="uploadPanoramasave">
                            <Button
                                className={`${classes.assignButton} mr-3`} 
                                // onClick={() => onAssignForUnitNumberHandler()} 
                                variant="contained" 
                                component="span"
                            >
                               Upload And Extract
                            </Button>
                        </label>

                        <input multiple onChange={(e) => onUploadPanoFile(e,false)} style={{display: "none"}} type="file" accept=".jpg" id="uploadPanorama"/>
                        <label style={{marginBottom: "0px"}} htmlFor="uploadPanorama">
                            <Button
                                className={`${classes.assignButton} mr-3`} 
                                // onClick={() => onAssignForUnitNumberHandler()} 
                                variant="contained" 
                                component="span"
                            >
                                Upload
                            </Button>
                        </label>

                    </>
                    : null 
                }
                <IconButton edge="start" color="inherit" onClick={onCloseUploadPanoDialog} aria-label="close">
                    <Close />
                </IconButton>            
            </Toolbar>
            </AppBar>
            <List>
                {
                    panoramaList && panoramaList.length===0 ? 
                        <ListItem>
                            <ListItemText primary={`No file uploaded yet.`}/>
                        </ListItem>
                    :
                
                    (panoramaList && panoramaList.length > 0) ?

                        <React.Fragment> 
                            
                           {selected.length > 0 && <EnhancedTableToolbar onDeletePanoFilesHandler={onDeletePanoFilesHandler} onExtractPanoFilesHandler={onExtractPanoFilesHandler} setSelected={setSelected} selected={selected} numSelected={selected.length} /> }
                               
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={panoramaList.length}
                                    />
                                    <TableBody>
                                        {stableSort(panoramaList, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {

                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    
                                                    hover
                                                                                                     
                                                    role="checkbox"

                                                    aria-checked={isItemSelected}

                                                    tabIndex={-1}

                                                    key={row.id}

                                                    selected={isItemSelected}
                                                >

                                                    <TableCell 
                                                        id={labelId}

                                                        // onClick={() => onSelectUserForUnitNumber(row.id)} 
                                                    >
                                                        <div className={`d-flex align-items-center`}>
                                                            
                                                            <span style={{cursor: "pointer"}}>{row.image}</span>

                                                        </div>
                                                    </TableCell>

                                                    <TableCell 
                                                        // onClick={() => onSelectUserForUnitNumber(row.id)} 
                                                    >
                                                        <div className={`d-flex align-items-center`}>
                                                            
                                                            <span>{moment(row.created_at).format('DD-MM-YYYY')}</span>

                                                        </div>
                                                    </TableCell>

                                                    <TableCell 
                                                        // onClick={() => onSelectUserForUnitNumber(row.id)} 
                                                    >
                                                        <div className={`d-flex align-items-center`}>
                                                            
                                                            <span>{row.fileSize}</span>

                                                        </div>
                                                    </TableCell>

                                                    <TableCell align="right" style={{padding:"0 15px"}}>

                                                        <Checkbox

                                                            onClick={(event) => handleClick(event, row.id)}

                                                            checked={isItemSelected}

                                                            inputProps={{ "aria-labelledby": labelId }}

                                                        />

                                                    </TableCell>
                                                    
                                                </TableRow>
                                            );
                                        })}                                    
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination

                                rowsPerPageOptions={[5, 10, 25]}

                                component="div"

                                count={panoramaList.length}

                                rowsPerPage={rowsPerPage}

                                page={page}

                                onChangePage={handleChangePage}

                                onChangeRowsPerPage={handleChangeRowsPerPage}

                            />     

                        </React.Fragment>

                    : null
                }
            </List>
      </Dialog>
    )
}

export default UploadPanoDialog
