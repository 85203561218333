import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import UpgradeProjectPlanStripeCheckoutForm from './UpgradeProjectPlanStripeCheckoutForm';
import '../../../../Assets/css/StripeElements.css';

const promise = loadStripe("pk_test_oG9aW1uQYaVeNUY9QSKu65tO0065H5XYfs");

export default function UpgradeProjectPlanModal({ openUpgradeProjectPlanModal, title, onUpdateSuccess, projectId, closeDialog }) {

    return (
      <Elements stripe={promise}>
        <Dialog disableBackdropClick aria-labelledby="simple-dialog-title" open={ openUpgradeProjectPlanModal } onClose={closeDialog}>
          <DialogTitle id="simple-dialog-title" className="upgradeProjectPlan">Upgrade your project plan</DialogTitle>
            <DialogContent style={{ minWidth: 320 }} >
              <UpgradeProjectPlanStripeCheckoutForm title={title} onUpdateSuccess={onUpdateSuccess} projectId={projectId} closeDialog={closeDialog}/>
            </DialogContent>
        </Dialog>
      </Elements>
    );
  };
  
