import { makeStyles } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import Menubar from '../Menubar/Menubar'
import LoadingSpinner from '../../utils/LoadingSpinner';
import { usersProjectList } from '../../DummyData/userProjectList';
import $ from 'jquery';
import MELogo from '../../Assets/img/ME-logo.svg'
import { urlToFile } from '../../utils/file';
import { API_VERSION, BASE_URL, BASE_URL_IMAGE_USER } from '../../Axios/api';
import { AuthContext } from '../../Context/userContext';
import { USER_ROLES } from '../../utils/const';
import logger from '../../utils/logger';
import AddUser from '../Users/AddUser';
import DeleteUserDialog from '../Users/DeleteUserDialog';
import FilterUsers from '../Users/FilterUsers';
import UserCard from '../Users/UserCard';
import UserActions from '../Users/UserActions';
import axiosInstance from '../../Axios/axios';
import SnackbarToast from '../../utils/SnackbarToast';
import AssignProjectDialog from '../Users/AssignProjectDialog';
import UserProjectListDialog from '../Users/UserProjectListDialog';
import AddressSelectionDialog from '../Users/AddressSelectionDialog';
import ActivityCard from './activityCard';
import FilterActivity from './filterActivity';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({

    actionEdit: {
        textAlign: "right",
        [theme.breakpoints.down(576)]: {
            textAlign: "center"
        }
    },

    actionDelete: {
        textAlign: "left",
        [theme.breakpoints.down(576)]: {
            textAlign: "center"
        }
    },

    userActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }

}));

function Activity(props) {

    const [logList, setLogList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activityUserFilter, setActivityUserFilter] = useState("");
    const [soldByFilter, setSoldByFilter] = useState("");
    const [soldByActivityFilter, setSoldByActivityFilter] = useState("");
    const [activityDateFromFilter, setActivityDateFromFilter] = useState();
    const [activityDateToFilter, setActivityDateToFilter] = useState();
    const [soldListUsers, setSoldListUsers] = useState([]);
    const [activityUserFilterList, setActivityUserFilterList] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        if (token) {
            axiosInstance.post('/userLists', {
                records: 'all'
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
                .then(res => {
                    if (res.data.status === 200) {
                        setSoldListUsers(res.data.payload.data)
                        setIsLoading(false);
                    }
                })
                .catch(err => {
                    setIsLoading(false);
                })

                axiosInstance.get('/activityUserFilterList', {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                })
                    .then(res => {
                        if (res.data.status === 200) {
                            setActivityUserFilterList(res.data.payload.data)
                            setIsLoading(false);
                        }
                    })
                    .catch(err => {
                        setIsLoading(false);
                    })
                
        } else {
            props.history.push('/login')
            localStorage.clear();
            setIsLoading(false);
        }
    }, [])
    
    useEffect(() => {
        console.log('activityDateFromFilter: ', activityDateFromFilter);
        console.log('activityDateToFilter: ', activityDateToFilter);
        setIsLoading(true);
        const token = localStorage.getItem("token");
        if (token) {
            axiosInstance.post('/logList',{
                activityUserFilter: activityUserFilter ? activityUserFilter : 'Show All',
                soldByFilter: soldByFilter ? soldByFilter : 'Show All',
                soldByActivityFilter: soldByActivityFilter ? soldByActivityFilter : 'Show All',
                activityDateFromFilter: activityDateFromFilter ? moment(activityDateFromFilter).format('YYYY-MM-DD HH:mm') : '',
                activityDateToFilter: activityDateToFilter ?  moment(activityDateToFilter).format('YYYY-MM-DD HH:mm') : ''
            }, 
            {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
                .then(res => {
                    if (res.data.status === 200) {
                        setLogList(res.data.payload.data)
                        setIsLoading(false);
                    }
                })
                .catch(err => {
                    setIsLoading(false);
                })
        } else {
            props.history.push('/login')
            localStorage.clear();
            setIsLoading(false);
        }
    }, [activityUserFilter,soldByFilter,soldByActivityFilter,activityDateFromFilter,activityDateToFilter])

    useEffect(() => {
        if (window.mainInitAll) {
            window.mainInitAll();
        }

        onToggleMobileSidebar();

        $('.content-wrapper').on('click', function (e) {

            onToggleMobileSidebar();

        });
    }, [])

    const onToggleMobileSidebar = () => {

        if ($('.sidebar-main').width() == 56) {

            $('.content-wrapper').addClass('content-wrapper-collapse');
        }

        if ($('body').hasClass('sidebar-mobile-main')) {
            $('body').removeClass('sidebar-mobile-main');
        }

        $('.sidebar-main').removeClass('sidebar-expand-toggle');
    }

    return (
        <div className="page-content">
            <Menubar />
            <div className="content-wrapper">
                <div className="page-header page-header-light">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Home</span> - Activity</h4>
                        </div>
                    </div>

                    <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                        <div className="d-flex">
                            <div className="breadcrumb">
                                <a href=" " className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</a>
                                <span className="breadcrumb-item active">Activity</span>
                            </div>

                            <a href=" " className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                    </div>
                </div>

                <div className="content" style={{ height:"100vh"}}>
                    <div className="row">

                        <div className="col-12 table-container">

                            <div className="row">

                                <div className="col-md-12">

                                    <FilterActivity
                                        setActivityUserFilter={setActivityUserFilter}
                                        activityUserFilter={activityUserFilter}
                                        setSoldByFilter={setSoldByFilter}
                                        soldByFilter={soldByFilter}
                                        soldListUsers={soldListUsers}
                                        activityUserFilterList={activityUserFilterList}
                                        setSoldByActivityFilter={setSoldByActivityFilter}
                                        soldByActivityFilter={soldByActivityFilter}
                                        setActivityDateFromFilter={setActivityDateFromFilter}
                                        setActivityDateToFilter={setActivityDateToFilter}
                                        activityDateFromFilter={activityDateFromFilter}
                                        activityDateToFilter={activityDateToFilter}
                                    />

                                </div>

                            </div>
                            <div className="row">
                                {logList.map(activity => (
                                    <div className="col-lg-4 col-md-6 col-sm-6" key={activity.id}>

                                        <ActivityCard
                                            key={activity.id}
                                            activity={activity}
                                        />
                                    </div>
                                ))
                                }
                            </div>

                            <LoadingSpinner isLoading={isLoading} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activity
