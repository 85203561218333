import React from 'react'
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@material-ui/core'
import { countryList } from '../../DummyData/countryList'
import { australianStateList } from '../../DummyData/australianStateList'
import { deepPurple } from '@material-ui/core/colors';
import ImageCrop from '../UI/ImageCrop';

const useStyles = makeStyles((theme) => ({
	large: {
		width: 50,
		height: 50,
		objectFit:'contain'
	},

    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	}
}));

function AddUser({
    handleImageDiscard,
    handleImageSave,
    setOpenImageCropper,
    openImageCropper,
    handleOpenAddUserDialog,
    openAddUserDialog,
    handleCloseAddUserDialog,
    actions,
    isEditModeOn,
    errors,
    id,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    companyName,
    setCompanyName,
    fax,
    setFax,
    address,
    setAddress,
    postalCode,
    setPostalCode,
    state,
    setState,
    country,
    setCountry,
    title,
    setTitle,
    email,
    setEmail,
    phone,
    setPhone,
    mobile,
    setMobile,
    role,
    setRole,
    about,
    setAbout,
    charactersLength,
    setCharactersLength,
    setMaxLimitReached,
    maxCount,
    addUserHandler,
    updateUserDetailsHandler,
    setIsEditModeOn,
    setImageFile,
    imageFile,
    setFinalImage,
    finalImage
}) {
    
    const classes = useStyles();

    return (
        <React.Fragment>
            {localStorage.getItem("user_role") === "Admin" ?
            <Button 
                className="ml-2 mr-2 mb-3"  
                onClick={handleOpenAddUserDialog} 
                variant="contained" 
                style={{backgroundColor:"#26a69a", color:"white"}}
            >
                Add Agent
            </Button> : null }
            <Dialog open={openAddUserDialog} onClose={handleCloseAddUserDialog} aria-labelledby="form-dialog-title">
                <DialogTitle className="text-center" id="form-dialog-title">
                    <div className="image-upload">
                        <input accept="image/*" onChange={(e)=>{setFinalImage(URL.createObjectURL(e.target.files[0])); setOpenImageCropper(true);}} style={{display: "none"}} id="icon-button-file" type="file" />
                        <label htmlFor="icon-button-file">
                            <Tooltip title="Change Profile Photo" arrow>
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <Avatar src={imageFile} className={classes.large}/>
                                </IconButton>
                            </Tooltip>
                        </label>
                    </div>
                </DialogTitle>
                <Dialog
                    open={openImageCropper}
                    onClose={()=>setOpenImageCropper(false)}
                >
                    <DialogContent>
                        <ImageCrop setFinalImage={setFinalImage} aspectRatio={1} imageFile={finalImage} handleImageSave={handleImageSave} handleImageDiscard={handleImageDiscard} />
                    </DialogContent>

                </Dialog>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6">
                            <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                margin="dense"
                                id="first-name"
                                label="First Name"
                                helperText = {
                                    errors.emptyError && (!firstName || firstName.trim().length === 0) ?
                                    errors.emptyError :
                                        ""
                                }
                                error = {
                                    errors.emptyError && (!firstName || firstName.trim().length === 0) ?
                                    true :
                                    false
                                }
                                value={firstName}
                                onChange={(event)=> setFirstName(event.target.value)}
                                type="text"
                                fullWidth
                            />
                            <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                helperText = {
                                    errors.emptyError && (!lastName || lastName.trim().length === 0 ) ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && (!lastName || lastName.trim().length === 0 ) ?
                                    true : false
                                }
                                margin="dense"
                                id="last-name"
                                label="Last Name"
                                type="text"
                                value={lastName}
                                onChange={(event)=>setLastName(event.target.value)}
                                fullWidth
                            />
                            <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                margin="dense"
                                id="company-name"
                                label="Company Name"
                                type="text"
                                value={companyName}
                                onChange={(event)=> setCompanyName(event.target.value)}
                                fullWidth
                            />
                            <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}                                
                                margin="dense"
                                id="fax"
                                label="Fax"
                                type="text"
                                value={fax}
                                onChange={(event)=>setFax(event.target.value)}
                                fullWidth
                            />
                            {/* <h5 style={{marginBottom:"0", marginTop:"13px", fontWeight:500}}>Address:</h5> */}
                            <TextField
                                // style={{marginTop:"0"}}
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                margin="dense"
                                helperText = {
                                    errors.emptyError && (!address || address.trim().length === 0 ) ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && (!address || address.trim().length === 0 ) ?
                                    true : false
                                }
                                id="address"
                                label="Address"
                                value={address}
                                onChange={(event)=>setAddress(event.target.value)}
                                type="text"
                                fullWidth
                            />

                            <TextField
                                // style={{marginTop:"0"}}
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                margin="dense"
                                helperText = {
                                    errors.lengthError && isNaN(postalCode.toString())
                                    ?
                                    errors.lengthError
                                    :
                                    errors.emptyError && postalCode.trim().length === 0
                                    ?
                                    errors.emptyError
                                    :
                                    ""
                                }
                                error = {
                                    (errors.lengthError && isNaN(postalCode.toString())) ||
                                    (errors.emptyError && postalCode.trim().length === 0) ||
                                    (errors.lengthError && isNaN(postalCode.toString())) 
                                    ?
                                    true
                                    : false
                                }
                                id="postalCode"
                                label="Postal Code"
                                value={postalCode}
                                onChange={(event)=>setPostalCode(event.target.value)}
                                type="text"
                                fullWidth
                            />

                            <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                                <InputLabel
                                    id="demo-simple-select-label"
                                    required
                                    helperText = {
                                        errors.emptyError && (!country || country.trim().length === 0) ?
                                        errors.emptyError : ""
                                    }
                                    error = {
                                        errors.emptyError && (!country || country.trim().length === 0) ?
                                        true : false
                                    }
                                >
                                    Country
                                </InputLabel>
                
                                <Select
                                inputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                helperText = {
                                    errors.emptyError && (!country || country.trim().length === 0) ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && (!country || country.trim().length === 0) ?
                                    true : false
                                }
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={country}
                                fullWidth
                                onChange={(event)=> {
                                    if(event.target.value === "Australia" && !australianStateList.includes(state)){
                                        setState("");
                                    };
                                    setCountry(event.target.value)
                                }}
                                >
                                {countryList.map((country => (
                                    <MenuItem key={country} value={country}>{country}</MenuItem>
                                )))}
                                </Select>

                            </FormControl>

                            { country !== "Australia" ? 
                                <TextField
                                    // style={{marginTop:"0"}}
                                    InputProps={{ readOnly: actions && !isEditModeOn }}
                                    margin="dense"
                                    id="state"
                                    label="State"
                                    value={state}
                                    onChange={(event)=>setState(event.target.value)}
                                    type="text"
                                    fullWidth
                                />
                                : 

                                <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        required
                                        helperText = {
                                            errors.emptyStateError && (!state || state.trim().length === 0) ?
                                            errors.emptyStateError : ""
                                        }
                                        error = {
                                            errors.emptyStateError && (!state || state.trim().length === 0) ?
                                            true : false
                                        }
                                    >
                                        State
                                    </InputLabel>
                    
                                    <Select
                                    inputProps={{ readOnly: actions && !isEditModeOn }}
                                    required={country==="Australia" ? true : false}
                                    helperText = {
                                        errors.emptyStateError && (!state || state.trim().length === 0) ?
                                        errors.emptyStateError : ""
                                    }
                                    error = {
                                        errors.emptyStateError && (!state || state.trim().length === 0) ?
                                        true : false
                                    }
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state}
                                    fullWidth
                                    onChange={(event)=> setState(event.target.value)}
                                    >
                                    {australianStateList.map((state => (
                                        <MenuItem key={state} value={state}>{state}</MenuItem>
                                    )))}
                                    </Select>

                                </FormControl>
                            }
                            
                            {/* <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                margin="dense"
                                helperText = {
                                    errors.emptyError && city.trim().length === 0 ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && city.trim().length === 0 ?
                                    true : false
                                }
                                id="city"
                                label="City"
                                value={city}
                                onChange={(event)=>setCity(event.target.value)}
                                type="text"
                                fullWidth
                            />
                            
                            <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                margin="dense"
                                helperText = {
                                    errors.emptyError && country.trim().length === 0 ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && country.trim().length === 0 ?
                                    true : false
                                }
                                id="country"
                                label="Country"
                                value={country}
                                onChange={(event)=>setCountry(event.target.value)}
                                type="text"
                                fullWidth
                            /> */}
                            
                        </div>
                        <div className="col-md-6">                            
                            <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                                <InputLabel 
                                    helperText = {
                                        errors.emptyError && (!title || title.trim().length === 0) ?
                                        errors.emptyError : ""
                                    }
                                    error = {
                                        errors.emptyError && (!title || title.trim().length === 0) ?
                                        true : false
                                    }
                                    required
                                    id="demo-simple-select-label"
                                >
                                    Select Title
                                </InputLabel>
                                <Select
                                inputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                helperText = {
                                    errors.emptyError && (!title || title.trim().length === 0) ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && (!title || title.trim().length === 0) ?
                                    true : false
                                }
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={title}
                                fullWidth
                                onChange={(event)=> setTitle(event.target.value)}
                                >
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                                <MenuItem value="Dr">Dr</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                helperText = {
                                    errors.emptyError && (!email || email.trim().length === 0) ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && (!email || email.trim().length === 0) ?
                                    true : false
                                }
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(event)=>setEmail(event.target.value)}
                                fullWidth
                            />
                            <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                required
                                helperText = {
                                    errors.emptyError && ( !mobile || mobile.trim().length === 0 ) ?
                                    errors.emptyError : ""
                                }
                                error = {
                                    errors.emptyError && ( !mobile || mobile.trim().length === 0 ) ?
                                    true : false
                                }
                                margin="dense"
                                id="mobile-number"
                                label="Mobile Number"
                                placeholder="+611234567890"
                                value={mobile}
                                onChange={(event)=>setMobile(event.target.value)}
                                
                                type="text"
                                fullWidth
                            />
                            <FormControl style={{width:"100%", marginTop:"5px", marginBottom:"4px"}}>
                                <InputLabel 
                                    helperText = {
                                        errors.emptyError && (!role || role.trim().length === 0) ?
                                        errors.emptyError : ""
                                    }
                                    error = {
                                        errors.emptyError && (!role || role.trim().length === 0) ?
                                        true : false
                                    }
                                    required 
                                    id="demo-simple-select-label"
                                >
                                    Select Role
                                </InputLabel>
                                
                                {   localStorage.getItem("user_role") === "User Manager" ?
                                    <Select
                                        inputProps={{ readOnly: actions && !isEditModeOn }}
                                        required
                                        helperText = {
                                            errors.emptyError && (!role || role.trim().length === 0) ?
                                            errors.emptyError : ""
                                        }
                                        error = {
                                            errors.emptyError && (!role || role.trim().length === 0) ?
                                            true : false
                                        }
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={role}
                                        fullWidth
                                        onChange={(event)=> setRole(event.target.value)}
                                    >
                                        {/* <MenuItem value="Project Manager">Project Manager</MenuItem> */}
                                        <MenuItem value="Agent">Agent</MenuItem>
                                        <MenuItem value="Affiliate">Affiliate</MenuItem>
                                    </Select>
                                    :
                                    <Select
                                        inputProps={{ readOnly: actions && !isEditModeOn }}
                                        required
                                        helperText = {
                                            errors.emptyError && (!role || role.trim().length === 0) ?
                                            errors.emptyError : ""
                                        }
                                        error = {
                                            errors.emptyError && (!role || role.trim().length === 0) ?
                                            true : false
                                        }
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={role}
                                        fullWidth
                                        onChange={(event)=> setRole(event.target.value)}
                                    >
                                        <MenuItem value="Project Manager">Project Manager</MenuItem>
                                        <MenuItem value="User Manager">User Manager</MenuItem>
                                        <MenuItem value="Master Agent">Master Agent</MenuItem>
                                        <MenuItem value="Agent">Agent</MenuItem>
                                        <MenuItem value="Affiliate">Affiliate</MenuItem>
                                    </Select>
                                }
                            </FormControl>
                            <TextField
                                InputProps={{ readOnly: actions && !isEditModeOn }}
                                margin="dense"
                                id="phone-number"
                                label="Phone Number"
                                type="text"
                                value={phone}
                                onChange={(event)=>setPhone(event.target.value)}
                                fullWidth
                            />
                            
                            <div className="about-form">
                                <TextField
                                    style={{marginTop:"22px"}}
                                    InputProps={{ readOnly: actions && !isEditModeOn }}                                
                                    margin="dense"
                                    id="about-me"
                                    label="About Me"
                                    type="text"
                                    rows={7}
                                    variant="outlined"
                                    multiline       
                                    helperText={charactersLength>1200 ? "Max Limit Reached" : null}
                                    error={charactersLength>1200 ? true: false}                                   
                                    value={about}
                                    onChange={(event)=>{ 
                                        if(charactersLength>1200){
                                            setMaxLimitReached(true)                                            
                                        }else{
                                            setMaxLimitReached(false)
                                        }
                                        setCharactersLength(event.target.value.length); 
                                        setAbout(event.target.value)
                                    }}
                                    fullWidth
                                />
                                <span style={{display:"block", textAlign:"right"}} className={`${charactersLength>1200 ? "text-error" : "text-dark"}`}>{charactersLength}/{maxCount}</span>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                {   !actions ? 
                    <React.Fragment>
                        <Button onClick={addUserHandler} color="primary">
                        Add
                        </Button>
                        <Button onClick={handleCloseAddUserDialog} color="primary">
                        Cancel
                        </Button>
                    </React.Fragment>
                        : 
                    <React.Fragment>
                        { !isEditModeOn ? 
                            <Button onClick={()=>setIsEditModeOn(true)} color="primary">
                                Edit
                            </Button> : 
                            <Button onClick={()=> updateUserDetailsHandler(id)} color="primary">
                                Confirm
                            </Button>
                        }
                        <Button onClick={handleCloseAddUserDialog} color="primary">
                        Cancel
                        </Button>
                    </React.Fragment> 
                }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default AddUser
