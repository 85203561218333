import React,{ useEffect, useState } from 'react'

import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Button,
  // Modal,
  CircularProgress,
  Box,
  Dialog,
  // DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
} from "@material-ui/core";

import EnhancedTableHead from './EnhancedTableHead';

import EnhancedTableToolbar from './EnhancedTableToolbar';

import { purple } from "@material-ui/core/colors";

import axiosInstance from '../../../Axios/axios';

import moment from "moment";
import logger from '../../../utils/logger';
import { API_VERSION, BASE_URL } from '../../../Axios/api';

const descendingComparator = (a, b, orderBy) => {

  if (b[orderBy] < a[orderBy]) {

    return -1;

  }

  if (b[orderBy] > a[orderBy]) {

    return 1;

  }

  return 0;

}

const getComparator = (order, orderBy) => {

  return order === "desc"

  ? (a, b) => descendingComparator(a, b, orderBy)

  : (a, b) => -descendingComparator(a, b, orderBy);

}

const stableSort = (array, comparator) => {

  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {

    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];

  });

  return stabilizedThis.map((el) => el[0]);

}

const useStyles = makeStyles((theme) => ({

  root: {
    width: "100%",
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  table: {
    minWidth: 1150,
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  buttonWrapper:{
    display:'flex',
    justifyContent:'flex-end'
  },

  modal:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },

  contentWrapper:{
    maxWidth:1300,
    padding:20,
    background:'white',
    borderRadius:4
  },

  spanButton: {
    cursor: "pointer",
    "&:hover": {
      color: "blue",
    }
  },

}));


const Transition = React.forwardRef(function Transition(props, ref) {

  return <Slide direction="right" ref={ref} {...props} />;

});


const AssignModal = ({
  selectedProject,
  assignModal,
  setAssignModal,
  assignProjectStaff,
  assignProjectMaster,
  setSelectedUserProfile,
  setOpenSnackbar,
  setMessageForSnackbar,
  setSnackbarType
}) => {

  const classes = useStyles();

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("");

  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);

  const [dense] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [userData, setUserData] = useState([]);

  const [userList, setUserList] = useState([]);

  const [filterText, setFilterText] = useState('')

  const [loadingUserList, setLoadingUserList] = useState(false);

  useEffect(() => {

    if(userList && userList.length > 0){

      const finalDataSet = userList.filter(usrData => (usrData.firstName && usrData.firstName.toLowerCase().includes(filterText.trim().toLowerCase())) || 
                                                     (usrData.lastName && usrData.lastName.toLowerCase().includes(filterText.trim().toLowerCase()))                                                     
                                          );

      if(finalDataSet && finalDataSet.length > 0){

        setUserData(finalDataSet);
     
      }
     
      else {
     
        setUserData([]);
     
      }
   
    }
  
  }, [userList,filterText])

  useEffect(() => {
  
    const fetchAvailableAgents = async (search) => {
  
      setLoadingUserList(true)
  
      const agentsList = await axiosInstance.post('/userLists',{
  
          pageNumber:1,
  
          recordsPerPage:10,

          search

      }).catch(err => console.log(err));

      if(agentsList && agentsList.data && agentsList.data.payload){

        console.log(agentsList.data.payload.data);

        setUserList(agentsList.data.payload.data);

        setLoadingUserList(false);

      }

    }

   if(assignModal && assignModal.type === 1){
    
    if(selectedProject && selectedProject.staff && selectedProject.staff.length){
    
      setSelected(selectedProject.staff.map(projStaff => projStaff.id));
    
    } else {
    
      setSelected([]);
    
    }
    
    const searchValue = {
    
      isEnable:1,
    
      // role:['Agent','Affiliate']
    
    }
    
    fetchAvailableAgents(JSON.stringify(searchValue));
   
  } else if(assignModal && assignModal.type === 2) {

    if(selectedProject && selectedProject.master_agents && selectedProject.master_agents.length){

      setSelected(selectedProject.master_agents.map(projMasterAgent => projMasterAgent.id));

    } else {

      setSelected([]);

    }

    const searchValue = {

      isEnable:1,

      role:['Master Agent']

    }

    fetchAvailableAgents(JSON.stringify(searchValue))

  }

  return () => {

    setUserData([]);

    setUserList([]);

  }

}, [assignModal, selectedProject])

const handleRequestSort = (event, property) => {

  const isAsc = orderBy === property && order === "asc";

  setOrder(isAsc ? "desc" : "asc");

  setOrderBy(property);

};

const handleSelectAllClick = (event) => {

  if (event.target.checked) {

    const newSelecteds = userData.map((n) => n.id);

    setSelected(newSelecteds);

    return;

  }

  setSelected([]);

};

const handleClick = (event, id) => {

  console.log(selected,assignModal,id);
  
  if(assignModal && assignModal.type === 1 && Array.isArray(selected)){
  
    const selectedIndex = selected.indexOf(id);
  
    let newSelected = [];
  
    if (selectedIndex === -1) {
    
      newSelected = newSelected.concat(selected, id);
    
    } else if (selectedIndex === 0) {
    
      newSelected = newSelected.concat(selected.slice(1));
    
    } else if (selectedIndex === selected.length - 1) {
    
      newSelected = newSelected.concat(selected.slice(0, -1));
    
    } else if (selectedIndex > 0) {
  
      newSelected = newSelected.concat(
    
        selected.slice(0, selectedIndex),
 
        selected.slice(selectedIndex + 1)
 
        );
 
      }
  
      setSelected(newSelected);
 
    } else {
 
      const selectedIndex = selected.indexOf(id);

      let newSelected = [];

      if (selectedIndex === -1) {

        newSelected = newSelected.concat(selected, id);

      } else if (selectedIndex === 0) {

        newSelected = newSelected.concat(selected.slice(1));

      } else if (selectedIndex === selected.length - 1) {

        newSelected = newSelected.concat(selected.slice(0, -1));

      } else if (selectedIndex > 0) {

        newSelected = newSelected.concat(

          selected.slice(0, selectedIndex),

          selected.slice(selectedIndex + 1)

        );

      }

      setSelected(newSelected);
 
    }
 
  };

  const handleChangePage = (event, newPage) => {
 
    setPage(newPage);
 
  };

  const handleChangeRowsPerPage = (event) => {
 
    setRowsPerPage(parseInt(event.target.value, 10));
 
    setPage(0);
 
  };

 
  // const handleChangeDense = (event) => {
 
  //   setDense(event.target.checked);
 
  // };

  const isSelected = (id) => {
 
    if(assignModal && assignModal.type === 1 && Array.isArray(selected)){
 
      return selected.indexOf(id) !== -1
 
    }
    
    else if (assignModal && assignModal.type === 2 && Array.isArray(selected)) {

      return selected.indexOf(id) !== -1

    }

  };
  
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userData.length - page * rowsPerPage);

  const theme = createMuiTheme({
  
    palette: {
  
      primary: {
          main: purple[500],
        },
        secondary: {
          main: '#2196f3',
        },
      },
  
  });

  const handleSave = async () => {
  
    if(assignModal && assignModal.type === 1){
  
      const selectedUsersId = [];
  
      const getSelectedUsers = selected.map(sel => {
  
        const user = userData.find(usr => usr.id === sel);
  
        selectedUsersId.push(user.id);
  
        return user;
  
      });
      
      const assignAgentResponse = await axiosInstance.post('/projectAssign', {
      
        user_ids: selectedUsersId,
        
        project_ids: [selectedProject.id]
      
      })
      .then(
        logger({
          title:'Project Assign',
          url:window.location.href,
          method:'POST',
          activity: 'projectAssign',
          user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
          api:BASE_URL+ API_VERSION+'/projectAssign',
          session:localStorage.getItem('session'),
          projectId:selectedProject.id,
          unitId:null,
          assignedId:selectedUsersId[0],
          })                          
      )
      .catch(err => {
          if (err.response.data.status === 422) {

            setOpenSnackbar(true);
            
            setSnackbarType("error");
            
            setMessageForSnackbar(err.response.data.message);
          
          }
      
      });
      
      if(assignAgentResponse){
      
        assignProjectStaff(getSelectedUsers);
      
        console.log(assignAgentResponse);

        setOpenSnackbar(true);

        setSnackbarType("success");

        setMessageForSnackbar(assignAgentResponse.data.message);

        setAssignModal({...assignModal,status:false})
      
      }

    }
    
    if(assignModal && assignModal.type === 2){
    
      const selectedUsersId = [];
    
      // const getSelectedName = userData.find(usr => usr.id === selected);
    
      const getSelectedUsers = selected.map(sel => {
    
        const user = userData.find(usr => usr.id === sel);
    
        selectedUsersId.push(user.id);
    
        return user;
    
      });
    
      const assignMasterAgentResponse = await axiosInstance.post(`/projectAssign`,{
    
          user_ids: selectedUsersId,

          project_ids: [selectedProject.id]
    
      })
      .then(
        logger({
          title:'Project Assign',
          url:window.location.href,
          method:'POST',
          activity: 'projectAssign',
          user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
          api:BASE_URL+ API_VERSION+'/projectAssign',
          session:localStorage.getItem('session'),
          projectId:selectedProject.id,
          unitId:null,
          assignedId:selectedUsersId[0],
          })                          
      )
      .catch(err => {
          
          if (err.response.data.status === 422) {

            setOpenSnackbar(true);
            
            setSnackbarType("error");
            
            setMessageForSnackbar(err.response.data.message);
          
          }
      
      });
      
      if(assignMasterAgentResponse){
      
        assignProjectMaster(getSelectedUsers);
      
        console.log(assignMasterAgentResponse);

        setOpenSnackbar(true);

        setSnackbarType("success");

        setMessageForSnackbar(assignMasterAgentResponse.data.message);

        setAssignModal({...assignModal,status:false})
      
      }
      
    }

  }

  return (
  
    <Dialog
    
      fullWidth={true}
    
      maxWidth={'lg'}
    
      TransitionComponent={Transition}
    
      open={assignModal.status}
    
      onClose={()=>setAssignModal({...assignModal,status:false})}
    
        aria-labelledby="simple-modal-title"
    
        aria-describedby="simple-modal-description"
    
        className={classes.modal}
    
    >
  
      <DialogContent>
  
        <div className={classes.contentWrapper}>
  
          <div className={classes.root}>
  
            <Paper className={classes.paper}>
    
              <EnhancedTableToolbar numSelected={selected && selected.length} assignModal={assignModal} setFilterText={setFilterText} />
  
                <TableContainer>
    
                  <Table
          
                    className={classes.table}
          
                    aria-labelledby="tableTitle"
          
                    size={dense ? "small" : "medium"}
          
                    aria-label="enhanced table"
                    
                    stickyHeader={true}
          
                  >
  
                  <EnhancedTableHead
        
                    classes={classes}
        
                    numSelected={selected && selected.length}
        
                    order={order}
        
                    orderBy={orderBy}
        
                    onSelectAllClick={handleSelectAllClick}
        
                    onRequestSort={handleRequestSort}
        
                    rowCount={userData.length}
        
                  />
  
                  <TableBody className='projTblTBody'>
        
                    {
        
                    loadingUserList ? (
        
                    <TableRow>
        
                      <TableCell colSpan={10}>
        
                        <Box
        
                          display="flex"
        
                          justifyContent="center"
        
                          alignItems="center"
        
                          style={{ height: 232 }}
        
                        >
        
                          <CircularProgress />
        
                        </Box>
        
                      </TableCell>
        
                    </TableRow>
        
                  ) :
        
                  stableSort(userData, getComparator(order, orderBy))
        
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        
                      .map((row, index) => {
        
                        const isItemSelected = isSelected(row.id);
        
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
        
                          <ThemeProvider theme={theme}>
        
                            <TableRow
          
                              hover
          
                              role="checkbox"
          
                              aria-checked={isItemSelected}
          
                              tabIndex={-1}
          
                              key={row.id}
          
                              selected={isItemSelected}
          
                            >
          
                              <TableCell
          
                                component="th"
          
                                id={labelId}
          
                                scope="row"
          
                                // padding="none"
          
                              >
          
                                {index+1}
          
                              </TableCell>
          
                              <TableCell padding="checkbox" align="center">
          
                                <Checkbox
          
                                  checked={isItemSelected}
          
                                  inputProps={{ "aria-labelledby": labelId }}
          
                                  onClick={(event) => handleClick(event, row.id)}
          
                                />
          
                              </TableCell>
          
                              <TableCell align="center">{row.isEnable ? 'Yes' : 'No'}</TableCell>
          
                              <TableCell align="center">{row.firstName ? row.firstName : "-"}</TableCell>
          
                              <TableCell align="center">{row.lastName ? row.lastName : "-"}</TableCell>
          
                              <TableCell align="center">{row.role ? row.role : "-"}</TableCell>
          
                              <TableCell align="center">
          
                              <span
          
                                style={{textDecoration:"underline"}}
          
                                className={classes.spanButton}
          
                                onClick={() => {
          
                                  setSelectedUserProfile(row);
          
                                  setAssignModal({...assignModal,status:false})
          
                                }}
          
                              >
          
                                Profile
          
                              </span>
          
                              </TableCell>
          
                              <TableCell align="center">{row.created_at ? moment(row.created_at).format("ll") : "-"}</TableCell>
          
                              <TableCell align="center">{row.address ? row.address : "-"}</TableCell>
          
                            </TableRow>
        
                          </ThemeProvider>
        
                        );
        
                      })}
        
                    {!loadingUserList && emptyRows > 0 && (
        
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
        
                        <TableCell colSpan={10} />
        
                      </TableRow>
        
                    )}
        
                  </TableBody>
  
                </Table>
  
              </TableContainer>
  
            <TablePagination
    
              rowsPerPageOptions={[5, 10, 25]}
      
              component="div"
      
              count={userData.length}
      
              rowsPerPage={rowsPerPage}
      
              page={page}
      
              onChangePage={handleChangePage}
      
      
              onChangeRowsPerPage={handleChangeRowsPerPage}
      
            />
  
          </Paper>

        </div>
    
      </div>
    
    </DialogContent>
    
    <DialogActions>
    
      <Button color="primary" onClick={handleSave}>
    
        Assign
    
      </Button>
    
      <Button onClick={()=>{setAssignModal({...assignModal,status:false});}} color="primary">
    
        Cancel
    
      </Button>

    </DialogActions>    
    
  </Dialog>

)}

export default AssignModal
