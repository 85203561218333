import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react'

const headCells = [
  { id: 'tags', numeric: true, disablePadding: false, label: 'Tags' },
  { id: 'firstName', numeric: false, disablePadding: true, label: 'First Name' },
  { id: 'lastName', numeric: false, disablePadding: true, label: 'Last Name' },
  { id: 'phoneNumber', numeric: true, disablePadding: false, label: 'Phone Number' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'leadValue', numeric: false, disablePadding: false, label: 'Lead Value' },
  { id: 'postalCode', numeric: true, disablePadding: false, label: 'Postal Code' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'city', numeric: false, disablePadding: false, label: 'City' },
  { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'website', numeric: false, disablePadding: false, label: 'Website' },
];

export default function ContactsTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        <TableCell 
          className = "table-headings"
          align = "center"
          padding = "none"
        >
          Action
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className = "table-headings"
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{marginLeft: `${headCell.id === 'tags' ? '-25px' : '0px'}`}}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ContactsTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
