import React from "react";

import PropTypes from "prop-types";

import { lighten, makeStyles, TextField, Toolbar, Typography } from "@material-ui/core";

import clsx from "clsx";

const useToolbarStyles = makeStyles((theme) => ({

  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  highlight:
    theme.palette.type === "light"
    ? {
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
    }
    : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.dark,
    },

  title: {
    flex: "1 1 100%",
  },

}));

const EnhancedTableToolbar = ({ numSelected, assignModal,setFilterText }) => {

  const classes = useToolbarStyles();
  
  return (
  
  <Toolbar
  
      className={clsx(classes.root, {
  
        [classes.highlight]: numSelected > 0,
  
      })}
  
    >
  
      {numSelected > 0 ? (
  
        <Typography
  
          className={classes.title}
  
          color="inherit"
  
          variant="subtitle1"
  
          component="div"
  
        >
  
          {numSelected} selected
  
        </Typography>
  
      ) : (
  
        <React.Fragment>
  
          <Typography
  
            className={classes.title}
  
            variant="h6"
  
            id="tableTitle"
  
            component="div"
  
          >
  
            {assignModal && assignModal.type === 2
  
              ? "Assign Master Agent"
  
              : "Assign Staff"}
  
          </Typography>
  
          <div style={{ minWidth: 200 }}>
  
            <TextField
  
              margin="dense"
  
              id="filter"
  
              label="Filter By Name"
  
              type="text"
  
              onChange={(event) => setFilterText(event.target.value)}
  
              fullWidth
  
            />
  
          </div>
  
        </React.Fragment>
  
      )}

    </Toolbar>

  );

};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
