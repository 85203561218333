import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@material-ui/core';
import { Close, LocalOffer } from '@material-ui/icons';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#263238',
        paddingRight: "0px !important"
    },
    title: {
        fontSize:"1rem",
        flex: 1,
    },
    assignButton: {
        color: "white",
        padding: "3px 10px",
        fontSize: "0.7rem",
        backgroundColor: "#26A69A",
        '&:hover': {
        backgroundColor: "#26A69A"
        }
    },
    formControl: {
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});


function AddNewLeadDialog({
    openAddNewLeadDialog,
    onCloseAddNewLeadDialog,
    changeLeadDetailsHandler,
    addNewLeadHandler,
    leadDetails,
    contactsTabs,
    contactToEdit,
    onCloseEditLeadDialog,
    editLeadHandler,
    openEditContactDialog
}) {
    
    const classes = useStyles();

    return (
        <Dialog maxWidth="sm" fullWidth open={openAddNewLeadDialog || openEditContactDialog} onClose={onCloseAddNewLeadDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        { !contactToEdit ? "Add New Lead" : "Edit Lead Details" }
                    </Typography>
                    
                    { !contactToEdit ? 
                        <IconButton edge="end" color="inherit" onClick={onCloseAddNewLeadDialog} aria-label="close">
                            <Close />
                        </IconButton> :
                        <IconButton edge="end" color="inherit" onClick={onCloseEditLeadDialog} aria-label="close">
                            <Close />
                        </IconButton>
                    }           
                </Toolbar>
            </AppBar>
            <DialogContent>
                <div className="row">
                    <div className="col-md-6">
                        <FormControl className={classes.formControl}>
                            <InputLabel required id="demo-simple-select-label">Folder</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="tab"
                                    value={leadDetails && leadDetails.tab}
                                    onChange={changeLeadDetailsHandler}
                                >
                                    { !contactToEdit && contactsTabs.map(tab => <MenuItem key={tab} value={tab}>{tab}</MenuItem>) }
                                    { contactToEdit && <MenuItem value={leadDetails && leadDetails.tab}>{leadDetails && leadDetails.tab}</MenuItem> }
                                </Select>
                        </FormControl>
                    </div>
                    {/* <div className="col-md-6">
                        <FormControl className={classes.formControl}>
                            <InputLabel required id="demo-simple-select-label">Source</InputLabel>
                                <Select
                                    required
                                    name="source"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={leadDetails && leadDetails.source}
                                    onChange={changeLeadDetailsHandler}
                                >
                                    <MenuItem value="Nothing selected">Nothing Selected</MenuItem>
                                    
                                </Select>
                        </FormControl>
                    </div> */}
                    <div className="col-md-6">
                        <FormControl className={classes.formControl}>
                            <InputLabel className="mb-2" required id="demo-simple-select-label">                                
                                    <LocalOffer style={{fontSize: "1rem"}}/>
                                    <span className="ml-1">Tags</span>
                            </InputLabel>
                                <Select
                                    required
                                    name="tags"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={leadDetails && leadDetails.tags}
                                    onChange={changeLeadDetailsHandler}
                                >
                                    <MenuItem value="Follow up">Follow up</MenuItem>
                                    <MenuItem value="Important">Important</MenuItem>
                                    <MenuItem value="Review">Review</MenuItem>
                                    <MenuItem value="Tomorrow">Tomorrow</MenuItem>
                                    <MenuItem value="To Do">To Do</MenuItem>

                                </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-6">
                        <TextField
                            required
                            margin="dense"
                            id="leadFirstName"
                            label="First Name"
                            name="firstName"
                            type="text"
                            value={leadDetails && leadDetails.firstName}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            required
                            margin="dense"
                            id="leadLastName"
                            label="Last Name"
                            name="lastName"
                            type="text"
                            value={leadDetails && leadDetails.lastName}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="leadAddress"
                            label="Address"
                            type="text"
                            fullWidth
                            name="address"
                            value={leadDetails && leadDetails.address}
                            onChange={changeLeadDetailsHandler}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="leadCity"
                            label="City"
                            type="text"
                            name="city"
                            value={leadDetails && leadDetails.city}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            required
                            margin="dense"
                            id="leadEmailAddress"
                            label="Email Address"
                            type="text"
                            name="email"
                            value={leadDetails && leadDetails.email}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="leadState"
                            label="State"
                            type="text"
                            name="state"
                            value={leadDetails && leadDetails.state}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="leadWebsite"
                            label="Website"
                            type="text"
                            name="website"
                            value={leadDetails && leadDetails.website}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="leadCountry"
                            label="Country"
                            type="text"
                            name="country"
                            value={leadDetails && leadDetails.country}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="leadPhone"
                            label="Phone"
                            type="text"
                            name="phoneNumber"
                            value={leadDetails && leadDetails.phoneNumber}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="leadPostalCode"
                            label="Postal Code"
                            type="text"
                            fullWidth
                            name="postalCode"
                            value={leadDetails && leadDetails.postalCode}
                            onChange={changeLeadDetailsHandler}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="leadValue"
                            label="Lead Value"
                            placeholder="Integer between 1 to 10"
                            type="number"
                            name="leadValue"
                            value={leadDetails && leadDetails.leadValue}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-12">
                        <TextField
                            margin="dense"
                            id="leadDescription"
                            label="Description"
                            type="text"
                            name="description"
                            value={leadDetails && leadDetails.description}
                            onChange={changeLeadDetailsHandler}
                            fullWidth
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                { !contactToEdit ?
                    <React.Fragment>
                        <Button onClick={addNewLeadHandler} color="primary">
                            Add
                        </Button>
                        <Button onClick={onCloseAddNewLeadDialog} color="primary">
                            Cancel
                        </Button>
                    </React.Fragment> :

                    <React.Fragment>
                        <Button onClick={editLeadHandler} color="primary">
                            Save
                        </Button>
                        <Button onClick={onCloseEditLeadDialog} color="primary">
                            Cancel
                        </Button>
                    </React.Fragment>
                }
            </DialogActions>
        </Dialog>
    )
}

export default AddNewLeadDialog
