import { makeStyles } from '@material-ui/core'

import React, { useEffect } from 'react'

import { GoogleMapWrapper } from '../../../utils/GoogleMapWrapper'

import Geocode from 'react-geocode';

const useStyles = makeStyles(theme=> ({
    headerTitle: {
        fontSize: "0.9rem",
        textTransform: "uppercase",
        paddingTop: "5px",
        paddingBottom: "5px",
        letterSpacing: "0.1em",
        backgroundColor: "rgb(192,229,226)",
        borderRadius: "6px",
        paddingLeft: "10px",
        fontWeight: 600,
        marginLeft: "auto",
        marginRight: "auto"
    }
}))

function ProjectLocation({
    project
}) {

    const classes = useStyles();

    const [lat, setLat] = React.useState(0);

    const [lng, setLng] = React.useState(0);

    const getCoordinates = () => {

        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

        Geocode.setLanguage("en");

        Geocode.fromAddress(project.address + " " + project.state + " " + project.country)
            .then(

                response => {

                    const { lat, lng } = response.results[0].geometry.location;

                    setLat(lat);

                    setLng(lng);

                },

                error => {

                    console.log("Error", error);

                }

            )
            .catch(err => {

                console.log("Error.", err);

            })

    }

    useEffect (()=> {
    
        getCoordinates();
        
    }, [project])

    return (
        
        <div className="col-12 mt-3 mb-3" style={{height:"250px"}}>

            <React.Fragment>  
                
                <h5 className={classes.headerTitle}>Project Location</h5>
            
                { lat !== 0 && lng !== 0 ?

                    <GoogleMapWrapper
                    
                    project={project}

                    lat={lat}

                    lng={lng}

                    googleMapURL = {
                        `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
                    }
                    
                    loadingElement = {<div className="h-100"></div>}
                    
                    containerElement= {<div className="h-100"></div>}
                    
                    mapElement= {<div className="h-100"></div>}
                
                    />
                
                : null 
                
                }

            </React.Fragment>

        </div>

    )

}

export default ProjectLocation
