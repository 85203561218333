import { AppBar, Dialog, DialogContent, IconButton, List, ListItem, makeStyles, Slide, Toolbar, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, EmailShareButton, EmailIcon } from 'react-share';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#263238',
    paddingRight: "0px !important"
  },
  title: {
    fontSize: "1rem",
    flex: 1,
  },
  share: {

  }
}));


function UnitShareModal({
  openUnitShareModal,
  setOpenUnitShareModal,
  floorPlanData,
  url,
  interiorData
}) {
  const classes = useStyles();
  return (
    <Dialog className="share-modal" maxWidth="sm" open={openUnitShareModal} onClose={() => setOpenUnitShareModal(false)} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar style={{ paddingRight: "5px" }}>
          <Typography variant="h6" className={classes.title}>
            SHARE
          </Typography>
          <IconButton style={{ marginLeft: "15px" }} color="inherit" onClick={() => setOpenUnitShareModal(false)} aria-label="close">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div className="share">
          <div className="share-btn font-weight-semibold">Floor Plan 
            <div className="social-group">
              <a className="ml-2" href={`mailto:?subject=Floor Plan&body=${floorPlanData}`}><EmailIcon size={32} round /></a>
              <FacebookShareButton
                  className="ml-2"
                  url={floorPlanData}
              >
                  <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                  className="ml-2"
                  url={floorPlanData}
              >
                  <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>
          <div className="share-btn font-weight-semibold">Interior 
            <div className="social-group">
              <a className="ml-2" href={`mailto:?subject=Interior&body=${interiorData}`}><EmailIcon size={32} round /></a>
              <FacebookShareButton
                  className="ml-2"
                  url={interiorData}
              >
                  <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                  className="ml-2"
                  url={interiorData}
              >
                  <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default UnitShareModal
