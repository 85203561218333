import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Chip, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import axiosInstance from '../../Axios/axios';
import Menubar from '../Menubar/Menubar'
import AppartmentAccordion from './AppartmentAccordion/AppartmentAccordion';
import AssignModal from './AssignModal/AssignModal';
import ProfileDialog from './AssignModal/ProfileDialog';
import ProjectDocumentsAccordion from './ProjectDocumentsAccordion/ProjectDocumentsAccordion';
import ProjectFormDialog from './ProjectDetailsAccordion/ProjectActions/ProjectForm/ProjectFormDialog';
import ProjectsHeader from './ProjectsHeader'
import DeleteProjectDialog from './ProjectDetailsAccordion/ProjectActions/DeleteProjectDialog';
import LoadingSpinner from '../../utils/LoadingSpinner';
import ProjectDetailsAccordion from './ProjectDetailsAccordion/ProjectDetailsAccordion';
import SnackbarToast from '../../utils/SnackbarToast';
import ProjectIcon from '@material-ui/icons/PlaylistAddCheck';
import { Done, FileCopy, Share } from '@material-ui/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AssignUnitNumberDialog from './AppartmentAccordion/AssignUnitNumberDialog/AssignUnitNumberDialog';
import UnitNumberDialog from './AppartmentAccordion/UnitNumberDialog/UnitNumberDialog';
import AddNewPriceDialog from './AppartmentAccordion/UnitNumberDialog/AddNewPriceDialog/AddNewPriceDialog';
import {ExcelRenderer} from 'react-excel-renderer';
import readXlsxFile from 'read-excel-file';
import ActivateConfirmationDialog from './AppartmentAccordion/UnitNumberDialog/ActivateConfirmationDialog/ActivateConfirmationDialog';
import DeleteConfirmationDialog from './AppartmentAccordion/UnitNumberDialog/DeleteConfirmationDialog/DeleteConfirmationDialog';
import ShareModal from './ProjectDetailsAccordion/ProjectActions/ProjectForm/ShareModal/ShareModal';
import UploadPanoDialog from './ProjectDetailsAccordion/ProjectActions/UploadPanoDialog';
import $ from 'jquery';

import {AuthContext} from '../../Context/userContext';
import { USER_ROLES } from '../../utils/const';
import { download_from_stream } from '../../utils/file';
import AssignRoleProjectDialog from './ProjectDetailsAccordion/ProjectActions/AssignRoleProjectDialog';
// import {marketingDocuments as marketingDocumentsData} from '../../DummyData/marketingDocuments';
// import { unitsTable } from '../../DummyData/unitsTable';
// import { panoramaList as panoList } from '../../DummyData/panoramaList';
import ShareIcon from '../../Assets/img/share_black_24dp.svg';
import logger from '../../utils/logger';
import { API_VERSION, BASE_URL } from '../../Axios/api';

const useStyles = makeStyles((theme) => ({
    
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    
    root: {
        width: '100%',
    },

    projectIdHeading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightBold,
        color: "rgba(0,0,0,0.7)"
    },
    
    onlineBadge: {
        borderRadius: "3px", 
        display: "inline-block", 
        background: "#397A17", 
        fontSize: "0.6rem", 
        padding: "2px 0px", 
        width: "55px", 
        color: "white",
        marginLeft: "10px"
    },

    onlineStatus: {
        letterSpacing: "0.04em",
        display: "block",
        textAlign: "center"
    },

    chip: {
        color: "white",
        backgroundColor: "#324148",
        margin: "-2px 0 25px 0",
        fontWeight: 500,
        borderRadius: "3px",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
    },

    projectIcon: {
        marginLeft: "10px !important",
        color: "white"
    },

    shareButton: {
        backgroundColor: "#458CCC",
        color:"white",
        '&:hover': {
            backgroundColor: "#458CCC",
            color: "white"
        }
    },

    shareIcon: {
        fontSize:"1.2rem",
        backgroundColor:"white",
        color:"#458CCC",
        borderRadius:"3px",
        marginLeft:"5px",
        marginRight: "10px",
        padding:"2px"
    },

    projectHeader: {
        display: "flex",
        alignItems:"center",
        marginTop:"0.2rem",
        flexWrap: 'wrap'
    },

    copyIcon: {
        fontSize: "1.2rem"
    }
    
}));

function Projects({match: {params: { projectId }}, history}) {
    const classes = useStyles();
    const [currentProjectDetails, setCurrentProjectDetails] = useState({});
    const [isProjectDetailsLoading, setIsProjectDetailsLoading] = useState(false);
    const [openAddProjectDialog, setOpenAddProjectDialog] = useState(false);
    const [openAssignManagerProject, setOpenAssignManagerProject] = useState(false);
    const [projectsList, setProjectsList] = useState([]);
    const [openDeployProjectDialog, setOpenDeployProjectDialog] = useState(false);
    const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    // const [projectInfoModal, setProjectInfoModal] = useState({status:false,type:0})
    // const [loadingProjects, setLoadingProjects] = useState(false);
    // const [open, setOpen] = useState(false);
    // const [marketingImagesModal, setMarketingImagesModal] = useState(false);
    const [selectedUserProfile, setSelectedUserProfile] = useState(false);
    const [assignModal, setAssignModal] = useState({status:false,type:0});
    const [marketingDocuments, setMarketingDocuments] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState("");
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [unitsData, setUnitsData] = useState([]);
    const [openAssignUnitNumberDialog, setOpenAssignUnitNumberDialog] = useState(false);
    const [userList, setUserList] = useState([]);
    const [roleForAssignUnitNumber, setRoleForAssignUnitNumber] = useState("");
    const [unitNumberToAssign, setUnitNumberToAssign] = useState("");
    const [unitIdToAssign, setUnitIdToAssign] = useState("");
    const [usersAssignedForUnitNumber, setUsersAssignedForUnitNumber] = useState([]);
    const [openUnitNumberDialog, setOpenUnitNumberDialog] = useState(false);
    const [selectedFloorPlanData, setSelectedFloorPlanData] = useState({
        data: [],
        isDisplayed: 0
    });
    const [selectedPriceData, setSelectedPriceData] = useState({
        data: [],
        isDisplayed: 0
    });
    const [showDataFor, setShowDataFor] = useState("");
    const [openNewPriceDialog, setOpenNewPriceDialog] = useState(false);
    const [newPrice, setNewPrice] = useState("");
    const [openActivateConfirmationDialog, setOpenActivateConfirmationDialog] = useState(false);
    const [documentToActivate, setDocumentToActivate] = useState(null);
    const [priceIdToActivate, setPriceIdToActivate] = useState("");
    const [floorPlanIdToActivate, setFloorPlanIdToActivate] = useState("");
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [toggleUploadPanoDialog, setToggleUploadPanoDialog] = useState(false);
    const [panoramaList, setPanoramaList] = useState([]);
    const userContext = useContext(AuthContext);
    const [userRole, setUserRole] = useState("");
    const [usedDocumentSpace, setUsedDocumentSpace] = useState(0);
    const [usedFloorplanSpace, setUsedFloorplanSpace] = useState(0);

    useEffect(() => {
        if (window.mainInitAll) {
            window.mainInitAll();
        }

        let user_role = localStorage.getItem("user_role");
        if (([USER_ROLES.UM].includes(user_role))) {
            window.location.href = '/';
            return;
        }

        setUserRole(user_role);
        onToggleMobileSidebar();

        $('.content-wrapper').on('click', function (e) {
            onToggleMobileSidebar();
        });
    }, [])

    useEffect(()=> {
        // setCurrentProjectDetails(projects[0]);
        if(projectId) { 
            
            setCurrentProjectDetails({});
            setIsProjectDetailsLoading(true);
            const token = localStorage.getItem("token");
    
            if(token) {
                axiosInstance.get(`projectShow/${projectId}`, {
                    headers: {
                        Authorization: "Bearer " +token
                    }
                })

                .then(res => {
                    if(res.data.status === 200) {
                        setCurrentProjectDetails(res.data.payload.data);
                        
                        // console.log('ss', res.data.payload);
                        setIsProjectDetailsLoading(false);
                        const fetchUnitsData = async () => {
                            const fetchUnitsDataResponse = await axiosInstance.get(`/unitByProjectId/${projectId}`)
                            .catch(err => console.log(err))
                            if (fetchUnitsDataResponse && fetchUnitsDataResponse.data.status === 200) {
                                setUnitsData(fetchUnitsDataResponse.data?.payload?.data);
                                setIsProjectDetailsLoading(false);
                                let floorSize = fetchUnitsDataResponse.data?.payload?.floorplanSize;
                                if (!!floorSize) setUsedFloorplanSpace(floorSize);
                            }
                        }
                        fetchUnitsData();
                        const fetchDocuments = async () => {
                            const fetchDocumentsDataResponse = await axiosInstance.get(`/getProjectDocuments/${projectId}`)
                            .catch(err => console.log(err));
                            if (fetchDocumentsDataResponse && fetchDocumentsDataResponse.data.status === 200) {
                                let data = fetchDocumentsDataResponse.data?.payload?.data;
                                if (!!data) {
                                    setUsedDocumentSpace(data.used);
                                    setMarketingDocuments(data.categories);
                                }
                            }
                        }
                        fetchDocuments();
                    }
                })
                .catch(err => {
                    if (err && !err.response) {
                        alert(err);
                        setIsProjectDetailsLoading(false);
                        return;
                    }

                    if(err.response.data.status === 500 && err.response.data.payload) {
                        setIsProjectDetailsLoading(false);
                        // setOpenSnackbar(openSnackbar => !openSnackbar);
                        // setSnackbarType("error");
                        alert(err.response.data.message);
                                
                        // setTimeout(() => {
                            history.push('/');
                        // }, 2000);
        
                        console.log(err);
                    }
                })
            } else {
                setIsProjectDetailsLoading(false);
                localStorage.clear();
                history.push('/login')
            }
        }
    }, [projectId])

    const onToggleMobileSidebar = () => {

        if ($('.sidebar-main').width() == 56) {

            $('.content-wrapper').addClass('content-wrapper-collapse');
        }

        if ($('body').hasClass('sidebar-mobile-main')) {

            $('body').removeClass('sidebar-mobile-main');
        }

        $('.sidebar-main').removeClass('sidebar-expand-toggle');
    }
    
    const onDeletePanoFilesHandler = async (fileIds) => {

        setIsProjectDetailsLoading(true);
        const response = await axiosInstance.post('/projectPanoFilesDelete', {project_id: currentProjectDetails.id, file_ids: fileIds})
        .then(
            logger({
                title:'Delete Project PanoFiles',
                url:window.location.href,
                method:'POST',
                activity: 'projectPanoFilesDelete',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +'/projectPanoFilesDelete',
                session:localStorage.getItem('session'),
                projectId:currentProjectDetails.id,
                unitId:null,
                assignedId:null,
                })              
        )
            .catch(err => {

                if (err && err.response.data.status === 422) {

                    onShowSnackbarWithDetails("error", err.response.data.message, true);
                }

                else if (err && err.response.data.status === 401) {

                    localStorage.clear();
                    history.push('/login');
                }
                
                else if (err && err.response.data.status === 500) {

                    onShowSnackbarWithDetails("error", err.response.data.message, true);
                }

                setIsProjectDetailsLoading(false);
                return;
            })

        if (response && response.data.status === 200) {

            onShowSnackbarWithDetails("success", response.data.message, true);
            const updatedPanoramaList = [...panoramaList];

            for (let id of fileIds) {

                const fileIndex = updatedPanoramaList.findIndex(file => file.id === id);
                updatedPanoramaList.splice(fileIndex,1);
                
            }

            setPanoramaList(updatedPanoramaList);
            setIsProjectDetailsLoading(false);
        }
        

    }

    const onExtractPanoFilesHandler = async (fileIds) => {

        setIsProjectDetailsLoading(true);
        const response = await axiosInstance.post('/projectPanoFilesExtract', {project_id: currentProjectDetails.id, file_ids: fileIds})
        .then(
            logger({
                title:'Extract project PanoFiles',
                url:window.location.href,
                method:'POST',
                activity: 'projectPanoFilesExtract',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +'/projectPanoFilesExtract' ,
                session:localStorage.getItem('session'),
                projectId:currentProjectDetails.id,
                unitId:null,
                assignedId:null,
                })              
        )
            .catch(err => {

                if (err && err.response.data.status === 422) {

                    onShowSnackbarWithDetails("error", err.response.data.message, true);
                }

                else if (err && err.response.data.status === 401) {

                    localStorage.clear();
                    history.push('/login');
                }
                
                else if (err && err.response.data.status === 500) {

                    onShowSnackbarWithDetails("error", err.response.data.message, true);
                }

                setIsProjectDetailsLoading(false);
                return;
            })

        if (response && response.data.status === 200) {

            onShowSnackbarWithDetails("success", response.data.message, true);
            setIsProjectDetailsLoading(false);
        }
        

    }

    const onUploadPanoFile = async (event,extract_file) => {

        setIsProjectDetailsLoading(true);
        
        const files = event.target.files;

        const formData = new FormData();

        for (let i=0; i<files.length; i++) {

            formData.append(`image_files[${i}]`, files[i]);

        }

        formData.append('extract_file', extract_file)
        formData.append('project_id', currentProjectDetails.id)

        const response = await axiosInstance.post('/projectPanoFilesUpload', formData)
        .then(
            logger({
                title:'Upload project PanoFiles',
                url:window.location.href,
                method:'POST',
                activity: 'projectPanoFilesUpload',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +'/projectPanoFilesUpload' ,
                session:localStorage.getItem('session'),
                projectId:currentProjectDetails.id,
                unitId:null,
                assignedId:null,
                })              
        )
        .catch(err => {

            if (err.response.data && err.response.data.status == 422) {

                setSnackbarType("error");

                setOpenSnackbar(true);

                setMessageForSnackbar(err.response.data?.payload?.error);

                setIsProjectDetailsLoading(false);
            }

            return;
        });

        if (response && response.data && response.data.status === 200) {
            
            setPanoramaList([...panoramaList, ...response.data.payload.data])
            
            setIsProjectDetailsLoading(false);

            setSnackbarType("success");
            
            setOpenSnackbar(true);

            setMessageForSnackbar("Files uploaded successfully!")
        }
    }

    const onOpenUploadPanoDialog = async () => {

        setIsProjectDetailsLoading(true);
        const response = await axiosInstance.get('/projectPanoFilesList/' +currentProjectDetails.id, {headers: {Authorization: 'Bearer ' +localStorage.getItem("token")}})
            .catch(err => {

                if (err && err.response.data.status === 401) {

                    localStorage.clear();
                    history.push('/login');
                }

                else if (err && err.response.data.status === 500) {

                    onShowSnackbarWithDetails("error", err.response.data.message, true);
                }

                setIsProjectDetailsLoading(false);
                return;
            })
        
        if (response && response.data.status === 200) {

            setPanoramaList(response.data.payload.data);
            setIsProjectDetailsLoading(false);
            setToggleUploadPanoDialog(true);
        }
    }

    const onCloseUploadPanoDialog = () => {

        setToggleUploadPanoDialog(false);
        setPanoramaList([]);
    }

    const onOpenShareModal = () => {
        setOpenShareModal(true);
    }

    const onUploadExcelFileForUnitsData = async (event) => {
        setIsProjectDetailsLoading(true);

        let fileObj = event.target.files[0];

        if (!fileObj.name.includes("xlsx") && !fileObj.type.includes("xlsx")) {

            onShowSnackbarWithDetails("warning", "Please upload .xlsx file only!", true);
            return;
        }

        const unitsTableData = [];

        readXlsxFile(fileObj).then(async rows => {
            console.log(rows);
            const rowTitles = rows[0].map(title => {
                    
                const modifiedTitle = (title[0].toLowerCase() + title.substring(1)).replace(/\s/g, "")

                if (modifiedTitle === "floorplan") return modifiedTitle.replace('p', 'P')
                
                if (modifiedTitle === "priceinputdate") {

                    let stringToReturn = modifiedTitle.replace("input", "Input");

                    return stringToReturn.replace('d', 'D')
                
                }

                if (modifiedTitle === "priceentrymadeby") {

                    let chars = {  'm': 'M', 'b': 'B' }
                    let stringToReturn = modifiedTitle.replace("entry", "Entry");

                    return stringToReturn.replace(/[mb]/g, ch => chars[ch])
                
                }

                if (modifiedTitle === "pricevalue") return modifiedTitle.replace('v', 'V');
                
                else return modifiedTitle

            });

            const unitsData = rows.slice(1);

            for ( let i=0; i <unitsData.length; i++ ) {

                let data = {};

                for (let j=0; j < rowTitles.length; j++) {
                    if (rowTitles[j].includes("price")) {
                        let priceValue = unitsData[i][j].toString();
                        priceValue = priceValue.replace(/[^0-9.-]+/g,"");

                        data["price"] = {
                            price: priceValue ? priceValue*1 : 0,
                            isActive: true,
                            isDisplayed: false
                        }
                    } else if ((rowTitles[j] === "floorPlan")) {
                        data[rowTitles[j]] = {isDisplayed: false, data: []}
                    } else {
                        data[rowTitles[j]] = unitsData[i][j] !== null ? unitsData[i][j] : "" ;
                    }
                }
                unitsTableData.push(data);
            }
            
            // setUnitsData(unitsTableData);
            
            const unitsResponse = await axiosInstance.post('/unitCreate', {
                project_id: currentProjectDetails.id,
                units: unitsTableData
            })
            .then(
                logger({
                    title:'Create Unit',
                    url:window.location.href,
                    method:'POST',
                    activity: 'unitCreate',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +'/unitCreate' ,
                    session:localStorage.getItem('session'),
                    projectId:currentProjectDetails.id,
                    unitId:null,
                    assignedId:null,
                    })                  
            )
            if (unitsResponse && unitsResponse.data.status === 200) {
                const fetchUnitsDataResponse = await axiosInstance.get(`/unitByProjectId/${projectId}`)
                .catch(err => {
                    console.log(err)
                    setIsProjectDetailsLoading(false);
                })

                if (fetchUnitsDataResponse && fetchUnitsDataResponse.data.status === 200) {
                    setUnitsData(fetchUnitsDataResponse.data?.payload?.data);
                    setIsProjectDetailsLoading(false);
                }
            }
        })
    };

    const onPerformActionForSelectedRowField = async (unitRowId, unitNo, status, actionFor) => {

        const updateUnitFieldResponse = await axiosInstance.post('/unitFieldChange', {
            id: unitRowId,
            fieldName: actionFor,
            fieldValue: status
        })
        .then(
            logger({
                title:'Change Unit Field',
                url:window.location.href,
                method:'POST',
                activity: 'unitFieldChange',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +'/unitFieldChange' ,
                session:localStorage.getItem('session'),
                projectId:null,
                unitId:unitRowId,
                assignedId:null,
                })              
        )
        .catch(err => console.log(err));

        if (updateUnitFieldResponse && updateUnitFieldResponse.data.status === 200) {

            const updatedUnitsData = [...unitsData];

            const selectedRowIndex = updatedUnitsData.findIndex(row => row.unitNo === unitNo);

            updatedUnitsData[selectedRowIndex][actionFor] = status;

            setUnitsData(updatedUnitsData);

        }

    }

    const onPerformBulkOperation = async (actionFor, bulkValue) => {
        
        setIsProjectDetailsLoading(true);

        if (actionFor === "floorPlan" || actionFor === "price") {

            if (actionFor === "price") {

                const response = await axiosInstance.post('/unitUpdateDisplayPrice', {
                    
                    isDisplayed: bulkValue === "Display All" ? true : false,
                    projectId: currentProjectDetails.id,

                })
                .then(
                    logger({
                        title:'Update Unit Display Price',
                        url:window.location.href,
                        method:'POST',
                        activity: 'unitUpdateDisplayPrice',
                        user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                        api:BASE_URL+ API_VERSION +'/unitUpdateDisplayPrice' ,
                        session:localStorage.getItem('session'),
                        projectId:currentProjectDetails.id,
                        unitId:null,
                        assignedId:null,
                        })            
                )
                .catch(err => {
                    
                    if (err.response.data && err.response.data.status == 422) {

                        setSnackbarType("error");
                        
                        setOpenSnackbar(true);
                        
                        setMessageForSnackbar(err.response.data?.payload?.error);
                        
                        setIsProjectDetailsLoading(false);
                    }

                    return;
                });

                if (response && response.data && response.data.status === 200) {

                    if (bulkValue === "Display All") {

                        const unitsDataArray = [...unitsData];

                        for (let data of unitsDataArray) {
                            
                            data[actionFor].isDisplayed = 1;

                        }

                        setUnitsData(unitsDataArray);

                        setIsProjectDetailsLoading(false);
                    }

                    if (bulkValue === "Display None") {

                        const unitsDataArray = [...unitsData];

                        for (let data of unitsDataArray) {

                            data[actionFor].isDisplayed = 0;

                        }

                        setUnitsData(unitsDataArray);

                        setIsProjectDetailsLoading(false);
                    }
                }
            }

            if (actionFor === "floorPlan") {

                const response = await axiosInstance.post('/unitUpdateDisplayFloor', {
                    
                    isDisplayFloor: bulkValue === "Display All" ? true : false,
                    projectId: currentProjectDetails.id,

                }).then(
                    logger({
                        title:'Update Unit Display Floor',
                        url:window.location.href,
                        method:'POST',
                        activity: 'unitUpdateDisplayFloor',
                        user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                        api:BASE_URL+ API_VERSION +'/unitUpdateDisplayFloor' ,
                        session:localStorage.getItem('session'),
                        projectId:currentProjectDetails.id,
                        unitId:null,
                        assignedId:null,
                        })                      
                )
                .catch(err => {
                    
                    if (err.response.data && err.response.data.status == 422) {

                        setSnackbarType("error");
                        
                        setOpenSnackbar(true);
                        
                        setMessageForSnackbar(err.response.data?.payload?.error);
                        
                        setIsProjectDetailsLoading(false);
                    }

                    return;
                });

                if (response && response.data && response.data.status === 200) {

                    if (bulkValue === "Display All") {

                        const unitsDataArray = [...unitsData];

                        for (let data of unitsDataArray) {
                            
                            data[actionFor].isDisplayed = 1;

                        }

                        setUnitsData(unitsDataArray);

                        setIsProjectDetailsLoading(false);
                    }

                    if (bulkValue === "Display None") {

                        const unitsDataArray = [...unitsData];

                        for (let data of unitsDataArray) {

                            data[actionFor].isDisplayed = 0;

                        }

                        setUnitsData(unitsDataArray);

                        setIsProjectDetailsLoading(false);
                    }
                }
            }
        }

        else {

            const updateUnitFieldResponse = await axiosInstance.post('/unitFieldChange', {
                fieldName: actionFor,
                fieldValue: bulkValue
            }).then(
                logger({
                    title:'Change Unit Field',
                    url:window.location.href,
                    method:'POST',
                    activity: 'unitFieldChange',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +'/unitFieldChange' ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:null,
                    assignedId:null,
                    })                      
            )
            .catch(err => {
                
                console.log(err);

                if (err.response.data && err.response.data.status == 422) {

                    setSnackbarType("error");
                    
                    setOpenSnackbar(true);
                    
                    setMessageForSnackbar(err.response.data?.payload?.error);
                    
                    setIsProjectDetailsLoading(false);
                }

                return;
            });

            if (updateUnitFieldResponse && updateUnitFieldResponse.data.status === 200) {

                const unitsDataArray = [...unitsData];

                for (let data of unitsDataArray) {

                    data[actionFor] = bulkValue;

                }

                setUnitsData(unitsDataArray);

                setIsProjectDetailsLoading(false);

            }

        }

    }

    const onSaveNewPrice = async () => {

        if (newPrice.trim().length===0) {

            onShowSnackbarWithDetails("error", "Price cannot be empty!", true);
            
            return;

        }

        setIsProjectDetailsLoading(true);

        let priceValue = newPrice.trim();
        const response = await axiosInstance.post('/unitPriceCreate', {
                unitNo: unitIdToAssign,
                price: priceValue*1,
                isActive: selectedPriceData.data.length === 0 ? true : false,
                isDisplayed: false
            }).then(
                logger({
                    title:'Create Unit Price',
                    url:window.location.href,
                    method:'POST',
                    activity: 'unitPriceCreate',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +'/unitPriceCreate' ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:unitIdToAssign,
                    assignedId:null,
                    })                      
            )
            .catch(err => {

                console.log(err);

                if (err.response.data && err.response.data.status == 422) {

                    setSnackbarType("error");
                    
                    setOpenSnackbar(true);
                    
                    setMessageForSnackbar(err.response.data?.payload?.error);
                    
                    setIsProjectDetailsLoading(false);
                }

                return;
            });

        if (response && response.data && response.data.status === 200) {

            const unitsTableData = [...unitsData];

            let currentDataIndex = unitsTableData.findIndex(data => data.id === unitIdToAssign)

            if (selectedPriceData.data.length === 0) {
            
                const newPriceData = {

                    id: response.data.payload?.data[0]?.id,

                    isActive: 1,

                    inputDate: new Date(response.data.payload?.data[0]?.created_at).toLocaleString(),

                    "entryMadeBy": response.data.payload?.data[0]?.user.fullname,

                    price: priceValue

                }
                
                setSelectedPriceData({...selectedPriceData, data: [newPriceData]});

                unitsTableData[currentDataIndex].price = { ...unitsTableData[currentDataIndex].price, data : [newPriceData] }
                
                setUnitsData(unitsTableData);

                if (!openUnitNumberDialog) {

                    setSelectedPriceData({ data: [], isDisplayed: 0 });

                    setSelectedFloorPlanData({ data: [], isDisplayed: 0 });

                }

            } 
            
            else {

                const newPriceData = [...selectedPriceData.data];

                const priceData = {
                    id: response.data.payload?.data[0]?.id,
                    isActive: 0,
                    inputDate: new Date(response.data.payload?.data[0]?.created_at).toLocaleString(),
                    "entryMadeBy": response.data.payload?.data[0]?.user.fullname,
                    price: priceValue

                }

                newPriceData.push(priceData)

                setSelectedPriceData({...selectedPriceData, data: newPriceData});
                
                unitsTableData[currentDataIndex].price.data.push(priceData);
                
                setUnitsData(unitsTableData);

            }

            setSnackbarType("success");

            setOpenSnackbar(true);

            setMessageForSnackbar("Price Added!");

            setIsProjectDetailsLoading(false);

            setOpenNewPriceDialog(false);

        }
    }

    const onCloseNewPriceDialog = () => {

        setNewPrice("");

        setOpenNewPriceDialog(false);

    }
    
    const onChangeActiveStatus = async (event) => {

        const dataFor = event.target.name;
        const dataValue = event.target.value;

        setIsProjectDetailsLoading(true);

        const response = await axiosInstance.post('/unitIsDisplayedChange', {
            dataFor: dataFor === "prices" ? "price" : dataFor,
            isDisplayed: dataValue === "false" ? false : true,
            unitNo: unitIdToAssign
        }).then(
            logger({
                title:'Change Unit IsDisplayed',
                url:window.location.href,
                method:'POST',
                activity: 'unitIsDisplayedChange',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +'/unitIsDisplayedChange' ,
                session:localStorage.getItem('session'),
                projectId:null,
                unitId:unitIdToAssign,
                assignedId:null,
                })                     
        )
            .catch(err => {

                console.log(err);

                if (err.response.data && err.response.data.status == 422) {

                    setSnackbarType("error");
                    
                    setOpenSnackbar(true);
                    
                    setMessageForSnackbar(err.response.data?.payload?.error);
                    
                    setIsProjectDetailsLoading(false);
                }

                return;
            });

        if (response && response.data && response.data.status === 200) { 

            if (dataFor === "floorPlan") {

                setSelectedFloorPlanData({
                    isDisplayed: dataValue === "false" ? 0 : 1,
                    data: selectedFloorPlanData.data
                })

                const unitDataIndex = unitsData.findIndex(unit => unit.id === unitIdToAssign);

                const unitsDataCopy = [...unitsData];

                unitsDataCopy[unitDataIndex].floorPlan = {
                    isDisplayed: dataValue === "false" ? 0 : 1,
                    data: selectedFloorPlanData.data
                };

                setUnitsData(unitsDataCopy);

                setIsProjectDetailsLoading(false);

                setSnackbarType("success");

                setOpenSnackbar(true);

                setMessageForSnackbar(`Floor Plan for unit no. ${unitNumberToAssign} is ${dataValue === "false" ? 'not visible' : 'visible'} now!`);
            }

            if (dataFor === "prices") {

                setSelectedPriceData({
                    isDisplayed: dataValue === "false" ? 0 : 1,
                    data: selectedPriceData.data
                })

                const unitDataIndex = unitsData.findIndex(unit => unit.id === unitIdToAssign);

                const unitsDataCopy = [...unitsData];

                unitsDataCopy[unitDataIndex].price = {
                    isDisplayed: dataValue === "false" ? 0 : 1,
                    data: selectedPriceData.data
                };

                setUnitsData(unitsDataCopy);

                setIsProjectDetailsLoading(false);

                setSnackbarType("success");

                setOpenSnackbar(true);

                setMessageForSnackbar(`Price for unit no. ${unitNumberToAssign} is ${dataValue === "false" ? 'not visible' : 'visible'} now!`);

            }   
        }
    }

    const onUploadFileForFloorPlan = async (file) => {

        setIsProjectDetailsLoading(true);

        const fileName = file.name;
        
        const formData = new FormData();

        formData.append('file', file);
        formData.append('unitId', unitIdToAssign);
        formData.append('isActive', selectedFloorPlanData.data.length === 0 ? true : false);
        formData.append('isDisplayFloor', false);
        
        const response = await axiosInstance.post('/unitFloorCreate', formData)
        .then(
            logger({
                title:'Create Unit Floor',
                url:window.location.href,
                method:'POST',
                activity: 'unitFloorCreate',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +'/unitFloorCreate' ,
                session:localStorage.getItem('session'),
                projectId:null,
                unitId:unitIdToAssign,
                assignedId:null,
                })                      
        )
            .catch(err => {
                console.log(err);
                if (err.response.data && err.response.data.status == 422) {
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                    setMessageForSnackbar(err.response.data?.payload?.error);
                    setIsProjectDetailsLoading(false);
                }
                return;
            });

        if (response && response.data && response.data.status === 200) {
            const unitsTableData = [...unitsData];
            let currentDataIndex = unitsTableData.findIndex(data => data.id === unitIdToAssign)
            if (selectedFloorPlanData.data.length === 0) {
                const newFloorPlanData = {
                    id: response.data.payload?.data[0]?.id,
                    isActive: 1,
                    inputDate: response.data.payload?.data[0]?.created_at,
                    "entryMadeBy": response.data.payload?.data[0]?.user.fullname,
                    file: response.data.payload?.data[0]?.file,
                }       

                unitsTableData[currentDataIndex].floorPlan = { ...unitsTableData[currentDataIndex].floorPlan, data : [newFloorPlanData] }
                
                setUnitsData(unitsTableData);
                setSelectedFloorPlanData({...selectedFloorPlanData, data: [newFloorPlanData]})
                setUnitsData(unitsTableData);

                if (!openUnitNumberDialog) {
                    setSelectedPriceData({ data: [], isDisplayed: 0 });
                    setSelectedFloorPlanData({ data: [], isDisplayed: 0 });
                }

                if (!openUnitNumberDialog) {
                    setSelectedPriceData({ data: [], isDisplayed: 0 });
                    setSelectedFloorPlanData({ data: [], isDisplayed: 0 });
                }
            }

            else {
                const newFloorPlanData = [...selectedFloorPlanData.data];
                const floorPlanData = {
                    id: response.data.payload?.data[0]?.id,
                    isActive: 0,
                    inputDate: response.data.payload?.data[0]?.created_at,
                    "entryMadeBy": response.data.payload?.data[0]?.user.fullname,
                    file: response.data.payload?.data[0]?.file,
                };

                newFloorPlanData.push(floorPlanData);
                setSelectedFloorPlanData({...selectedFloorPlanData, data: newFloorPlanData})
                unitsTableData[currentDataIndex].floorPlan.data.push(newFloorPlanData);
                setUnitsData(unitsTableData);
            }

            let used = response.data.payload?.used;
            if (!!used) setUsedFloorplanSpace(used);

            setSnackbarType("success");
            setOpenSnackbar(true);
            setMessageForSnackbar(response.data.message);
            setIsProjectDetailsLoading(false);
        }

    }

    const onSaveUnitsTableData = (dataFor) => {

        const currentRow = unitsData.find(row => row.id === unitIdToAssign);

        if (dataFor === "floorPlan") {

            currentRow.floorPlan = {...selectedFloorPlanData};
            
        }

        if (dataFor === "prices") {

            currentRow.price = {...selectedPriceData};

        }

    }

    const onDeleteSelectedData = async (dataFor) => {

        let updatedData = [];
        
        setIsProjectDetailsLoading(true);

        if (dataFor === "floorPlan") {

            const response = await axiosInstance.delete(`/unitFloorDelete/${floorPlanIdToActivate}`)
            .then(
                logger({
                    title:'Delete Unit Floor',
                    url:window.location.href,
                    method:'DELETE',
                    activity: 'unitFloorDelete',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +`/unitFloorDelete/${floorPlanIdToActivate}` ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:unitIdToAssign,
                    assignedId:null,
                    })                      
            )    
            .catch(err => {
                console.log(err);
                if (err.response.data && err.response.data.status == 422) {
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                    setMessageForSnackbar(err.response.data?.payload?.error);
                    setIsProjectDetailsLoading(false);
                }
                return;
            })

            if (response && response.data && response.data.status === 200) {
                updatedData = [...selectedFloorPlanData.data];
                setSelectedFloorPlanData({...selectedFloorPlanData, data: updatedData.filter(floorPlan => floorPlan.id !== floorPlanIdToActivate)});
                let unitsTableData = [...unitsData];
                let currentDataIndex = unitsTableData.findIndex(data => data.id === unitIdToAssign);

                if (currentDataIndex !== -1) {
                    unitsTableData[currentDataIndex].floorPlan.data = unitsTableData[currentDataIndex].floorPlan.data.filter(floorPlan => floorPlan.id !== floorPlanIdToActivate);
                    setUnitsData(unitsTableData);
                    setIsProjectDetailsLoading(false);
                    setSnackbarType("success");
                    setOpenSnackbar(true);
                    setMessageForSnackbar("Floor Plan Deleted!");
                    setOpenDeleteConfirmationDialog(false);
                    setFloorPlanIdToActivate("");
                }

                let freed = response.data.payload?.freed;
                if (!!freed) {
                    setUsedFloorplanSpace(usedFloorplanSpace - freed);
                }
            }
        }
        else if (dataFor === "prices") {
            const response = await axiosInstance.delete(`/unitPriceDelete/${priceIdToActivate}`)
            logger({
                title:'Delete Unit Price',
                url:window.location.href,
                method:'DELETE',
                activity: 'unitPriceDelete',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +`/unitPriceDelete/${priceIdToActivate}` ,
                session:localStorage.getItem('session'),
                projectId:null,
                unitId:unitIdToAssign,
                assignedId:null,
                })                      
            .catch(err => {
                console.log(err);
                if (err.response.data && err.response.data.status == 422) {
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                    setMessageForSnackbar(err.response.data?.payload?.error);
                    setIsProjectDetailsLoading(false);
                }
                return;
            })

            if (response && response.data && response.data.status === 200) {
                updatedData = [...selectedPriceData.data];
                setSelectedPriceData({...selectedPriceData, data: updatedData.filter(price => price.id !== priceIdToActivate)});
                let unitsTableData = JSON.parse(JSON.stringify(unitsData));
                let currentDataIndex = unitsTableData.findIndex(data => data.id === unitIdToAssign);
                if (currentDataIndex !== -1) {
                    unitsTableData[currentDataIndex].price.data = unitsTableData[currentDataIndex].price.data.filter(price => price.id !== priceIdToActivate);
                    setUnitsData(unitsTableData);
                    setIsProjectDetailsLoading(false);
                    setSnackbarType("success");
                    setOpenSnackbar(true);
                    setMessageForSnackbar("Price Deleted!");
                    setOpenDeleteConfirmationDialog(false);
                    setPriceIdToActivate("");
                }
            }
        }
        else if (dataFor === "document") {
            if (!documentToActivate) return;

            setIsProjectDetailsLoading(true);
            axiosInstance.post('/deleteDocumentFile', {file_ids: [documentToActivate.id]})
            .then(response => {
                logger({
                    title:'Delete Document File',
                    url:window.location.href,
                    method:'POST',
                    activity: 'deleteDocumentFile',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +'/deleteDocumentFile' ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:null,
                    assignedId:null,
                    })                      
                setIsProjectDetailsLoading(false);
                setOpenDeleteConfirmationDialog(false);
                if (response && response.data && response.data.status === 200) {
                    setSnackbarType("success");
                    setOpenSnackbar(true);
                    setMessageForSnackbar("File deleted successfully!")
                    
                    const new_marketing_documents = [...marketingDocuments];
                    const currentCategory = new_marketing_documents.find(category => category.id == documentToActivate.category_id);
                    if (!currentCategory) return;
                    let idx = currentCategory.files.findIndex(doc => doc.id == documentToActivate.id);
                    if (idx >= 0) currentCategory.files.splice(idx, 1);
                    setMarketingDocuments(new_marketing_documents);
                    let deleted_size = response.data?.payload?.deleted_size;
                    if (!!deleted_size) {
                        setUsedDocumentSpace(usedDocumentSpace - deleted_size);
                    }
                } else {
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                    setMessageForSnackbar(response.data.message);
                }
            }).catch(err => {
                if (err.response.data && err.response.data.status == 422) {
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                    setMessageForSnackbar(err.response.data?.payload?.error);
                }
                setIsProjectDetailsLoading(false);
                setOpenDeleteConfirmationDialog(false);
            });
        }
    }

    const onActivateSelectedData = async (dataFor) => {

        setIsProjectDetailsLoading(true);

        let updatedData = [];
        let selectedId = "";

        if (dataFor === "floorPlan") {

            const response = await axiosInstance.post(`/unitFloorActivated/${floorPlanIdToActivate}`, {
                unitNo: unitIdToAssign,
                isActive: true
            })
            .then(
                logger({
                    title:'unitFloorActivated',
                    url:window.location.href,
                    method:'POST',
                    activity: 'unitFloorActivated',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +`/unitFloorActivated/${floorPlanIdToActivate}` ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:unitIdToAssign,
                    assignedId:null,
                    })                      
            )
            .catch(err => {

                console.log(err);

                if (err.response.data && err.response.data.status == 422) {

                    setSnackbarType("error");

                    setOpenSnackbar(true);

                    setMessageForSnackbar(err.response.data?.payload?.error);

                    setIsProjectDetailsLoading(false);
                }

                return;
            })

            if (response && response.data && response.data.status === 200) {

                updatedData = [...selectedFloorPlanData.data];
                selectedId = floorPlanIdToActivate;

            }
        } 

        if (dataFor === "prices") {

            const response = await axiosInstance.post(`/unitPriceActivated/${priceIdToActivate}`, {
                unitNo: unitIdToAssign,
                isActive: true
            })
            .then(
                logger({
                    title:'Activated Unit Price',
                    url:window.location.href,
                    method:'POST',
                    activity: 'unitPriceActivated',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +`/unitPriceActivated/${priceIdToActivate}` ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:unitIdToAssign,
                    assignedId:null,
                    })                      
            )
            .catch(err => {

                console.log(err);

                if (err.response.data && err.response.data.status == 422) {

                    setSnackbarType("error");

                    setOpenSnackbar(true);

                    setMessageForSnackbar(err.response.data?.payload?.error);

                    setIsProjectDetailsLoading(false);

                }

                return;
            })

            if (response && response.data && response.data.status === 200) {

                updatedData = [...selectedPriceData.data];
                selectedId = priceIdToActivate

            }
        }

        const currentActiveDataIndex = updatedData.findIndex(data => data.isActive);

        if (currentActiveDataIndex !== -1) {

            updatedData[currentActiveDataIndex].isActive = false;

        }

        const newActiveDataIndex = updatedData.findIndex(data => data.id === selectedId);

        updatedData[newActiveDataIndex].isActive = true;

        if (dataFor === "floorPlan") {

            setSelectedFloorPlanData({...selectedFloorPlanData, data: updatedData});

            setSnackbarType("success");

            setOpenSnackbar(true);

            setMessageForSnackbar("Floor Plan Activated!");

            setIsProjectDetailsLoading(false);

            const unitDataIndex = unitsData.findIndex(unit => unit.id === unitIdToAssign);

            const unitsDataCopy = [...unitsData];

            unitsDataCopy[unitDataIndex].floorPlan = selectedFloorPlanData;

            setUnitsData(unitsDataCopy);

        }

        if (dataFor === "prices") {

            setSelectedPriceData({...selectedPriceData, data: updatedData});

            setSnackbarType("success");

            setOpenSnackbar(true);

            setMessageForSnackbar("Price Activated!");

            setIsProjectDetailsLoading(false);

            const unitDataIndex = unitsData.findIndex(unit => unit.id === unitIdToAssign);

            const unitsDataCopy = [...unitsData];

            unitsDataCopy[unitDataIndex].price = selectedPriceData;

            setUnitsData(unitsDataCopy);

        }

        setOpenActivateConfirmationDialog(false);

    }

    const onOpenUnitNumberDialog = (dataFor, data) => {
        let unitNumber = data.unitNo && data.unitNo !== '0' ? data.unitNo : data.masterUnitNo;

        if(dataFor === "floorPlan") {
            setSelectedFloorPlanData(data.floorPlan);
            setUnitNumberToAssign(unitNumber);
            setUnitIdToAssign(data.id);
            setOpenUnitNumberDialog(true);
            setShowDataFor("floorPlan");
        }

        if(dataFor === "prices") {
            setSelectedPriceData(data.price);
            setUnitNumberToAssign(unitNumber);
            setUnitIdToAssign(data.id);
            setOpenUnitNumberDialog(true);
            setShowDataFor("prices");
        }
    }

    const onCloseUnitNumberDialog = () => {
        setSelectedPriceData({ data: [], isDisplayed: 0 });
        setSelectedFloorPlanData({ data: [], isDisplayed: 0 });
        setUnitNumberToAssign("");
        setUnitIdToAssign("");
        setShowDataFor("");
        setOpenUnitNumberDialog(false);
        setFloorPlanIdToActivate("");
        setPriceIdToActivate("");
    }

    const onCloseAssignUnitNumberDialog = () => {
        setUserList([]);
        setRoleForAssignUnitNumber("");
        setUnitNumberToAssign("");
        setUnitIdToAssign("");
        setUsersAssignedForUnitNumber([]);
        setOpenAssignUnitNumberDialog(false);
    }

    const onOpenAssignUnitNumberDialog = async (role, unitNumber, unitId) => {

        setIsProjectDetailsLoading(true);
        const response = await axiosInstance.post('/userListsByUnit', {
            role: role,
            unitId: unitId,
        }).catch(err => {
            console.log(err);
            return;
        });

        if (response && response.data && response.data.payload) {
            let users = response.data.payload.data;
            setUserList(users);
            setIsProjectDetailsLoading(false);
            setOpenAssignUnitNumberDialog(true);
            setRoleForAssignUnitNumber(role);
            setUnitNumberToAssign(unitNumber);
            setUsersAssignedForUnitNumber(users.filter(user => user.assigned_unit.length > 0).map(user => user.id));
            setUnitIdToAssign(unitId);
        }
    }

    const onSelectUserForUnitNumber = (userId) => {
        if (usersAssignedForUnitNumber.findIndex(id => id === userId) === -1) {
            setUsersAssignedForUnitNumber([userId, ...usersAssignedForUnitNumber])
        } 
        else {
            setUsersAssignedForUnitNumber(usersAssignedForUnitNumber.filter(id => id !== userId));
        }
    }

    const onAssignForUnitNumberHandler = () => {
        let oldAssignedUsers = userList.filter(user => user.assigned_unit.length > 0).map(user => user.id);
        let cancelled_users = oldAssignedUsers.filter(uid => usersAssignedForUnitNumber.indexOf(uid) === -1);
        let newAssignedUsers = usersAssignedForUnitNumber.filter(uid => oldAssignedUsers.indexOf(uid) === -1);
        if(cancelled_users.length>0 || newAssignedUsers.length > 0) {
            setIsProjectDetailsLoading(true);

            axiosInstance.post('/units/assign', {
                id: unitIdToAssign,
                users: newAssignedUsers,
                roles: [roleForAssignUnitNumber],
                cancelled: cancelled_users,
            })
            .then(response => {
                logger({
                    title:'Unit To Assign',
                    url:window.location.href,
                    method:'POST',
                    activity: 'assign units',
                    user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                    api:BASE_URL+ API_VERSION +'/units/assign' ,
                    session:localStorage.getItem('session'),
                    projectId:null,
                    unitId:unitIdToAssign,
                    assignedId:newAssignedUsers,
                    })                      
    
                if (response && response.data && response.data.status === 200) {
                    setSnackbarType("success");
                    setOpenSnackbar(true);
                    onShowSnackbarWithDetails("success", "Users Assigned Successfully", true);
                } else {
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                    setMessageForSnackbar(response.data.message);
                }
                onCloseAssignUnitNumberDialog();
                setIsProjectDetailsLoading(false);
            })
            .catch(err => {
                if (err.response.data && err.response.data.status == 422) {
                    console.log(err);
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                    setMessageForSnackbar(err.response.data?.payload?.error);
                }
                onCloseAssignUnitNumberDialog();
                setIsProjectDetailsLoading(false);
            })
        }
        
        else {
            // onShowSnackbarWithDetails("info", "Select atleast one user to assign!", true);
        }
    }

    // const onOpenAddProjectDialogHandler = () => {
    
    //     setSelectedProject(null);
    
    //     setOpenAddProjectDialog(true);
    
    // };

    // const fileChangedHandler = async (files) => {
    
    //     const filePromises = files.map(toBase64);
    
    //     const convertedFiles = await Promise.all(filePromises);
        
    //     // const mappedFiles = filePaths.map((base64File) => ({ selectedFile: base64File }));
        
    //     const findProject = projectsList.findIndex(proj => proj.id === selectedProject.id);
    
    //     const copyProj = [...projectsList];
    
    //     copyProj[findProject] = {
    
    //         ...copyProj[findProject],
    
    //         marketing_files: copyProj[findProject].marketing_files && copyProj[findProject].marketing_files.concat(convertedFiles)
    
    //     }
    
    //     setProjectsList(copyProj);
    
    //     console.log(convertedFiles);
    
    //     // return mappedFiles;
    
    // }

    // const toBase64 = (file) => {
    
    //     let validPattern = /image-*/;
    
    //     if (file.type.match(validPattern)) {
    
    //         return new Promise((resolve, reject) => {
    
    //             const reader = new FileReader();
    
    //             reader.readAsDataURL(file);
    
    //             reader.onload = () => resolve({
    
    //                 name: file.name,
    
    //                 path: reader.result
    
    //             });
    
    //             reader.onerror = error => reject(error);
    
    //         });
    
    //     }
    
    // };

    const onDeleteProjectHandler = async (projectId) => {

        setProjectsList(

            projectsList.filter(project => project.id !== projectId)

        );

        const deletedProject = 
        await axiosInstance.delete(`/projectDestroy/${projectId}`)
        .then(
            logger({
                title:'Destroy Project',
                url:window.location.href,
                method:'DELETE',
                activity: 'projectDestroy',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +`/projectDestroy/${projectId}`,
                session:localStorage.getItem('session'),
                projectId:projectId,
                unitId:null,
                assignedId:null,
                })                      
        )
            .catch(err => {
                console.log(err);
                
                setOpenSnackbar(openSnackbar => !openSnackbar);

                setSnackbarType("error");

                setMessageForSnackbar(err.response.data.message);

                return;
        })

        if(deletedProject && deletedProject.data.status === 200) {
            setOpenDeleteProjectDialog(false);

            setOpenSnackbar(openSnackbar => !openSnackbar);

            setSnackbarType("success");

            setMessageForSnackbar("Project Deleted Successfully");

            setTimeout(()=> {
                
                history.push('/')

            }, 500);
        }

    };

    const onOpenDeleteProjectDialogHandler = (project) => {

        setOpenDeleteProjectDialog(true);

        setSelectedProject(project);

    };

    const onCloseDeleteProjectDialogHandler = () => {

        setOpenDeleteProjectDialog(false);

    };

    const onDeployProjectHandler = (projectId) => {
        setIsProjectDetailsLoading(true);
        axiosInstance.post(`/projectDeploy/${projectId}`)
        .then(response => {
            logger({
                title:'Deploy Project',
                url:window.location.href,
                method:'POST',
                activity: 'projectDeploy',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +`/projectDestroy/${projectId}`,
                session:localStorage.getItem('session'),
                projectId:projectId,
                unitId:null,
                assignedId:null,
                })                      
    
            setIsProjectDetailsLoading(false);
            setOpenDeployProjectDialog(false);
            setOpenSnackbar(openSnackbar => !openSnackbar);
            setSnackbarType("success");
            setMessageForSnackbar("Project Deployed Successfully");
            setTimeout(()=> {
                window.location.reload();
            }, 500);
        })
        .catch(err => {
            console.log(err);
            setIsProjectDetailsLoading(false);
            setOpenSnackbar(openSnackbar => !openSnackbar);
            setSnackbarType("error");
            setMessageForSnackbar(err.response.data.message);
        });
    };

    const onOpenDeployProjectDialogHandler = (project) => {
        setOpenDeployProjectDialog(true);
        setSelectedProject(project);
    };

    const onCloseDeployProjectDialogHandler = () => {
        setOpenDeployProjectDialog(false);
    };


    const assignProjectStaff = (staffList) => {

        const findProjectIndex = projectsList.findIndex(proj => proj.id === selectedProject.id);

        const copyProjectList = [...projectsList];

        copyProjectList[findProjectIndex] = {

            ...copyProjectList[findProjectIndex],

            staff: staffList

        };

        setProjectsList(copyProjectList);

    }

    const assignProjectMaster = (master) => {

        const findProjectIndex = projectsList.findIndex(proj => proj.id === selectedProject.id);

        const copyProjectList = [...projectsList];

        copyProjectList[findProjectIndex] = {

            ...copyProjectList[findProjectIndex],

            master: master

        };

        setProjectsList(copyProjectList);

    }

    // const addNewlyAddedMarketingImages = (fileList) => {

    //     const findProjectIndex = projectsList.findIndex(proj => proj.id === selectedProject.id);

    //     const copyProjectList = [...projectsList];

    //     copyProjectList[findProjectIndex] = {

    //         ...copyProjectList[findProjectIndex],

    //         marketing_files: fileList

    //     };

    //     setProjectsList(copyProjectList);

    // }

    // const handleClose = (event, reason) => {

    //     if (reason === 'clickaway') {

    //         return;

    //     }

    //     setOpen(false);

    // };

    const onShowSnackbarWithDetails = (snackbarType, snackbarMessage, isSnackbarOpen) => {

        setSnackbarType(snackbarType);

        setOpenSnackbar(isSnackbarOpen);

        setMessageForSnackbar(snackbarMessage);

    }

    const handleSnackbarClose = () => {

        setOpenSnackbar(false);

        setSnackbarType("");

        setMessageForSnackbar("");

    }

    const onMarketingDocumentChangeHandler = (event) => {
        const category_id = event.target.id.split("upload-file-")[1];
        const elem = document.getElementById(event.target.id);

        let formdata = new FormData();
        formdata.append('project_id', projectId);
        formdata.append('category_id', category_id);
        for (let i=0; i<event.target.files.length; i++) {
            formdata.append(`files[${i}]`, event.target.files[i]);
        }

        setIsProjectDetailsLoading(true);
        axiosInstance.post('/uploadDocumentFiles', formdata)
        .then(response => {
            logger({
                title:'Upload Document Files',
                url:window.location.href,
                method:'POST',
                activity: 'uploadDocumentFiles',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +'/uploadDocumentFiles',
                session:localStorage.getItem('session'),
                projectId:null,
                unitId:null,
                assignedId:null,
                })                          
            setIsProjectDetailsLoading(false);
            elem.value = '';
            if (response && response.data && response.data.status === 200) {
                setSnackbarType("success");
                setOpenSnackbar(true);
                setMessageForSnackbar(response.data.message)

                const new_marketing_documents = [...marketingDocuments];
                const currentMarketingDocument = new_marketing_documents.find(category => category.id == category_id);
                if (!currentMarketingDocument) return;
                let data = response.data.payload?.data;
                if (!!data) {
                    currentMarketingDocument.files = [...currentMarketingDocument.files, ...data.files];
                    setMarketingDocuments(new_marketing_documents)
                    setUsedDocumentSpace(data.used);
                }
            } else {
                setSnackbarType("error");
                setOpenSnackbar(true);
                setMessageForSnackbar(response.data.message);
            }
        }).catch(err => {
            if (err.response.data && err.response.data.status == 422) {
                setSnackbarType("error");
                setOpenSnackbar(true);
                setMessageForSnackbar(err.response.data?.payload?.error);
            }
            setIsProjectDetailsLoading(false);
            elem.value = '';
        });

    }

    const onDocumentDownloadHandler = (id, filename) => {
        setIsProjectDetailsLoading(true);
        axiosInstance.get('/downloadDocumentFile/'+id)
        .then(response => {
            logger({
                title:'Download Document Files',
                url:window.location.href,
                method:'GET',
                activity: 'downloadDocumentFile',
                user_id:localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).id: null,
                api:BASE_URL+ API_VERSION +'/downloadDocumentFile',
                session:localStorage.getItem('session'),
                projectId:null,
                unitId:null,
                assignedId:null,
                })                          
            setIsProjectDetailsLoading(false);
            if (response && response.data) {
                download_from_stream(response.data, filename);
                setSnackbarType("success");
                setOpenSnackbar(true);
                setMessageForSnackbar("File downloaded successfully!")
            } else {
                setSnackbarType("error");
                setOpenSnackbar(true);
                setMessageForSnackbar(response.data.message);
            }
        }).catch(err => {
            if (err.response.data && err.response.data.status == 422) {
                setSnackbarType("error");
                setOpenSnackbar(true);
                setMessageForSnackbar(err.response.data?.payload?.error);
            }
            setIsProjectDetailsLoading(false);
        });
    }

    const onDocumentDeleteHandler = (id, category_id) => {
        setDocumentToActivate({ id, category_id });
        setShowDataFor('document');
        setOpenDeleteConfirmationDialog(true);
    }

    const onDocumentUpdateSuccess = (updateStorageSpace) => {
        if (currentProjectDetails){
            setCurrentProjectDetails({...currentProjectDetails, documentSpace : updateStorageSpace})
        }
    }
    const onFloorPlanUpdateSuccess = (updateStorageSpace) => {
        if (currentProjectDetails){
            setCurrentProjectDetails({...currentProjectDetails, floorplanSpace : updateStorageSpace})
        }
    }
    return (
        
        <div className="page-content">

            <Menubar />

            <div className="content-wrapper">

                <ProjectsHeader />

                <LoadingSpinner isLoading={isProjectDetailsLoading}/>

                <div className="content">
                {
                    
                    userContext.user
                    // userContext.user && 
                    // (([USER_ROLES.ADMIN, USER_ROLES.PM, USER_ROLES.MA, USER_ROLES.UM].indexOf(userContext.user.role) !== -1)
                    // || userContext.user.plan > 0)
                    ?
                    <div className="row">

                        <div className={classes.root}>

                            <div className={classes.projectHeader}>

                            {currentProjectDetails && currentProjectDetails.id ? 
                            
                                <div className={`${classes.idSection} mr-3`}>
                                    <Chip
                                        icon={<ProjectIcon className={classes.projectIcon}/>}
                                        label={
                                            <span className="d-flex align-items-center" style={{fontSize:"15px", letterSpacing :"0.02em"}}>
                                                {`PROJECT ID : ${currentProjectDetails.id}`}
                                                <div className={classes.onlineBadge}>
                                                    <span className={`${classes.onlineStatus} font-weight-bolder`}>ONLINE</span>
                                                </div>
                                            </span>
                                        }
                                        className={classes.chip}
                                        deleteIcon={<Done/>}
                                        variant="outlined"
                                    />
                                </div>
                            : null }

                            { currentProjectDetails.threeDUrl ? 

                                <div className={`${classes.urlSection}`}>

                                    <Button

                                        className={`${classes.shareButton} mr-2 mb-4`}
                                
                                        variant="contained"
                                        
                                    >
                                
                                        URL : 
                                
                                        <span className="ml-1 mr-1" style={{textTransform:"lowercase"}}> {currentProjectDetails.threeDUrl}</span>
                                        <span className="mx-1" onClick={(e) => {e.stopPropagation(); onOpenShareModal()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"/></svg>
                                        </span>
                                        
                                        <CopyToClipboard 
                                        
                                            text={currentProjectDetails.threeDUrl} 
                                            
                                            onCopy={() => {

                                                setSnackbarType("info");

                                                setOpenSnackbar(true);

                                                setMessageForSnackbar("3D URL copied to clipboard!")

                                            }}>

                                            <FileCopy className={classes.copyIcon}/>

                                        </CopyToClipboard>
                                
                                    </Button>

                                </div>

                                : null

                            }

                            </div>
                            
                            <ProjectDetailsAccordion                             
                                setSelectedProject={setSelectedProject}
                                setOpenAddProjectDialog={setOpenAddProjectDialog}
                                project={currentProjectDetails}
                                onOpenUploadPanoDialog={onOpenUploadPanoDialog}
                                setAssignModal={setAssignModal}  
                                onOpenDeployProjectDialogHandler={onOpenDeployProjectDialogHandler}
                                onOpenDeleteProjectDialogHandler={onOpenDeleteProjectDialogHandler}
                                setOpenAssignManagerProject={setOpenAssignManagerProject}
                            />

                            <UploadPanoDialog

                                toggleUploadPanoDialog={toggleUploadPanoDialog}

                                onCloseUploadPanoDialog={onCloseUploadPanoDialog}

                                panoramaList={panoramaList}

                                onUploadPanoFile={onUploadPanoFile}

                                onDeletePanoFilesHandler={onDeletePanoFilesHandler}

                                onExtractPanoFilesHandler={onExtractPanoFilesHandler}
                            />
                            
                            <ProjectDocumentsAccordion
                                projectId={currentProjectDetails.id}
                                deployed={!!currentProjectDetails.deployedAt}
                                userRole={userRole}
                                document_size={(usedDocumentSpace/1048576).toFixed(1)}
                                total_document_size={currentProjectDetails.documentSpace}
                                floorplan_size={(usedFloorplanSpace/1048576).toFixed(1)}
                                total_floorplan_size={currentProjectDetails.floorplanSpace}
                                onMarketingDocumentChangeHandler={onMarketingDocumentChangeHandler}
                                onDocumentDownloadHandler={onDocumentDownloadHandler}
                                onDocumentDeleteHandler={onDocumentDeleteHandler}
                                marketingDocuments={marketingDocuments}
                                onDocumentUpdateSuccess={onDocumentUpdateSuccess}      
                                onFloorPlanUpdateSuccess={onFloorPlanUpdateSuccess}           
                            />

                            <AppartmentAccordion 
                                // projectUnits={currentProjectDetails.units ? currentProjectDetails.units : []}
                                projectUnits = {unitsData ? unitsData : []} 
                                onOpenAssignUnitNumberDialog={onOpenAssignUnitNumberDialog}
                                onOpenUnitNumberDialog={onOpenUnitNumberDialog}
                                onPerformBulkOperation={onPerformBulkOperation}
                                projectId={currentProjectDetails.id}
                                onUploadExcelFileForUnitsData={onUploadExcelFileForUnitsData}
                                setOpenNewPriceDialog={setOpenNewPriceDialog}
                                onUploadFileForFloorPlan={onUploadFileForFloorPlan}
                                setUnitNumberToAssign={setUnitNumberToAssign}
                                setUnitIdToAssign={setUnitIdToAssign}
                                onPerformActionForSelectedRowField={onPerformActionForSelectedRowField}
                                userRole={userRole}
                            />

                            <AssignUnitNumberDialog
                                userList={userList}
                                openAssignUnitNumberDialog={openAssignUnitNumberDialog}
                                onCloseAssignUnitNumberDialog={onCloseAssignUnitNumberDialog}
                                role={roleForAssignUnitNumber}
                                unitNumber={unitNumberToAssign}
                                onSelectUserForUnitNumber={onSelectUserForUnitNumber}
                                usersAssignedForUnitNumber={usersAssignedForUnitNumber}
                                onAssignForUnitNumberHandler={onAssignForUnitNumberHandler}
                            />

                            <UnitNumberDialog
                                unitNumber = {unitNumberToAssign}
                                openUnitNumberDialog = {openUnitNumberDialog}
                                onCloseUnitNumberDialog = {onCloseUnitNumberDialog}
                                floorPlan = {selectedFloorPlanData}
                                prices = {selectedPriceData}
                                showDataFor = {showDataFor}
                                onChangeActiveStatus = {onChangeActiveStatus}
                                onUploadFileForFloorPlan = {onUploadFileForFloorPlan}
                                onSaveUnitsTableData = {onSaveUnitsTableData}
                                onDeleteSelectedData = {onDeleteSelectedData}
                                setOpenNewPriceDialog = {setOpenNewPriceDialog}
                                setOpenActivateConfirmationDialog = {setOpenActivateConfirmationDialog}
                                setFloorPlanIdToActivate = {setFloorPlanIdToActivate}
                                setPriceIdToActivate = {setPriceIdToActivate}
                                setOpenDeleteConfirmationDialog = {setOpenDeleteConfirmationDialog}
                            />

                            <AddNewPriceDialog

                                setNewPrice = {setNewPrice}

                                onCloseNewPriceDialog = {onCloseNewPriceDialog}

                                openNewPriceDialog = {openNewPriceDialog}

                                onSaveNewPrice = {onSaveNewPrice}

                            />

                            <ActivateConfirmationDialog

                                onActivateSelectedData={onActivateSelectedData}

                                setOpenActivateConfirmationDialog={setOpenActivateConfirmationDialog}

                                openActivateConfirmationDialog={openActivateConfirmationDialog}
                                
                                showDataFor={showDataFor}

                            />

                            <DeleteConfirmationDialog
                                onDeleteSelectedData={onDeleteSelectedData}
                                setOpenDeleteConfirmationDialog={setOpenDeleteConfirmationDialog}
                                openDeleteConfirmationDialog={openDeleteConfirmationDialog}
                                showDataFor={showDataFor}
                            />

                            <ShareModal 
                                openShareModal={openShareModal}
                                setOpenShareModal={setOpenShareModal}
                                url={currentProjectDetails.threeDUrl}
                            />

                            <ProjectFormDialog                                 

                                setCurrentProjectDetails={setCurrentProjectDetails}

                                setOpenAddProjectDialog={setOpenAddProjectDialog}

                                setOpenDeleteProjectDialog={setOpenDeleteProjectDialog}

                                openAddProjectDialog={openAddProjectDialog} 

                                selectedProject={selectedProject}                                

                            />  

                            <AssignRoleProjectDialog
                            project={currentProjectDetails}

                            setOpenAssignManagerProject={setOpenAssignManagerProject}

                            openAssignManagerProject={openAssignManagerProject} 

                            />
                            <AssignModal 

                                selectedProject={selectedProject} 

                                assignModal={assignModal}

                                setAssignModal={setAssignModal}

                                assignProjectStaff={assignProjectStaff}

                                assignProjectMaster={assignProjectMaster}

                                setSelectedUserProfile={setSelectedUserProfile}

                                setOpenSnackbar={setOpenSnackbar}

                                setSnackbarType={setSnackbarType}

                                setMessageForSnackbar={setMessageForSnackbar}

                            />                              

                            <ProfileDialog 

                                selectedUserProfile={selectedUserProfile} 

                                setSelectedUserProfile={setSelectedUserProfile}

                                setAssignModal={setAssignModal}

                                assignModal={assignModal}

                            /> 

                            <DeleteProjectDialog

                                onCloseDeleteProjectDialogHandler={onCloseDeleteProjectDialogHandler}

                                onDeleteProjectHandler={onDeleteProjectHandler}

                                openDeleteProjectDialog={openDeleteProjectDialog}

                                selectedProject={selectedProject}

                            />

                            <Dialog
                                open={openDeployProjectDialog}
                                onClose={onCloseDeployProjectDialogHandler}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                    Do you want to deploy this project?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onCloseDeployProjectDialogHandler} color="primary" >No</Button>
                                    <Button onClick={() => onDeployProjectHandler(selectedProject.id)} color="primary" autoFocus>Yes</Button>
                                </DialogActions>
                            </Dialog>

                            <SnackbarToast

                                handleSnackbarClose={handleSnackbarClose}

                                openSnackbar={openSnackbar}

                                snackbarType={snackbarType}

                                message={messageForSnackbar}

                            />                            

                        </div>

                    </div>
                    : null
                    // <div className="row">
                    //     You need to upgrade to Premium to view this page.
                    // </div>
                }

                </div>

            </div>

        </div>
    
    )
}

export default Projects
