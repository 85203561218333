import { Button, makeStyles, Tooltip } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons';
import React from 'react'

const useStyles = makeStyles(() => ({

    button: {
        color: "white",
        backgroundColor: "#26A69A",
        '&:hover': {
        backgroundColor: "#26A69A"
        }
    },

    inputFile: {
        display: "none"
    },

    inputFileLabel: {
        marginBottom: "0"
    },

    uploadDocument: {
        display: "inline-block"
    },

    downloadbutton: {
        backgroundColor: "#6D76D3",
        color: "white",
        '&:hover': {
            backgroundColor: "#6D76D3"
        }
    }


}));

function ContactsLeadActions({
    importContactsRef,
    sampleContactsFile,
    onImportLeadsFile,
    onOpenAddNewLeadDialog,
    onOpenAddNewTabDialog
}) {

    const classes = useStyles();

    return (
        <div className="d-flex flex-wrap align-items-center">

            {/* <Tooltip title="Create Group" arrow>
                <AddCircle
                    className="mr-2 mb-3"
                    style={{cursor : "pointer"}}
                />
            </Tooltip> */}

            <Button                               
                className={`${classes.button} mr-2 mb-3`} 
                onClick={onOpenAddNewLeadDialog} 
                variant="contained"
                component="span"
            >
                New Contact

            </Button>

            <div className={classes.uploadDocument}>
        
                <input

                    ref={importContactsRef}
                    onChange={(event) => onImportLeadsFile(event)}
                    accept=".csv, .xlsx"
                    className={`${classes.inputFile} d-none`}
                    id={`upload-leads-file`}
                    type="file"

                />

                <label className={classes.inputFileLabel} htmlFor={`upload-leads-file`}>

                    <Button 
                        className={`${classes.button} mr-2 mb-3`} 
                        variant="contained" component="span"
                    >
                        Import Contacts 
                    </Button>

                </label>                                            

            </div>

            <Tooltip title="To upload contacts in bulk, Please use this template" arrow>
                <a href={`data:text/csv;charset=utf-8,${encodeURI(sampleContactsFile)}`} download={"sample_contact_file.csv"}>
                    <Button                               
                        className={`${classes.downloadbutton} mr-2 mb-3`} 
                        variant="contained"
                        component="span"
                    >
                        Download sample file

                    </Button>
                </a>
            </Tooltip>

            <Button                               
                className={`${classes.button} mr-2 mb-3`} 
                onClick={onOpenAddNewTabDialog} 
                variant="contained"
                component="span"
            >
                New Folder

            </Button>

        </div>
    )
}

export default ContactsLeadActions
