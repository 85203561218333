import React from 'react'

import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    
    uploadDocument: {
        display: "inline-block"
    },

    inputFile: {
        display: "none"
    },

    inputFileLabel: {
        marginBottom: "0"
    },

    uploadButton: {
        color: "white",
        padding: "3px 10px",
        fontSize: "0.7rem",
        backgroundColor:"#26A69A",
        '&:hover': {
            backgroundColor:"#26A69A"
        }

    },

    documentList: {
        height: "200px",
        overflowY: "scroll",
        padding: "0"
    },

    documentDetails: {
        backgroundColor: "ghostwhite",
        paddingTop: "0"
    },

    fileName: {
        fontWeight: 500
    },

    fileSize: {
        marginBottom: 0
    }

}));

function ExcelFileUploader({
    onUploadExcelFileForUnitsData
}) {

    const classes = useStyles();

    return (
        <div className={classes.uploadDocument}>
        
            <input

                onChange={(event) => onUploadExcelFileForUnitsData(event)}

                accept=".xlsx"

                className={`${classes.inputFile} d-none`}

                id={`upload-excel-file`}

                type="file"

            />

            <label className={classes.inputFileLabel} htmlFor={`upload-excel-file`}>

                <Button className={classes.uploadButton} variant="contained" component="span">

                    Upload 

                </Button>

            </label>                                            

        </div>
    )
}

export default ExcelFileUploader
