import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'

import React from 'react'

function DeleteContactsDialog({
    openDeleteContactsDialog,
    setOpenDeleteContactsDialog,
    onDeleteSelectedContacts
}) {

    return (

        <Dialog
        
            open={openDeleteContactsDialog}
        
            onClose={() => setOpenDeleteContactsDialog(false)}
        
            aria-labelledby="alert-dialog-title"
        
            aria-describedby="alert-dialog-description"
        
        >
        
            <DialogContent>
        
                <DialogContentText id="alert-dialog-description">
        
                Do you want to delete selected contacts? This action cannot be
                undone.
        
                </DialogContentText>
        
            </DialogContent>
        
            <DialogActions>
        
                <Button
        
                onClick={() => setOpenDeleteContactsDialog(false)}
        
                color="primary"
        
                >
        
                No
        
                </Button>
        
                <Button
        
                onClick={onDeleteSelectedContacts}
        
                color="primary"
        
                autoFocus
        
                >
        
                Yes
        
                </Button>
        
            </DialogActions>
        
        </Dialog>
    
    )

}

export default DeleteContactsDialog
