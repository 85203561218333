import { AppBar, Dialog, IconButton, List, ListItem, makeStyles, Slide, Toolbar, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, EmailShareButton, EmailIcon} from 'react-share';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#263238',
    paddingRight: "0px !important"
  },
  title: {
    fontSize:"1rem",
    flex: 1,
  }
}));


function ShareModal({
    openShareModal,
    setOpenShareModal,
    url
}) {
    const classes = useStyles();
    return (
        <Dialog maxWidth="sm" open={openShareModal} onClose={() => setOpenShareModal(false)} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar style={{paddingRight:"5px"}}>
                    <Typography variant="h6" className={classes.title}>
                        SHARE 3D URL
                    </Typography>                
                    <IconButton style={{marginLeft: "15px"}} color="inherit" onClick={() => setOpenShareModal(false)} aria-label="close">
                        <Close />
                    </IconButton>            
                </Toolbar>
            </AppBar>
            <List>
                <ListItem className="justify-content-center">
                    <FacebookShareButton
                        className="mr-2"
                        url={url}
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <EmailShareButton
                        className="mr-2"
                        url={url}
                    >
                        <EmailIcon size={32} round />
                    </EmailShareButton>

                     <TwitterShareButton
                        className="mr-2"
                        url={url}
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>

                </ListItem>
            </List>
        </Dialog>
    )
}

export default ShareModal
